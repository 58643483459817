import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import { 
    mapInit, getOccupiedTiles, getTileInfo,  
    attackTile, getDefenseList, defenseTile, resetBlockInfo
} from '../../services/slice-map';


import BlockBasic from './basic';
import BlockTroops from './troops';
import CodDetail from '../cod/detail';
import BlockBtns from './btns';

import { getMapId, getMapConfig, createOccupieds } from '../../maps/map-res'

import { GoBack, ToastTip } from '../../components'
import Modal from 'react-modal';

import Texts from '../../const/texts.json';
import { logTrace } from '../../utils/log'


export default function TilePop(props){
    // console.log('TilePop', props);
    const dispatch = useDispatch(); 
	let { tileInfo } = props;  
    const tileData = tileInfo.data.data || {};
    
    const xyIndex = (tileData.xyIndex || '').split('^');
    const x_id = xyIndex[0]/1;
    const y_id = xyIndex[1]/1;

    const { detail } = useSelector(function(state){
        return state.map;
    });

    logTrace({tileData, detail, x_id, y_id }, 'map.tile.1')

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
   
    const keys = {
        name: 'dialog.map.block_' + detail.type + '_' + (detail.parameter || 0) + '.name',
        buff: 'dialog.staff.skill.' + detail.buff_id
    }

    useEffect(()=>{
        dispatch(getTileInfo({
            x: x_id,
            y: y_id
        }));
    }, [x_id, y_id]);

	let codId = getCodId(tileData);

    const { codsAll } = useSelector(function(state){
        return state.generals;
    });
    logTrace(codsAll, 'map.tile.codsAll')
    // console.log('cod create', codsAll)

    const [currentBlockType, setCurrentBlockType] = useState('Status');

    function getCodId(tileData){
        let xyIndex = tileData.xyIndex || '';
        if(!xyIndex){
            return '';
        }
        return 'block_' + xyIndex.split('^').join('_');
    }

    if(detail.type === 6){
        return (
            <ToastTip text={ Texts[keys.name] } callback={ ()=>{ 
                props.close();
                dispatch(resetBlockInfo());
            } } />
        );
    }


    if(window.LeagueOfThronesConfig.isMobile && detail.type > 0){
        return (
            <Modal
                style = {{
                    overlay: { backgroundColor: 'transparent' },
                    content: {
                        width: '800px',
                        marginLeft: '-400px',
                        height: '440px'
                    }
                }}
                className = { 'App-game-modal-block App-game-modal-gray' }
                isOpen={ true }
                _ga_event="map.block.close"
                onRequestClose = { () => { 
                    props.close && props.close();
                    dispatch(resetBlockInfo());
                } }
                contentLabel="Tile Modal">
                <div className="App-game-modal-block-content" style={{ position: 'relative' }}>
                    <div _ga_event="map.block.close" onClick={ () => {
                        props.close && props.close();
                        dispatch(resetBlockInfo());
                    } }><GoBack size="60px" /></div>
                    <div className="App-game-modal-header">{ Texts[keys.name] || keys.name }({x_id},{y_id})</div>
                    <div className="App-general-logis App-block-types">
                        <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
                            <div 
                                className={ 'App-general-tab-normal ' + (currentBlockType === 'Status' ? 'App-general-tab-hightlight' : '') } 
                                _ga_event={ "general.stats" }
                                onClick={ (e)=> setCurrentBlockType('Status') }>
                                <span>Status</span>
                            </div>
                            <div 
                                className={ 'App-general-tab-normal ' + (currentBlockType === 'Assembly' ? 'App-general-tab-hightlight' : '') } 
                                _ga_event={ "general.strengthen" }
                                onClick={ (e)=> setCurrentBlockType('Assembly') }>
                                <span>Assembly</span>
                            </div>
                        </div>
                    </div>

                    <div className="App-block-panel">
                        {currentBlockType === 'Status' && <div className="App-block-detail">
                            <div className="App-game-modal-content2">
                                <div style={{ display: 'flex' }}>
                                    <BlockBasic detail={ detail } tileData={ tileData } />
                                    <BlockTroops detail={ detail } tileData={ tileData } />
                                </div>
                                <BlockBtns detail={ detail } tileData={ tileData } />
                            </div>
                        </div>}
                        { (currentBlockType === 'Assembly' && codsAll[codId]) && <CodDetail codId={ codId } />}
                    </div>
                </div>
            </Modal>
        )
    }

	return (
		<Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: (codsAll[codId]) ? '1280px' : '800px',
                    marginLeft: (codsAll[codId]) ? '-640px' : '-400px',
                    height: '440px'
                }
            }}
            className = { 'App-game-modal-block App-game-modal-gray' }
            isOpen={  detail.type > 0 && true }
            _ga_event="map.block.close"
            onRequestClose = { () => { 
                props.close()
                dispatch(resetBlockInfo());
            } }
            contentLabel="Tile Modal">
            <div className="App-game-modal-block-content" style={{ position: 'relative' }}>
                <div _ga_event="map.block.close" onClick={ () => {
                    props.close();
                    dispatch(resetBlockInfo());
                } }><GoBack size="60px" /></div>
                <div className="App-game-modal-header">{ Texts[keys.name] || keys.name }({x_id},{y_id})</div>
                <div className="App-block-panel">
                    <div className="App-game-modal-content2">
                        <div style={{ display: 'flex' }}>
                            <BlockBasic detail={ detail } tileData={ tileData } />
                            <BlockTroops detail={ detail } tileData={ tileData } />
                        </div>
                        <BlockBtns detail={ detail } tileData={ tileData } />
                    </div>
                    { codsAll[codId] && <CodDetail codId={ codId } />}
                </div>
            </div>
        </Modal>
	)
}