import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as EthFuns from '../../wallets/ethereum';
import * as DAContract from '../../da/contract';

import * as DASlices from '../../da/slice';

import WalletSelect from '../../wallets/wallet-select';
import Header from './header';
import MyRewards from '../reward-pool/my-rewards';
import SeasonItem from '../season/season'
import CreateSeason from './create'
import { CreateIcon } from '../../components'
import { Texts, Texts2, Chains } from '../../const'

import { logTrace } from '../../utils/log';
import * as cache from '../../utils/cache'

export default function SeasonList(props){
    const dispatch = useDispatch(); 

    const link = window.location.href;
    const isHistorySeason = link.indexOf('?type=end') > -1;

    let address = cache.get('accountCacheKey') || '';
    const [ account, setAccount ] = useState('');
    var chainNameList = ['zeta', 'bsc', 'emerald'];

    const [menuName, setMenuName] = useState('');
    const [chainId, setChainId] = useState('');

    const [currentChainName, setCurrentChainName] = useState(chainNameList[0]);

    let { daSeasons, seasonHis, seasonAll } = useSelector(function(state){
        return state.dauction;
    });
    let seasonList = daSeasons;
    if(isHistorySeason){
        seasonList = seasonHis;
    }
    const [mySeasons, setMySeasons] = useState({});

    var pager = 4;
    if(window.LeagueOfThronesConfig.isMobile){
        pager = 1;
    }
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [pageMap, setPageMap] = useState({});

    function getPagerMap(page){
        let _total = 5;
        let pageMap = {};
        let start = 0;
        if(page > 2){
            start = page - 2;
        }
        if(total - page <= _total){
            start = total - _total;
        }
        // start = Math.min(total - 4, start);
        start = Math.max(0, start);
        for(var i=0; i< _total; i++){
            let index = start + i;
            if(index <= total){
                pageMap[index] = true;
            }
        }
        // console.log({start, page, total, _total}, pageMap);
        return pageMap;
    }
    useEffect(()=>{
        setTimeout(function(){
            let _total = Math.ceil(seasonList.length/pager);
            setTotal(_total);
        }, 0);

        let result = getPagerMap(page);
        setPageMap(result);
    }, [page, seasonList, total]);

    useEffect(()=>{
        setPage(0);
    }, [currentChainName]);

    const [refreshSignUpCount, setRefreshSignUpCount] = useState(0);
    const [selectWalletPop, setSelectWalletPop] = useState(false);

    useEffect(()=>{
        dispatch(DASlices.getHistorySeasons());            
        dispatch(DASlices.getDASeasonList());            
    }, [account, refreshSignUpCount]);

    useEffect(()=>{
        // dispatch(DASlices.getDASeasonList());
        // dispatch(DASlices.getHistorySeasons());
    }, []);

	return(<>
        {link.indexOf('?createSeason=') > -1 && <CreateSeason />}
        <div className="App-season-menus"> 
            <Header isHistorySeason={ isHistorySeason } address={ address } />

            {seasonHis.length > 0 && <div className="App-season-menus-right">
                <MyRewards address={ address } seasons={ seasonHis } onWalletSelect={ ()=>{ 
                    if(address){return}
                    setSelectWalletPop(true) 
                } } />
            </div>}
        </div>

        <div className="App-general-logis" style={{ margin: '10px 0 10px',  width: '100%', textAlign: 'center' }}>
            <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
            {chainNameList.map((name, index)=>
                <div key={ 'chain-' + index } 
                    className={ 
                        'App-general-tab-normal ' 
                        + (currentChainName === name ? 'App-general-tab-hightlight' : '') 
                    } 
                    _ga_event={ "auction.menu.chain." + name }
                    onClick={ (e)=> {
                        if(name !== 'zeta'){
                            return;
                        }
                        setCurrentChainName(name);
                        setMenuName(Chains[name].chainName);
                    } }>
                    <span style={{ margin: '-2px 5px 0 0' }}>
                        <CreateIcon type={ Chains[name].name } size="16" />
                    </span>
                    <span>
                        { Chains[name].isCrossChain ? Chains[name].chainName : Chains[name].chainName }
                    </span>
                </div>
            )}
            </div>
        </div>

        {selectWalletPop && <WalletSelect seasonBasicInfo={ {} } 
            onSelect={ async(info)=>{ 
                let { wallet } = info;
                let res = await EthFuns.setWallet(wallet);
                await EthFuns.connect();
                EthFuns.login({}, function(res){
                    if(!res.account){
                        return;
                    }
                    setAccount(res.account);
                    cache.set('accountCacheKey', res.account)
                    setSelectWalletPop(false);
                });
                await DAContract.init(seasonAll[0], wallet);
            } }
            onClose={ ()=>{ setSelectWalletPop(false) } }
        />}

        <div className="App-season-list">
            {[...Array(pager)].map((x, index) => {
                let realIndex = page*pager + index;
                let seasonInfo = seasonList[realIndex];
                return <div style={{ display: 'inline' }} key={  index }>
                {seasonInfo && <SeasonItem 
                onLogin={ (account)=>{ 
                    if(!account){ return; }
                    setAccount(account); 
                    cache.set('accountCacheKey', account);
                } }
                onCountFresh={ ()=>{
                    setRefreshSignUpCount(new Date().getTime()); 
                } } seasonBasicInfo={ seasonInfo } chain={ {
                    chainId: chainId, 
                    name: currentChainName
                } } />}</div>
                }
            )}

            {seasonList.length > pager && <div className="App-gallery-index chain-seasons-pages">
                <div
                    _ga_event={ "auction.season.list.page.prev" }
                    onClick={ ()=>{
                        let current = page - 1;
                        if(current < 0){
                            current = total - 1;
                        }
                        setPage(current);
                    }} 
                    className="App-gallery-index-prev">
                    prev
                </div>
                {[...Array(total)].map((x, index) =>
                    <span 
                        _ga_event={ "auction.season.list.page.number" }
                        onClick={ ()=>{ setPage(index); } }
                        style={{ display: (pageMap[index] ? 'inline-block': 'none') }}
                        className={ index === page ? 'normal current' : 'normal' } 
                        key={ 'page-' + index }>
                        { index + 1 }
                    </span>
                )}
                <div 
                    onClick={ ()=>{
                        let current = page + 1;
                        if(current >= total){
                            current = 0;
                        }
                        setPage(current);
                    }}
                    _ga_event={ "auction.season.list.page.next" }
                    className="App-gallery-index-next">
                    next
                </div>
            </div>}
        </div>
    </>);
}