import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSeasonConfig } from '../../services/slice-map';
import { getShortUrl } from '../../services/api';

import { CreateIcon, GoBack } from '../../components'
import { NotifyPop } from '../../components'

import { Texts2, Chains } from '../../const/';
import { logTrace } from '../../utils/log'
import { subs } from '../../utils/utils'

import './share.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SnShare(props) {
    let dispatch = useDispatch();

    const { seasonDetail, sign } = useSelector(function(state){
        return state.user;
    });
    let currentSeasonId = sign.seasonId;
    let currentSeasonDetail = seasonDetail[currentSeasonId] || {};
    let seasonBasicInfo = currentSeasonDetail['seasonBasicInfo'] || {};
    let rewardToken = Chains[seasonBasicInfo.chain].reward;
    const amount = seasonBasicInfo.reward_amount_1/1 + seasonBasicInfo.reward_amount_2/1;

    const [sharePop, setSharePop] = useState(true);
    const [shortUrlReady, setShortUrlReady] = useState(false);

    let { params, facebook, twitter, content } = props;
    params.amount = amount;
    params.token = rewardToken;
    console.log({ params, facebook, twitter, content });

    function fbShare(){
        let link = 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(subs(facebook, params));
        window.open(link);
        props.onShare && props.onShare({platform: 'facebook'});
    }
    function twitterShare(){
        let link = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(subs(twitter, params));
        window.open(link);
        props.onShare && props.onShare({platform: 'twitter'});
    }

    useEffect(()=>{
        getShortUrl({
            url: params.prod
        }, function(res){
            if(res.code === 0){
                params.appLink = res.short_url;
            }else{
                params.appLink = params.prod;
            }
            setShortUrlReady(true);
        });
    }, [params.prod]);

    return (<>
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '540px',
                    marginLeft: '-270px',
                    height: '320px',
                    marginTop: '-150px'
                }
            }}
            className = { 'App-game-modal-share App-game-modal-gray' }
            isOpen={ shortUrlReady && sharePop }
            contentLabel="SNS SHARE">
            <div style={{ position: 'relative' }}>
                <div _ga_event="share.close" onClick={ ()=>{ setSharePop(false); props.onClose && props.onClose() } }>
                    <GoBack size="60px" />
                </div>

                <div className="App-game-modal-header" style={{ lineHeight: '2rem' }}>{ Texts2['dialog.share.title'] }</div>
                { content }
                <div className="App-sns-share-btns">
                    <span 
                        _ga_event="share.twitter"
                        onClick={ ()=>{
                            twitterShare();
                        } } 
                        className="App-btn-twitter">
                        { Texts2['dialog.share.btn.twitter'] }
                    </span>
                    <span 
                        _ga_event="share.facebook"
                        onClick={ ()=>{
                            fbShare(); 
                        } } 
                        className="App-btn-facebook">
                        { Texts2['dialog.share.btn.facebook'] }
                    </span>
                </div>
            </div>
        </Modal>
    </>);
}