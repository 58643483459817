import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter, HashRouter,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";

import { throneInit } from './services/throne.init';
import { cityInit, resourceUpdate } from './services/slice-city';
import { generalInit, getDefenseCityGeneralId, generalListUpdated } from './services/slice-general';
import { mapInit, getSeasonInfo, getSeasonRankResult } from './services/slice-map';
import { strategyInit, pointUpdate } from './services/slice-strategies';
import { chainInit } from './services/slice-chain';
import { getAllPlayersGeneralPortraits } from './services/slice-signup';

import { isProd } from './utils/ui-res'

import SeasonAll from './features/season-list/season-all';

import { MainMap } from './features/map/map';
import ToolBar from './features/toolbar/toolbar';
import ResourceTips from './features/resource/resource'
import CurrentUser from './features/players/current-user';
import ChatBoxBtn from './features/chat/chat-btn'
import RecentEvents from './features/events/events'
import ChargeCoin from './features/charge/charge-coin';
import UnionBonus from './features/unions/bonus';
import Offers from './features/offers/offers';

import { DisConnect, Maintenancing } from './components'
import HelpPanel from './features/help/help'
import GuideBook from './features/help/guidebook'

import { getMapId } from './utils/ui-res'
import { logTrace } from './utils/log'
import * as cache from './utils/cache'

function GameApp() {
    const dispatch = useDispatch(); 

    const { appReady, version, currentUser } = useSelector(function(state){
        return state.app;
    });
    const { currentSeasonId, seasonDetail, sign } = useSelector(function(state){
        return state.user;
    });
    let seasonId = currentSeasonId;
    let currentSeasonDetail = seasonDetail[seasonId] || {};
    logTrace({ currentUser, currentSeasonDetail, seasonDetail, sign, seasonId }, 'app.init');

    const { mapReady, seasonRank } = useSelector(function(state){
        return state.map;
    });
    logTrace({ mapReady, seasonRank }, 'app.init.map');

    const { cityReady, resources, recruitResult } = useSelector(function(state){
        return state.city;
    });
    logTrace({ cityReady, resources, recruitResult }, 'app.init.city');

    const { generalReady } = useSelector(function(state){
        return state.generals;
    });
    logTrace({ generalReady }, 'app.init.generals');

    const { strategyReady } = useSelector(function(state){
        return state.strategies;
    });

    const { chainReady } = useSelector(function(state){
        return state.chain;
    });

    // logTrace({ defenseCityGenerals, isReady, resources, recruitResult }, 'map.init in app');

    const [wsErr, setWsErr] = useState({});
    const [disconnect, setDisconnect] = useState(false);
    const [isMapShow, setIsMapShow] = useState(false);
    const [isZetaShow, setIsZetaShow] = useState(false);

    useEffect(()=>{
        if(!isProd){
            document.title += ' ' + window.LeagueOfThronesConfig.version + ' ' + version;            
        }
    }, []);

    useEffect(()=>{
        // after enter game, begin to init game
        if(sign.ready){
            let params = { 
                seasonId: seasonId,
                username: sign.address,
                // mapId: getMapId(currentSeasonDetail),
                wsurl: sign.wsurl,
                wsCloseCallback: function(res, status, type){
                    console.log('reconnect wsCloseCallback', {res, status, type});
                    setWsErr(res);
                    setDisconnect(status === 0);
                }
            };
            dispatch(throneInit(params));
        }
    }, [sign.ready, disconnect]);

    useEffect(()=>{
        if(appReady){
            dispatch(mapInit());
            dispatch(generalInit());
            dispatch(cityInit());
            dispatch(strategyInit());
            dispatch(chainInit());
        }
    }, [appReady]);

    useEffect(()=>{
        if(mapReady){
            dispatch(getSeasonInfo());
        }
        if(generalReady){
            dispatch(generalListUpdated());
        }
        if(cityReady){
            dispatch(resourceUpdate());
        }
        if(strategyReady){
            dispatch(pointUpdate());
        }
        dispatch(getAllPlayersGeneralPortraits({ seasonId }));
        logTrace({mapReady, generalReady, cityReady}, 'app.init.components');
    }, [mapReady, generalReady, cityReady, strategyReady]);

    // console.log({signReady: sign.ready, disconnect});
    // console.log({appReady, mapReady, generalReady, cityReady, strategyReady});

    if(!appReady || !mapReady || !generalReady || !cityReady || !strategyReady || !chainReady){
        return (<>
            <SeasonAll over={ (data)=>{
                cache.set('LoginUserUnionId', data.unionId);
            } } />
            {disconnect && (<DisConnect wsErr={ wsErr } refresh={ (reconnect)=>{
                setTimeout(function(){
                    window.location.reload(true);
                }, 5);
            }} />)}
            <Maintenancing />
        </>)
    }

    return (
        <div className="App" id="App">
            <MainMap onShow={ ()=>{ setIsMapShow(true) } } />
            
            {
                isMapShow && <>
                    <ToolBar 
                        showZetachain={ (flag)=>{ setIsZetaShow(flag) } }
                        resource={ resources } seasonId={ sign.seasonId } 
                        seasonInfo={ currentSeasonDetail } seasonBasicInfo={ currentSeasonDetail.seasonBasicInfo }  
                    />
                    <ResourceTips currentUser={ currentUser } />
                    <CurrentUser />
                    <ChargeCoin />
                    <RecentEvents isZetaShow={ isZetaShow } />
                    <ChatBoxBtn currentUser={ currentUser } />
                    <GuideBook />
                    <UnionBonus />
                    <Offers />

                    <HelpPanel />
                    
                    {disconnect && (<DisConnect wsErr={ wsErr } refresh={ (reconnect)=>{
                        cache.set('userInfo', currentSeasonDetail, true);
                        window.location.reload(true);
                    }} />)}
                    <Maintenancing />
                </>
            }

        </div>
    );
}

export default GameApp;
