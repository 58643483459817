import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { cityInit, getAbleActivityInfo, removeActivityNew, donateSilver } from '../../services/slice-city'

import { actImagePath } from '../../utils/ui-res'
import { numberFormat, timeFormat, dateFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log';
import { GoBack, CreateIcon, ClockCounter } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import './activity.scss';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ActivityPop(props){
    const dispatch = useDispatch(); 
    const { seasonDetail } = useSelector(function(state){
        return state.user;
    });
    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const currentSeasonDetail = seasonDetail[currentSeasonId] || {};
    const seasonBasicInfo = currentSeasonDetail['seasonBasicInfo'] || {};

    const { cityReady, activities, donateResult } = useSelector(function(state){
        return state.city;
    });
    const [amount, setAmount] = useState(0);
    const [actIndex, setActIndex] = useState(0);
    const [removeRedPoint, setRemoveRedPoint] = useState(0);

    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    function chooseActivity(index){
        setActIndex(index);
    }

    useEffect(()=>{
        dispatch(getAbleActivityInfo(seasonBasicInfo));
        activities.forEach(function(item){
            if(item.redPoint){
                dispatch(removeActivityNew(item));
            }
        });
        setRemoveRedPoint(1);
    }, [cityReady, donateResult]); 

    useEffect(()=>{
        setTimeout(function(){
            dispatch(getAbleActivityInfo(seasonBasicInfo));
        }, 10);
    }, [removeRedPoint]); 
    // console.log('activities', activities, activities[actIndex]);

    return(<>
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    height: '340px',
                    width: '900px',
                    margin: '-150px 0 0 -450px'
                }
            }}
            className = { 'App-game-modal-activity App-game-modal' }
            isOpen={ true }
            _ga_event="strategy.close"
            onAfterOpen={  ()=>{ return; }  }
            onRequestClose={ ()=>{ props.onClose() } }
            contentLabel="Activities Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event="activity.close" onClick={(e)=> { props.onClose() }} style={{ position: 'absolute', top: '10px', right: '20px', zIndex: 999 }}>
                    <GoBack size="60px" />
                </div>
                <div className="App-general-teams" style={{ margin: '-20px 0 -25px', background:'none' }}>
                    <div className="App-general-types">
                        <div className="App-general-tab-normal App-general-tab-hightlight">
                            <CreateIcon type="general" size="48" />
                            <div style={{ color: '#fff' }}>{ 'ACTIVITY' }</div>
                        </div>
                    </div>
                </div>
                <div className="App-activities">
                    <div className="App-activitiy-list">
                    {activities.map((item, index)=>
                        <div className={"App-activitiy-item" + (actIndex === index ? ' current' : '')} key={ index } onClick={ ()=>chooseActivity(index) }>
                            {item.redPoint && <span className="App-redpoint" style={{ top: '-3px', right: '-3px', zIndex: '999' }}>new</span>}
                            <img src={ actImagePath(item.type).banner } className="banner" />
                            <div className="App-game-season-clockwise" style={{ width:'80%', marginLeft: '20%', backgroundColor: 'RGBA(168, 73, 8, 1)' }}>
                                <span className="time">{ Texts2[ 'dialog.activity.name.' + item.type] }</span>
                            </div>
                            {item.status === 'closed' 
                            ? <div className="closed">{ Texts2['dialog.closed'] }</div>
                            : <>
                                <div className="openning">{ Texts2['dialog.' + item.status] }</div>
                                <span className="time">
                                    { Texts2['dialog.countdown.activity.' + item.status] } &nbsp;
                                    <ClockCounter time={ item.remainTime } />
                                </span>
                            </>}
                        </div>
                    )}
                        <div style={{ height: '50px' }}></div>
                    </div>
                    {activities.length > 0 && (<div className="App-activitiy-main">
                        <div className="App-activitiy-detail">
                            <img src={ actImagePath(activities[actIndex].type).icon } className="icon" />
                            <div className="title">
                                { Texts2[ 'dialog.activity.name.' + activities[actIndex].type] }
                            </div>
                            <div className="App-game-season-clockwise" style={{ width: '200px' }}>
                                {activities[actIndex].status !== 'closed' ? (<span className="time">
                                    { Texts2['dialog.countdown.activity.' + activities[actIndex].status] } &nbsp;
                                    <ClockCounter time={ activities[actIndex].remainTime + 0 } />
                                </span>) : (<span className="time">
                                    { Texts2['dialog.closed'] } in &nbsp;
                                    { dateFormat(activities[actIndex].endTime/0.001, 'yyyy/MM/dd hh:mm') }
                                </span>)}
                            </div>
                            <div className="pool">
                                <CreateIcon type="coin" size="36" />
                                <label className="label">{ Texts2['button.daily_activity.pool'] }</label>
                                <span className="number">{ numberFormat(activities[actIndex].totalReward) }</span>
                            </div>
                            <div className="desc">{ Texts2[ 'dialog.activity.information.' + activities[actIndex].type] }</div>
                        </div>

                        <div className="App-activitiy-indicators">
                            <div className="App-activitiy-indicator">
                                <label>{ Texts2['dialog.your_rank'] }</label>
                                {
                                    (activities[actIndex].rank === -1 || activities[actIndex].rank >= 200) ? 
                                    (<span>{ Texts2['dialog.no_rank'] }</span>) :
                                    (<span>{ activities[actIndex].rank }</span>)
                                }
                            </div>
                            <div className="App-activitiy-indicator">
                                { activities[actIndex].type === 2 
                                    ? (<label>{ Texts2['dialog.recruit_troops'] }</label>)
                                    : (<label>{ Texts2['dialog.season.activity.desc.' + activities[actIndex].type] }</label>)
                                }
                                <span>
                                    { numberFormat(activities[actIndex].value) }
                                    { activities[actIndex].type !== 3 && '/h' }
                                </span>
                            </div>
                            <div className="App-activitiy-indicator">
                                <label>{ Texts2['dialog.expect_reward'] }</label>
                                <span>{ numberFormat(activities[actIndex].rankReward) }</span>
                            </div>
                        </div>

                        {(activities[actIndex].type === 3 && activities[actIndex].status === 'opening') && (<div className="App-activitiy-donate">
                            <input type="number" value={ amount } onChange={ (e)=> { setAmount(e.target.value) }} />
                            <div className="silver">
                                <CreateIcon type="silver" size="24" />
                            </div>
                            {amount/1 === 0 ? (<span 
                                _ga_event="activity.donate.disable"
                                onClick={ ()=>{
                                    setNotifyPop(true);
                                } } 
                                className="App-general-btn App-general-btn-blue App-btn-disable">
                                { Texts2['button.donate'] } 
                            </span>) : (<span className="App-general-btn App-general-btn-blue"
                                _ga_event="activity.donate"
                                onClick={ ()=>{
                                    setWaitingPop(true);
                                    dispatch(donateSilver({
                                        activityId: activities[actIndex].activityId,
                                        amount: amount/1 || 0
                                    }))
                                }}>
                                { Texts2['button.donate'] } 
                            </span>)}
                        </div>)} 

                        { notifyPop && <ToastTip text={ 
                            Texts['dialog.activity.no_silver_donate'] 
                        } callback={ ()=>{ setNotifyPop(false) } } />}

                        { waitingPop && <NotifyPop tips={{
                            loading: '',
                            success: Texts2['toaster.donate_success'],
                            fail: Texts2['toaster.donate_fail']
                        }} data={ donateResult } callback={ ()=>{
                            setWaitingPop(false);
                            dispatch(getAbleActivityInfo(seasonBasicInfo));
                        }} />}
                    </div>)}
                </div>
            </div>
        </Modal>
    </>);
}