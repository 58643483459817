import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Ranks, CreateIcon } from '../../components'
import { Texts, Texts2, Chains, RewardCoins } from '../../const/';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SeasonReward(props){
    let { seasonId, seasonInfo, account } = props;

    const { seasonDetail, currentSeasonId } = useSelector(function(state){
        return state.user;
    });
    let currentSeasonDetail = seasonDetail[currentSeasonId || seasonId] || {};
    let seasonBasicInfo = currentSeasonDetail['seasonBasicInfo'] || {};
    const chainName = seasonBasicInfo.chain;
    const reward_token_addr = (seasonBasicInfo['reward_token_addr'] || '').toLowerCase();
    const rewardSymbol = (RewardCoins[reward_token_addr] || {}).reward || (Chains[chainName] || {}).reward || '';


    let SeasonResult = (seasonInfo.SeasonInfo || {}).SeasonResult || {};
    let unionRewardResult = SeasonResult.unionRewardResult || [];
    let gloryRewardResult = SeasonResult.gloryRewardResult || [];
    const [ rewardPop, setRewardPop ] = useState(false);

    let rankInfo = {};
    gloryRewardResult.forEach(function(item, index){
        if(item.username === account){
            let info = {...item};
                info.rank = index + 1;
            rankInfo = info;
        }
    });

    return (<div className="App-season-personal-reward-wrapper">
        {rankInfo.rank && <div className="App-season-personal-reward">
            <span className="App-season-personal-reward-rank">
                {rankInfo.rank < 4 && <span className={"App-season-personal-reward-rank-index " + ('App-personal-rank-index' + rankInfo.rank)}>
                    { rankInfo.rank }
                </span>}
                <span className="App-season-personal-reward-rank-number">
                    No.{ rankInfo.rank }
                </span>
            </span>

            <span style={{ position: 'relative', top: '-3px' }}>
                <CreateIcon type={ rewardSymbol } size="22" />
                <span style={{ display: 'inline-block', marginLeft: '2px', paddingTop: '5px'}}>x{ rankInfo.count }</span> 
            </span>
        </div>}
        <span 
            _ga_event="season.reward.open"
            onClick={ (e)=>{
                setRewardPop(true);
            } } 
            style={{ marginRight: 0 }}
            className="App-general-btn App-general-btn-blue">
            { Texts['dialog.rank'] }
        </span>
        {rewardPop && <RankPop 
            close= { (e)=>{ setRewardPop(false); } }
            datas={{
                seasonId,
                unionRewardResult,
                gloryRewardResult
            }} 
        />}
    </div>);
}

function RankPop(props){
    let { seasonId, unionRewardResult, gloryRewardResult } = props.datas;
    let types = ['CONQUEST', 'GlORY RANKING'];
    const [currentType, setCurrentType] = useState(types[1]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                height: '500px'
            }
        }}
        className = { 'App-game-modal-reward App-game-modal-gray' }
        isOpen={ true }
        onRequestClose = { () =>{ props.close() } }
        contentLabel="Preview Map Modal">
        <div style={{ position: 'relative' }}>
            <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>Reward</div>
            <div className="App-general-logis" style={{ margin: '10px 0 0',  width: '100%', textAlign: 'center' }}>
                <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
                {types.map((item)=>
                    <div key={ item } 
                        className={ 'App-general-tab-normal ' + (currentType === item ? 'App-general-tab-hightlight' : '') } 
                        _ga_event={ "season.reward." + item }
                        onClick={ (e)=> setCurrentType(item) }>
                        <span>{ item }</span>
                    </div>
                )}
                </div>
            </div>
            {currentType === 'CONQUEST' && <Ranks seasonId={ seasonId } data={ unionRewardResult } />}
            {currentType === 'GlORY RANKING' && <Ranks seasonId={ seasonId } data={ gloryRewardResult } />}
        </div>
    </Modal>);
}