import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getServerStatus } from '../services/throne.init';

import CreateIcon from './icons';
import { Texts, Texts2 } from '../const/';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Maintenancing(props){
    const dispatch = useDispatch();
    const { wsErr = {} } = props;

    const { maintenanceInfo } = useSelector(function(state){
        return state.app;
    });
    // console.log(maintenanceInfo);

    function reloadPage(){
        if(maintenanceInfo.isMaintenancing){
            return;
        }
        window.location.reload(true);
    }

    useEffect(()=>{
        // let t = setInterval(function(){
        //     dispatch(getServerStatus())
        // }, 25000);
        // return ()=>{
        //     clearInterval(t);
        // }
    }, []);

    return (<></>)
        
    if(!maintenanceInfo.isMaintenancing){
        return (<></>)
    }

	return(<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 999999999999 },
            content: {
                width: '440px',
                marginLeft: '-220px',
                height: '300px',
                marginTop: '-130px'
            }
        }}
        className = { 'App-game-modal-gray App-game-modal-mantance' }
        isOpen={ true }
        onRequestClose = { () => reloadPage() }
        contentLabel="Maintenancing">
        <div style={{ position: 'relative' }} onClick={ () => reloadPage() }>
            <div className="App-game-modal-header" style={{ lineHeight: '2rem' }}>
                { Texts2['dialog.maintenance.title'] }
            </div>
            <div className="App-sns-share" style={{ marginTop: '3rem' }}>
                <div className="intro">{ maintenanceInfo.reason || Texts2['dialog.maintenance.desc'] }</div>
            </div>
        </div>
    </Modal>);
}