import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { pointUpdate } from '../../services/slice-strategies'
import { numberFormat, subs } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import { CreateIcon, GoBack, LogisIcon, Stars, PersonNumber } from '../../components'
import Texts from '../../const/texts.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ProtectWarning(props){
    const dispatch = useDispatch(); 

    const [pop, setPop] = useState(true);

    function afterOpenModal(){}

    return (
        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'transparent'
                },
                content: {
                    height: '300px',
                    marginTop: '-150px'
                }
            }}
            className = { 'App-game-modal-gray-headless' }
            isOpen={ pop }
            onAfterOpen={ afterOpenModal }
            onRequestClose={ ()=>afterOpenModal(false) }
            contentLabel="Protect Warning">
            <div className="App-battle-modal-protect" style={{ position:'relative' }}>
                <div style={{ textAlign:'center', padding: '90px 20px 40px' }}>
                    { Texts['dialog.attack.be_protect.desc'] }
                </div>

                <div style={{ textAlign: 'center' }}>
                    <span className="App-general-btn App-general-btn-green"
                        onClick = { (e) => { 
                            props.callback(true);
                        }}>
                        Attack
                    </span>
                    
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <span className="App-general-btn App-general-btn-green"
                        onClick = { (e) => { 
                            props.callback(false);
                        }}>
                        { Texts['popup.map_defense_cancel'] }
                    </span>
                </div>
            </div>
        </Modal>
    );
}