import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getRecentWorldBattleRecords, getNewWorldRecords, removeNewWorldRecords } from '../../services/slice-general'
import { refreshTransactions, clearNewTransactions } from '../../services/slice-chain'

import { subs } from '../../utils/utils'
import { getNickName, shortString } from '../../utils/ui-res'
import UserCard from '../players/user-card';
import PlayerPop from '../players/player-pop';

import { noticeTpls } from '../../const/transaction-types';
import { Texts, OfferTexts, transactionLink } from '../../const';
import './events.scss';

import Modal from 'react-modal';
Modal.setAppElement('#root');

function getUniqEvent(list){
    let eventIds = {};
    let newList = [];
    list.forEach(function(item){
        if(!eventIds[item.id]){
            newList.push(item);
            eventIds[item.id] = true;
        }
    })
    return newList;
}

export default function RecentEvents(props){
    const dispatch = useDispatch();

    const { isZetaShow } = props;

    const [isZetaHL, setIsZetaHL] = useState(false);
    const [type, setType] = useState('battle');
    const [user, setUser] = useState({});
    const [recordsPop, setRecordsPop] = useState(false);

    const { recentWorldBattleRecords, newWorldRecords } = useSelector(function(state){
        return state.generals;
    });
    const { transactions, newTransactions, zetaTransactions } = useSelector(function(state){
        return state.chain;
    });
    const supportTxTypes = [49];

    useEffect(()=>{
        setIsZetaHL(zetaTransactions.length > 0);
    }, [zetaTransactions.length]);

    useEffect(()=>{
        setRecordsPop(isZetaShow);
        if(isZetaShow){
            setType('zeta');
        }
    }, [isZetaShow]);

    useEffect(()=>{
        // if(recentWorldBattleRecords.length === 0 && transactions.length > 0){
        //     setType('other');
        // }

        let t1 = setInterval(function(){
            dispatch(refreshTransactions());
            dispatch(getRecentWorldBattleRecords());
            dispatch(getNewWorldRecords());
        }, 1000);
        
        return ()=>{
            clearInterval(t1);
        }
    }, [dispatch, recentWorldBattleRecords, transactions]);

    useEffect(()=>{
        if(newTransactions.length  === 0){
            return;
        }
        let t2 = setTimeout(function(){
            dispatch(clearNewTransactions());
            dispatch(removeNewWorldRecords());
        }, 10*1000);
    }, [dispatch, newTransactions, newWorldRecords]);

    // console.log('recentWorldBattleRecords', {recordsPop, isZetaHL}, newTransactions, newWorldRecords, zetaTransactions, newWorldRecords, transactions);
    if(!recordsPop && (newTransactions.length > 0 || newWorldRecords.length > 0 || zetaTransactions.length > 0)){
        return (<>
            <div className="App-world-records-notify-wrapper" style={{
                height: Math.min(newWorldRecords.length +  newTransactions.length + zetaTransactions.length, 4)*70 + 'px'
            }}>
            <div className="App-world-records-notify" _ga_event="tx_record.open" onClick={ ()=>{
                    setRecordsPop(true);
                    dispatch(clearNewTransactions());
                    dispatch(removeNewWorldRecords());
                }}>
                <div _ga_event="tx_record.battle.open" onClick={ ()=>{ setType('battle') } }>
                    {getUniqEvent(newWorldRecords).map((item, index)=>
                        <RecentWorldBattleRecord item={ item } key={ index } onUserPop={ (data)=>{
                            return;
                        } } />
                    )}
                </div>
                <div _ga_event="tx_record.other.open" onClick={ ()=>{ setType('other') } }>
                    {newTransactions.map((item, index)=>
                        (item.TransID.length > 3 && item.Data && item.Data.txType && supportTxTypes.indexOf(item.Data.txType) === -1) && <RecentTransaction type="other" item={ item } key={ index } onUserPop={ (data)=>{
                            setUser(data);
                        } } />
                    )}
                </div>
                <div className="App-world-records-zeta" _ga_event="tx_record.zeta.open" onClick={ ()=>{ setType('zeta'); setIsZetaHL(false); } }>
                    {zetaTransactions.map((item, index)=>
                        <RecentTransaction type="zeta" item={ item } key={ index } onUserPop={ (data)=>{
                            setUser(data);
                        } } />
                    )}
                </div>
            </div>
            </div>
            {!recordsPop && <div className="App-world-records-fold" _ga_event="tx_record.open" onClick={ ()=>{
                setRecordsPop(true);
                } }>
                {isZetaHL && <span className="App-redpoint" style={{ top: '-3px', left: '67px', zoom: '2' }}>zeta transaction</span>}
                close
            </div>}
        </>);
    }

    return (<>
        {!recordsPop && <><div className="App-world-records-fold" _ga_event="tx_record.open" onClick={ ()=>{
                setRecordsPop(true);
            } }>
            {isZetaHL && <span className="App-redpoint" style={{ top: '-3px', left: '67px', zoom: '2' }}>zeta transaction</span>}
            close
            </div>
        </>}
        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'transparent'
                },
                content: {
                    right: '-10px',
                    left: 'auto',
                    width: '400px',
                    height: '500px',
                    marginTop: '-250px'
                }
            }}
            className = { 'App-game-modal-events App-game-modal-chain-headless' }
            isOpen={ recordsPop }
            onRequestClose={ ()=>{ setRecordsPop(false);} }
            contentLabel="Battles Recent">
            <div className="App-world-records-unfold" _ga_event="tx_record.close" onClick={ ()=>{
                setRecordsPop(false);
            } }>close</div>

            <div className="App-world-records-main">
                <div className="App-general-logis" style={{ 
                    marginTop: '25px', 
                    width: '95%', 
                    display: 'block'
                }}>
                <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
                    <div 
                        className={ 'App-general-tab-normal ' + (type === 'battle' ? 'App-general-tab-hightlight' : '') }
                         _ga_event="tx_record.battle.open" 
                        onClick={ (e)=> setType('battle') }>
                        <span>Battle</span>
                    </div>
                    <div 
                        className={ 'App-general-tab-normal ' + (type === 'other' ? 'App-general-tab-hightlight' : '') } 
                         _ga_event="tx_record.other.open"
                        onClick={ (e)=> setType('other') }>
                        <span>Other</span>
                    </div>
                    <div 
                        className={ 'App-general-tab-normal ' + (type === 'zeta' ? 'App-general-tab-hightlight' : '') } 
                         _ga_event="tx_record.zeta.open"
                        onClick={ (e)=> {setType('zeta'); setIsZetaHL(false); } }>
                        <span>zeta</span>
                        {isZetaHL && <span className="App-redpoint" style={{ top: 0, left: '13px' }}>zeta transaction</span>}
                    </div>
                </div>
                </div>

                <div style={{ height: '10px' }}></div>

                <div className="App-world-records-list">
                    {type === 'battle' && (<>
                        {getUniqEvent(recentWorldBattleRecords).map((item, index)=>
                            <RecentWorldBattleRecord type={ type } item={ item } key={ index } onUserPop={ (data)=>{
                                setUser(data);
                            } } />
                        )}
                    </>)}
                    {type === 'other' && (<>
                        {transactions.map((item, index)=>
                            (item.TransID.length > 3 && item.Data && item.Data.txType && supportTxTypes.indexOf(item.Data.txType) === -1) 
                            && <RecentTransaction type={ type } item={ item } key={ index } onUserPop={ (data)=>{
                                setUser(data);
                            } } />
                        )}
                    </>)}
                    {type === 'zeta' && (<>
                        {zetaTransactions.map((item, index)=>
                            <RecentTransaction type={ type } item={ item } key={ index } onUserPop={ (data)=>{
                                setUser(data);
                            } } />
                        )}
                    </>)}

                    {user.pop && <PlayerPop userInfo={ user.info } onClose={()=>{
                        setUser({pop: false});
                    }} />}
                </div>
            </div>
        </Modal>
    </>);
}

function RecentWorldBattleRecord(props){
    let item = props.item;

    const { allPlayers } = useSelector(function(state){
        return state.user;
    });

    return (
        <div className="App-world-record-item">
            <div _ga_event="tx_record.player.open" onClick={ ()=>{
                    props.onUserPop({
                        pop: true,
                        info: item.attackInfo
                    });
                }}><UserCard userInfo={{
                username: item.attackInfo.username,
                avatar: item.attackInfo.iconId,
                unionId: item.attackInfo.unionId
            }} /></div>
            <div className="App-world-record-item-text">
                <span className="App-link" _ga_event="tx_record.player.open" onClick={ ()=>{
                    props.onUserPop({
                        pop: true,
                        info: item.attackInfo
                    });
                }}>{ shortString(getNickName(allPlayers, item.attackInfo.username), 10) }</span> 
                {item.recordType === 'spy' ? 'spy' :  'attacked' }
                {(item.recordType === 'city' || item.recordType === 'spy') && <> <span className="App-link" _ga_event="tx_record.player.open" onClick={ ()=>{
                    props.onUserPop({
                        pop: true,
                        info: item.defenseInfo
                    });
                }}>{ shortString(getNickName(allPlayers, item.defenseInfo.username), 10) }</span>`s city </>} 
                { (item.recordType === 'block' || item.recordType === 'assembly') &&  <> block({item.blockInfo.x_id},{item.blockInfo.y_id}) </>}
                 and { item.result ? 'success.' : 'fail.' }
                <a href={ transactionLink + item.txHash } rel="noreferrer" target="_blank" className="App-transaction-link">transaction</a>
            </div>
        </div>
    );
}

function RecentTransaction(props){
    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { userAvatar } = useSelector(function(state){
        return state.generals;
    });
    const { allPlayers } = useSelector(function(state){
        return state.user;
    });

    const userInfo = {
        username: currentUser.username,
        avatar: userAvatar,
        iconId: userAvatar,
        unionId: currentUser.unionId
    };

    const item = props.item;
    const Data = item.Data || {};
    const { type } = props;
    const zetaLink = 'https://explorer.zetachain.com/evm/tx/'; 
    // console.log('props', props)

    //new: 8, 25, 26, 32

    function transactionAdopter(data){
        let detail = data.Data || {};
        let levelTo = detail.levelTo || 0;
        let orderNames = {
            '27': 'Get silver coins',
            '28': 'Get troops',
            '29': 'Improve morale',
            '30': '8 Hour Protection',
            '31': 'Temporary Store',
            '39': '1 Hour Protection'
        };

        let offer_id = (detail.data || {}).offer_id || '';
        let offer_name = OfferTexts['dialog.offer.name.' + offer_id] || '';
                  
        return {
            player_name: '',
            building_name: detail.facility,
            building_level: levelTo,
            troops_count: detail.amount,
            offer_id: offer_id,
            offer_name: offer_name,
            general_name: Texts['dialog.general.card.name.' + detail.generalId],
            general_level: levelTo,
            skill_name: Texts['dialog.general.skill.' +  (detail.skillIndex + 1) + '.name'],
            skill_level: levelTo,
            gold: '',
            order_name: orderNames[detail.txType] || ''
        }
    }

    return (
        <div className={"App-world-record-item txType-" + Data.txType}>
            <div _ga_event="tx_record.player.open" onClick={ ()=>{
                    props.onUserPop({
                        pop: true,
                        info: userInfo
                    });
                }}><UserCard userInfo={ userInfo } /></div> 
             { type === 'zeta' ?<div className="App-world-record-item-text" style={{ paddingTop: '15px' }}>
                <span className="App-link" _ga_event="tx_record.player.open" onClick={ ()=>{
                    props.onUserPop({
                        pop: true,
                        info: userInfo
                    });
                }}>{ shortString(getNickName(allPlayers, userInfo.username), 10) }</span>
                zeta transaction 
                <a href={ zetaLink + Data.hash } _ga_event="tx_record.zeta.link" rel="noreferrer" target="_blank" className="App-transaction-link">transaction</a>
            </div>
            : <div className="App-world-record-item-text">
                <span className="App-link" _ga_event="tx_record.player.open" onClick={ ()=>{
                    props.onUserPop({
                        pop: true,
                        info: userInfo
                    });
                }}>{ shortString(getNickName(allPlayers, userInfo.username), 10) }</span>
                { subs(noticeTpls[Data.txType] || Data.txType + '' || '', transactionAdopter(item)) }
                { Data.result ? ' success.' : ' fail.' }
                <a href={ transactionLink + item.TransID } _ga_event="tx_record.link" rel="noreferrer" target="_blank" className="App-transaction-link">transaction</a>
            </div>}
        </div>
    );
}


