import React, { useEffect, useState } from 'react';

import Texts from '../../const/texts.json';
import { createRadarSvg } from '../../utils/utils'

export default function SkillRadar(){
    let skillsData = [
        { label: Texts['dialog.attack'] , type: 'ATK', value: 80 },
        { label: Texts['dialog.defense'], type: 'DEF', value: 60 },
        { label: Texts['dialog.hp'], type: 'HP', value: 100 },
        { label: Texts['dialog.attack_speed'], type: 'SPD', value: 50 },
        { label: Texts['dialog.move_speed'], type: 'MOV', value: 20 },
        { label: Texts['dialog.load'], type: 'LOAD', value: 80 } 
    ];

    useEffect(() => {
        let target = document.querySelector('.App-troops-svg');
        let elem = createRadarSvg(skillsData);
        target.appendChild(elem);
    }, []);

    return (
        <div className="App-troops-skills">
            <div className="App-troops-svg">
                {skillsData.map((item, index)=>
                    <div className={ 'App-troops-svg-node App-troops-svg-node-' + (index + 1) } key={ index }>
                        <span className="type">{ item.type }</span>
                        <span className="icon"></span>
                    </div>
                )}
            </div>
            {skillsData.map((item, index)=>
                <div className="App-troops-skill-radar-item" key={ index }>
                    <label>{ item.label }</label>
                    <span>{ item.value }</span>
                </div>
            )}
        </div>
    );
}