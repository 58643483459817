import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addConfig, resetConfig } from '../../services/throne.init.js'

import './help.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function HelpPanel(){
    const dispatch = useDispatch();
    const [ open, setOpen ] = useState(false);
    const { debugConfig } = useSelector(function(state){
        return state.app;
    });
    let names = [];
    for(var name in debugConfig){
        names.push(name);
    }
    // console.log(debugConfig, names);

    let times = 0;
    function showHelp(e){
        // console.log(e.keyCode)
        if(e.keyCode === 13) {
            times += 1;
        }
        if(times >= 10){
            setOpen(true);
            times = 0;
        }
    }
    function hideHelp(){
        times = 0;
        setOpen(false);
    }

    useEffect(()=>{
        document.addEventListener("keyup", showHelp, false);
        let clearTimes =  setTimeout(function(){
            times = 0;
        }, 5000);
        return ()=>{
            document.removeEventListener("keyup", showHelp, false);
            clearTimeout(clearTimes);
        }
    }, [debugConfig, open]);


	return(
        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)', zIndex: '9999' },
                content: {
                    width: '200px',
                    position: 'fixed',
                    top: '10px',
                    bottom: '10px',
                    left: 'auto',
                    right: '10px',
                    zIndex: '9999'
                }
            }}
            isOpen={ open }
            contentLabel="Help">
            <div style={{ position: 'relative' }} className="App-help">
                <h2>Hide Component</h2>
                { names.map((item, index)=>
                    <div className="App-help-item" key={ index }>
                        <label>{ item }</label>
                        <input type="checkbox" name={ item } checked={ debugConfig[item] } onChange={ (e)=>{
                            let data = {};
                            data[item] = e.target.checked;
                            dispatch(addConfig(data))
                        }} />
                    </div>
                )}
                <button onClick={ hideHelp }>hide</button>
            </div>
        </Modal>
	);
}