import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';

import { generalInit, battleRecordsSearch, enemiesSearch } from '../../services/slice-general'

import { GoBack, CreateIcon, LogisIcon, Stars, PersonNumber } from '../../components'
import SelectGeneral from '../generals/generals-selector'

import { logTrace } from '../../utils/log'

import './battle.scss'
import Lannister from './assets/lannister.png'
import vIcon from './assets/victory.png'
import failIcon from './assets/failure.png'
import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath
} from '../../utils/ui-res'
import { numberFormat } from '../../utils/utils'

import Texts from '../../const/texts.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function BattleResult(props){
    const result = props.result;
    const type = props.type;

    // console.log(result, props);

    const [isOver, setIsOver] = useState(true);

    function resultModalHide(){
        props.overConfirm(result.win);
    }

    useEffect(()=>{
        return ()=>{
        }
    }, []);

    return (    
        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '150px',
                    marginTop: '-75px'
                }
            }}
            className = { 'App-game-modal-transparent' }
            isOpen={ isOver }
            onRequestClose={ ()=>{ resultModalHide(); } }
            contentLabel="Battle Result Modal">
            <div style={{ position: 'relative' }} _ga_event="battle.result.close"  onClick={ resultModalHide }>
            { result.error && (
                <div className="App-battle-over-info App-battle-over-lost">
                    <div className="App-battle-over-info-inner">
                    <div className="App-battle-over-title">{ result.error }</div>
                    </div>
                </div>
            )}
            { result.win && 
                (<div className="App-battle-over-info App-battle-over-victory">
                    <div className="App-battle-over-info-inner">
                        <div className="App-battle-over-title">{ 
                            result.type === 'attack' 
                            ? Texts['dialog.battle_log.victory'].toUpperCase() 
                            : Texts['dialog.battle_log.success'].toUpperCase()
                        }</div>
                        { result.durabilityReduce ? (<div className="App-battle-over-details">
                            <div className="App-battle-over-detail durability enemyloss">
                                <label>{ Texts['dialog.block_attack.block_duration'] }:</label>
                                <span>-{ numberFormat(result.durabilityReduce) }</span>
                            </div>
                            <div className="App-battle-over-detail durability">
                                <label>{ Texts['dialog.glory'] }:</label>
                                <span>{ numberFormat(result.attackGloryGet) }</span>
                            </div> 
                        </div>) : (<div className="App-battle-over-details">
                            {(type === 'city') && (<div className="App-battle-over-detail reward">
                                <label>{ Texts['dialog.silvers'] }:</label>
                                <span>{ numberFormat(result.silverGet) }</span>
                            </div>)}
                            <div className="App-battle-over-detail ourloss">
                                <label>{ Texts['dialog.mine_troop_lost'] }:</label>
                                <span>{ numberFormat(result.attackTroopReduce) }</span>
                            </div>
                            <div className="App-battle-over-detail enemyloss">
                                <label>{ Texts['dialog.competitior_troop_lost'] }:</label>
                                <span>{ numberFormat(result.defenseTroopReduce) }</span>
                            </div>
                            <div className="App-battle-over-detail enemyloss">
                                <label>{ Texts['dialog.glory'] }:</label>
                                <span>{ numberFormat(result.attackGloryGet) }</span>
                            </div>      
                        </div>)}
                    </div>
                </div>) }
            { !result.error && !result.win && 
                (<div className="App-battle-over-info App-battle-over-lost">
                    <div className="App-battle-over-info-inner">
                    <div className="App-battle-over-title">{ Texts['dialog.battle_log.defeat'] }</div>
                    <div className="App-battle-over-details">
                        {(type === 'city') && (<div className="App-battle-over-detail reward">
                            <label>{ Texts['dialog.silvers'] }:</label>
                            <span>{ numberFormat(result.silverGet) }</span>
                        </div>)}
                        <div className="App-battle-over-detail ourloss">
                            <label>{ Texts['dialog.mine_troop_lost'] }:</label>
                            <span>{ numberFormat(result.attackTroopReduce) }</span>
                        </div>
                        <div className="App-battle-over-detail enemyloss">
                            <label>{ Texts['dialog.competitior_troop_lost'] }:</label>
                            <span>{ numberFormat(result.defenseTroopReduce) }</span>
                        </div>
                        <div className="App-battle-over-detail enemyloss">
                            <label>{ Texts['dialog.glory'] }:</label>
                            <span>{ numberFormat(result.attackGloryGet) }</span>
                        </div>  
                    </div>
                    </div>
                </div>)
            }
            </div>
        </Modal>
    );
}