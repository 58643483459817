import { idDev, isProd } from '../utils/ui-res';

import GameTexts from './app.json';
import Texts from './texts.json';
import Texts2 from './texts2.json';
import OfferTexts from './offer.json';
import SpyTexts from './spy.json';
import CodTexts from './cod.json';
import DATexts from './da.json';
import MapCustomTexts from './map-custom.json';

import getChains from './chains';
import * as NFTConfig from './nfts';

let host = window.location.host;
let apiHost = '';
let wsHost = 'wss://' + host;

if(idDev){
    apiHost = 'https://test.leagueofthrones.com';
    wsHost = 'wss://test.leagueofthrones.com';
}

let zetaContractExplorer = 'https://zetachain.blockscout.com/address/0x4C926AaecB8309Af18C34FAbc3a188Fe39b57A01?tab=read_contract#b2bdfa7b';
let zetaTransactionLink = 'https://zetachain.blockscout.com/tx/';
let zetaTransactionAddressLink = 'https://zetachain.blockscout.com/address/';
let transactionLink = 'http://test-web.leagueofthrones.com/transaction/';
if(isProd){
    transactionLink = 'https://www.leagueofthrones.com/transaction/';
}

const Chains = getChains();
const getChainInfo = function(key, value){
	let chainInfo = {};
	for(var name in Chains){
		let chain = Chains[name];
		if(chain[key] === value){
			chainInfo = chain;
		}
	}
	return chainInfo;
}

const chainIds = {
    '0xa516': 'emerald',
    '0x38': 'bsc',
    '0x61': 'bsctest',
    '0x1b59': 'zetatest'
};

const RewardCoins = {
	'0x0998e94b6778ab6c4d382a142ea779164995fcf4': {
		chain: 'zeta',
		reward: 'lot',
		contractAddr: '0x0998e94b6778ab6c4d382a142ea779164995fcf4'
	},
	'0xdc19a122e268128b5ee20366299fc7b5b199c8e3': {
		chain: 'oasis',
		reward: 'usdt',
		contractAddr: '0xdc19a122e268128b5ee20366299fc7b5b199c8e3'
	},
	'0xf02b3e437304892105992512539f769423a515cb': {
		chain: 'oasis',
		reward: 'yuzu',
		contractAddr: '0xf02b3e437304892105992512539f769423a515cb'
	},
	'0x55d398326f99059ff775485246999027b3197955': {
		chain: 'bsc',
		reward: 'usdt',
		contractAddr: '0x55d398326f99059ff775485246999027b3197955'
	},
	'0x2b90e061a517db2bbd7e39ef7f733fd234b494ca': {
		chain: 'bsc',
		reward: 'usdt',
		contractAddr: '0x2b90e061a517db2bbd7e39ef7f733fd234b494ca'
	},
	'0x9dbc25e38099020e3d330239b7b4cffce7162dcf': {
		chain: 'zetatest',
		reward: 'aZETA',
		contractAddr: '0x9dbc25e38099020e3d330239b7b4cffce7162dcf'
	}
};

export {
	Texts, Texts2,

	GameTexts,
	SpyTexts,
	OfferTexts,
	CodTexts,
	MapCustomTexts,
	DATexts,

	getChainInfo,
	apiHost,
    wsHost,
	transactionLink,
	zetaTransactionLink,
	zetaTransactionAddressLink,
	zetaContractExplorer,

	NFTConfig,
	Chains,
	chainIds,
	RewardCoins
}