import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';

import { generalInit, battleRecordsSearch, enemiesSearch } from '../../services/slice-general'

import { GoBack, CreateIcon, LogisIcon, Stars, PersonNumber } from '../../components'
import SelectGeneral from '../generals/generals-selector'

import { logTrace } from '../../utils/log'

import './battle.scss'
import Lannister from './assets/lannister.png'
import vIcon from './assets/victory.png'
import failIcon from './assets/failure.png'
import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath
} from '../../utils/ui-res'
import { numberFormat } from '../../utils/utils'

import Texts from '../../const/texts.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function DefenseResult(props){
    const result = props.result;
    const type = props.type;

    // console.log(1111111, result, props);

    const [isOver, setIsOver] = useState(true);

    function resultModalHide(){
        props.overConfirm();
    }

    useEffect(()=>{
        return ()=>{
        }
    }, []);

    return (    
        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '150px',
                    marginTop: '-75px'
                }
            }}
            className = { 'App-game-modal-transparent' }
            isOpen={ isOver }
            onRequestClose={ ()=>{ resultModalHide(); } }
            contentLabel="Battle Result Modal">
            <div style={{ position: 'relative' }} _ga_event="defense.result.close" onClick={ resultModalHide }>
            { result.result 
                ? (<div className="App-battle-over-info App-battle-over-victory">
                    <div className="App-battle-over-info-inner">
                        <div className="App-battle-over-title" style={{ paddingTop: '15px' }}>{ 
                            Texts['dialog.battle_log.success'].toUpperCase()
                        }</div>
                    </div>
                </div>) 
                : (<div className="App-battle-over-info App-battle-over-lost">
                    <div className="App-battle-over-info-inner">
                    <div className="App-battle-over-title">{ result.error || Texts['dialog.battle_log.defeat'] }</div>
                    </div>
                </div>)
            }
            </div>
        </Modal>
    );
}