import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    recruitEstimate, recruitTroops, resetRecruitResult, 
    getRecruitState, recruitTroopsOver,
    resourceRefresh
} from '../../services/slice-city'
import { 
    generalTypeImagePath
} from '../../utils/ui-res'

import { numberFormat, timeFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import { GoBack, CreateIcon, ClockCounter, Slider } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import troopType from './assets/troop.png'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function RecruiTroops(props){
    const dispatch = useDispatch(); 

    const [sliderValue, setSliderValue] = useState(0); //percents

    const {recruitResult = {}, recruitOverResult = {} } = useSelector(function(state){
        return state.city;
    });
    logTrace(recruitResult, 'training.recruit.result');

    const [errTips, setErrTips] = useState(false); //tips

    const { resources } = useSelector(function(state){
        return state.city;
    });
    
    logTrace(resources, 'training.resources');

    let spd = resources.troop.production || 30;
        // spd = 510; //traing speed
    let unit = 5;    //percent
    let tps = 100;  //100 silvers = 1 troop, beginner.maxTroops
    let progressRate= sliderValue/100;

    let silvers = Math.floor(resources.silver.value || 0);
    let data = {
        silvers: silvers,
        currentTroops: Math.floor(resources.troop.value || 0),
        maxTroops: Math.floor(silvers/tps)
    };

    data.troops = Math.floor(data.maxTroops * progressRate); //unit = k

    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    function setSliderValueClick(value){
        value = Math.min(value, 100);
        value = Math.max(value, 0);
        setSliderValue(value);
    }

    function troopsRecruitStart(){
        setWaitingPop(true);
        dispatch(recruitTroops({
            amount: data.troops
        }));
    }

    function getRecruiTime(endtime){
        //endtime, timestramp as seconds;
        return endtime - (new Date().getTime())/1000;
    }

    function getRecruiTimeByTrain(amount){
        //src/Game/Logic/game.ts, recruit( amount: number )
        let recruiTime = amount/spd;   //h
        recruiTime = Math.floor(recruiTime*3600);
        return recruiTime;
    }

    function computeStatus(){
        let { amount, status, endtime, result } = recruitResult;
            
        return {
            normal: (!status || status === 'none') && !amount,
            going: status === 'going',
            ready: status === 'ready'
        }
    }

    const { recruitEstimateResult } = useSelector(function(state){
        return state.city;
    });
    useEffect(() => {
        dispatch(recruitEstimate({
            amount: data.troops
        }));
    }, [sliderValue]);
    // console.log('recruitEstimateResult', data.troops, recruitEstimateResult)

    useEffect(() => {
        dispatch(getRecruitState());

        if(props.isBeginnerGuide){
            data.troops = 10;
            setSliderValueClick(10*100/data.maxTroops);
        }
    }, [recruitOverResult]);

    useEffect(() => {
        dispatch(resourceRefresh());
    }, [recruitResult]);

    return (<>
        <div className="App-troops-training">
            <img alt="" className="avatar" src={ troopType } />

            <div className="App-troops-total">
                <label>{ Texts['dialog.army.human_have'] }</label>
                <span>
                    { numberFormat(data.currentTroops) }
                </span>
            </div>

            {computeStatus().ready && (
                <div>
                    <div className="App-troops-op">
                        <div className="App-slider-value" style={{ marginBottom: '-20px' }}>
                            { numberFormat(recruitResult.amount) }
                        </div>
                        <div className="App-progress" style={{ margin: '30px 20px' }}>
                            <div className="App-progress-blue" style={{ width: '100%' }}></div>
                            <div className="App-progress-text" style={{ left: '0', right: 0, textAlign: 'center' }}>
                                { Texts['dialog.army.recruit_compete'] }
                            </div>
                        </div>
                    </div>
                    <span 
                        _ga_event="troops.claim"
                        onClick={ ()=>{ 
                            setWaitingPop(true);
                            dispatch(recruitTroopsOver()); 
                            setSliderValueClick(0);
                        } }
                        className="App-general-btn App-general-btn-green">
                        { Texts['button.claim'] }
                    </span>

                    { waitingPop && <NotifyPop tips={{
                        loading: '',
                        success: Texts2['dialog.troop.claim_success'],
                        fail: ''
                    }} data={{ result: true }} callback={ ()=>{
                        setWaitingPop(false);
                    }} />}
                </div>
            )}

            {computeStatus().going && (<TrainingCounter 
                total={ getRecruiTimeByTrain(recruitResult.amount) } 
                values={ recruitResult } 
                recruiTime={ getRecruiTime(recruitResult.endtime) } />
            )}
            
            {computeStatus().normal && (<>
                <div style={{ margin: '0 40px' }}>
                    <Slider total={ data.maxTroops } value={ sliderValue } onChange={ (value)=>{
                        setSliderValue(value);
                    }} />
                </div>
                <div className="App-troops-op">
                    <div className="App-troops-silvers">
                        <CreateIcon type="check" size="20" /> 
                        <CreateIcon type="silver" size="22" /> 
                        <span className="count">{ numberFormat(recruitEstimateResult.cost) }</span>
                    </div>
                    {(data.troops) ?
                    (<span 
                        _ga_event="troops.recruit"
                        onClick={ (e)=> { 
                            troopsRecruitStart() 
                        } }
                        className="App-general-btn App-general-btn-blue">
                        { Texts['button.army.recruit'] }
                    </span>) : (<span
                        _ga_event="troops.recruit.disable"
                        onClick={ ()=>{
                            setNotifyPop(true);
                        } }  
                        className="App-general-btn App-general-btn-blue App-btn-disable">
                        { Texts['button.army.recruit'] }
                    </span>)}
                    <div className="time">
                        { timeFormat(recruitEstimateResult.time) }
                    </div>

                    { notifyPop && <ToastTip text={ 
                        Texts2['dialog.troop.train_fail_no_number'] || 'Please set troops number'
                    } callback={ ()=>{ setNotifyPop(false) } } />}

                    { waitingPop && <NotifyPop tips={{
                        loading: '',
                        success: Texts2['dialog.troop.train_success'],
                        fail: Texts2['dialog.troop.train_fail']
                    }} data={ recruitResult } callback={ ()=>{
                        setWaitingPop(false);
                    }} />}
                </div>
            </>)}
        </div>

        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'rgba(0,0,0,0.2)'
                },
                content: {
                    height: '240px',
                    width: '400px',
                    marginTop: '-120px',
                    marginLeft: '-200px'
                } 
            }}
            className = { 'App-game-modal' }
            isOpen = { errTips }
            onRequestClose={ ()=>{ setErrTips(false); } }
            contentLabel="Troops Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event="troops.recruit.fail" onClick={ ()=>setErrTips(true) }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header">{ Texts['dialog.tip'] }</div>
                <div style={{ color: '#fff', paddingTop: '40px', textAlign:'center' }}>
                    { Texts['dialog.army.silver_not_enough'] }
                </div>
            </div>
        </Modal>
    </>);
}

function TrainingCounter(props){
    const dispatch = useDispatch(); 
    let data = props.values;
    let total = props.total;

    let t = props.recruiTime;

    const [trainingProgress, setTrainingProgress ] = useState(1);
    const [recruiTime, setRecruiTime ] = useState(t);

    useEffect(()=>{
        let timer = setInterval(function(){
            setRecruiTime(recruiTime - 1);
            setTrainingProgress(100*(total - recruiTime + 1)/total);
        }, 1000);

        return ()=>{
            clearInterval(timer);
        }
    }, [recruiTime]);

    return (<div className="App-troops-op">
        <div className="App-progress" style={{ margin: '40px 20px 10px' }}>
            <div className="App-progress-blue" style={{ width: trainingProgress + '%' }}></div>
            <div className="App-progress-text" style={{ width: '100%', textAlign: 'center' }}>
                {recruiTime > 0 && <ClockCounter time={ recruiTime } onEnd={ ()=>{
                    dispatch(resourceRefresh());
                }} />}
            </div>
        </div>
        <div className="time">
            { Texts['dialog.army.human_recruit'] }: { numberFormat(data.amount) }
        </div>
    </div>);
}

