import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getShortUrl } from '../../services/api';
import { 
    getUserSignupInfo,
    generateGeneralPortraits, 
    generateGeneralPortraitsTest,
    generalPortraitConfirm,
    resetGPTResult
} from '../../services/slice-signup';
import { setShareRecord, getShareRecords } from '../../services/slice-user'

import { GoBack, Loading, CreateIcon } from '../../components';
import {
    generalBigImagePath, generalImagePath, getNftImageUrl
} from '../../utils/ui-res'
import { subs } from '../../utils/utils'
import Texts2 from '../../const/texts2.json';
import AppGame from '../../const/app.json';
import './generate.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function GenerateAvatar(props){
    const dispatch = useDispatch();

    const { chain, nftData, address, seasonId, test = 0 } = props;
    console.log({chain, nftData, address, seasonId, test}, '1sssss');

    const { generalPortrait = {} } = nftData;
    const hasPortrait = !!generalPortrait.image;
    const isConfirmPortrait = !!generalPortrait.share_confirmed;
    // console.log(11111, {hasPortrait, isConfirmPortrait, generalPortrait})

    const timeout = 10;
    const [ canReset, setCanReset ] = useState(true);
    const [ isShared, setIsShared ] = useState(false);
    const [ isGenerated, setIsGenerated ] = useState(false);
    const [ isConfirm, setIsConfirm ] = useState(false);
    const [ generalUid, setGeneralUid ] = useState('');
    const [ images, setImages ] = useState([]);

    const { 
        userSignupInfo,
        allPlayersGeneralPortraits, 
        generalPortraitsResult, 
        generalPortraitsTestResult,
        generalPortraitConfirmResult
    } = useSelector(function(state){
        return state.signup;
    });

    function genereteImages(){
        setGeneralUid('');
        dispatch(resetGPTResult());
        setCanReset(false);
        if(test){
            dispatch(generateGeneralPortraitsTest({
                chain: chain,
                nft_type: nftType, 
                nft_id: 1,
                general_id: 2
            }));
            return;
        }
        dispatch(generateGeneralPortraits({
            season_id: seasonId,
            address: address,
            general_id: nftData.generalId
        }));
    }

    useEffect(()=>{
        if(generalPortraitsResult.back){
            setTimeout(()=>{
                setCanReset(true);
            }, timeout * 1000);
        }
        setImages(RenderImages(generalPortraitsResult.images));
    }, [generalPortraitsResult]);

    const [ nftType, setNftType ] = useState('Airose');

    const { shareRecords } = useSelector(function(state){
        return state.user;
    });
    // console.log('shareRecords',shareRecords, generalPortraitsResult)

    const [ generalPortraitSelected, setGeneralPortraitSelected ] = useState('');

    useEffect(()=>{
        let generalId = nftData.generalId;
        let generalPortraitMapping = userSignupInfo.general_portraits || {};
        let generalPortraitItem = userSignupInfo[generalId] || {};
        let generalPortrait = generalPortraitItem.image || '';
        setGeneralPortraitSelected(generalPortrait);
    }, [userSignupInfo]);

    useEffect(()=>{
        dispatch(getUserSignupInfo({
            address,
            seasonId
        }))
        dispatch(getShareRecords({
            season_id: seasonId,
            seasonId: seasonId,
            address: address
        }))
    }, [generalPortraitConfirmResult]);


    function RenderImages(obj){
        obj = obj || {};
        var imageList = [];
        for(var uid in obj){
            imageList.push({
                uid: uid,
                data: obj[uid]
            });
        }
        return imageList;
    }
    useEffect(()=>{
        setImages(RenderImages(generalPortraitsTestResult.images));
    }, [generalPortraitsTestResult]);

    // console.log('generalPortraits',{ images, userSignupInfo, generalPortraitsResult, generalPortraitConfirmResult, generalPortrait, generalPortraitSelected });

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                width: '400px',
                height: '570px',
                margin: '-280px 0 0 -200px'
            }
        }}
        className = { 'App-game-modal-gpt-avatar App-game-modal-gray-headless' }
        isOpen={ true }
        contentLabel="Generate Avatar Modal">
        <div style={{ position: 'relative', padding: '1px' }}>
            <div _ga_event={ "gpt.avatar.close" } onClick={ ()=>{ props.onClose(); }}>
                <GoBack size="60px" />
            </div>
            <div className="App-sign-steps">
                <div className="App-sign-step current" 
                    style={{ display: 'block', textAlign: 'center', color: '#fff' }}>
                    { Texts2['dialog.signup.gpt.tools'] }
                </div>
            </div>
            
            {test !== '1' && <>
            <div className="App-gpt-main">
                <div className="App-gpt-nft-banner">
                    <img src={ nftData.image || getNftImageUrl(nftData.type, nftData.nftId)  } style={{ height: '100px', backgroundColor: 'rgba(85, 54, 15, 1)' }} />
                    <h2>{ Texts2['dialog.signup.gpt.u-nft'] }</h2>
                    <span style={{ display: 'block', width: '30px', margin: '0 auto', transform: 'rotate(' + 90 + 'deg)' }}><CreateIcon type="greenArr" size="24" /></span>
                </div>

                {hasPortrait && <img src={ generalPortrait.image } 
                    style={{ 
                        margin: '10px',
                        cursor: 'pointer'
                    }} />
                }

                {(!hasPortrait && images.length === 0) && <div style={{ textAlign: 'center' }}>
                    <div className="default-generate-image"></div>
                    <div className="default-generate-image"></div>
                    <div className="default-generate-image"></div>
                </div>}

                {(!hasPortrait && images.length > 0) && images.map((image, index) => (
                    <img src={ image.data } 
                        key={ index }
                        title={ image.uid }
                        _ga_event={ "gpt.avatar.select" }
                        onClick={ ()=>{ 
                            if(isConfirm){
                                return;
                            }
                            setGeneralUid(image.uid) 
                        } }
                        style={{ 
                            margin: '10px 5px',
                            cursor: 'pointer',
                            display: (isConfirm && generalUid && generalUid !== image.uid) ?  'none' : 'inline-block',
                            border: '2px solid ' + (generalUid === image.uid ? '#fff' : 'transparent' ) 
                    }} />
                ))}

                <div className="App-sign-content" style={{ padding: '5px' }}>{ Texts2['dialog.signup.gpt.intro'] }</div>
            </div>

            {(!hasPortrait || !isConfirmPortrait) && <div className="App-sign-btns">
                {(!isConfirm && !hasPortrait) && <><span 
                    _ga_event={ "gpt.avatar.generate" }
                    onClick={ ()=>{
                        setIsGenerated(true);
                        genereteImages();
                    } }
                    className={"App-general-btn App-general-btn-blue " + (canReset ? '' : 'App-btn-disable')}>
                    { Texts2['dialog.signup.gpt.btn.generate'] }
                </span>
                {(isGenerated && !generalPortraitsResult.back) && <Loading type="headless" tip="Generating……" />}

                {images.length > 0 && <span 
                    _ga_event={ "gpt.avatar.confirm" }
                    onClick={ ()=>{ 
                        if(generalUid === ''){
                            return;
                        }
                        dispatch(generalPortraitConfirm({
                            season_id: seasonId,
                            address: address,
                            general_id: nftData.generalId,
                            image_key: generalUid
                        }));
                        setIsConfirm(true);
                    } }
                    style={{ marginLeft: '3rem' }}
                    className={"App-general-btn App-general-btn-green " + (generalUid === '' ? 'App-btn-disable' : '')}>
                    { Texts2['dialog.signup.gpt.btn.confirm'] }
                </span>}
                {(!isGenerated && !generalPortraitsResult.back) && <div className="App-sign-content" style={{ padding: '5px' }}>{ Texts2['dialog.signup.gpt.intro2'] }</div>}
                {images.length > 0 && <div className="App-sign-content" style={{ padding: '5px' }}>{ Texts2['dialog.signup.gpt.select'] }</div>}
                {(generalPortraitsResult.back && images.length === 0) && <div className="App-sign-content" style={{ color: 'rgba(238, 20, 71, 1)' }}>
                    { subs(Texts2['dialog.signup.gpt.busy'], { timeout: timeout}) }
                </div>}
                </>}

                {((isConfirm && !isShared) || (hasPortrait && !isConfirmPortrait)) && <>
                    <GPTShare data={{
                        NFT_name: nftData.name,
                        imageLink:  generalPortrait.image || generalPortraitSelected || (generalPortraitConfirmResult.data || {}).image_url,
                        season_id: seasonId,
                        address: address,
                        generalId: nftData.generalId
                    }} 
                    onShare={ (result)=>{ setIsShared(true) } } />
                    <div className="App-sign-content" style={{ padding: '10px' }}>{ Texts2['dialog.signup.gpt.share'] }</div>
                </>}
            </div>}

            {((hasPortrait && isConfirmPortrait) || isShared) && <div className="App-sign-btns">
                <div className="App-sign-content">{ Texts2['dialog.signup.gpt.working'] }</div>
            </div>}
            </>}


            {test === '1' && <div className="App-gpt-main">
                {RenderImages(generalPortraitsTestResult.images).map((image, index) => (
                    <img src={ image.data } 
                        key={ index }
                        style={{ 
                            margin: '20px', height: '90px', 
                            borderRadius: '5px', 
                            border: '2px solid ' + (generalUid === image.uid ? '#fff' : '#333' ) 
                        }} />
                ))}
                <div className="App-sign-btns">
                    {['Airose','Apex','SeibaraClub'].map((nft, index) => (
                        <span 
                            style={{ margin: '10px 20px', color: (nft === nftType ? '#fff' : '#666' )}}
                            _ga_event={ "gpt.avatar.nft" }
                            key={ index } onClick={ ()=>{ setNftType(nft) } }>
                            { nft }
                        </span>
                    ))}
                    <div style={{ paddingTop: '20px' }}><span 
                        _ga_event={ "gpt.avatar.generate" }
                        onClick={ ()=>{
                            genereteImages();
                        } }
                        className={"App-general-btn App-general-btn-green"}>
                        { Texts2['dialog.signup.gpt.btn.generate'] }
                    </span></div>
                </div>
            </div>}
        </div>
    </Modal>);
}

function GPTShare(props){
    const dispatch = useDispatch();
    const { imageLink, NFT_name, address, generalId, season_id } = props.data;

    const appLink = AppGame.prod;

    var params = {
        NFT_name: NFT_name,
        twitter: AppGame.twitter
    };
    const [ appLinkShort, setAppLinkShort ] = useState('');
    const [ imageLinkShort, setImageLinkShort ] = useState('');

    useEffect(()=>{
        getShortUrl({
            url: appLink
        }, function(res){
            let surl = appLink;
            if(res.code === 0){
                surl = res.short_url;
            }
            setAppLinkShort(surl);
            if(!imageLink){
                setImageLinkShort(surl);
            }
        });

        if(!imageLink){
            return;
        }
        getShortUrl({
            url: imageLink
        }, function(res){
            let surl = imageLink;
            if(res.code === 0){
                surl = res.short_url;
            }
            setImageLinkShort(surl);
        });
    }, []);

    function fbShare(params){
        let link = 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(imageLinkShort);
        window.open(link);
        dispatch(setShareRecord({
            season_id: season_id,
            seasonId: season_id,
            address: address,
            action: 'aigc_general_portrait', //attack_territory
            platform: 'facebook',
            general_id: generalId
        }))
    }
    function twitterShare(params){
        params.appLink = appLinkShort;
        params.imageLink = imageLinkShort;
        const content = Texts2['dialog.share.content.gpt'];
        const desc = subs(content, params);
        let link = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(desc);
        window.open(link);
        dispatch(setShareRecord({
            season_id: season_id,
            seasonId: season_id,
            address: address,
            action: 'aigc_general_portrait', //attack_territory
            platform: 'twitter',
            general_id: generalId
        }))
    }

    if(!appLinkShort || !imageLinkShort){
        return(<></>)
    }

    return (<div className="App-sns-share-btns">
        <span 
            _ga_event={ "gpt.avatar.share.twitter" }
            onClick={ ()=>{
                twitterShare(params);
                props.onShare && props.onShare(true);
            } } 
            className="App-btn-twitter">
            { Texts2['dialog.share.btn.twitter'] }
        </span>
        <span 
            _ga_event={ "gpt.avatar.share.facebook" }
            onClick={ ()=>{
                fbShare(params);
                props.onShare && props.onShare(true);
            } } 
            className="App-btn-facebook">
            { Texts2['dialog.share.btn.facebook'] }
        </span>
    </div>);
}

