import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import { getSeasonInfo, getUnionPlayers, getAllPlayers } from '../../services/slice-user';
import { getUnionOverView } from '../../services/slice-map';

import { colors, unionImagePath, getNickName } from '../../utils/ui-res'
import { GoBack, CreateIcon } from '../../components'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import { numberFormat } from '../../utils/utils'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SignUpStats(props){
    const dispatch = useDispatch();
    const { seasonId } = props;

    let types = [
        Texts['dialog.camp_1.name'],
        Texts['dialog.camp_2.name'],
        Texts['dialog.camp_3.name'],
        Texts['dialog.camp_4.name']
    ];

    const [unionId, setUnionId] = useState(1);
    const [currentType, setCurrentType] = useState(types[0]);

    const { unionPlayers, allPlayers } = useSelector(function(state){
        return state.user;
    });
    const currentAllPlayers = allPlayers[seasonId] || {};
    const { unionPlayersWithGlory } = useSelector(function(state){
        return state.map;
    });

    let list = unionPlayers[seasonId] || [];
    if(props.from === 'preview'){
        list = unionPlayersWithGlory[unionId-1] || [];
    }
    console.log({seasonId, allPlayers, unionPlayers, unionPlayersWithGlory, list, unionId, currentType})

    useEffect(()=>{
        dispatch(getAllPlayers({ seasonId }))
    }, []);

    useEffect(()=>{
        dispatch(getUnionOverView());
    
        dispatch(getUnionPlayers({
            unionId,
            seasonId
        }));
    }, [unionId]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                height: '500px',
                marginTop: '-250px'
            }
        }}
        className = { 'App-game-modal-sign-overview App-game-modal-gray' }
        isOpen={ true }
        onRequestClose = { () =>{ props.close() } }
        contentLabel="Camp Overview">
        <div style={{ position: 'relative' }}>
            <div _ga_event={ "signup.stat.close" } onClick={ ()=>{ props.close() } }>
                <GoBack size="60px" />
            </div>
            <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>Camp Overview</div>
            <div className="App-general-logis" style={{ margin: '20px 0 0',  width: '100%', textAlign: 'center' }}>
                <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
                {types.map((item, index)=>
                    <div key={ item } 
                        className={ 'App-general-tab-normal ' + (currentType === item ? 'App-general-tab-hightlight' : '') } 
                        _ga_event={ "signup.stat.union" }
                        onClick={ (e)=> {setCurrentType(item); setUnionId(index + 1)} }>
                        <span>{ item }</span>
                    </div>
                )}
                </div>
            </div>

            <div className="App-game-modal-content1" style={{ padding: '0', height: '340px', overflow: 'auto' }}>
                <div className="App-game-table App-game-table-rank"><table>
                    <thead><tr>
                        <td className="center rank">{ Texts['dialog.rank'] }</td>
                        <td className="player">{ Texts['dialog.player'] }</td>
                        <td className="center">{ Texts2['dialog.camp'] }</td>
                        <td className="right">{ Texts['dialog.glory'] } &nbsp;&nbsp;</td>
                    </tr></thead>
                    <tbody>
                    {list.map((item, index) => (
                        <tr key={ 'rank' + index }>
                            <td className="center"><span className="index">{ index + 1 }</span></td>
                            <td><span style={{ display:'inline-block', width: '10rem', paddingTop: '5px', overflow:'hidden',textOverflow:'ellipsis' }} title={ item.name || item.username }>
                                { getNickName(currentAllPlayers, item.name || item.username) }
                            </span></td>
                            <td className="center" style={{ paddingTop: '8px' }}> 
                                <img alt="" style={{ width: '20px',borderRadius: '10px' }} src={ unionImagePath(unionId) } /> 
                            </td>
                            <td className="right"> { item.glory || 0 } &nbsp;&nbsp;</td>
                        </tr>)
                    )}
                    </tbody>
                </table></div>
            </div>

            <div className="App-sign-content" style={{ textAlign:'center' }}>{ Texts2['dialog.login.overview.tips'] }</div>
        </div>
    </Modal>);
}