import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    cityInit, 
    onReceiveChat, getHistoryMessages,
    sendMessage, 
    checkNewMessage, setNewMessageRead,
    resetMessageResult
} from '../../services/slice-city'
import { getSeasonInfo, getUnionPlayers, getAllPlayers } from '../../services/slice-user';

import { numberFormat, timeFormat, getStringLength } from '../../utils/utils'
import * as cache from '../../utils/cache'
import { logTrace } from '../../utils/log';

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import './chat.scss'

import { CreateIcon, ClockCounter, GoBack } from '../../components'
import MessageOne from './msg'
import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ChatBox(props){
    const dispatch = useDispatch();
    const { currentUser } = props;

    const [ content, setContent ] = useState('');
    const [ channel, setChannel ] = useState(1);
    const [ isHandScroll, setIsHandScroll ] = useState(false);
    //WORLD  = 1, Camp   = 2

    function synContent(e){
        // console.log('synContent', e.target.value);
        let content = e.target.value || '';
        let isBlank = e.keyCode === 32;
        let pos1 = e.target.selectionStart;
        let pos2 = e.target.selectionEnd;
        // console.log('isBlank', isBlank, e.target.selectionStart, e.target.selectionEnd);
        if(isBlank){
            let len = content.length;
            content = content.substring(0, pos1) + ' ' + content.substring(pos2, len);
        }
        cache.set('channel-' + channel, content);
        setContent(content);
        setTimeout(function(){
            e.target.setSelectionRange(pos1 + 1, pos1 + 1);
        }, 10);
    }

    const { cityReady, newMessages, messageListWorld = [], messageListUnion = [], messageResult } = useSelector(function(state){
        return state.city;
    });

    let messageList = messageListWorld;
    if(channel === 2){
        messageList = messageListUnion;
    }
    // console.log({channel, messageListWorld, messageListUnion, messageList, messageResult}, 'chat.log');
    
    let maxCount = 100;

    function send(){
        dispatch(sendMessage({
            channel: channel,
            content: content
        }));
    }

    function setPosition(){
        let target = document.getElementById('App-game-chat-main');
        if(target){
            target.scrollTop = 10000000;
        }
    }

    function updateReadTime(){
        let len = messageList.length - 1;
        if(len < 0 || !newMessages[channel]){
            return;
        }
        let lastMessage = messageList[len];
        cache.set('chat-read-time-' + channel, lastMessage.ts);
        dispatch(setNewMessageRead({
            channel: channel,
            message: lastMessage
        }));
    }

    function getUnreadCount(channel){
        let readTime = cache.get('chat-read-time-' + channel) || 0;
        let messageList = messageListWorld;
        if(channel === 2){
            messageList = messageListUnion;
        }
        let count = 0;
        messageList.forEach(function(msg){
            if(msg.ts >= readTime){
                count += 1;
            }
        });
        return count > 99 ? '99+' : count;
    }

    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;

    useEffect(()=>{
        dispatch(getAllPlayers({
            seasonId: currentSeasonId
        }))
    }, [currentSeasonId]);

    useEffect(()=>{
        dispatch(onReceiveChat({
            channel: 1
        }));
        dispatch(onReceiveChat({
            channel: 2
        }));
        dispatch(checkNewMessage());
        let t = setInterval(function(){
            dispatch(getHistoryMessages({
                channel: 1,
                unionId: currentUser.unionId
            }));
            dispatch(getHistoryMessages({
                channel: 2,
                unionId: currentUser.unionId
            }));
        }, 2000);
        return ()=>{
            clearInterval(t);
        }
    }, [cityReady]);

    useEffect(()=>{
        dispatch(checkNewMessage());
    }, [newMessages.reset]);

    useEffect(()=>{
        updateReadTime();
        return ()=>{
            if(isHandScroll){
                return;
            }
            setPosition();
        }
    }, [messageList]);

    useEffect(()=>{
        setTimeout(function(){
            if(messageResult.result){
                dispatch(resetMessageResult());
            }
        }, 5*1000);
        if(messageResult.result){
            setContent('');
        }
    }, [channel, messageResult]);

    useEffect(()=>{
        let content = cache.get('channel-' + channel) || '';
        setContent(content);
        dispatch(resetMessageResult());
        setIsHandScroll(false);
    }, [channel]);

	return(
        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    width: '500px',
                    height: '600px',
                    margin: '0',
                    top: 'auto',
                    left: '50px',
                    bottom: '50px',
                    zIndex: 189
                }
            }}
            className = { 'App-game-modal-gray-headless App-game-modal-chat' }
            isOpen={ true }
            onRequestClose = { () => props.onClose() }
            contentLabel="Chat">
            <div style={{ position: 'relative' }}>
                <div onClick={ ()=>{ props.onClose() } } className="App-game-modal-chat-close">
                    <GoBack size="60px" />
                </div>
                <div className="App-game-chat-header">
                    <div className={ 'header-item' + (channel === 1 ? ' current' : '')} 
                        _ga_event="chat.channel.1"
                        onClick={ ()=>{ setChannel(1) } }>
                        <div className="world"></div>
                        {(newMessages['1']) && <span className="App-redpoint-unread">{ getUnreadCount(1) }</span>}
                        <span className="text">{ Texts2['dialog.chat.title.world'] }</span>
                    </div>
                    <div className={ 'header-item' + (channel === 2 ? ' current' : '')} 
                        _ga_event="chat.channel.2"
                        onClick={ ()=>{ setChannel(2) } }>
                        <div className="camp"></div>
                        {(newMessages['2']) && <span className="App-redpoint-unread">{ getUnreadCount(2) }</span>}
                        <span className="text">{ Texts2['dialog.chat.title.union'] }</span>
                    </div>
                </div>
                <div className="App-game-chat-main" id="App-game-chat-main" onScroll={ (e)=>{
                    setIsHandScroll(true);
                    }}>
                    <div className="App-game-chat-msg">
                        {messageList.map((message, index)=>
                            <MessageOne message={ message } currentUser={ currentUser } key={ index} onClose={ ()=>{
                                props.onClose();
                            }} />
                        )}
                    </div>
                </div>
                <div className="App-game-chat-chatbox">
                    <div className="App-game-chat-input">
                        <input type="text" name="content" placeholder="typing"
                            value={ content }
                            onKeyUp= { (e)=>{
                                synContent(e);
                                let contentOK = content.length <= maxCount && content.length > 0;
                                let canSend = !messageResult.result;
                                let isEnter = e.keyCode === 13;
                                if(isEnter && contentOK && canSend){
                                    send();
                                }
                            }}
                            onChange={ (e)=>{ synContent(e) } } />
                        <span className="count">{ getStringLength(content) }/{maxCount}</span>
                    </div>
                    {(messageResult.result || getStringLength(content) > maxCount || getStringLength(content) === 0) ? 
                    (<span className="App-loading-btn"><span
                        className="App-general-btn App-general-btn-blue App-btn-disable"
                        style={{ width:'3.4rem',paddingTop: '10px', paddingBottom: '10px' }}>
                        { Texts2['dialog.chat.send'] }
                        {messageResult.result && (<>&nbsp;<ClockCounter time="5" /></>)}
                    </span></span>) : (<span className="App-loading-btn"><span 
                        _ga_event="chat.message.send"
                        onClick={ send } 
                        className="App-general-btn App-general-btn-blue" 
                        style={{ width:'3.4rem',paddingTop: '10px', paddingBottom: '10px' }}>
                        { Texts2['dialog.chat.send'] }
                    </span></span>)}
                </div>
            </div>
        </Modal>
	);
}