import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AppGame from '../../const/app.json';
import Texts2 from '../../const/texts2.json';
import './help.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function GuideBook(props){
    const dispatch = useDispatch();

    useEffect(()=>{
    }, []);

	return(
        <div className="App-help-guidebook">
            <a href={ AppGame.daGuidebook } onClick={ ()=>{
                props.onLoad && props.onLoad();
                } } target="_blank">
                <span>{ Texts2['button.guidebook'] }</span>
            </a>
        </div>
	);
}