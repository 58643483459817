import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import { getOriginIndex } from '../../maps/map-res'

export default function createBlockView(x_id, y_id, mapConfig){
    const bgIds = mapConfig.bgIndexs;
    const mapIds = mapConfig.mapIndexs;
    const cols = mapConfig.cols;

    let {x, y} = getOriginIndex(x_id, y_id, mapConfig);
    let tileWidth = mapConfig.tileWidth;
    let pos = y*cols + x;

    let bgImages = mapConfig.bg.images;
    let mapImages = mapConfig.map.images;

    let bgIndex = bgIds[pos] - 1;
    let mapIndex = mapIds[pos] - 1;

    let bgPos = (tileWidth*bgIndex*-1) + 'px 0';
    let mapPos = tileWidth*mapIndex*-1 + 'px 0';

    return {
        bg: {
            backgroundImage: 'url(' + bgImages + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: bgIndex >= 0 ? bgPos : '0 -200px',
            backgroundSize: 'auto 100%',
            height: tileWidth + 'px',
            width: (tileWidth + 0) + 'px'
        },
        map: {
            backgroundImage: 'url(' + mapImages + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: mapIndex >= 0 ? mapPos : '0 -200px',
            backgroundSize: 'auto 100%',
            height: tileWidth + 'px',
            width: (tileWidth + 0) + 'px'
        }
    }
}