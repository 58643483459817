import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    resourceRefresh, refreshCityData, healTroopsStart, healEstimate, resetHealResult
} from '../../services/slice-city'

import { numberFormat, } from '../../utils/utils'
import { logTrace } from '../../utils/log'

export default function HealTroopsNotify(props){
    const dispatch = useDispatch(); 
    const { type } = props;

    const { cities, healTroopsCount, healResult, healEstimateResult } = useSelector(function(state){
        return state.city;
    });

    function getRecoverLimit(){
        let info = {};
        cities.forEach(function(item){
            if(item.type === 'hospital'){
                info = item.info;
            }
        });
        return info.recovery_limit || 0;
    }

    const [recoveryLimit, setRecoveryLimit] = useState(0);
    
    const healRealCount = Math.min(healTroopsCount.value || 0, (recoveryLimit + healTroopsCount.today/1));

    const { resources, golds } = useSelector(function(state){
        return state.city;
    });
    let silvers = Math.floor(resources.silver.value || 0);

    useEffect(() => {
        dispatch(refreshCityData())
        dispatch(resourceRefresh())
    }, [healResult]);

    useEffect(() => {
        let amount = Math.floor(healRealCount);
        dispatch(healEstimate({ amount }));
    }, [type]);

    useEffect(() => {
        setRecoveryLimit(getRecoverLimit());
    }, [cities]);

    return (<>
        {
            (
                (healEstimateResult.silver > 0 && healEstimateResult.silver < silvers) 
                || (healEstimateResult.gold > 0 && healEstimateResult.gold < golds)
            ) ?  <span className="App-redpoint" style={{ margin: '-58px 0 0 15px' }}>new troop</span>
            : <></>
        }
    </>);
}