import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUnionWinInfo } from '../../services/slice-map';

import { ClockCounter } from '../../components'

import { logTrace } from '../../utils/log'
import { unionImagePath } from '../../utils/ui-res'

import Texts from '../../const/texts.json';
import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function WillWin(props){
    const dispatch = useDispatch(); //must in component function

    const { mapReady, unionWinInfo, occupieds } = useSelector(function(state){
        return state.map;
    });
    const [isOver, setIsOver] = useState(false);

    logTrace({ unionWinInfo, mapReady }, 'map.tile willwin')
    // console.log(unionWinInfo)
    
    const { unionId, status, remainTime } = unionWinInfo || {};
    useEffect(() => {
        // setIsSeasonEnd(true); HaveWin
        dispatch(getUnionWinInfo());
        return ()=>{
        }
    }, [ mapReady, occupieds ]); 

    useEffect(() => {
        if(status === 'HaveWin'){
            window.game && window.game.events.emit("gameout", true);            
        }
    }, [ unionWinInfo ]); 

    return (<>  
        {(status === 'WaitToWin' && !isOver) && (<div className="App-modal-tips">
            <img alt="" src={ unionImagePath(unionId) } style={{ borderRadius: '50%', verticalAlign:'bottom',height: '22px',marginRight: '10px' }}/> 
            will win in&nbsp;
            <ClockCounter time={ remainTime } onEnd={ ()=>{
                setIsOver(true);
                dispatch(getUnionWinInfo());
            } } />
        </div>)}

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '140px',
                    marginTop: '-70px',
                    width: '440px',
                    marginLeft: '-220px'
                }
            }}
            className = { 'App-game-modal-willwon App-game-modal-gray-headless' }
            isOpen={ status === 'HaveWin' || isOver }
            onRequestClose = { () => { window.location.reload(true) } }
            contentLabel="Season End">
            <div style={{ position: 'relative' }} _ga_event="season.win" onClick={ () => {} }>
               <div style={{ textAlign: 'center', paddingTop: '60px' }}>
                    <img alt="" src={ unionImagePath(unionId) } style={{ verticalAlign:'bottom',height: '20px',marginRight:'10px' }}/>
                    { Texts['dialog.season_victory'] }
               </div>
            </div>
        </Modal> 
    </>)
}