import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import { rewardConfig } from 'throne-underlying';

import { pointUpdate } from '../../services/slice-strategies'
import { resourceRefresh } from '../../services/slice-city'
import { 
    mapInit, getOccupiedTiles, getTileInfo, 
    attackTile, getDefenseList, defenseTile, resetBattleResult
} from '../../services/slice-map';
import { generalInit, getSkillInfo, getDefenseBlockGenerals } from '../../services/slice-general'
import { setShareRecord, getShareRecords } from '../../services/slice-user'

import SendTile from '../chat/send-tile'
import CodCreate from '../cod/cod-create'

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, timeFormat, subs, getStamina } from '../../utils/utils'
import { unionImagePath, generalTypeImagePath } from '../../utils/ui-res'
import { getMapId, getMapConfig } from '../../maps/map-res'
import * as cache from '../../utils/cache'

import AppGame from '../../const/app.json';
import mainFortress from './assets/fortress.png';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import { logTrace } from '../../utils/log'

import SelectGeneral from '../generals/generals-selector'
import BattleResult from '../battle/battle-result'
import DefenseResult from '../battle/defense-result'
import ProtectWarning from '../battle/protect-warning'

import SnShare from '../share/share'

import Modal from 'react-modal';

var occupiedsTemp = {};
export default function Attack(props) {
    const dispatch = useDispatch(); 
    const {  detail, tileData } = props;
    
    const { sign, seasonDetail } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const mapId = getMapId(seasonDetail[currentSeasonId].seasonBasicInfo);
    const mapConfig = getMapConfig(mapId);
    // console.log('createBlockView currentSeasonId', currentSeasonId, mapId, mapConfig)

    const [attackBattleOver, setAttackBattleOver] = useState(false);
    const [defenseBattleOver, setDefenseBattleOver] = useState(false);
    const [tipPop, setTipPop] = useState(false);

    const xyIndex = (tileData.xyIndex || '').split('^');
    const x_id = xyIndex[0]/1;
    const y_id = xyIndex[1]/1;


    const isInitBlock = (Math.abs(x_id) === mapConfig.cols - 1 && Math.abs(y_id) === (mapConfig.rows - 2)/2);

    const { occupieds } = useSelector(function(state){
        return state.map;
    });
    logTrace({occupieds, occupiedsTemp, detail, mapConfig, tileData}, 'map.tile.occupieds')

    const { 
        attackResult, 
        defenseList,
        defenseResult
    } = useSelector(function(state){
        return state.map;
    });

    logTrace({tileData, detail, defenseList, attackResult, defenseResult }, 'map.tile.1')

    const { defenseBlockGenerals } =  useSelector(function(state){
        return state.generals;
    });
    logTrace(defenseBlockGenerals, 'map.tile.defenseBlockGenerals')

    const { appReady, currentUser } = useSelector(function(state){
        return state.app;
    });
    // logTrace({currentUser, appReady }, 'map.tile.unionId 1')

    const myUnionId = currentUser.unionId;
    const occupiederUnionId = (detail.belong || {}).unionId || 0;
    let isNotMine = myUnionId !== occupiederUnionId;

    // console.log('miningResult' ,{detail, isNotMine, isInitBlock, xyIndex, myUnionId, occupiederUnionId});

    let defenseBlockGeneralsMap = {};
    let defensingGeneralId = 0;
    defenseBlockGenerals.forEach(function(item){
        //item = {generalId: 1,x_id: 1, y_id: 1} 
        if(item.x_id === x_id && item.y_id === y_id){
            defensingGeneralId = item.generalId;
        }
    });

    // logTrace({ currentUser, defenseBlockGenerals}, 'map.tile.2')

    const [rulePop, setRulePop] = useState(false);
    const [isStartAttack, setIsStartAttack] = useState(false);

    function getBattleResultInfo(data, type){
        let record = data.record || {};
        let attackInfo = record.attackInfo || {};
        let defenseInfo = record.defenseInfo || {};
        // console.log({record, attackInfo, data, type}, 111111);
        if(!data.record){
            return {
                type: type,
                error: data.error || '',
                attackGloryGet: data.gloryGet || 0,
                durabilityReduce: data.durabilityReduce,
                result: data.result,
                win: data.result
            }
        }
        return {
            type: type,
            error: data.error || '',
            attackGloryGet: attackInfo.gloryGet,
            attackTroopReduce: attackInfo.troopReduce,
            defenseGloryGet: defenseInfo.gloryGet,
            defenseTroopReduce: defenseInfo.troopReduce,
            durabilityReduce: attackResult.durabilityReduce,
            // silverGet: attackInfo.silverGet,
            result: record.result,
            win: record.result
        }
    }
    // console.log('attackResult', attackResult)

    const [ noNeighbor, setNoNeighbor ] = useState({ pop: false });
    const [ notifyPop, setNotifyPop ] = useState(false);
    const [waitingPop, setWaitingPop] = useState(false);
    const [sharePop, setSharePop] = useState(false);

    function startAttack() {
        let isNeighbor = isNeighborTile(detail, mapConfig['ports'], occupieds, {
            myUnionId, x_id, y_id
        });
        if(!isNeighbor){
            setNoNeighbor({
                pop: true,
                tip: Texts['dialog.block_attack.rule']
            });
            setNotifyPop(true);
            return;
        }
        setIsStartAttack(true);            
    }

    function isNeighbor() {
        let isNeighbor = isNeighborTile(detail, mapConfig['ports'], occupieds, {
            myUnionId, x_id, y_id
        });
        return isNeighbor;
    }

    const { strategyReady, info } = useSelector(function(state){
        return state.strategies;
    });
    // let protect = info.protect || {};
    // let protect1 = info.protect1 || {};
    // let protecting = !!protect.able || !!protect1.able;
    const [ informAttackUnderProtecting, setInformAttackUnderProtecting ] = useState(false);
    const [ attackInfo, setAttackInfo ] = useState({});

    function informAttack(general){
        logTrace(general, 'map.attackTile.inform');
        let generalId = general.qualification.general_id;
        dispatch(attackTile({
            x: x_id,
            y: y_id,
            generalId: generalId
        })); 
    }

    useEffect(()=>{
        //after occupied
        let record = attackResult.record || {};
        let blockInfo = attackResult.detail || {};
        let isSameTile = (blockInfo.x_id === x_id && blockInfo.y_id === y_id)
        // console.log('tileAttackWin tileclose', {isSameTile, attackResult, blockInfo, defenseResult, defenseBattleOver})
        if(isSameTile && attackResult.result){
            if(blockInfo.belong.unionId === myUnionId){
                occupiedsTemp[tileData.xyIndex] = { unionId: myUnionId };
                // console.log('tileAttackWin send', myUnionId)
                window.game.events.emit("tileAttackWin", {
                    xyIndex: tileData.xyIndex,
                    unionId: myUnionId
                });
                window.game.events.on("tileUpdated", function(){
                    setTimeout(function(){
                        dispatch(resetBattleResult());
                        props.close && props.close();
                    }, 2000);
                });
            }
        }

        dispatch(resourceRefresh());
        dispatch(getOccupiedTiles());
        dispatch(getTileInfo({
            x: x_id,
            y: y_id
        }));
        dispatch(getDefenseList({
            x: x_id,
            y: y_id
        }));
    }, [attackResult.result]);

    useEffect(()=>{
        dispatch(pointUpdate());
    }, [strategyReady]);

    useEffect(()=>{
        dispatch(getOccupiedTiles());

        dispatch(getDefenseBlockGenerals());
        dispatch(getDefenseList({
            x: x_id,
            y: y_id
        }));
        if(detail.buff_id){
            dispatch(getSkillInfo({ skill_id: detail.buff_id }));            
        }
        let game;
        if(detail.area !== 0 || detail.area !== 5){
            // game = createTileMap(tileData, x_id, y_id);
        }

        return ()=>{
            if(game){
                game.destroy();
            }
        }
    }, []);


    const { shareRecords } = useSelector(function(state){
        return state.user;
    });
    // console.log('shareRecords', shareRecords, attackResult)

    useEffect(()=>{
        dispatch(getShareRecords({
            address: currentUser.username,
            seasonId: currentSeasonId,
            season_id: currentSeasonId
        }))
    }, [attackResult]);

    if(detail.type === 6){
        return (
            <ToastTip text="Mountain" callback={ ()=>{} } />
        );
    }

    // if(props.currentBlockType === 'Assembly'){
    //     return (<div className="App-game-modal-header">{ Texts[keys.name] || keys.name }({x_id},{y_id})</div>)
    // }

    return (<>
        {(isNotMine) && ((detail.protect_time || isInitBlock) ? (<span 
            style={{ verticalAlign: 'top', marginTop: '15px' }}
            className="App-general-btn App-general-btn-green App-btn-disable">
            { Texts['button.block_attack'] }
            <span className="heart">❤</span>-{ getStamina('attackPlots') }
        </span>):(<><span 
            style={{ verticalAlign: 'top', marginTop: '15px' }}
            className="App-general-btn App-general-btn-green"
            _ga_event="block.attack"
            onClick={ (e)=>{ startAttack() } }>
            { Texts['button.block_attack'] }
            &nbsp; <span className="heart">❤</span>-{ getStamina('attackPlots') }
        </span>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CodCreate blockInfo={{ x_id,y_id }} isNeighbor={ isNeighbor() } detail={ detail } />
        </>))}

        {isStartAttack && <SelectGeneral 
            battleType="attackBlock"
            firstDefense={ defenseList[0] || {} }
            defenseList={ defenseList.concat(defenseBlockGenerals) } 
            onClose={ ()=>{ setIsStartAttack(false) } }
            onSelected={ (res)=>{
                let { times, general, flag } = res;
                logTrace({general, flag}, 'map.attackTile.selected');
                setIsStartAttack(false);
                if(flag){
                    // if(protecting){
                    //     setInformAttackUnderProtecting(true);
                    //     setAttackInfo(general);
                    // }else{
                        informAttack(general);
                        setAttackBattleOver(true); 
                    // }
                }
            } 
        } />}
        {informAttackUnderProtecting && <ProtectWarning callback={(confirm)=>{
            setInformAttackUnderProtecting(false);
            if(!confirm){
                return;
            }
            informAttack(attackInfo);
            setAttackBattleOver(true); 
        }} /> }
        { !attackResult.back && attackBattleOver && <Loading 
            type="headless" /> }
        { (attackResult.back && attackBattleOver) && <BattleResult 
            type="block"
            result={ getBattleResultInfo(attackResult, 'attack') } 
            overConfirm={ (result)=>{ 
                setAttackBattleOver(false); 
                dispatch(resetBattleResult());

                let isSharePoped = cache.get('isSharePoped') || '';
                if(!isSharePoped && result){
                    setSharePop(true);
                    cache.set('isSharePoped', '1');
                }
            } 
        } /> }

        {(sharePop && !shareRecords['attack_territory']) && <SnShare 
            content={<div className="App-sns-share">
                    <div className="intro">{ Texts2['dialog.share.intro'] }</div>
                    <div>
                        <span style={{ marginTop: '-23px', display: 'inline-block' }}>
                            <CreateIcon type="coin" size="22" />
                        </span>
                        <span className="App-item-value" style={{ paddingLeft: '10px' }} >
                            { rewardConfig.attack.value }
                        </span>
                    </div>
                </div>} 
            params = { AppGame }
            facebook={ AppGame.prod }
            twitter={ Texts2['dialog.share.content.block'] } 
            onShare={ (data)=>{
                dispatch(setShareRecord({
                    address: currentUser.username,
                    action: 'attack_territory', //
                    platform: data.platform,
                    seasonId: currentSeasonId,
                    season_id: currentSeasonId
                }))
            }
        } />}

        { notifyPop && <ToastTip text={ 
            noNeighbor.tip 
        } callback={ ()=>{ setNotifyPop(false) } } />}
    </>);
}

function isNeighborTile(targetBlock, ports, occupieds, info){
    let flag = false;
    let y = info.y_id;
    let x = info.x_id;
    let myUnionId = info.myUnionId;

    var neighbors = {};

    //top 1
    neighbors[x + '^' + (y + 2)] = true;

    //bottom 1
    neighbors[x + '^' +  (y - 2)] = true;

    //left 2
    neighbors[(x - 1) + '^' +  (y + 1)] = true;
    neighbors[(x - 1) + '^' +  (y - 1)] = true;

    //right 2
    neighbors[(x + 1) + '^' +   (y + 1)] = true;
    neighbors[(x + 1) + '^' +   (y - 1)] = true;

    ports = ports || {};
    let allMyTiles = {};
    let iHasHarbor = false;
    for(var prop in occupieds){
        let unionId = occupieds[prop].unionId || 0;
        if(unionId === myUnionId){
            allMyTiles[prop] = true;
        }
        if(unionId === myUnionId && ports[prop]){
            iHasHarbor = true;
        }
    }

    let isTargetIsHarbor = (targetBlock.type == 2 && targetBlock.parameter == 14) || targetBlock.type == 8;
    if(isTargetIsHarbor && iHasHarbor){
        return true;
    }

    for(var prop in occupiedsTemp){
        let unionId = occupiedsTemp[prop].unionId || 0;
        if(unionId === myUnionId){
            allMyTiles[prop] = true;
        }
    }

    for(var id in neighbors){
        if(allMyTiles[id]){
            flag = true;
        }
    }
    return flag;
}