import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import * as DASlices from '../../da/slice';

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, timeFormat, subs } from '../../utils/utils'
import { unionImagePath, shortString, shortHash } from '../../utils/ui-res'
import { getMapId, getMapConfig } from '../../maps/map-res'
import * as cache from '../../utils/cache'

import mainFortress from './assets/fortress.png';
import mainFortress3 from './assets/castle.png';
import portImg from './assets/port.png';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import { logTrace } from '../../utils/log'

import createBlockView from './view'

import Modal from 'react-modal';

const mapId = 7;
export default function BlockBasic(props) {
    const dispatch = useDispatch(); 
    const { detail, landInfo = {} } = props;

    const { gameStart } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;

    const occupiederUnionId = landInfo.unionId || '0';

    const mapConfig = getMapConfig(mapId);

    const { x_id, y_id } = detail;

    const blockView = createBlockView(x_id, y_id, mapConfig);
  
    return (<>
        <div className="App-Resources-stat" style={{ width: '43.3%', zoom: 0.7, display: 'flex', marginTop: '-13px' }}>
            <div className="App-Resources-tile">
                { detail.type === 2 && (<img src={ mainFortress3 } />) }
                {(detail.type !== 2 && detail.type !== 8) && (<div id="App-single-tile">
                    <div style={{ height: '8px' }}></div>
                    <div style={ blockView.bg }><div style={ blockView.map }></div></div>
                </div>)}
            </div>
            <div className="App-Resources-stat-list" style={{ width: '240px', overflow: 'hidden' }}>
                <div className="App-Resources-stat-title">{ props.title }</div>
                <div className="App-Resources-stat-item">
                    <span style={{ position: 'absolute' }}><CreateIcon type="tile-status" size="32" /></span>
                    <dt>{ Texts['dialog.block_stats'] }</dt>
                    { '0' !== occupiederUnionId && (<dd>
                        <img style={{ width: '20px', borderRadius: '50%', display: 'none' }} src={ unionImagePath(occupiederUnionId) } />
                        <span style={{ verticalAlign: 'top', margin: '0', lineHeight: '1.8' }}>{ Texts['dialog.block_stats_be_occupy'] }</span>
                    </dd>)}
                    <dd>{ '0' === occupiederUnionId && Texts['dialog.block_stats_neutral'] }</dd>
                </div>
                <div className="App-Resources-stat-item">
                    <span style={{ position: 'absolute' }}><CreateIcon type="tile-roseSpeed" size="32" /></span>
                    <dt>Owner</dt>
                    <dd>{ 
                        (landInfo.owner && landInfo.owner !== '0x0000000000000000000000000000000000000000')
                        ? shortHash(landInfo.owner || '', 7) 
                        : '-'
                    }</dd>
                </div>
            </div>
        </div>
    </>);
}
