import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath
} from '../../utils/ui-res'
import { numberFormat, getStamina } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import { CreateIcon } from '../../components'

import GeneralPortrait from './general-portrait'
import GeneralPortraitGPT from './general-gpt'

import { 
    generalListUpdated
} from '../../services/slice-general'

import { Texts, Texts2 } from '../../const/';

export default function GeneralsData(props){
    const dispatch = useDispatch(); 

    const generalList = useSelector(function(state){
        return state.generals.list || [];
    });
    let ableGenerals = [];
    generalList.forEach(function(general){
        if(general.able && general.stamina >= getStamina('spy')){
            ableGenerals.push(general);
        }
    });
    // console.log(ableGenerals)

    const [currentGeneralIndex, setCurrentGeneralIndex] = useState(0);
    const currentGeneral = ableGenerals[currentGeneralIndex];
    function setCurrentGeneralInfo(index, generalId){
        setCurrentGeneralIndex(index);
        props.onSelected(generalId);
    }

    useEffect(()=>{
        setCurrentGeneralInfo(0);
    }, []);

    useEffect(()=>{
        dispatch(generalListUpdated());
    }, [currentGeneralIndex, props.spyResult]);

    if(ableGenerals.length === 0){
        return (<>
            <div style={{ height: '200px',color: '#fff', textAlign: 'center', paddingTop: '4rem' }}>{ Texts2['dialog.battle.no-general'] }</div>
        </>)
    }

    return (<>
        <div className="App-general-detail">
            <div className="App-general-teams" style={{ width: '300px' }}>
                <div className="App-general-persons" style={{ margin: 0 }}>
                    {ableGenerals.map((item, index)=>
                        <div className={ 'App-general-person ' + (item.able ? '': 'App-general-person-disable') } key={ item.qualification.general_id } 
                            _ga_event={ "general.spy.select" }
                            onClick={ (e) => { setCurrentGeneralInfo(index, item.qualification.general_id) }}>
                            <GeneralPortrait generalId={ item.qualification.general_id } />
                            <span className="App-general-type">
                                <img src={ generalTypeImagePath(item.qualification.general_type) } />
                            </span>
                            <span className="App-general-level">{ item.level }</span>
                        </div>
                    )}
                </div>
            </div>

            {currentGeneral.qualification && <div className="App-general-current" style={{ width: '180px', margin: '-50px 100px 0' }}>
                <div className="big-body">
                    <GeneralPortraitGPT generalId={ currentGeneral.qualification.general_id } />
                </div>
                <div className="App-general-detail-energy" style={{ zoom: 1.2 }}>
                    <span>{ currentGeneral.stamina }/{ currentGeneral.qualification.stamina }</span>
                </div>
            </div>}
        </div>
    </>);
}