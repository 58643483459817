import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { randomCampReward } from 'throne-underlying';
import { getSeasonInfo, getAllUnionPlayers, getUserNFTs } from '../../services/slice-user';
import { getUnionOverView } from '../../services/slice-map';
import { 
    getUserSignupInfo, getAllPlayersGeneralPortraits, generateGeneralPortraits, 
    generateGeneralPortraitsTest, generalPortraitConfirm 
} from '../../services/slice-signup';

import GenerateAvatar from './generate-avatar'
import UserScoreBuffsInfo from '../buffs/vip-buff-info'
import { CreateIcon, ClockCounter, Loading, GoBack, Buffs } from '../../components'
import { logTrace } from '../../utils/log';
import {
    unionImagePath, generalBigImagePath, generalImagePath, getNftImageUrl
} from '../../utils/ui-res'

import { Texts, Texts2, NFTConfig } from '../../const/';
import diceImg from './steps/dice.png';

import './steps.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SignUpInfo(props){
    const dispatch = useDispatch();
    const { address, seasonId, chain } = props.data;

    const { seasonDetail, allUnionPlayers, userNFTs } = useSelector(function(state){
        return state.user;
    });
    let currentSeasonInfo = seasonDetail[seasonId] || {};
    let NFTs = userNFTs[seasonId] || [];

    const { 
        userSignupInfo, allPlayersGeneralPortraits, generalPortraitsResult, 
        generalPortraitsTestResult, generalPortraitConfirmResult 
    } = useSelector(function(state){
        return state.signup;
    });
    // console.log('signup',{userSignupInfo, allPlayersGeneralPortraits, generalPortraitsResult, generalPortraitsTestResult, generalPortraitConfirmResult });

    let seasonStatus = currentSeasonInfo.seasonStatus || {};
    const unionId = userSignupInfo.camp;

    const vipSilverBuff = 0.1;

    var nftExistDefault = NFTConfig.nfts;
    const [ nftExist, setNftExist ] = useState([]);
    useEffect(()=>{
        let generalIds = userSignupInfo.general_ids || [];
        let nftInfos = userSignupInfo.nft_infos || {};
        let generalPortraits = userSignupInfo.general_portraits || {};
        let result = [];
        // console.log(NFTs);
        NFTs.forEach(function(nft, index){
            let generalId = generalIds[index] || 0;
            let item = JSON.parse(JSON.stringify(nft));
                item.generalId = generalId;
                item.generalPortrait = generalPortraits[generalId] || {};
                item.emptyImg = item.type ? nftExistDefault[item.type].emptyImg : '';
                item.gpt = !!(nftExistDefault[item.type] || {}).gpt;
                item.nftId = item.type ? nftInfos[generalId].nft_id : 0;
                item.order = 500 + generalId;
            result.push(item);
        });
        // if(NFTs.length === 1){
        //     result.push({});
        // }
        // if(NFTs.length === 0){
        //     result = [{},{}];
        // }
        setNftExist(result);
    }, [userSignupInfo, userNFTs]);
    console.log('nftExist', nftExist, userNFTs, NFTs)

    const [ generatePop, setGeneratePop ] = useState(false);
    const [ generateTestPop, setGenerateTestPop ] = useState(false);
    const [ generateNftData, setGenerateNftData ] = useState({});
    function generateAvatar(nftData){
        setGeneratePop(true);
        setGenerateNftData(nftData);
    }

    useEffect(()=>{
        // dispatch(getSeasonInfo({
        //     seasonId
        // }));
        dispatch(getAllUnionPlayers({
            seasonId
        }));

        dispatch(getUserNFTs({
            address, seasonId
        }));

        dispatch(getUserSignupInfo({ address, seasonId }));
        dispatch(getAllPlayersGeneralPortraits({ seasonId }));
    }, [generatePop]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                width: '1000px',
                height: '400px',
                margin: '-150px 0 0 -500px'
            }
        }}
        className = { 'App-game-modal-gpt App-game-modal-gray-headless' }
        isOpen={ true }
        contentLabel="Preview Map Modal">
        <div style={{ position: 'relative' }}>
            <div _ga_event={ "signup.info.close" } onClick={ ()=>{ props.onConfirm(); }}>
                <GoBack size="60px" />
            </div>
            <div className="App-sign-steps">
                <div className="App-sign-step current" 
                    style={{ display: 'block', textAlign: 'center', color: '#fff' }}>
                    { Texts2['dialog.signup.gpt.title'] }
                </div>
            </div>
            <div className="App-sign-step1">
                <div className="App-sign-nfts App-sign-nftConfig">
                    {nftExist.map((nft, index) => (
                        <div
                            key={ index }
                            style={{ order: nft.order || 5, width: '24%' }} 
                            className={"App-sign-nft App-gpt-nft"}>
                            <h2 className="gpt-title" style={{ marginBottom: '30px' }}>{ nft.name || 'No NFT' }</h2>

                            <NFTImage nftData={ nft } />
                            <span style={{ margin: '2px' }}><CreateIcon type="greenArr" size="24" /></span>
                            <GeneralImage nftData={ nft } />

                            <div style={{ textAlign:'right', paddingTop: '10px' }}>
                                {nft.generalId > 0 ? <span 
                                    style={{ display: nft.gpt ? 'inline-block' : 'none' }}
                                    _ga_event={ "signup.info.gpt" }
                                    onClick={ ()=>{ generateAvatar(nft) } }
                                    className="App-general-btn App-general-btn-green">
                                    { Texts2['dialog.signup.gpt.btn.gpt'] }
                                </span> : <span className="App-no-nft">
                                    { Texts2['dialog.signup.gpt.no-nft'] }
                                </span>}
                            </div>
                        </div>
                    ))}
                    {[...Array( 2- nftExist.length )].map((x, index) =>
                        <div key={ index } style={{ width: '33%' }} className={"App-sign-nft App-gpt-nft"}></div>
                    )}
                    {generatePop && <GenerateAvatar 
                        onConfirm = { (res)=>{
                        }}
                        onClose = { (res)=>{
                            // console.log('GenerateAvatar', res);
                            setGeneratePop(false);
                        }}
                        chain={ chain }
                        address={ address }
                        seasonId={ seasonId }
                        nftData={ generateNftData } 
                    />}

                    <div 
                        style={{ order: 800, width: '240px' }} 
                        className="App-sign-nft App-sign-camp App-gpt-nft">
                        <UserScoreBuffsInfo address={ address } />
                    </div>

                    <div 
                        style={{ order: 900 }} 
                        className="App-sign-nft App-sign-camp App-gpt-nft">
                        <h2 className="gpt-title">{ Texts2['dialog.signup.gpt.camp'] }</h2>
                        {unionId && <img src={ unionImagePath(unionId) } style={{ height: '74px', borderRadius: '50%', margin: '30px auto' }} />}
                        <span className="camp">{ Texts['dialog.camp_' + unionId + '.name'] }</span>
                    </div>
                </div>
                <div className="App-sign-content">{ Texts2['dialog.signup.gpt.content'] }</div>

                <div style={{ 
                    textAlign:'center', paddingTop: '10px', 
                    display: window.location.hash === '#TestGenerate' ? 'block' : 'none' }}>
                    <span 
                        _ga_event={ "signup.info.gpt.test" }
                        onClick={ ()=>{ setGenerateTestPop(true) } }
                        className="App-general-btn App-general-btn-green">
                        Test Generate
                    </span>
                </div>
                {generateTestPop && <GenerateAvatar 
                    onConfirm = { (res)=>{
                    }}
                    onClose = { (res)=>{
                        // console.log('GenerateAvatarTest', res);
                        setGenerateTestPop(false);
                    }}
                    test="1"
                    address={ address }
                />}
            </div>
        </div>
    </Modal>);
}

function GeneralImage(props){
    let { nftData } = props;
    let portrait = nftData.generalPortrait || {};
    if(portrait.image){
        return <div className={"role " + (portrait.share_confirmed ? 'share_confirmed' : 'share_not_confirmed' )}>
            <img className="general-image" src={ portrait.image } />
        </div>
    }
    if(nftData.generalId){
        return <div className="role"><img className="general-image" src={ generalImagePath({index: nftData.generalId}) } /></div>
    }
    return <div className="role none">None</div>
}

function NFTImage(props){
    let { nftData } = props;
    if(nftData.nftId){
        return <div className="role"><img src={ nftData.image || getNftImageUrl(nftData.type, nftData.nftId)  } /></div>
    }
    return <div className="role none">None</div>
}
