import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAllPlayers } from '../../services/slice-user';

import TilePop from '../block/block-pop';
import GeneralPortrait from '../generals/general-portrait';

import { 
    unionImagePath,
    generalImagePath, generalTypeImagePath, 
    getNickName, shortString
} from '../../utils/ui-res'

import vIcon from './assets/victory.png'
import failIcon from './assets/failure.png'

import { Texts, CodTexts } from '../../const/';
import { CreateIcon } from '../../components'
import { GoBack, ToastTip } from '../../components'
import { subs, dayTimeFormat } from '../../utils/utils'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Records(props){
    const dispatch = useDispatch();
    // texts
    let typeMap = {
        attack_city: 'dialog.battle_log.type_user_attack',
        defense_city: 'dialog.battle_log.type_user_defense',
        attack_block: 'dialog.battle_log.type_city_attack',
        defense_block: 'dialog.battle_log.type_city_defense',
        attack_block_durability: 'dialog.attack_block_durability_success',
        attack_spy: 'dialog.battle_log.spy',
        defense_spy: 'dialog.battle_log.spy',
        attack_assembly: 'dialog.report.assembly_attack_plots',
        defense_assembly: 'dialog.report.assembly_attack_plots'
    };

    const { allPlayers } = useSelector(function(state){
        return state.user;
    });

    let records = props.records;
    let all = props.all;
    console.log('records', records)

    let list = [];
    records.forEach(function(item, index){
        let newItem = JSON.parse(JSON.stringify(item));
        newItem.tip = 'dialog.' + item.type + (item.recordType === 'city' ? '' : '_' + item.recordType);
        newItem.block_name = 'dialog.map.block_' + item.blockInfo.type + '_' + (item.blockInfo.parameter || 'parameter') + '.name';
        newItem.title = (Texts[typeMap[item.type + '_' + item.recordType]] || CodTexts[typeMap[item.type + '_' + item.recordType]])  + ' ';
        if(item.blockInfo.durabilityReduce){
            newItem.tip = 'dialog.attack_block_durability';
        }
        list.push(newItem);
        // console.log(typeMap[item.type + '_' + item.recordType]);
    });
    // console.log(list);

    const [tileInfo, setTileInfo] = useState({pop:false});
    function openTile(blockInfo){
        let data = {
            // x: x,
            // y: y,
            isBlock: false,
            xyIndex: blockInfo.x_id + '^' + blockInfo.y_id,
            target: {}
        }
        setTileInfo({
            pop: true,
            data: data
        })
    }

    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    
    useEffect(()=>{
        dispatch(getAllPlayers({
            seasonId : currentSeasonId
        }))
    }, [currentSeasonId]);

    return (<>
        <div className="App-record-list">
            {list.map((item, index) => 
            (<div className="App-record-item" key={ index }>
                <div className="App-record-item-info">
                    <div className="App-record-me">
                        <div className="App-general-person">
                            <GeneralPortrait generalId={ item.myInfo.generalId || -1 } />
                            {item.myInfo.generalId > -1 && (<span className="App-general-type">
                                <img alt="" src={ generalTypeImagePath((all[item.myInfo.generalId] || {}).general_type) } />
                            </span>)}
                            <span className="App-general-level">{ item.myInfo.generalLevel }</span>
                            <div className="App-battle-block">
                                {item.leader && <span className="App-general-username" title={ item.leader }>
                                    { getNickName(allPlayers, item.leader) }
                                </span>}
                                {(!item.leader && item.myInfo.username) && <span className="App-general-username" title={ item.myInfo.username }>
                                    { getNickName(allPlayers, item.myInfo.username) }
                                </span>}
                                {item.myInfo.unionId > 0 && <img className="union" src={ unionImagePath(item.myInfo.unionId) } title={ item.myInfo.username }/>}
                            </div>
                        </div>
                    </div>
                    <div className="App-record-result">
                        <span style={{ margin: '0 10px' }}>
                            <CreateIcon type="greenArr" size="26" /> 
                        </span> 
                        <div className="time">{ dayTimeFormat(item.timestamp/0.001) }</div>
                        {
                            item.result ? 
                            (<div className="App-record-result-victory">
                                { item.title }
                                { Texts['dialog.battle_log.victory'] }
                            </div>) :
                            (<div className="App-record-result-fail">
                                { item.title }
                                { Texts['dialog.battle_log.defeat'] }
                            </div>)
                        }
                        {(item.recordType === 'block' || item.recordType === 'assembly') && (<div className="App-general-pos" 
                        _ga_event="record.block"
                        onClick={ ()=>{ openTile(item.blockInfo); }}>
                            { '[' + item.blockInfo.x_id + ',' +  item.blockInfo.y_id + ']' }
                        </div>)}
                    </div>
                    <div className="App-record-opposite">
                        <div className="App-general-person">
                            <GeneralPortrait generalId={ item.enemyInfo.generalId } />
                            {item.enemyInfo.generalId > -1 && (<span className="App-general-type">
                                <img alt="" src={ generalTypeImagePath(all[item.enemyInfo.generalId].general_type) } />
                            </span>)}
                            {item.enemyInfo.generalLevel > 0 && <span className="App-general-level">{ item.enemyInfo.generalLevel }</span>}
                            <div className="App-battle-block">
                                {item.enemyInfo.username && <span className="App-general-username" title={ item.enemyInfo.username }>
                                    { getNickName(allPlayers, item.enemyInfo.username) }
                                </span>}
                                {item.enemyInfo.unionId > 0 && <img className="union" src={ unionImagePath(item.enemyInfo.unionId) } title={ item.enemyInfo.username }/>}
                            </div>
                        </div>
                    </div>
                </div>

                {item.result ? 
                (<div className="App-record-desc App-record-victory">
                    <img alt="" src={ vIcon } />
                    <p>
                        {
                            subs(Texts[item.tip + '_success'] || Texts[item.tip] || '', {
                                enemy_name: shortString(getNickName(allPlayers, item.enemyInfo.username), 10),
                                enemy_silver: item.myInfo.silverGet,
                                durabilityReduce: item.blockInfo.durabilityReduce || 0,
                                glory: item.myInfo.gloryGet,
                                enemy_troop: Math.round(item.enemyInfo.troopReduce),
                                mine_troop: Math.round(item.myInfo.troopReduce),
                                block_name: Texts[item.block_name]
                            })
                        }
                    </p>
                </div>) :
                (<div className="App-record-desc App-record-fail">
                    <img alt="" src={ failIcon } />
                    <p>
                        {
                            subs(Texts[item.tip + '_fail'] || Texts[item.tip] || Texts['dialog.' + item.type + '_' + item.recordType + '_fail'] || '', {
                                enemy_name: shortString(getNickName(allPlayers, item.enemyInfo.username), 10),
                                enemy_silver: item.myInfo.silverGet,
                                durabilityReduce: item.blockInfo.durabilityReduce || 0,
                                glory: item.myInfo.gloryGet,
                                enemy_troop: Math.round(item.enemyInfo.troopReduce),
                                mine_troop: Math.round(item.myInfo.troopReduce),
                                mine_silver: item.enemyInfo.silverGet,
                                block_name: Texts[item.block_name]
                            })
                        }
                    </p>
                </div>)}
            </div>)
            )}
        </div>

        {tileInfo.pop && <TilePop tileInfo={ {data: tileInfo} } close = { () => { setTileInfo({pop: false}) } } />}
    </>);
}

function createMockRecords(typeMap){
    let mockRecords = [];
    for(var prop in typeMap){
        (function(key){
            let mockItem = {
                myInfo: {
                    generalId: 1,
                    generalLevel: 2,
                    username: 'test',
                    troopReduce: 1000,
                    silverGet: 100,
                    gloryGet: 100
                },
                enemyInfo: {
                    generalId: -1,
                    generalLevel: 2,
                    username: 'test1',
                    troopReduce: 1500,
                    silverGet: -100,
                    gloryGet: 100
                },
                blockInfo: { x_id: -1, y_id: -1 },
                type: 'attack',
                timestamp :0,
                recordType:"city",
                result: true
            };

            // console.log(key);
            let ts = key.split('_');
            mockItem.type = ts[0];
            mockItem.recordType = ts[1];
            mockItem.myInfo.generalId = Math.ceil(Math.random()*99999)%9 + 1;
            mockItem.tip = 'dialog.' + mockItem.type + (mockItem.recordType === 'city' ? '' : '_' + mockItem.recordType);

            let mockItem1 = JSON.parse(JSON.stringify(mockItem));
            mockItem1.result = true;
            mockRecords.push(mockItem1);

            let mockItem2= JSON.parse(JSON.stringify(mockItem));
            mockItem2.result = false;
            mockRecords.push(mockItem2);
        })(prop);
    }
    return mockRecords;
}