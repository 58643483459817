import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import { getOccupiedTiles, getUnionOverView } from '../../services/slice-map';
import { getCodList } from '../../services/slice-general';

import { unionImagePath } from '../../utils/ui-res'
import { colors, getMapId, getMapConfig } from '../../maps/map-res'

import SceneBase from './scene-base';

import { GoBack, Ranks } from '../../components'
import SignUpStats from '../signup/signup-stat';

import { Texts, Texts2 } from '../../const/';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function MapPreview(){
    const dispatch = useDispatch();
    const { codsAll } = useSelector(function(state){
        return state.generals;
    });
    const { occupieds } = useSelector(function(state){
        return state.map;
    });
    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const { seasonDetail } = useSelector(function(state){
        return state.user;
    });
    const mapId = getMapId(seasonDetail[currentSeasonId].seasonBasicInfo);
    const mapConfig = getMapConfig(mapId);
    const width = mapConfig.width;
    const height = mapConfig.height;

    const [isSnapshot, setIsSnapshot] = useState(false);
    const [playerStatPop, setPlayerStatPop] = useState(false);

    let _occupieds = [];
    let mapResults = {};
    let occupiedTotals = [];

    for(var prop in occupieds){
        let _area = occupieds[prop].unionId || 0;
        if(_area){
            _occupieds[prop] = {
                color: colors[_area],
                unionId: occupieds[prop].unionId
            };

            mapResults[_area] = mapResults[_area] || 0;
            mapResults[_area] += 1;
        }
    }

    for(var prop1 in mapResults){
        occupiedTotals.push({
            name: prop1,
            total: mapResults[prop1]
        });
    }


    const { unionPlayersWithGlory } = useSelector(function(state){
        return state.map;
    });
    // console.log(occupiedTotals);

    let gamePreview = null;

    function afterOpenModal(){
        const config = {
            callbacks: {
                preBoot: game => {
                },
            },
            type: Phaser.CANVAS,
            disableContextMenu: true,
            title: 'League of Thrones',
            parent: "App-phaser-preview",
            width: width,
            height: height,
            transparent: true,
            canvasStyle: 'height:100%;',
            scene: [ new SceneBase({occupieds, codsAll, mapConfig}, true) ]
        };
        gamePreview = new Phaser.Game(config);
    }

    useEffect(()=>{
        dispatch(getUnionOverView());
        dispatch(getOccupiedTiles());
        dispatch(getCodList());
        return (()=>{
            gamePreview && gamePreview.destroy();
        });
    }, [isSnapshot, dispatch, gamePreview]);

    return (<>
        <div className="App-preview-map-btn" _show={ isSnapshot.toString() } id="App-preview-map-btn" 
            _ga_event="map.preview.close"
            onClick={()=>setIsSnapshot(!isSnapshot)}>
            <span className="text">{ Texts['button.map_review.title'] }</span>
        </div>

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '820px',
                    height: '475px',
                    margin: '-200px 0 0 -410px'
                }
            }}
            className = { 'App-game-modal-mappreview App-game-modal-gray' }
            isOpen={ isSnapshot }
            onAfterOpen = { afterOpenModal }
            onRequestClose = { () => setIsSnapshot(false) }
            contentLabel="Preview Map Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event={ "map.preview.open" } onClick={ ()=>{ setIsSnapshot(false)  } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ padding: '10px', lineHeight: '2' }}>
                    { Texts['button.map_review.title'] }
                </div>
                <div className="App-game-modal-content2" style={{ display: 'flex',padding:'10px 0' }}>
                    <div className="App-game-preview-rule App-seaon-map-canvas-rotate" style={{ width: '480px' }}>
                        <div id="App-phaser-preview" style={{ width: '480px', height: '380px' }}></div>
                    </div>
                    <div className="App-game-table App-game-table-rank" style={{ clear: 'both', marginLeft:'0' }}>
                        <table style={{ margin: '-5px 15px 0',width: '90%' }}>
                            <thead><tr>
                                <td>{ Texts2['dialog.camp'] }</td>
                                <td className="right">{ Texts2['dialog.player'] }</td>
                                <td className="right">{ Texts2['dialog.plots'] } &nbsp;</td>
                            </tr></thead>
                            <tbody>
                            { 
                                occupiedTotals.map(
                                    (item) => (<tr key={item.name}>
                                        <td>&nbsp;&nbsp; <img alt="" src={ unionImagePath(item.name) } style={{ width: '20px',borderRadius: '10px' }} /></td>
                                        <td className="right">{ (unionPlayersWithGlory[item.name-1] || []).length } &nbsp;</td>
                                        <td className="right">{ item.total } &nbsp;&nbsp;</td>
                                    </tr>)
                                )
                            }
                            </tbody>
                        </table>
                        <div className="App-game-modal-content-desc" style={{ margin: '10px' }}>
                            { Texts['button.map_review_rule.desc'] }
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <span className="App-general-btn App-general-btn-blue" 
                                _ga_event="map.preview.overview"
                                onClick={()=>setPlayerStatPop(true)}>
                                { 'OVERVIEW' }
                            </span>
                        </div>
                        {playerStatPop && <SignUpStats seasonId={ currentSeasonId } from="preview" close={()=>{ setPlayerStatPop(false) }} />}
                    </div>
                </div>
            </div>
        </Modal>
    </>);
}