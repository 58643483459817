import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    generalListUpdated, getGeneralQuaValue, 
    getCodList, getCodGeneralIds, resetCodResult, createCod, cancelCod, joinCod, quitCod,
    getCodCreatorDetail 
} from '../../services/slice-general'
import { getSeasonConfig } from '../../services/slice-map'
import { resourceRefresh } from '../../services/slice-city'

import GeneralPortrait from '../generals/general-portrait'
import { CreateIcon, GoBack, ClockCounter, LogisIcon } from '../../components'
import { NotifyPop, ToastTip } from '../../components'
import * as cache from '../../utils/cache'

import { Texts2, Chains, CodTexts } from '../../const'
import { numberFormat } from '../../utils/utils'
import { unionImagePath, generalImagePath, generalTypeImagePath, getNickName } from '../../utils/ui-res'
import { logTrace } from '../../utils/log'

import './cod.scss'

export default function CodDetal(props) {
    let dispatch = useDispatch();
    let { codId } = props;

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { allPlayers } = useSelector(function(state){
        return state.user;
    });

    const { codCreatorDetail, codResult } = useSelector(function(state){
        return state.generals;
    });
    const battleInfo = codCreatorDetail.battleInfo || {};
    const generalInfo = codCreatorDetail.generalInfo || {};
    const qualificationInfo = codCreatorDetail.qualificationInfo || {};
    // console.log('codCreatorDetail', codCreatorDetail)
    // console.log('codCreatorDetail', codResult)

    const { codsAll } = useSelector(function(state){
        return state.generals;
    });

    let currentCod = codsAll[codId] || {};
    let members = currentCod.members || [];

    const [isQuitCod, setIsQuitCod] = useState(false);

    useEffect(()=>{
        dispatch(getCodList());
        dispatch(getCodCreatorDetail(codId));
        dispatch(resourceRefresh());
    }, []);

    function getLastTime(codItem){
        if(!codItem.lastTime){
            return -1;
        }
        let n = new Date().getTime();
        let time = Math.floor(codItem.createTime + codItem.lastTime - n/1000);
        return time;
    }

    if(!currentCod.creator){
        return (<div className="App-block-panel-cod">
            <div className="App-block-cod">assembly not exist</div>
        </div>);
    }

    if(!battleInfo.base){
        return (<></>);
    }

    return (<div className="App-block-panel-cod">
        <div className="App-cod-header">
            {getLastTime(currentCod) >= -10 && <div className="App-general-cod-lastTime">
                <label>Assembling</label>
                <span><ClockCounter time={ getLastTime(currentCod) + 4 } onEnd={ ()=>{
                    dispatch(getCodCreatorDetail(codId));                    
                }} /></span>
            </div>}
            <div className="App-general-person" >
                <GeneralPortrait generalId={ currentCod.generalId } />
                <span className="App-general-type">
                    <img src={ generalTypeImagePath(qualificationInfo.general_type) } />
                </span>
                <span className="App-general-level">{ generalInfo.level }</span>
            </div>
            {(!!battleInfo.base) && (<div className="App-general-data">
                <div className="App-general-logis-item" key={ 'attack' }>
                    <LogisIcon type={ 'attack' } style={{ marginLeft: '-20px' }} />
                    <div className="stats">{ 'attack'.toUpperCase() }</div>
                    <div className="current" style={{ width: '40%' }}>
                        {numberFormat(battleInfo.base.attack) }
                        <span className="plus">+{ numberFormat(battleInfo.sum.attack - battleInfo.base.attack) }</span>
                    </div>
                </div>
                <div className="App-general-logis-item" key={ 'defense' }>
                    <LogisIcon type={ 'defense' } style={{ marginLeft: '-20px' }} />
                    <div className="stats">{ 'defense'.toUpperCase() }</div>
                    <div className="current" style={{ width: '40%' }}>
                        {numberFormat(battleInfo.base.defense) }
                        <span className="plus">+{ numberFormat(battleInfo.sum.defense - battleInfo.base.defense) }</span>
                    </div>
                </div>
                <div className="App-general-logis-item" key={ 'load' }>
                    <LogisIcon type={ 'load' } style={{ marginLeft: '-20px' }} />
                    <div className="stats">{ 'load'.toUpperCase() }</div>
                    <div className="current" style={{ width: '40%' }}>
                        {numberFormat(battleInfo.base.load) }
                        <span className="plus">+{ numberFormat(battleInfo.sum.load - battleInfo.base.load) }</span>
                    </div>
                </div>
            </div>)}
        </div>

        <div className="App-cod-msg-troops">
            <label>{ CodTexts['dialog.assembly_troops'] }: </label>
            <span>{ currentCod.troopNow }/{ currentCod.troopTotal }</span>
        </div>

        <div className="App-block-cod-members">
        {members.map((member, index)=>
            <div className="App-block-cod-member" key={ index }>
                <div className="App-general-person" >
                    <GeneralPortrait generalId={ member.generalId } />
                </div>

                <div className="App-block-cod-troops">
                    <h2>{ member.username }</h2>
                    <label>{ CodTexts['dialog.assembly_sent_troops'] }: </label>
                    <span>{ member.troops }/{ currentCod.troopTotal }</span>
                </div>

                {currentUser.username === member.username && <span
                    className={ "App-general-btn App-general-btn-blue" }
                    _ga_event="assembly.quit"
                    onClick = { (e) => {
                        setIsQuitCod(true);
                        if(currentCod.creator === member.username){
                            dispatch(cancelCod(currentCod));
                            return;
                        }
                        dispatch(quitCod(currentCod));
                    }}>
                    <CreateIcon type="quit" size="20" /> &nbsp;&nbsp;
                    { 
                        currentCod.creator === member.username 
                        ? CodTexts['button.assembly_cancel'] 
                        : CodTexts['button.assembly_quit'] 
                    }
                </span>}
            </div>
        )}
        
        {(codResult.back && isQuitCod) && <NotifyPop tips={{
            success: codResult.op + ' success',
            fail: codResult.error || CodTexts['dialog.assembly_error']
        }} data={ codResult } callback={ ()=>{
            dispatch(getCodCreatorDetail(codId));
            dispatch(resetCodResult())
            setIsQuitCod(false);
        }} />}
        </div>
    </div>);
}
