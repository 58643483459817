import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import SeasonMap from './cover';
import SeasonVote from './map-vote';
import { GoBack } from '../../components'

import { Texts } from '../../const/';
import mapList from '../../maps/map_list.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SeasonMapViewer(props){
    const dispatch = useDispatch(); 
    const { seasonId, account, mapInfo, seasonStatus } = props;
    let currentMapId = mapInfo['map_result'] || 1;
    const voteData = mapInfo['votes'] || {};
    for(let addr in voteData){
        if(addr.toLowerCase() === account){
            currentMapId = voteData[addr];
        }
    }

    const isVote = mapInfo['map_config'] === 'vote' && seasonId.indexOf('vote') > -1;

    const [selectMapId, setSelectMapId] = useState(currentMapId);
    console.log(voteData, mapInfo, currentMapId, account)

    useEffect(() => {
    }, []); 

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                height: '480px',
                width: '800px',
                margin: '-240px 0 0 -400px'
            }
        }}
        className = { 'App-game-modal-vote App-game-modal-gray' }
        isOpen={ true }
        onRequestClose = { () =>{ props.onClose() } }
        contentLabel="Preview Map Modal">
        <div style={{ position: 'relative' }}>
            <div onClick={ ()=>{ props.onClose() } }>
                <GoBack size="60px" />
            </div>
            <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>{ Texts['button.map_preview'] }</div>
            <div className="App-game-map-view" style={{ display: 'flex',padding:'10px 0' }}>
                <div className="App-game-map-view-main">
                    {mapList.map((mapItem, index)=><div key={ index }>
                        {selectMapId === mapItem.map_id && <SeasonMap mapInfo={ { map_result: mapItem.map_id } } />}
                    </div>)}
                </div>
                <SeasonVote isVote={ isVote } seasonId={ seasonId } seasonStatus={ seasonStatus } account={ account } currentMapId={ currentMapId } voteData={ voteData } onSelected={ (mapId)=>{
                    setSelectMapId(mapId);
                } } /> 
            </div>
        </div>
    </Modal>);
}