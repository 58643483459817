import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './claim.scss';
import { ClockCounter, CreateIcon } from '../../components'

import { timeFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import Texts from '../../const/texts.json';

import { generalImagePath } from '../../utils/ui-res'
import { generalInit, getDefenseCityGeneralId, setDefenseCityGeneral, generalListUpdated } from '../../services/slice-general';
import { cityInit, recruitTroopsOver, getRecruitState } from '../../services/slice-city'

import SelectGeneral from '../generals/generals-selector'
import GeneralPortrait from '../generals/general-portrait';

export default function TroopsClaim(props){
    const dispatch = useDispatch(); 

    let kpcData = { title: 'Silver Info', speed: '200/1h'};

    const { generalReady, defenseCityGeneralId } = useSelector(function(state){
        return state.generals;
    });

    const { cityReady, recruitResult} = useSelector(function(state){
        return state.city;
    });
    // console.log(recruitResult);

    logTrace({defenseCityGeneralId, recruitResult}, 'training.app');

    let recruiTime = getRecruiTime(recruitResult.endtime);

    function getRecruiTime(endtime){
        //endtime, timestramp as seconds;
        return endtime - (new Date().getTime())/1000;
    }

    const [selectGeneralPop, setSelectGeneralPop ] = useState(false);

    function setSelectGeneral(general, flag){
        let generalId = -1;
        if(flag){
            generalId = general.id;
        }
        dispatch(setDefenseCityGeneral({
            generalId: generalId
        }))
        dispatch(getDefenseCityGeneralId());
        setSelectGeneralPop(false);
    }

    const generalList = useSelector(function(state){
        // console.log('state.generals',state.generals);
        return state.generals.list || [];
    });
    const { resources } = useSelector(function(state){
        return state.city;
    });
    const hasAbleGeneral = function(){
        let result = false;
        let list = generalList || [];
        list.forEach(function(item){
            if(item.able){
                result = true;
            }
        });
        return result;
    }
    const [hasGeneral, setHasGeneral ] = useState(false);


    useEffect(()=>{
        setHasGeneral(hasAbleGeneral());
    }, [generalList]);

    useEffect(()=>{
        dispatch(getDefenseCityGeneralId());
        dispatch(generalListUpdated());
    }, [cityReady, generalReady, resources]);

    return (<>
        <div className="App-mod-rose" style={{ display: 'none' }}>
            <div className="App-mod-rose-title">{ kpcData.title }</div>
            <span className="App-mod-rose-value" style={{ paddingLeft: '10px' }}>
                <CreateIcon type="silver" />
                <span style={{ paddingLeft: '10px' }}>{ kpcData.speed }</span>
            </span>
        </div>

        { recruitResult.status === 'going' && (<div className="App-recruit-status"
            _ga_event="troops.status.going"
            onClick={ ()=>{ 
                props.showTroops();
            } }>
            <div className="recruiting">
                <div className="status">{ Texts['dialog.army.human_recruit'] }</div>
                <div className="time">
                    <ClockCounter time={ recruiTime } onEnd={ ()=>{
                        dispatch(getRecruitState());
                    } } />
                </div>
            </div>
        </div>)}

        { recruitResult.status === 'ready' && (<div className="App-recruit-status"
            style={{ cursor: 'pointer' }} 
            _ga_event="troops.status.ready"
            onClick={ ()=>{ 
                props.showTroops();
            } }>
            <div className="recruiting">
                <span className="App-redpoint" style={{ left: '132px', top: '12px' }}>ready</span>
                <div className="status">
                    { Texts['dialog.army.recruit_compete'] }
                </div>
                <div className="time">{ recruitResult.amount }</div>
            </div>
        </div>)} 

        <div className="App-maincity-status" 
            _ga_event="troops.general.select"
            onClick={ ()=>{ setSelectGeneralPop(true) } }>
            {defenseCityGeneralId && <GeneralPortrait generalId={ defenseCityGeneralId } cls="no-cls" />}
            {defenseCityGeneralId > -1 ? (<div className="defensing">
                { Texts['dialog.general.card.name.' + defenseCityGeneralId ]}
            </div>) :
            (<div className="nodefense">
                {hasGeneral && <span className="App-redpoint" style={{ margin: '0px 0 0 85px' }}>city upgrade</span>}
                { Texts['dialog.city_no_defense'] }
            </div>)}
        </div>

        { selectGeneralPop && <SelectGeneral 
            battleType="defenseCity" 
            defenseList={ [{ generalId:defenseCityGeneralId}] } 
            onClose={ ()=>{ setSelectGeneralPop(false) } }
            onSelected={ (res)=>{
                let { times, general, flag } = res;
                setSelectGeneral(general, flag);
            } 
        } />}
    </>);
}