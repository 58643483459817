import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NumericFormat from 'react-number-format';
import { Ranks, CreateIcon } from '../../components'
import { Texts, Texts2, Chains, RewardCoins } from '../../const/';
import { numberFormat, dateFormat, getWalletSignErrMessage, showPriceData } from '../../utils/utils'
import { unionImagePath } from '../../utils/ui-res'

import * as DASlices from '../../da/slice';

// import Modal from 'react-modal';
// Modal.setAppElement('#root');

function getMyBlockTotal(lands, address){
    let total = 0;
    for(var posId in lands){
        if(lands[posId].owner === address){
            total += 1;
        }
    }
    return total;
}

export default function SeasonStatus(props){
    const dispatch = useDispatch();

    const [recordsPop, setRecordsPop] = useState(true);

    let { gameStart, gameInfo, lands, shareInfo, claimedReword } = useSelector(function(state){
        return state.dauction;
    });
    const { rewords = [] } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;
    gameInfo = gameInfo[seasonId] || {};
    shareInfo = shareInfo[seasonId] || {};
    // console.log(gameInfo, shareInfo, claimedReword);

    useEffect(()=>{
        if(!address || !seasonId || !unionId){
            return;
        }

        let params = {
            unionId,
            seasonId,
            address
        };
        dispatch(DASlices.getGameInfo(params));
        dispatch(DASlices.getShareInfo(params));
        dispatch(DASlices.getClaimedReword(params));
    }, [gameStart, recordsPop]);

    function getRankIndex(address){
        let list = JSON.parse(JSON.stringify(rewords));
        list = list.sort(function(a, b){
            return a.divident/1 > b.divident/1 ? -1: 1;
        });
        let rankIndex = 1;
        list.forEach(function(item, index){
            if(item.owner.toLowerCase() === address.toLowerCase()){
                rankIndex = index + 1;
            }
        });
        return rankIndex;
    }

    return <div className="App-acution-season-status">
        <div className="App-reward-pool-item">
            <label>Plots</label>
            <span>
                <CreateIcon type="blocks" />
                { ' ' + getMyBlockTotal(lands, address) }
            </span>
        </div>
        <div className="App-reward-pool-item">
            <label>Income</label>
            <span>
                <CreateIcon type="zeta" /> { ' ' }
                <NumericFormat value={ showPriceData(claimedReword.divident || 0, 18, 4) } displayType="text" thousandSeparator="," />
            </span>
        </div>
        <div className="App-reward-pool-item">
            <label>Stocks</label>
            <span>
                <CreateIcon type="camp" /> { ' ' }
                <NumericFormat value={ claimedReword.shares || 0 } displayType="text" thousandSeparator="," />
            </span>
        </div>

        <div className="App-reward-pool-item">
            <label>Rank</label> &nbsp;
            <div className={ 'App-reward-useravatar App-reward-useravatar-' + getRankIndex(address) }>
                { getRankIndex(address) }
            </div>
        </div>
    </div>
}
