import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resourceRefresh } from '../../services/slice-city'
import { pointUpdate, resetBuyResult, refreshPoints } from '../../services/slice-strategies'
import { buySilver, buyTroop, buyMorale, buyProtect, buyStore, buyProtect1 } from '../../services/slice-strategies'

import GetResourceMutiTimes from './get-resource-muti'

import { getPolicyIcon } from '../../utils/ui-res'
import { CreateIcon, ClockCounter, ProgressBarMini } from '../../components'
import { NotifyPop, ToastTip } from '../../components'
import { numberFormat, timeFormat, subs } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import Texts2 from '../../const/texts2.json';
import './strategies.scss'

export default function Strategies(props){
    const dispatch = useDispatch(); 

    const { strategyReady, pointInfo, need, pointNeed, info } = useSelector(function(state){
        return state.strategies;
    });
    // console.log({ need, pointNeed, info }, 'strategy.js');

    const { buyResult } = useSelector(function(state){
        return state.strategies;
    });
    logTrace(buyResult, 'strategy.buyResult');
    logTrace(pointInfo, buyResult, 'strategy.pointInfo');
    console.log(pointInfo, need, 'strategy.pointInfo');

    const [type1, setType1] = useState('');
    const [type, setType] = useState('');
    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    const [isMutiTimesPop, setIsMutiTimesPop] = useState(false);
    const [mutiTimesType, setMutiTimesType] = useState('');

    function strategyConfirm(type){
        setType1(type);
        if(type === 'buyMorale'){
            setType('get_morale');
            dispatch(buyMorale());
        }
        if(type === 'buyTroop'){
            setType('get_troops');
            dispatch(buyTroop(1));
        }
        if(type === 'buySilver'){
            setType('get_silver');
            dispatch(buySilver(1));
        }
        if(type === 'protect'){
            setType('protect');
            dispatch(buyProtect());
        }
        if(type === 'protect1'){
            setType('protect1');
            dispatch(buyProtect1());
        }
        if(type === 'store'){
            setType('store');
            dispatch(buyStore());
        }
    }

    useEffect(()=>{
        dispatch(pointUpdate());
    }, [strategyReady]);

    useEffect(()=>{
        dispatch(refreshPoints());
        dispatch(resourceRefresh());
        dispatch(pointUpdate());
    }, [buyResult]);

    return (
        <div className="App-policy-list">
            <div className="App-policy-item">
                <img className="icon" src={ getPolicyIcon('get_gold') } />
                <div className="App-policy-item-desc">
                    <h2>{ Texts2['dialog.order.get_gold.name'] }</h2>
                    <p>{ subs(Texts2['dialog.order.get_gold.desc'], { count: info.buySilverCount }) }</p>
                </div>
                {pointInfo.strategyPoint < need.buySilver ? (<span _ga_event="strategy.silver.disable" onClick={ ()=>{
                        setType('buySilver');
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                    x{ need.buySilver }
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="strategy.silver"
                    onClick = { (e) => { 
                        if(pointInfo.strategyPoint/need.buySilver >= 2){
                            setMutiTimesType('buySilver');
                            setIsMutiTimesPop(true);
                            return;
                        }
                        setWaitingPop(true);
                        strategyConfirm('buySilver') 
                    }}>
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                     x{ need.buySilver }
                </span>)}
            </div>

            {isMutiTimesPop && <GetResourceMutiTimes data={ {
                mutiTimesType
            } } onClose={ ()=>{ setIsMutiTimesPop(false) } }/>}

            <div className="App-policy-item">
                <img className="icon" src={ getPolicyIcon('get_human') } />
                <div className="App-policy-item-desc">
                    <h2>{ Texts2['dialog.order.get_human.name'] }</h2>
                    <p>{ subs(Texts2['dialog.order.get_human.desc'], { count: info.buyTroopCount }) }</p>
                </div>
                {pointInfo.strategyPoint < need.buyTroop ? (<span _ga_event="strategy.troop.disable" onClick={ ()=>{
                        setType('buyTroop');
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                    x{ need.buyTroop }
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="strategy.troop"
                    onClick = { (e) => { 
                        if(pointInfo.strategyPoint/need.buyTroop >= 2){
                            setMutiTimesType('buyTroop');
                            setIsMutiTimesPop(true);
                            return;
                        } 
                        setWaitingPop(true);
                        strategyConfirm('buyTroop') 
                    }}>
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                     x{ need.buyTroop }
                </span>)}
            </div>

            <div className="App-policy-item">
                <img className="icon" src={ getPolicyIcon('morale') } />
                <div className="App-policy-item-desc">
                    <h2>{ Texts2['dialog.order.morale.name'] }</h2>
                    <p>{ subs(Texts2['dialog.order.morale.desc'], { count: info.buyMoraleCount }) }</p>
                </div>
                {pointInfo.strategyPoint < need.buyMorale ? (<span _ga_event="strategy.morale.disable" onClick={ ()=>{
                        setType('buyMorale');
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                    x{ need.buyMorale }
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="strategy.morale"
                    onClick = { (e) => {  
                        setWaitingPop(true);
                        strategyConfirm('buyMorale') 
                    }}>
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                     x{ need.buyMorale }
                </span>)}
            </div>
            
            <div className="App-policy-item">
                <img className="icon" src={ getPolicyIcon('shield') } />
                <div className="App-policy-item-desc">
                    <h2>{ Texts2['dialog.order.shield_1.name'] }</h2>
                    <p>{ subs(Texts2['dialog.order.shield_1.desc'], { count: info.protect1 }) }</p>
                    {info.protect1.able > 0 && (
                        <div style={{ marginTop: '5px', width: '150px' }}>
                            <ProgressBarMini data={{ 
                                text: <ClockCounter time={ info.protect1.remainTime }  />, 
                                width: (info.protect1.maxTime - info.protect1.remainTime)*100/info.protect1.maxTime + '%'
                            }} />
                        </div>
                    )}
                </div>
                {(pointInfo.strategyPoint < need.protect1 || info.protect1.able) ? (<span _ga_event="strategy.strategy.disable" onClick={ ()=>{
                        setType('protect1');
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                    x{ need.protect1 }
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="strategy.strategy"
                    onClick = { (e) => {  
                        setWaitingPop(true);
                        strategyConfirm('protect1') 
                    }}>
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                     x{ need.protect1 }
                </span>)}
            </div>

            <div className="App-policy-item">
                <img className="icon" src={ getPolicyIcon('temporary_store') } />
                <div className="App-policy-item-desc">
                    <h2>{ Texts2['dialog.order.temporary_store.name'] }</h2>
                    <p>{ subs(Texts2['dialog.order.temporary_store.desc'], { count: info.store }) }</p>
                    {info.store.able > 0 && (
                        <div style={{ marginTop: '5px', width: '150px' }}>
                            <ProgressBarMini data={{ 
                                text: <ClockCounter time={ info.store.remainTime }  />, 
                                width: (info.store.maxTime - info.store.remainTime)*100/info.store.maxTime + '%'
                            }} />
                        </div>
                    )}
                </div>
                {(pointInfo.strategyPoint < need.store || info.store.able) ? (<span _ga_event="strategy.store.disable" onClick={ ()=>{
                        setType('store');
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                    x{ need.store }
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="strategy.store"
                    onClick = { (e) => {  
                        setWaitingPop(true);
                        strategyConfirm('store') 
                    }}>
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                     x{ need.store }
                </span>)}
            </div>

            <div className="App-policy-item">
                <img className="icon" src={ getPolicyIcon('shield') } />
                <div className="App-policy-item-desc">
                    <h2>{ Texts2['dialog.order.shield.name'] }</h2>
                    <p>{ subs(Texts2['dialog.order.shield.desc'], { count: info.protect }) }</p>
                    {info.protect.able > 0 && (
                        <div style={{ marginTop: '5px', width: '150px' }}>
                            <ProgressBarMini data={{ 
                                text: <ClockCounter time={ info.protect.remainTime }  />, 
                                width: (info.protect.maxTime - info.protect.remainTime)*100/info.protect.maxTime + '%'
                            }} />
                        </div>
                    )}
                </div>
                {(pointInfo.strategyPoint < need.protect || info.protect.able) ? (<span _ga_event="strategy.protect.disable" onClick={ ()=>{
                        setType('protect');
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                    x{ need.protect }
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="strategy.protect"
                    onClick = { (e) => {  
                        setWaitingPop(true);
                        strategyConfirm('protect') 
                    }}>
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                     x{ need.protect }
                </span>)}
            </div>

            { notifyPop && <ToastTip text={ 
                (info[type] || {}).able ? 'Already under protecting now.'
                : subs(Texts2['dialog.order.buy_order_no_gold'], { count: (info[type1 + 'Count'] || 0) })
            } callback={ ()=>{ setNotifyPop(false) } } />}
            {waitingPop && <NotifyPop tips={{
                loading: '',
                success: subs(Texts2['dialog.order.' + type + '_success'], { count: (info[type1 + 'Count'] || 0) }),
                fail: subs(Texts2['dialog.order.' + type + '_fail'], { count: (info[type1 + 'Count'] || 0) })
            }} data={ buyResult } callback={ ()=>{
                setWaitingPop(false);
                dispatch(resetBuyResult());
            }} />}
        </div>
    );
}