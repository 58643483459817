import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { rewardConfig } from 'throne-underlying';

import { cityInit, buildingUpdate, resetBuildingUpdateResult, resourceRefresh, refreshCityData } from '../../services/slice-city'
import { setShareRecord, getShareRecords } from '../../services/slice-user'

import { CreateIcon, ProgressBar, GoBack, NotifyPop, ToastTip } from '../../components'

import SnShare from '../share/share'

import { numberFormat, subs } from '../../utils/utils'
import { logTrace } from '../../utils/log'
import { cityImagePath } from '../../utils/ui-res'

import AppGame from '../../const/app.json';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import './build.scss'

export default function Buildings(props){
    const dispatch = useDispatch(); 

    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const { cityReady, cities, summary } = useSelector(function(state){
        return state.city;
    });
    console.log('cities', cities)

    const { resources, buildingUpdateResult } = useSelector(function(state){
        return state.city;
    });
    let troops = resources.troop.value;
    let silvers = resources.silver.value;

    const [notEnoughType, setNotEnoughType] = useState({silvers: false, troops: false });
    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);
    const [currentBuild, setCurrentBuild] = useState({});
    const [sharePop, setSharePop] = useState(false);


    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { shareRecords } = useSelector(function(state){
        return state.user;
    });

    useEffect(()=>{
    	dispatch(getShareRecords({
    		address: currentUser.username,
            seasonId: currentSeasonId,
            season_id: currentSeasonId
    	}))
    	dispatch(refreshCityData())
    }, []);
    useEffect(()=>{
    	dispatch(resourceRefresh())
    }, [cities, buildingUpdateResult]);

	return(
		<div className="App-build-wrap">
			<div className="App-build-troops">
				<label>{ Texts['dialog.department.troop_need'] }: </label>
				<span className="App-build-troops-value">
					<CreateIcon type="troop" size="26" /> 
					<span style={{ padding: '5px' }}>
						{ numberFormat(resources.maintainNeedTroop) }
					</span>
				</span>
			</div>
			<div className="App-build-list">
				{cities.map((item)=>
					<div className="App-build-item" key={ item.id }>
						<div className="App-build-city">
							<img src={ cityImagePath(item) } />
							{ item.type }
		                </div>
						<div className="App-build-info">
							<div className="App-build-city-level">
								Lv { item.level }
							</div>

							{(item.info.employ_count) && (
							<div className="App-build-city-active">
								{ Texts['dialog.department.fortress.desc'] }:  
								<div className="App-build-city-indicator">
								{ numberFormat(item.info.employ_count) }
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								{ numberFormat(item.nextInfo.employ_count) } </>)}
								</div>
							</div>)}

							{(item.info.assemble_troops) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.assemble.desc'] }:
								<div className="App-build-city-indicator">
								<span>
									<CreateIcon type="troop" size="26" /> 
									{ numberFormat(item.info.assemble_troops) }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									<CreateIcon type="troop" size="26" /> 
									{ numberFormat(item.nextInfo.assemble_troops) }
								</span></>)}
								</div>
							</div>)}

							{(item.info.scale_of_troop_defense) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.wall.desc'] }:
								<div className="App-build-city-indicator">
								<span>
									<CreateIcon type="troop" size="26" /> 
									{ numberFormat(item.info.scale_of_troop_defense) }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									<CreateIcon type="troop" size="26" /> 
									{ numberFormat(item.nextInfo.scale_of_troop_defense) }
								</span></>)}
								</div>
							</div>)}

							{(item.info.scale_of_troop_attack) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.militarycenter.desc'] }:
								<div className="App-build-city-indicator">
								<span>
									<CreateIcon type="troop" size="26" /> 
									{ numberFormat(item.info.scale_of_troop_attack) }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									<CreateIcon type="troop" size="26" /> 
									{ numberFormat(item.nextInfo.scale_of_troop_attack) }
								</span> </>)}
								</div>
							</div>)}

							{(item.info.silver_save) && (
							<div className="App-build-city-callon" style={{ padding: '5px 0 0' }}>
								{ Texts['dialog.department.store.desc'] }:
								<div className="App-build-city-indicator">
								<span>
									<CreateIcon type="silver" size="22" /> 
									{ numberFormat(item.info.silver_save) }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									<CreateIcon type="silver" size="22" /> 
									{ numberFormat(item.nextInfo.silver_save) }
								</span></>)}
								</div>
							</div>)}

							{(item.info.infantry_attack) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.infantrycamp.desc.1'] }:
								<div className="App-build-city-indicator">
								<span>
									{ item.info.infantry_attack }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									{ item.nextInfo.infantry_attack }
								</span></>)}
								</div>
							</div>)}

							{(item.info.infantry_defense) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.infantrycamp.desc.2'] }:
								<div className="App-build-city-indicator">
								<span>
									{ item.info.infantry_defense }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									{ item.nextInfo.infantry_defense }
								</span></>)}
								</div>
							</div>)}

							{(item.info.cavalry_attack) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.cavalrycamp.desc.1'] }:
								<div className="App-build-city-indicator">
								<span>
									{ item.info.cavalry_attack }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									{ item.nextInfo.cavalry_attack }
								</span></>)}
								</div>
							</div>)}

							{(item.info.cavalry_defense) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.cavalrycamp.desc.2'] }:
								<div className="App-build-city-indicator">
								<span>
									{ item.info.cavalry_defense }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									{ item.nextInfo.cavalry_defense }
								</span></>)}
								</div>
							</div>)}

							{(item.info.archer_attack) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.archercamp.desc.1'] }:
								<div className="App-build-city-indicator">
								<span>
									{ item.info.archer_attack }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									{ item.nextInfo.archer_attack }
								</span></>)}
								</div>
							</div>)}

							{(item.info.archer_defense) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.archercamp.desc.2'] }:
								<div className="App-build-city-indicator">
								<span>
									{ item.info.archer_defense }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									{ item.nextInfo.archer_defense }
								</span></>)}
								</div>
							</div>)}

							{(item.info.product_silver) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.home.desc'] }:
								<div className="App-build-city-indicator">
								<span>
									<CreateIcon type="silver" size="22" /> 
									{ item.info.product_silver }/h
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									<CreateIcon type="silver" size="22" /> 
									{item.nextInfo.product_silver}/h
								</span></>)}
								</div>
							</div>)}

							{(item.info.get_troop) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.trainingcenter.desc'] }:
								<div className="App-build-city-indicator">
								<span>
									<CreateIcon type="troop" size="20" /> 
									{ item.info.get_troop }/h
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									<CreateIcon type="troop" size="20" /> 
									{item.nextInfo.get_troop}/h
								</span></>)}
								</div>
							</div>)}

							{(item.info.recovery_limit) && (
							<div className="App-build-city-callon">
								{ Texts['dialog.department.hospital.desc'] }:
								<div className="App-build-city-indicator">
								<span>
									<CreateIcon type="troop" size="20" /> 
									{ item.info.recovery_limit }
								</span> 
								{(item.level < item.maxLevel) && (<>
								<span style={{ margin: '0 10px' }}>
									<CreateIcon type="greenArr" size="16" /> 
								</span> 
								<span>
									<CreateIcon type="troop" size="20" /> 
									{item.nextInfo.recovery_limit}
								</span></>)}
								</div>
							</div>)}

							{(item.level < item.maxLevel) && (
							<div style={{ color: '#54ff00', marginTop: '.5rem' }}>
								<span style={{ marginRight: '1.5rem' }}>
									<CreateIcon type="check" size="20" /> 
									<CreateIcon type="silver" size="22" /> 
									{ numberFormat(item.info.need_silver) }
								</span>
								{item.info.need_troop > 0 && (<span>
									<CreateIcon type="check" size="20" /> 
									<CreateIcon type="troop" size="22" /> 
									{ numberFormat(item.info.need_troop) }
								</span>)}
							</div>)}

							{/* <ProgressBar data={{ value: 0.4, text: '11m 38s' }} type="dark" /> */}
		                </div>

		                <div style={{ width: '200px', paddingRight:'20px', textAlign: 'center' }}>
							{(item.canUpgrade && item.level < item.maxLevel) ? (
			                <span 
			                	_ga_event={"building." + item.type + ".update"}
			                	onClick={ ()=>{
			                		setWaitingPop(true);
			                		setCurrentBuild(item);
			                		dispatch(buildingUpdate(item));
			                		setTimeout(function(){
			                			setWaitingPop(false);
			                		}, 15000);
			                	} } 
			                    style={{ verticalAlign: 'top', marginTop: '35px' }}
			                    className="App-general-btn App-general-btn-blue">
			                    { Texts['dialog.department'] }
			                </span>) : (<>
			                <span 
			                	_ga_event={"building." + item.type + ".update.disable"}
			                	onClick={ ()=>{
			                		setNotifyPop(true);
			                		setNotEnoughType({
			                			silvers: item.info.need_silver > silvers,
			                			troops: item.info.need_troop > troops
			                		});
			                	} } 
			                    style={{ verticalAlign: 'top', marginTop: '35px' }}
			                    className="App-general-btn App-general-btn-blue App-btn-disable">
			                    { Texts['dialog.department'] }
			                </span>
							{(item.info.need_silver > silvers || item.info.need_troop > troops)
								&& item.level < item.maxLevel 
								&& (<span style={{ lineHeight: 3 }}>Insufficient resources</span>)}
			                </>)}
			                {/* <div style={{ textAlign: 'center', lineHeight: 2, display: 'none' }}>
			                	8h 36m
			                </div> */}
		                </div>
					</div>
				)}
			</div>
            { notifyPop && <ToastTip text={ 
            	notEnoughType.silvers
            	? Texts2['dialog.building.upgrade_sliver_not_enough'] 
            	: Texts2['dialog.building.upgrade_troops_not_enough']
            } callback={ ()=>{ setNotifyPop(false) } } />}

	        { waitingPop && <NotifyPop data={ buildingUpdateResult } tips={{
	            loading: '',
	            success: Texts2['dialog.building.upgrade_success'],
	            fail: Texts2['dialog.building.upgrade_fail']
	        }} callback={ ()=>{
	            setWaitingPop(false);
	            dispatch(resetBuildingUpdateResult());
	            if(currentBuild.type === 'fortress' && currentBuild.level === 4){
	            	setSharePop(true);
	            }
	        }} />}

	        {(sharePop && !shareRecords['upgrade_fortress']) && <SnShare
	        	content={<div className="App-sns-share">
                    <div className="intro">{ Texts2['dialog.share.intro'] }</div>
                    <div>
                        <span style={{ marginTop: '-23px', display: 'inline-block' }}>
                            <CreateIcon type="coin" size="22" />
                        </span>
                        <span className="App-item-value" style={{ paddingLeft: '10px' }} >
                            { rewardConfig.upgrade_fortress.value }
                        </span>
                    </div>
                </div>} 
                params = { AppGame }
	        	facebook={ AppGame.prod }
	        	twitter={ Texts2['dialog.share.content.fortress'] } 
	        	onShare={ (data)=>{
		        	dispatch(setShareRecord({
		        		address: currentUser.username,
						action: 'upgrade_fortress', //attack_territory
						platform: data.platform,
			            seasonId: currentSeasonId,
			            season_id: currentSeasonId
		        	}))
	        	}
	    	} />}
		</div>
	);
}