import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GoBack, CreateIcon } from './'
import loading from './assets/loading.png'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Loading(props){
    const dispatch = useDispatch(); 
    const [ deg, setDeg ] = useState(0);

    function afterOpenModal(){
    }

    function setPop(){
        props.back && props.back()
    }

    let type = props.type || '';
    let start = 0;
    useEffect(()=>{
        let t = setInterval(function(){
            start += 10;
            setDeg(start);
        }, 50);
        return()=>{
            clearInterval(t);
        };
    }, []);

	return(<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                height: '200px',
                width: '400px',
                margin: '-50px 0 0 -200px'
            }
        }}
        className = { 'App-game-modal-loading ' + (type === 'headless' ? 'App-game-modal-noborder' : 'App-game-modal') }
        isOpen={ true }
        contentLabel="Stat Modal">
        <div style={{ position: 'relative' }}>
            <div className="App-loading-content" style={{ paddingTop: '40px' }}>
                <img src={ loading } style={{ transform: 'rotate(' + deg + 'deg)', width: '80px', height: '80px' }} />
                <div style={{ lineHeight: '20px' }}>{ props.tip || '' }</div>
            </div>
        </div>
    </Modal>);
}