import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import * as echarts from '../../da/echarts-5.5.1';
// import * as echarts from 'echarts';
import * as DASlices from '../../da/slice';
import { numberFormat, timeFormat, dateFormat, subs, showPriceData } from '../../utils/utils'
import { unionImagePath } from '../../utils/ui-res'

export default function KLineMap(props){
    const dispatch = useDispatch(); 
    let { kLineData, landInfo } = props;

    useEffect(()=>{
    }, []);


    const { gameStart } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo } = gameStart;
    const startAt = seasonInfo['startAt'];

    var data = getDailyData(kLineData, landInfo, seasonInfo);

    let lastOne = kLineData[kLineData.length - 1] || { args: { currentPrice: 0 } };
    let changeValue = 0;
    if(data.data.length === 1){
        changeValue = 0;
    }else{
        let priceList = data.data;
        let len = priceList.length;
        changeValue = priceList[len - 1]/priceList[0] - 1
    }

    return (<div className="App-acution-block-kline">
        {
            kLineData.length > 0 
            ? <DailyTransactions data={ data } />
            : <div style={{ color: '#fff', textAlign: 'center', paddingTop: '50px' }}>No price data.</div>
        }
        {kLineData.length > 0 && <div className="App-acution-block-kline-increase">
            Increase: <span className={ changeValue > 0 ? 'App-acution-block-kline-plus' : 'App-acution-block-kline-minis' }>
                { changeValue > 0 ? '+' : '' }
                { Math.round(changeValue*10000)/100 }%
            </span>
        </div>}
    </div>);
}


function getDailyData(kLineData, landInfo, seasonInfo){
    let dates = [];
    let data = [];
    let unionIds = [];
    var minY = 100000;
    var maxY = 0.00001;
    let last = 0;

    let timestamp = seasonInfo.timestamp/1 + 1000 ;
    let price = showPriceData(landInfo.nextLandPrice/1 || 0, 18, 4);
    // dates.push(dateFormat(timestamp, 'M-d hh:mm'));
    // data.push(price);
    // unionIds.push(landInfo.unionId);
    // minY = Math.min(minY, price);
    // maxY = Math.max(maxY, price);

    kLineData.forEach(function(item, index){
        let price = showPriceData(item.args.currentPrice/1, 18, 4);
        dates.push(dateFormat(item.timestamp, 'M-d hh:mm'));
        data.push(price);
        unionIds.push(item.args.unionId);
        minY = Math.min(minY, price);
        maxY = Math.max(maxY, price);
    });
    return { dates, data, minY, maxY, unionIds }
}

function DailyTransactions(props){
    let grid = {
        w: '620px',
        h: '225px',
    };
    let { w, h } = grid;

    let { dates, data, unionIds, minY, maxY } = props.data || {};
    let min = Math.floor(minY/100)*100;

    let option = {
        grid: {
            left: '10px',
            right: '10px',
            top: '18px',
            bottom: '25px'
        },
        textStyle: {
            color: '#a79074'
        },
        xAxis: {
            data: dates,
            type: 'category',
            axisLine: {
                show: false
            },
            axisLabel: {
                show: true,
                margin: 4,
                padding: [0, 0, 0, 0],
                align: 'left',
                rotate: 0,
                alignMinLabel: 'left'
            },
            axisTick: {
                show: false
            },
            splitNumber: 20,
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#6c4434'],
                    width: 1,
                    type: 'solid'
                }
            }
        },
        yAxis: {
            type: 'value',
            splitNumber: 10,
            interval: maxY/10,
            // min: 0,
            // max: 1,
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#6c4434'],
                    width: 1,
                    type: 'solid'
                }
            }
        },
        tooltip: {
            trigger: 'item',
            backgroundColor: 'transparent',
            border: 'none',
            formatter: '<span style="margin: -10px 0 0 -50px; border:0">{c} Zeta</span>',
            textStyle: {
                color: '#fff'
            }
        },
        legend: {},
        series: [
            {
                data: data,
                type: 'line',
                symbol: function(value, params){
                    let index = params.dataIndex;
                    let unionId = unionIds[index];
                    return 'image://' + unionImagePath(unionId);
                },
                symbolSize: 16,
                itemStyle: {
                    color: 'rgba(167, 29, 1, 0.1)'
                },
                lineStyle: {
                    color: 'rgba(255, 162, 0, 1)',
                    width: 2.5
                },
                smooth: true
            }
        ]
    };

    if(data.length > 0){
        option.series[0].markLine = {
            data: [{ type: 'max', name: 'Max' }],
            label: { show: true, position: 'insideEndTop' },
            tooltip: { show: false },
            lineStyle: {
                color: '#f5f5f5',
                width: 1
            },
            emphasis: { disabled: true , label:{ show: false } },
            symbolSize: 0
        };
    }

    useEffect(()=>{
        var chartDom = document.querySelector('.App-acution-dailyTransactions');
        var myChart = window.echarts.init(chartDom);
            myChart.setOption(option);
    }, []);

    return(
        <div className="App-acution-dailyTransactions" style={{
            width: w,
            height: h
        }}>
        </div>
    );
}