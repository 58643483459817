import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getVipSilverBuff } from 'throne-underlying';
import { getUserLevel } from '../../services/slice-user'

import { GoBack, CreateIcon, LogisIcon, Buffs, ShowPercent } from '../../components'
import { subs } from '../../utils/utils';
import { Texts2 } from '../../const/'
import * as API from '../../services/api'

export default function UserScoreBuffsInfo(props){
    const dispatch = useDispatch();

    const { address } = props;
    const { userLevel } = useSelector(function(state){
        return state.user;
    });
    const { accountRating = 0, vipBuffs = {}} = userLevel;
    // console.log('userVipBuffs', userLevel);

    useEffect(()=>{
        dispatch(getUserLevel({ address }));
    }, [address]);

    return (<>
        <h2 className="gpt-title">{ Texts2['dialog.signup.account.rating'] }: { accountRating }</h2>
        <div style={{ textAlign: 'left'}}>
            <Buffs buffs={{
                generalCount: (vipBuffs.add_general_id || []).length,
                product: ShowPercent(vipBuffs.product),
                troop_product: ShowPercent(vipBuffs.recruit),
                attack: ShowPercent(vipBuffs.attack),
                defense: ShowPercent(vipBuffs.defense),
                load: ShowPercent(vipBuffs.load)
            }} />
        </div>
    </>);
}


