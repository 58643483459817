import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter, HashRouter,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";

import * as DASlices from '../da/slice';
import SceneBase from './map/scene-base';
import { getMapId, getMapConfig } from '../maps/map-res'
import BlockDetail from './block/block-detail';

import CurrentUser from './players/current-user';
import WillWin from './battle/win';

import RewardPool from './reward-pool/reward-pool';
import PlayersRank from './reward-pool/players-rank';
import Events from './events/events';

import GuideBook from './help/guidebook'
import MapPreview from './map/preview';

import DiscordWidget from './discord/discord';

import { numberFormat, showPriceData } from '../utils/utils'
import './dutch-auction.scss';

const mapId = 7;
const unlockUnit = 300;

function getSeasonStatus(seasonInfo){
    let { unlockedRing, startAt, nextRingUnlockedTs = 0 } = seasonInfo;
    let now = new Date().getTime()/1000;
    let isOpen = true;
    let unlockTime = 0;
    let remainTime = 0;

    //season open
    if(now > startAt){
        //unlock circle5 after 300s.
        isOpen = true;
        if(unlockedRing === -1 || now - startAt < unlockUnit){
            unlockedRing = 6;
            unlockTime = unlockUnit + startAt - now;
        }else{
            // unlockedRing = unlockedRing;
            // unlockTime = (5 - unlockedRing)*1800 + unlockUnit  + nextRingUnlockedTs - now;
            // let unlockedRingMax = 5;
            // let unlockSlope = Math.floor(unlockedRing/unlockedRingMax);
            // let diffTime = 1800*(1 - unlockSlope) + unlockUnit*unlockSlope;
            unlockTime =  nextRingUnlockedTs - now;
        }
    }else{
        isOpen = false;
        unlockedRing = 6;
        remainTime =  startAt - now;
    }
    return { isOpen, unlockedRing, remainTime, unlockTime };
}

function DutchAuctionMap(props) {
    const dispatch = useDispatch(); 

    const [isReady, setIsReady] = useState(false);

    let { isLandscape, isMobile } = window.LeagueOfThronesConfig;
    const isRotate = isLandscape && isMobile;
    const mapConfig = getMapConfig(mapId, isRotate);

    const { gameInfo, gameStart, lands, landsDetail, allLands, capitalInfo = {} } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;
    // console.log('landsDetail', landsDetail, seasonInfo)

    const [refreshClocker, setRefreshClocker] = useState(true);
    const [seasonStatus, setSeasonStatus] = useState(getSeasonStatus(seasonInfo));
        
    useEffect(() => {
        setRefreshClocker(false);
        setSeasonStatus(getSeasonStatus(seasonInfo));
        setTimeout(function(){
            setRefreshClocker(true);
        }, 1);
        // dispatch(DASlices.getLandsDetail(seasonInfo));
    }, [seasonInfo.unlockedRing]); 

    useEffect(() => {
        if(window.gamePVP){
            window.gamePVP.events.emit("unlockedRingChanged", seasonStatus);
        }
    }, [seasonStatus.unlockedRing]); 

    let noTile = {
        pop: false,
        data: {},
        codId: '',
        accupied: {
            type: ''
        },
        target: null
    };
    const [tile, setTile] = useState(noTile);

    function refreshGlobalData(){
        dispatch(DASlices.getGameInfo({ seasonId, address }));
        dispatch(DASlices.getShareInfo({ seasonId, address, unionId }));
        dispatch(DASlices.getClaimedReword({ seasonId, address, unionId }));
        dispatch(DASlices.refreshSeasonInfo({seasonId}));
        dispatch(DASlices.getDAEvents({
            seasonId
        }));
    }

    useEffect(() => {
        if(!seasonId){
            return;
        }
        // refreshGlobalData();
        dispatch(DASlices.getLands({ seasonId, address }));
        dispatch(DASlices.getLandsDetail(seasonInfo));
    }, [seasonId, tile]); 

    useEffect(() => {
        if(window.gamePVP){
            window.gamePVP.events.emit("occupiedsChanged", {
                occupieds: lands,
                landsDetail,
                from: 'map'
            });
        }
        // refreshGlobalData();
    }, [lands, landsDetail]); 

    const [isWinning, setIsWinning] = useState({ winnning: false, remainTime: 0, status: '' });
    let { fortressTakenFinishTs = 0, totalFinalPoolValue = 0 } = gameInfo[seasonId] || {};
    useEffect(() => {
        let { fortressTakenFinishTs = 0 } = gameInfo[seasonId] || {};
        let isOccupiedUnionId = capitalInfo.unionId || 0;
        if(isOccupiedUnionId - 0 > 0){
            let now = new Date().getTime()/1000;
            let remainTime = fortressTakenFinishTs - now;
            setIsWinning({
                winnning: false,
                unionId: isOccupiedUnionId,
                status: remainTime < 0 ? 'HaveWin' : '',
                remainTime
            });
            setTimeout(function(){
                setIsWinning({
                    winnning: true,
                    unionId: isOccupiedUnionId,
                    status: remainTime < 0 ? 'HaveWin' : '',
                    remainTime
                });
            }, 5);
        }
    }, [capitalInfo.unionId, fortressTakenFinishTs]); 


    useEffect(() => {
        if(window.gamePVP){
            window.gamePVP.events.emit("occupiedsChanged", {
                totalFinalPoolValue: showPriceData(totalFinalPoolValue || 0, 18, 4),
                from: 'rewardPool'
            });
        }
        // refreshGlobalData();
    }, [totalFinalPoolValue]); 

    useEffect(() => {
        if(seasonInfo.unlockedRing !== 0){
            return;
        }
        dispatch(DASlices.getCapitalInfo({
            address,
            seasonId,
            p: {x: 0, y: 0}
        }));
    }, [tile]); 

    useEffect(() => {
        gameRender();
        let target = document.querySelector('.App-mobile #root #App-phaser-parent');
        if(target){
            const screenW = document.documentElement.clientWidth;
            const screenH = document.documentElement.clientHeight;
            let offset = screenW/2/screenH;
            target.style.transformOrigin = '50% ' + (offset * 100) + '%';            
        }

        if(seasonId && address){
            setInterval(function(){
                dispatch(DASlices.getLands({ seasonId, address }));
            }, 20*1000);
            setInterval(function(){
                dispatch(DASlices.getLandsDetail(seasonInfo));
            }, 10*1000);
        }
        dispatch(DASlices.getLandsDetail(seasonInfo));

        if(seasonInfo.unlockedRing !== 0){
            return;
        }
        dispatch(DASlices.getCapitalInfo({
            address,
            seasonId,
            p: {x: 0, y: 0}
        }));
    }, []); 

    function gameRender(){
        if(isReady){
            return true;
        }

        const config = {
            callbacks: {
                preBoot: (game)=> {
                },
            },
            type: Phaser.AUTO,
            disableContextMenu: true,
            title: 'League of Thrones',
            parent: "App-phaser-parent",
            width: mapConfig.mapWidth,
            height: mapConfig.mapHeight,
            dom: {
                createContainer: true
            },
            transparent: true,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 100 },
                    debug: false
                }
            },
            scene: [ new SceneBase({
                occupieds: lands, 
                codsAll: {}, 
                mapConfig, 
                totalFinalPoolValue: showPriceData(totalFinalPoolValue || 0, 18, 4),
                unlockedRing: seasonStatus.unlockedRing,
                landsDetail 
            }, false) ]
        }
        window.gamePVP = new Phaser.Game(config);

        window.gamePVP.events.on("READY", function(){
            setIsReady(true);
            props.onShow && props.onShow();
        });

        window.gamePVP.events.on("TileClick", function(res) {
            setTile({
                pop: true,
                data: res
            });
        });
    }

    return (<><div className="App App-acution" id="App">
        <div className={"App-phaser-parent-wrapper App-phaser-map-togather"}>
            <div id="App-phaser-parent" className="App-seaon-map-canvas-rotate"></div> 
        </div>
        {tile.pop && <BlockDetail unlockedRing={ seasonStatus.unlockedRing } tileInfo={ tile } close={ () => {
            setTile(noTile);

            // let tileData = tile.data.data || {};
            // let blockId = tileData.xyIndex || '';
            // DASlices.resetLandInfo({ blockId });
        } } />}

        {(!seasonStatus.isOpen && refreshClocker) && <WillWin data={{
            remainTime: seasonStatus.remainTime,
            unionId: 0,
            content: 'Season will start in '
        }} onEnd={ ()=>{
            dispatch(DASlices.refreshSeasonInfo({seasonId}));
        } } />}

        {(seasonStatus.isOpen && refreshClocker) && seasonStatus.unlockedRing > 0 && <WillWin data={{
            remainTime: seasonStatus.unlockTime <= 0 ? 1800 : seasonStatus.unlockTime,
            unionId: 0,
            content: 'Next circle will be unlocked in '
        }} onEnd={ ()=>{
            dispatch(DASlices.refreshSeasonInfo({seasonId}));
        } } />}

        {isWinning.winnning && <WillWin data={{
            remainTime: isWinning.remainTime,
            unionId: isWinning.unionId,
            status: isWinning.status,
            content: 'Season will end in '
        }} onEnd={ ()=>{
            dispatch(DASlices.refreshSeasonInfo({seasonId}));
        } } />}

        <CurrentUser />
        <PlayersRank />

        {isReady && <MapPreview unlockedRing={ seasonStatus.unlockedRing } onLoad={ ()=>{ refreshGlobalData() } } />}
        <GuideBook onLoad={ ()=>{ refreshGlobalData() } } />
        <RewardPool />
        {seasonStatus.unlockedRing < 6 && <Events />}
        <DiscordWidget />
    </div></>);
}

export default DutchAuctionMap;
