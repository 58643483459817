import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    resourceRefresh, refreshCityData, healTroopsStart, healEstimate, resetHealResult
} from '../../services/slice-city'
import { 
    generalTypeImagePath
} from '../../utils/ui-res'

import { numberFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import { GoBack, CreateIcon, Slider, ClockCounter } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import troopType from './assets/troop.png'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function HealTroops(props){
    const dispatch = useDispatch(); 

    const [isTipPop, setIsTipPop] = useState('');
    const [healType, setHealType] = useState('silver'); 
    const [sliderValue, setSliderValue] = useState(0); //percents

    const { cities, healTroopsCount, healResult, healEstimateResult } = useSelector(function(state){
        return state.city;
    });

    function getRecoverLimit(){
        let info = {};
        cities.forEach(function(item){
            if(item.type === 'hospital'){
                info = item.info;
            }
        });
        return info.recovery_limit || 0;
    }

    const [recoveryLimit, setRecoveryLimit] = useState(0);
    const [healAmount, setHealAmount] = useState(0);
    
    const healRealCount = Math.min(healTroopsCount.value || 0, (recoveryLimit + healTroopsCount.today/1));
    // console.log(sliderValue,{healEstimateResult, healRealCount, recoveryLimit, healTroopsCount, healResult}, 'heal.result');

    const { resources, golds } = useSelector(function(state){
        return state.city;
    });
    let silvers = Math.floor(resources.silver.value || 0);
    
    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    useEffect(() => {
        dispatch(refreshCityData())
        dispatch(resourceRefresh())
    }, [healResult]);

    useEffect(() => {
        if(healRealCount < 0){
            return;
        }
        let amount = Math.floor(healRealCount * sliderValue / 100);
        setHealAmount(amount);
        dispatch(healEstimate({ amount }));
    }, [sliderValue]);

    useEffect(() => {
        setRecoveryLimit(getRecoverLimit());
    }, [cities]);

    return (<>
        <div className="App-troops-training">
            <img alt="" className="avatar" src={ troopType } />

            <div className="App-troops-total App-troops-total-heal">
                <label>{ Texts2['dialog.hospital.staff_count'] }</label>
                <span>
                    { numberFormat(healTroopsCount.value) }
                </span>

                <span className="tips" style={{ position: 'absolute', marginLeft: '15px' }} 
                    _ga_event="troops.heal.tips"
                    onClick={ ()=> { setIsTipPop('heal') }}>
                    <span style={{ top: '-2px', left: '-2px', position: 'relative' }}>
                        <CreateIcon type="info-tips" size="18" />
                    </span> 
                </span>
                {isTipPop === 'heal' && <ShowTips 
                    updateTime={ healTroopsCount.updateTime }
                    title={ Texts2['dialog.hospital.title'] }
                    content={ Texts2['dialog.hospital.rule'] } 
                    onClose={ ()=>{ setIsTipPop('') } } 
                />}

                <p></p>

                <label>{ Texts2['dialog.hospital.daily_recover_count'] }</label>
                <span>
                    { numberFormat(Math.abs(healTroopsCount.today)) }/{ numberFormat(recoveryLimit) }
                </span>
            </div>
            
            <div style={{ margin: '0 40px' }}>
                <Slider total={ healRealCount } value={ sliderValue } disable={ Math.abs(healTroopsCount.today) >= recoveryLimit } unit={ 1 } onChange={ (value)=>{
                    setSliderValue(value);
                }} />
            </div>
            <div className="App-troops-op" style={{ margin: '30px' }}>
                {(healEstimateResult.silver > 0 && healEstimateResult.silver < silvers) ? <span 
                    _ga_event="troops.heal.silver"
                    onClick={ (e)=> {
                        setHealType('silver');
                        dispatch(healTroopsStart({type: 'silver', amount: healAmount}));
                        setWaitingPop(true);
                    } }
                    style={{ marginRight: '2rem' }}
                    className="App-general-btn App-general-btn-blue">
                    <CreateIcon type="silver" size="22" /> 
                    <span className="App-heal-price">{ healEstimateResult.silver }</span>
                    { Texts2['button.hospital.recover'] }
                </span> : <span 
                    _ga_event="troops.heal.silver.disable"
                    onClick={ (e)=> { 
                        setHealType('silver');
                        setNotifyPop(true)
                    } }
                    style={{ marginRight: '2rem' }}
                    className="App-general-btn App-general-btn-blue App-btn-disable">
                    <CreateIcon type="silver" size="22" /> 
                    <span className="App-heal-price">{ healEstimateResult.silver || 0 }</span>
                    { Texts2['button.hospital.recover'] }
                </span>}

                {(healEstimateResult.gold > 0 && healEstimateResult.gold < golds) ? <span 
                    _ga_event="troops.heal.gold"
                    onClick={ (e)=> { 
                        setHealType('gold');
                        dispatch(healTroopsStart({type: 'gold', amount: healAmount}));
                        setWaitingPop(true);
                    } }
                    className="App-general-btn App-general-btn-blue">
                    <CreateIcon type="coin" size="22" /> 
                    <span className="App-heal-price">{ healEstimateResult.gold }</span>
                    { Texts2['button.hospital.recover'] }
                </span> : <span 
                    _ga_event="troops.heal.gold.disable"
                    onClick={ (e)=> { 
                        setHealType('gold');
                        setNotifyPop(true);
                    } }
                    className="App-general-btn App-general-btn-blue App-btn-disable">
                    <CreateIcon type="coin" size="22" /> 
                    <span className="App-heal-price">{ healEstimateResult.gold || 0 }</span>
                    { Texts2['button.hospital.recover'] }
                </span>}

                { notifyPop && <ToastTip text={ 
                    healAmount === 0 ? Texts2['dialog.hospital.not_troops'] : (healType === 'silver'
                    ? Texts2['dialog.hospital.not_enough_silver']
                    : Texts2['dialog.hospital.not_enough_gold'])
                } callback={ ()=>{ 
                    setNotifyPop(false);
                } } />}

                { waitingPop && <NotifyPop tips={{
                    loading: '',
                    success: Texts2['dialog.hospital.heal_successful'],
                    fail: Texts2['dialog.hospital.heal_fail']
                }} data={ healResult } callback={ ()=>{
                    setWaitingPop(false);
                    dispatch(resetHealResult())
                    setSliderValue(0);
                }} />}
            </div>
        </div>
    </>);
}

function ShowTips(props){
    let { title, content, updateTime, onClose } = props;
    let remainTime = 24*60*60 - updateTime%(24*60*60);
    return (
        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'rgba(0,0,0,0.2)'
                },
                content: {
                    height: '220px',
                    width: '400px',
                    marginTop: '-100px',
                    marginLeft: '-200px'
                } 
            }}
            className = { 'App-game-modal-tips App-game-modal' }
            isOpen = { true }
            onAfterOpen={ ()=>{} }
            onRequestClose={ ()=>{ onClose(''); } }
            contentLabel="Troops Tips Modal">
            <div style={{ position: 'relative', paddingTop: '2rem' }}>
                <div _ga_event="troops.tips.close" onClick={ ()=>onClose('') }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ color: '#fff' }}> { title }</div>
                <div className="App-game-season-rule-pop">
                    { content }
                    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                        Daily Limit Recovery in: <ClockCounter time={ remainTime } />
                    </div>
                </div>
            </div>
        </Modal>
    )
}


