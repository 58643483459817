import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSeasonInfo, userLogin, enterGame, signUpGame, getAllPlayers } from '../../services/slice-user';
import { getAllPlayersGeneralPortraits } from '../../services/slice-signup';

import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath
} from '../../utils/ui-res'

export default function GeneralPortrait(props){
    const dispatch = useDispatch(); 
    const generalId = props.generalId || -1;
    const cls = props.cls || 'App-general-avatar';

    const { allPlayersGeneralPortraits } = useSelector(function(state){
        return state.signup;
    });
    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { mapReady, seasonRank } = useSelector(function(state){
        return state.map;
    });
    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;

    const uid = currentUser.username + ':' + generalId;
    const portrait = allPlayersGeneralPortraits[uid] || '';

    useEffect(()=>{
        dispatch(getSeasonInfo({
            seasonId: currentSeasonId
        }));
    }, []);

    useEffect(()=>{
        dispatch(getAllPlayersGeneralPortraits({
            seasonId: currentSeasonId
        }));
    }, [currentSeasonId]);

    return (
        <img className={cls} src={ 
            portrait ? portrait : generalImagePath({ index: generalId}) 
        } alt="" />
    );
}
