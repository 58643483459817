import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAllGenerals, generalInit, getBattleRecords, enemiesSearch } from '../../services/slice-general'
import { checkNewRecords, removeNewRecords } from '../../services/slice-general'
import { pointUpdate } from '../../services/slice-strategies'

import { numberFormat, subs, getStamina } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import { CreateIcon, GoBack, LogisIcon, Stars, PersonNumber, ConfirmTip } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import Records from './records'
import UnionList from '../unions/union-list'
import SpyEnamy from '../spy/spy'

import './battle.scss'

import { Texts, Texts2 } from '../../const/';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Battles(props){
    const dispatch = useDispatch(); 

    const { all, generalReady, players, battleRecords, newRecords } = useSelector(function(state){
        return state.generals;
    });
    // console.log(players);
    const { strategyReady, pointInfo } = useSelector(function(state){
        return state.strategies;
    });

    const [battlePop, setBattlePop] = useState(true);
    const [player, setPlayer] = useState({});

    const isPlayerCanBattle = function(){
        return player.username && !player.isProtected;
    }

    const [keyword, setKeyword] = useState('');
    const [isBattling, setIsBattling] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [menu, setMenu] = useState('player');

    logTrace({menu, players, player, battleRecords, all}, 'battle.data');
    // console.log({menu, players, player, battleRecords, all}, 'battle.data');

    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    function startSearch(){
        dispatch(enemiesSearch(keyword));
    }

    function selectPlayer(item){
        setPlayer(item);
    }

    const [isStartAttackCityConfirm, setIsStartAttackCityConfirm] = useState(false);
    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    function isSameCamp(){
        return currentUser.unionId === player.unionId;
    }
    function selectConfirm(flag){
        if(flag && isSameCamp()){
            setIsStartAttackCityConfirm(true);
            return;
        }
        setIsConfirm(true);
        props.onSelected(player, flag);
    }

    function battleOverModalClose(){
        setIsBattling(false);
    }

    function afterOpenModal(){

    }

    useEffect(()=>{
        dispatch(enemiesSearch());
        dispatch(getBattleRecords());
        dispatch(getAllGenerals());
        dispatch(checkNewRecords());
    }, [generalReady]);

    useEffect(()=>{
        dispatch(pointUpdate());
    }, [strategyReady]);

    useEffect(()=>{
        let len = battleRecords.length - 1;
        let { timestamp = 0 } = battleRecords[0] || {};
        if(timestamp <= 0){
            return;
        }
        if(menu !== 'record' || len < 0 || !newRecords){
            return;
        }
        dispatch(removeNewRecords({
            timestamp
        }));
    }, [menu]);
 
    return (
        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'transparent'
                },
                content: {
                    width: '900px',
                    marginLeft: '-450px'
                }
            }}
            className = { 'App-game-modal-battle App-game-modal-gray-headless' }
            isOpen={ battlePop }
            onAfterOpen={ afterOpenModal }
            onRequestClose={ ()=>selectConfirm(false) }
            contentLabel="Battles Modal">
            <div className="App-battle-modal-content">
                <div _ga_event="battle.close" onClick={ ()=>selectConfirm(false) } style={{ position: 'absolute', top: '-10px', right: '20px', zIndex: 999 }}>
                    <GoBack size="60px" />
                </div>
                <div className="App-battle-sidebar">
                    <div className="App-battle-title">{ Texts['button.battle'] }</div>
                    <div className="App-battle-menu">
                        <div 
                            className={ 'App-battle-menu-item ' + (menu == 'player' ? 'App-battle-menu-current' : '') } 
                             _ga_event="battle.menu.player"
                            onClick={ ()=> setMenu('player') }>
                            { Texts['button.player'] }
                        </div>
                        <div 
                            className={ 'App-battle-menu-item ' + (menu == 'record' ? 'App-battle-menu-current' : '') }
                             _ga_event="battle.menu.record"
                            onClick={ ()=> {setMenu('record'); selectPlayer({})} }>
                            { Texts['button.record'] }
                            {newRecords && <span className="App-redpoint" style={{ margin: '-5px 0 0 48px' }}>new records</span>}
                        </div>
                    </div>
                    <div className="App-battle-search" style={{ visibility: menu === 'player' ? 'visible':'hidden'}}>
                        <input type="text" className="App-form-input" placeholder="Search" onChange={ (e)=>setKeyword(e.target.value) }  />
                        <span className="App-btn-search" _ga_event="battle.menu.search" onClick={ startSearch }>{ Texts['dialog.search'] }</span>
                    </div>
                </div>  

                {menu == 'record' && (<div className="App-battle-content">
                    <Records records={ battleRecords } all={ all } />
                </div>)}
                {menu == 'player' && (<div className="App-battle-content">
                    <UnionList data={ players } onSelected={ selectPlayer } />
                    
                    <div style={{ textAlign: 'center' }}>
                        <SpyEnamy enamy={ player } />

                        <span className={ "App-general-btn App-general-btn-green" + (isPlayerCanBattle() ? '' : ' App-btn-disable')}
                            onClick = { (e) => { 
                                if(isPlayerCanBattle()){
                                    selectConfirm(true) 
                                }else{
                                    setNotifyPop(true);
                                }
                            }}>
                            { Texts['common.atk'] } <span className="heart">❤</span>-{ getStamina('attackPlayer') }
                        </span>
                    </div>

                    {isStartAttackCityConfirm && <ConfirmTip 
                        texts={{
                            title: Texts['popup.attack_same_camp_attention.title'],
                            content: Texts['popup.attack_same_camp_attention.desc'],
                            cancel: Texts['popup.map_defense_cancel'],
                            confirm: Texts['popup.map_defense_confirm']
                        }}
                        confirm={ ()=>{
                            setIsConfirm(false);
                            props.onSelected(player, true);
                            setIsStartAttackCityConfirm(false);
                        } }
                        cancel = { ()=>{
                            setIsStartAttackCityConfirm(false);
                        }}
                    />}
                </div>)}

                { notifyPop && <ToastTip text={ 
                    player.isProtected 
                    ? Texts['dialog.player_under_protection'] 
                    : Texts2['dialog.battle.attack_fail_choose_general']
                } callback={ ()=>{ setNotifyPop(false) } } />}

                { waitingPop && <NotifyPop tips={{
                    loading: '',
                    success: Texts2['dialog.battle.attack_success'],
                    fail: Texts2['dialog.battle.attack_error']
                }} data={ {} } callback={ ()=>{
                    setWaitingPop(false);
                }} />}
            </div>
        </Modal>
    );
}