import React, { useEffect, useState } from 'react';
import { timeFormat } from '../utils/utils'

export default function ClockCounter(props){
    const [time, setTime] = useState(Math.ceil(props.time || 0));

    useEffect(()=>{
        let timing = setInterval(function(){
            setTime(time - 1);
            if(time <= 0){
                clearInterval(timing);
                props.onEnd && props.onEnd();
            }
        }, 1000);
        return ()=>{
            clearInterval(timing);
        }
    }, [ time ]);
    
    if(time < 0){
        return (<></>);
    }
    
    return (<>{ timeFormat(time) }</>);
}