import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import { rewardConfig } from 'throne-underlying';

import { pointUpdate } from '../../services/slice-strategies'
import { resourceRefresh } from '../../services/slice-city'
import { 
    mapInit, getOccupiedTiles, getTileInfo, 
    attackTile, getDefenseList, defenseTile
} from '../../services/slice-map';
import { generalInit, getSkillInfo, getDefenseBlockGenerals } from '../../services/slice-general'
import { setShareRecord, getShareRecords } from '../../services/slice-user'

import SendTile from '../chat/send-tile'
import CodCreate from '../cod/cod-create'

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, timeFormat, subs, getStamina } from '../../utils/utils'
import { unionImagePath, generalTypeImagePath } from '../../utils/ui-res'
import * as cache from '../../utils/cache'

import AppGame from '../../const/app.json';
import mainFortress from './assets/fortress.png';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import { logTrace } from '../../utils/log'

import SelectGeneral from '../generals/generals-selector'
import BattleResult from '../battle/battle-result'
import DefenseResult from '../battle/defense-result'
import ProtectWarning from '../battle/protect-warning'

import SnShare from '../share/share'

import Modal from 'react-modal';

var occupiedsTemp = {};
export default function Tile(props) {
    const dispatch = useDispatch(); 
    let { tileData } = props;  
    
    const [tipPop, setTipPop] = useState(false);

    const xyIndex = (tileData.xyIndex || '').split('^');
    const x_id = xyIndex[0]/1;
    const y_id = xyIndex[1]/1;

    const { 
        mapReady,
        detail, 
        attackResult, 
        defenseList,
        defenseResult
    } = useSelector(function(state){
        return state.map;
    });


    useEffect(()=>{    
        dispatch(getDefenseList({
            x: x_id,
            y: y_id
        }));
    }, []);

    return (<>  
        <div className="App-Resources-troops">
            <div className="App-Resources-stat-title">
                { Texts['dialog.block_troops_count'] }: { detail.defense_list_len }
            </div>
            <div 
                style={{ position: 'relative', float:'right', marginTop: '-30px', cursor: 'pointer' }}>
                <span _ga_event="block.mining.introduce.open" onClick = { ()=>setTipPop(true) }>
                    <CreateIcon type="info-tips" size="20" />
                    <span style={{ fontSize: '14px', marginLeft: '4px' }}>{ Texts['dialog.block_mining_introduce.name'] }</span>
                </span>
                <span style={{ fontSize: '14px', marginLeft: '20px' }}>
                    <SendTile detail={ detail } />
                </span>
            </div>
            <div className="App-game-table" style={{ clear: 'both', height: '200px', background: '#1e160c' }}><table>
                <thead><tr>
                    <td style={{ paddingLeft: '35px' }}>{ Texts['button.block_defense_force.name'] }</td>
                    <td className="right">{ Texts['button.block_defense_force.attack'] }</td>
                    <td className="right">{ Texts['button.block_defense_force.defense'] }</td>
                    <td className="right">{ Texts['button.block_defense_force.count'] }</td>
                </tr></thead>
                <tbody>
                {defenseList.map(
                    (item, index) => (<tr key={ index }>
                        <td>
                            <img src={ generalTypeImagePath(item.generalType) } style={{ height: '20px', position: 'absolute' }} />
                            <span style={{ paddingLeft: '25px' }}>{ Texts['dialog.general.card.name.' + item.generalId] || 'name.' + item.generalId }</span>
                        </td>
                        <td className="right">{ numberFormat(item.attack) }</td>
                        <td className="right">{ numberFormat(item.defense) }</td>
                        <td className="right">{ numberFormat(item.troops) }</td>
                    </tr>)
                )}
                </tbody>
            </table></div>
        </div>
    </>);
}

