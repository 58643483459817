import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getConfig } from '../../services/throne.init'
import { pointUpdate } from '../../services/slice-strategies'
import { battleLasting, checkNewRecords, generalListUpdated } from '../../services/slice-general'
import { 
    getRecruitState, recruitTroops, 
    resourceRefresh,
    getGuideStep, setGuideStep,
    getAbleActivityInfo,
    removeActivityNew
} from '../../services/slice-city'

import './toolbar.scss'
import { GoBack, CreateIcon } from '../../components'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import Buildings from '../build/build';
import SeasonRule from '../season-rule/season-rule';
import GeneralManage from '../generals/general-manage';
import Strategies from '../strategies/strategies';

import TroopsClaim from '../troops/claim';
import Battles from '../battle/battle';
import SelectGeneral from '../generals/generals-selector'
import BattleResult from '../battle/battle-result'
import ProtectWarning from '../battle/protect-warning'

import Troops from '../troops/troops';
import HealTroopsNotify from '../troops/heal-notify'

import ActivityPop from '../activity/activity';
import BeginnerPlayGuide from '../beginner/play';
import BeginnerSeasonGuide from '../beginner/season';

import { logTrace } from '../../utils/log'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ToolBar(props){
    const dispatch = useDispatch();

    const currentSeasonId = props.seasonId;
    const { seasonDetail } = useSelector(function(state){
        return state.user;
    });
    let currentSeasonDetail = seasonDetail[currentSeasonId] || {};
    const seasonBasicInfo = currentSeasonDetail.seasonBasicInfo;

    const { battleOverInfo } = useSelector(function(state){
        return state.generals;
    });
    const { cityReady, cities, canCityUpgrade } = useSelector(function(state){
        return state.city;
    });
    logTrace(battleOverInfo, 'battle.result');

    const [currentName, setCurrentName] = useState('');
    
    const [generalPop, setGeneralPop] = useState(false);
    const [troopsPop, setTroopsPop] = useState(false);
    const [activitiesPop, setActivitiesPop] = useState(false);

    const [battleEnemyModal, setBattleEnemyModal] = useState(false);
    const [battleEnemy, setBattleEnemy] = useState({});

    const [battleGeneralModal, setBattleGeneralModal] = useState(false);
    const [battleGeneral, setBattleGeneral] = useState({});
    const [battleOver, setBattleOver] = useState(true);
    const [highlightType, setHighlightType] = useState('');

    const { strategyReady, info } = useSelector(function(state){
        return state.strategies;
    });
    let protect = info.protect || {};
    let protect1 = info.protect1 || {};
    let protecting = !!protect.able || !!protect1.able;
    const [ informAttackUnderProtecting, setInformAttackUnderProtecting ] = useState(false);
    const [ attackInfo, setAttackInfo ] = useState({});

    function getCls(name){
        let hoverCls = '';
        if(currentName === name || highlightType === name){
            hoverCls = ' App-toolbar-item-hover';
        }
        return 'App-toolbar-item' + hoverCls;        
    }

    function afterOpenModal(value){
    }

    function modalClose(item){
        //todo, clear listener in modal
    }

    function startBattle(general){
        dispatch(battleLasting({
            generalId: general.qualification.general_id,
            name: battleEnemy.username
        }));
    }

    useEffect(()=>{
        // dispatch(generalInit());
        dispatch(resourceRefresh())
    }, [battleOverInfo]);

    useEffect(()=>{
        dispatch(pointUpdate());
    }, [strategyReady]);

    const [isBeginnerGuide, setIsBeginnerGuide] = useState(false);
    const [beginnerSeasonGuide, setBeginnerSeasonGuide] = useState(false);
    const [currentGeneralType, setCurrentGeneralType] = useState('STATS');
    const { guideStep } = useSelector(function(state){
        return state.city;
    });
    const { debugConfig } = useSelector(function(state){
        return state.app;
    });
    // console.log('debugConfig', seasonBasicInfo, debugConfig)

    useEffect(()=>{
        dispatch(getConfig({
            seasonId: seasonBasicInfo.sid
        }));
        dispatch(getGuideStep());
    }, [seasonBasicInfo]);

    const { generalReady, activeStats, newRecords } = useSelector(function(state){
        return state.generals;
    });

    const { recruitResult = {} } = useSelector(function(state){
        return state.city;
    });
    const { resources } = useSelector(function(state){
        return state.city;
    });

    useEffect(()=>{
        dispatch(checkNewRecords());
    }, [generalReady, resources]);
    // console.log('newRecords', newRecords)

    useEffect(()=>{
        dispatch(getRecruitState());
    }, [cityReady]);

    useEffect(()=>{
        dispatch(generalListUpdated());
    }, [resources]);

    // console.log({cityReady, resources})
    let hasRecruiting = recruitResult.status === 'going';
    let silversForRecruit = Math.floor(resources.silver.value || 0) > 100*1; //100silvers = 1 soilder

    useEffect(()=>{
        dispatch(getAbleActivityInfo(seasonBasicInfo));
    }, [cityReady]);
    const { activities, newActivities } = useSelector(function(state){
        return state.city;
    });
    function removeActivityRedPoint(){
        if(activities.length === 0){
            return;
        }
        let { activityId } = activities[0];
        // console.log({activityId});
        dispatch(removeActivityNew({
            activityId
        }));
    }

    return (
        <div className="App-toolbar">
            <BeginnerPlayGuide oprators={{
                setHighlightType: function(type){
                    setHighlightType(type);
                },
                closePop: function(){
                    setCurrentName('');
                    setGeneralPop(false);
                    setTroopsPop(false);
                },
                city: function(){
                    setCurrentName('building');
                },
                general: function(){
                    setCurrentName('');
                    setGeneralPop(true);
                },
                showStrengthen: function(){
                    setCurrentGeneralType('STRENGTHEN');
                },
                troop: function(){
                    setCurrentName('');
                    setTroopsPop(true);
                    setIsBeginnerGuide(true);
                },
                over: function(){
                    setIsBeginnerGuide(false);
                }
            }} />
            <TroopsClaim showTroops={ ()=>{
                setTroopsPop(true);
            }} />

            <div className={ getCls('season') } _ga_event="toolbar.season" onClick={(e)=> {
                    if(window.LeagueOfThronesConfig.isMobile || guideStep.season >= 25 || debugConfig['guide-season']){
                        setCurrentName('season')      
                    }else{
                        setBeginnerSeasonGuide(true);               
                    }
                }}>
                <div className="App-toolbar-item-image"><CreateIcon type="season" size="60" /></div>
                <div>{ Texts['button.season'] }</div>
            </div>
            {beginnerSeasonGuide && <BeginnerSeasonGuide oprators={{
                setHighlightType: function(type){
                    setHighlightType(type);
                },
                openGeneral: function(){
                    setCurrentName('');
                    setGeneralPop(true);
                },
                closeGeneral: function(){
                    setCurrentName('');
                    setGeneralPop(false);
                },
                showZetachain: function(flag){
                    props.showZetachain(flag);
                },
                openSeason: function(){
                    setCurrentName('');
                    setCurrentName('season');
                }
            }} />}
            <Modal
                style = {{
                    overlay: { backgroundColor: 'transparent' },
                    content: {
                        width: '900px',
                        marginLeft: '-450px',
                        height: '610px',
                        marginTop: '-300px'
                    } 
                }}
                className = { 'App-game-modal-season-rule App-game-modal-gray' }
                isOpen={ currentName === 'season' }
                onAfterOpen={ afterOpenModal }
                onRequestClose={ ()=>{ modalClose(currentName);setCurrentName(''); } }
                contentLabel="Toolbar Modal">
                <div style={{ position: 'relative' }}>
                    <div _ga_event="season.rule.open" onClick={(e)=> { setCurrentName('') }}>
                        <GoBack size="60px" />
                    </div>
                    <SeasonRule />
                </div>
            </Modal>

            <div className={ getCls('building') } _ga_event="toolbar.building" onClick={(e)=> { setCurrentName('building') }}>
                <div className="App-toolbar-item-image"><CreateIcon type="building" size="60" /></div>
                {canCityUpgrade && <span className="App-redpoint" style={{ margin: '-58px 0 0 15px' }}>city upgrade</span>}
                <div>{ Texts['button.building'] }</div>
            </div>
            <Modal
                style = {{
                    overlay: { backgroundColor: 'transparent' },
                    content: {
                        height: '460px',
                        marginTop: '-220px'
                    }
                }}
                className = { 'App-game-modal-buildings App-game-modal-gray' }
                isOpen={ currentName === 'building' }
                onAfterOpen={ afterOpenModal }
                onRequestClose={ ()=>{ modalClose(currentName);setCurrentName(''); } }
                contentLabel="Toolbar Modal">
                <div style={{ position: 'relative' }}>
                    <div _ga_event="building.close" onClick={(e)=> { setCurrentName('') }}>
                        <GoBack size="60px" />
                    </div>
                    <div className="App-game-modal-header">{ Texts['button.building'] }</div>
                    <Buildings />
                </div>
            </Modal>

            <div className={ getCls('general') } _ga_event="toolbar.general" onClick={(e)=> { setGeneralPop(true) }}>
                <div className="App-toolbar-item-image"><CreateIcon type="hero" size="60" /></div>
                {activeStats.able_count < activeStats.max_able_count && <span className="App-redpoint" style={{ margin: '-58px 0 0 15px' }}>general recruit</span>}
                <div>{ Texts['button.general'] }</div>
            </div>
            <Modal
                style = {{
                    overlay: { backgroundColor: 'transparent' },
                    content: {
                        height: '440px',
                        marginTop: '-220px'
                    }
                }}
                className = { 'App-game-modal-general-manage App-game-modal' }
                isOpen={ generalPop }
                _ga_event="general.list.close"
                onAfterOpen={afterOpenModal}
                onRequestClose={ ()=>setGeneralPop(false) }
                contentLabel="Generals Modal">
                <div _ga_event="general.close" onClick={ ()=>setGeneralPop(false) } style={{ position: 'absolute', right: '20px', zIndex: 999 }}>
                    <GoBack size="60px" />
                </div>
                <GeneralManage currentGeneralType={ currentGeneralType } />
            </Modal>


            <div className={ getCls('strategy') } _ga_event="toolbar.strategy" onClick={(e)=> { setCurrentName('strategy') }}>
                <div className="App-toolbar-item-image"><CreateIcon type="strategy" size="60" /></div>
                <div>{ Texts2['button.order'] }</div>
            </div>
            <Modal
                style = {{
                    overlay: { backgroundColor: 'transparent' }
                }}
                className = { 'App-game-modal-strategy App-game-modal-gray' }
                isOpen={ currentName === 'strategy' }
                onAfterOpen={ afterOpenModal }
                onRequestClose={ ()=>{ modalClose(currentName);setCurrentName(''); } }
                contentLabel="Toolbar Modal">
                <div style={{ position: 'relative' }}>
                    <div _ga_event="strategy.close" onClick={(e)=> { setCurrentName('') }}>
                        <GoBack size="60px" />
                    </div>
                    <div className="App-game-modal-header">{ Texts2['dialog.order.title'] }</div>
                    <Strategies />
                </div>
            </Modal>


            <div className={ getCls('battle') } _ga_event="toolbar.battle" onClick={(e)=> { setBattleEnemyModal(true) }}>
                <div className="App-toolbar-item-image"><CreateIcon type="battle" size="60" /></div>
                {newRecords && <span className="App-redpoint" style={{ margin: '-58px 0 0 15px' }}>new records</span>}
                <div>{ Texts['button.battle'] }</div>
            </div>
            { battleEnemyModal && <Battles onSelected={ (player, flag)=>{ 
                setBattleEnemyModal(false); 
                if(flag){
                    setBattleEnemy(player);
                    setBattleGeneralModal(flag);
                }
            } } />}
            { battleGeneralModal && <SelectGeneral battleType="attackCity" 
                onClose={ ()=>{ setBattleGeneralModal(false) } }
                onSelected={ (res)=>{
                    let { times, general, flag } = res;
                    setBattleGeneralModal(false);
                    if(flag){
                        setBattleEnemyModal(false); 
                        setBattleOver(flag);
                        if(protecting){
                            setInformAttackUnderProtecting(true);
                            setAttackInfo(general);
                        }else{
                            setBattleGeneral(general);
                            startBattle(general);  
                        }
                    }else{
                        setBattleEnemyModal(true);
                    }
                } } 
            />}
            {informAttackUnderProtecting && <ProtectWarning callback={(confirm)=>{
                setInformAttackUnderProtecting(false);
                if(!confirm){
                    return;
                }
                setBattleGeneral(attackInfo);
                startBattle(attackInfo);
                // props.close();  
            }} /> }
            { battleOverInfo.over && battleOver && <BattleResult type="city" 
                result={ battleOverInfo.result } 
                overConfirm={ ()=>{ 
                    setBattleOver(false); 
            } } /> }


            <div className={ getCls('troop') } _ga_event="toolbar.troop" onClick={(e)=> { setTroopsPop(true) }}>
                <div className="App-toolbar-item-image"><CreateIcon type="team" size="60" /></div>
                {(silversForRecruit && !hasRecruiting)
                    ? <span className="App-redpoint" style={{ margin: '-58px 0 0 15px' }}>new troop</span>
                    : <HealTroopsNotify />
                }
                <div>{ Texts['button.troop'] }</div>
            </div>
            <Modal
                style = {{
                    overlay: { 
                        backgroundColor: 'transparent'
                    },
                    content: {
                        height: '440px',
                        marginTop: '-220px'
                    } 
                }}
                className = { 'App-game-modal-troops App-game-modal' }
                isOpen={ troopsPop }
                _ga_event="troops.close"
                onAfterOpen={ afterOpenModal }
                onRequestClose={ ()=>setTroopsPop(false) }
                contentLabel="Troops Modal">
                <div _ga_event="troops.close" onClick={(e)=> { setTroopsPop(false) }} style={{ position: 'absolute', top: '-10px', right: '20px', zIndex: 999 }}>
                    <GoBack size="60px" />
                </div>
                <Troops isBeginnerGuide={ isBeginnerGuide } />
            </Modal>


            <div className={ getCls('activities') } _ga_event="toolbar.activity" onClick={(e)=> { 
                setActivitiesPop(true);
                removeActivityRedPoint();
                }}>
                <div className="App-toolbar-item-image"><CreateIcon type="activities" size="60" /></div>
                {newActivities && <span className="App-redpoint" style={{ margin: '-58px 0 0 15px' }}>newActivities</span>}
                <div>{ Texts['button.activities'] || 'Activities' }</div>
            </div>
            {activitiesPop && <ActivityPop onClose={ ()=>{
                setActivitiesPop(false);
            }} />}
        </div>
    );
}