import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClipboardJS from 'clipboard';

import * as API from '../services/api'
import * as EthFuns from './ethereum';

import { CreateIcon, GoBack } from '../components'
import { chainIcon, shortString } from '../utils/ui-res'

import './wallets.scss';

import Modal from 'react-modal';

import { Texts, Texts2, GameTexts, Chains, getChainInfo, RewardCoins } from '../const'

export default function PrivateKeyExport(props) {
    let dispatch = useDispatch();
    const { seasonDetail, account } = props;
    const seasonId = seasonDetail.seasonId;
    let zetaAddress = ( seasonDetail.signUpInfo || {} ).wallet || '';
    let chain = ( seasonDetail.signUpInfo || {}).chain;

    const [copied, setCopied] = useState(false);
    const [copied1, setCopied1] = useState(false);
    const [showKey, setShowKey] = useState(false);
    const [showPrivateKey, setShowPrivateKey] = useState(false);
    const [privateKey, setPrivateKey] = useState('');

    let { end_ts } = seasonDetail.seasonBasicInfo;
    let now = new Date().getTime();
    let diff = 60 * 60;

    useEffect(()=>{
        new ClipboardJS('.chain-copy');
    }, []);

    async function getPrivateKey(){
        setShowPrivateKey(true);
        // setPrivateKey('1x16eed499b072f697ce75101e98f97d486e1b398713ff1b4f8f0116dd1a1234');
        // return;

        const ts = Math.round(new Date().getTime()/1000);
        const message = `dumpwallet-${seasonId}-${ts}`
        await EthFuns.connect();
        EthFuns.personalSign({
            address: account,
            message
        }, function(res){
            if(res.code !== 'ok'){
                alert('wallet err');
                return;
            }
            let sign = res.data;
            API.privateKeyExport({
                "chain": chain, 
                "address": account,
                "sign": sign, //wallet sign
                "sid": seasonId,
                "ts": ts
            }, function(res1){
                let res = res1 || {};
                setPrivateKey(res.privatekey);
                console.log('showPrivateKey', sign, res);
            });
        });
    }
    
    function afterOpenModal(){
        // props.onClose && props.onClose();
    }

    return (<>
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '500px',
                    marginLeft: '-245px',
                    height: '340px',
                    marginTop: '-170px',
                    zIndex: '99999999'
                }
            }}
            className = { 'App-game-modal-avatar-select App-game-modal-gray-headless App-game-modal-wallet-select' }
            isOpen={ true }
            onRequestClose={ afterOpenModal }
            contentLabel="Wallets">

            <div className="App-export-privatekey">
                <div _ga_event={ "privatekey.export.close" } 
                    style={{ float: 'right', position: 'relative', top: '-57px', right: '17px' }} 
                    onClick={ ()=>{ props.onClose(); }}>
                    <GoBack size="60px" />
                </div>
                <div className="App-export-publickey">
                    <span className="address">{ shortString(zetaAddress, 20) }</span>
                    {copied && <span className="copied">Copied!</span>}
                    <a className="chain-copy" 
                        data-clipboard-text={ zetaAddress }
                        _ga_event="address.copy" 
                        onClick={ ()=>{
                            setCopied(true);
                            setTimeout(function(){
                                setCopied(false);   
                            }, 2000);
                        }
                    }>copy</a>
                </div>

                {showPrivateKey
                ? <><div className="App-privatekey-content">
                    <p>{ privateKey }</p>

                    {copied1 && <span className="copied">Copied!</span>}
                    <a className="chain-copy" 
                        data-clipboard-text={ privateKey }
                        _ga_event="privateKey.copy" 
                        onClick={ (event)=>{
                            setCopied1(true);
                            setTimeout(function(){
                                setCopied1(false);   
                            }, 2000);
                        }
                    }>copy</a>
                </div>
                <div className="App-tips-warning">
                    Warning: Never disclose this private key. Anyone with your private key can steal any assets held in your account.
                </div>
                </>

                : <div className="App-wallets-btns" style={{ textAlign: 'center' }}>
                    <span 
                        _ga_event="wallet.confirm"
                        style={{ marginLeft: '50px', display: (now/1000 > end_ts/1 + diff ? 'block' : 'none') }}
                        onClick={ (e)=> {
                            getPrivateKey();
                        }}
                        className={"App-general-btn App-general-btn-blue"}>
                        { Texts['button.web.how_to_play.private'] || 'Show PrivateKey' }
                    </span>
                    <div className="App-tips-info" style={{ display: (now/1000 < end_ts/1 + diff ? 'block' : 'none') }}>
                        Please wait, zeta wallet for players to receive season rewards will be avilable in 1 hour after season end.
                    </div>
                    <div className="App-tips-info" style={{ display: (now/1000 > end_ts/1 + diff ? 'block' : 'none') }}>
                        Lot will create a zeta wallet for players who sign up for the cross-chain season to receive season rewards.
                    </div>
                </div>}
            </div>
        </Modal>
    </>);
}
