import React, { useEffect, useState } from 'react';

//todo all list
/*

*/ 

export default function CreateIcon(props){
    let type = props.type;
    let size = props.size || 24;

    let styles = { 
        width: size + 'px',
        height: size + 'px'
    }

    function getClassNames(){
        return 'App-item-icon App-item-icon-' + type + ' App-item-icon-' + size;
    }

    return (
        <span className={ getClassNames() } style={ styles }></span>
    );
}