import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    generalInit,
    generalListUpdated, 
    getGeneralQuaValue, generalStatusChange, generalUpdated,
    resetGeneralStatusChangeResult,
    getUpgradeGeneralCost
} from '../../services/slice-general'
import { resourceRefresh } from '../../services/slice-city'

import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath
} from '../../utils/ui-res'

import { CreateIcon, LogisIcon, PersonNumber, Stars, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, GoBack } from '../../components'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import { numberFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import GeneralLevelTo from './level-to'
import SkillList from './skill-list'
import GeneralPortrait from './general-portrait'
import GeneralPortraitGPT from './general-gpt'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function GeneralDetail(props){
    const dispatch = useDispatch(); 

    const [currentGeneralIndex, setCurrentGeneralIndex] = useState(0);
    const [isLevelToPop, seIsLevelToPop] = useState(false);

    let type = props.currentGeneralType || 'STATS';
    const [currentGeneralType, setCurrentGeneralType] = useState(type);
    useEffect(()=>{
        setCurrentGeneralDetail(type);
    }, [type]);

    const generalList = useSelector(function(state){
        // console.log('state.generals',state.generals);
        return state.generals.list || [];
    });
    // console.log(generalList, 'generalList')
    let currentGeneral = generalList[currentGeneralIndex] || { qualification: {}};

    const { general_max_level, skill_max_level } = useSelector(function(state){
        return state.generals.config;
    });
    logTrace({ general_max_level, skill_max_level }, 'general.detail.config');

    const { currentStrength, nextStrength, upgradeData } = useSelector(function(state){
        return state.generals.detail;
    });
    const { canUpgrade, upgradeSilver } = upgradeData;
    logTrace({ currentStrength, nextStrength, upgradeData }, 'general.detail.level');

    const { generalReady, activeStats, generalUpdatedResult } = useSelector(function(state){
        return state.generals;
    });
    const { cityReady, resources } = useSelector(function(state){
        return state.city;
    });
    let resourcesData = resources || {};
    let troops = resourcesData.troop || {};
    let silvers = resourcesData.silver || {};

    const { upgradeGeneralCost } = useSelector(function(state){
        return state.generals;
    });

    logTrace({ activeStats, generalList, currentGeneralIndex, currentGeneral }, 'general.detail');

    function setCurrentGeneralInfo(index){
        setCurrentGeneralIndex(index);
        // setCurrentGeneralDetail(currentGeneralType);
        // setCurrentGeneralType('STATS');
    }
    useEffect(()=>{
        setCurrentGeneralDetail(currentGeneralType);
        dispatch(getUpgradeGeneralCost({
            id: currentGeneral.id,
            level: currentGeneral.level/1,
            levelTo: currentGeneral.level/1 + 2
        }));
    }, [currentGeneralIndex]);

    function setCurrentGeneralDetail(type){
        // currentGeneral = generalList[currentGeneralIndex];
        // console.log(currentGeneralIndex, generalList, currentGeneral);
        dispatch(getGeneralQuaValue({
            id: currentGeneral.qualification.general_id,
            level: currentGeneral.level
        }));
        setCurrentGeneralType(type);
    }

    function ableGeneral(data){
        dispatch(generalStatusChange({
            id: data.qualification.general_id,
            fn: 'ableGeneral'
        }));
        dispatch(getGeneralQuaValue({
            id: currentGeneral.qualification.general_id,
            level: currentGeneral.level
        }));
    }

    const [staminaTip, setStaminaTip] = useState(false);
    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    //skill notice
    const { skillList } = useSelector(function(state){
        return state.generals.detail;
    });
    let { skill_id = [] } = skillList;
    let skillCanUpgrade = false;
    for (let i = 0; i < skill_id.length; i++) {
        if(skill_max_level > skillList.skill_level[i] && skillList.check_upgrade[i]){
            skillCanUpgrade = true;
        }
    }

    useEffect(()=>{
        // dispatch(generalInit());
        dispatch(generalListUpdated());
        dispatch(resourceRefresh());
    }, [generalReady]);

    useEffect(()=>{
        dispatch(resourceRefresh());
    }, [currentStrength]);

    const { generalStatusChangeResult } = useSelector(function(state){
        return state.generals;
    });
    console.log('currentGeneral', currentGeneral, upgradeGeneralCost, silvers);

    function getStaminaRecoveryTime(t1){
        let diff = Math.abs(new Date().getTime()/1000 - t1);
        return Math.ceil(2*60*60 - diff);
    }

    return (<>
        <div className="App-general-detail">
            <div className="App-general-teams">
                <div className="App-general-types" style={{ paddingLeft: '10px' }}>
                    <div className="App-general-tab-normal App-general-tab-hightlight">
                        <CreateIcon type="general" size="48" />
                        <div>{ Texts['button.general'] }</div>
                    </div>
                </div>
                <div className="App-general-persons">
                    {generalList.length >0 && generalList.map((item, index)=>
                        <div key={index} style={{ display: 'inline'}}>
                        <div
                            className={ 'App-general-person ' + (item.able ? '': 'App-general-person-disable') } 
                            key={ item.qualification.general_id } 
                            _ga_event={ "general.select." + item.qualification.general_id }
                            onClick={ (e) => { setCurrentGeneralInfo(index) }}>
                            <GeneralPortrait generalId={ item.qualification.general_id } />
                            <span className="App-general-type">
                                <img src={ generalTypeImagePath(item.qualification.general_type) } />
                            </span>
                            <span className="App-general-level">{ item.level }</span>
                        </div>                        
                        {
                            (activeStats.able_count < activeStats.max_able_count && !item.able) 
                            && <span className="App-redpoint" style={{ margin: '5px 0 0 -77px', zIndex: 999 }}>able upgrade</span>
                        }
                        </div>
                    )}
                </div>
                <div className="App-general-toActive">
                    <label>{ Texts['dialog.can_recruit'] }:</label>
                    <span>{ activeStats.able_count }/{ activeStats.max_able_count}</span>
                </div>
            </div>

            <div className="App-general-current">
                <div className="big-body">
                    <GeneralPortraitGPT generalId={ currentGeneral.qualification.general_id } />
                </div>
                <div className="App-general-detail-energy">
                    <span>{ currentGeneral.stamina }/{ currentGeneral.qualification.stamina }</span>
                </div>
                <span 
                    style={{ top: '-2px', left: '5px', position: 'relative' }} 
                    _ga_event={ "general.stamina.tip" }
                    onClick={ ()=>{
                        setStaminaTip(true);
                    }}>
                    <CreateIcon type="info-tips" size="18" />
                </span> 
                <Modal
                    style = {{
                        overlay: { 
                            backgroundColor: 'rgba(0,0,0,0.2)'
                        },
                        content: {
                            height: '140px',
                            width: '400px',
                            marginTop: '-100px',
                            marginLeft: '-200px'
                        } 
                    }}
                    className = { 'App-game-modal-general-select App-game-modal' }
                    isOpen = { staminaTip }
                    onAfterOpen={ ()=>{} }
                    onRequestClose={ ()=>{ setStaminaTip(false); } }
                    contentLabel="Stamina Tip">
                    <div style={{ position: 'relative' }}>
                        <div className="App-game-season-rule-pop" style={{ fontSize: '14px', padding: '3rem 0 0', textAlign: 'center'}}>
                            { Texts2['popup.general_stamina.desc'] }
                        </div>
                        {(currentGeneral.staminaTime > 0) && <div className="App-general-stamina-recoveryTime">
                            { Texts2['popup.general_stamina.timer'] }: &nbsp; 
                            <ClockCounter time={ getStaminaRecoveryTime(currentGeneral.staminaTime) } />
                        </div>}
                    </div>
                </Modal>

                <div className="App-general-detail-main">
                    <img className="type" src={ generalTypeImagePath(currentGeneral.qualification.general_type) } />
                    <div style={{ display: 'inline-block' }}>
                        <div className="App-general-detail-main-name">{ Texts['dialog.general.card.name.' + currentGeneral.qualification.general_id ]}</div>
                        <div className="App-general-degree">Lv { currentGeneral.level }</div>
                    </div>
                </div>
            </div>

            <div className="App-general-logis">
                <div className="App-general-types">
                    <div 
                        className={ 'App-general-tab-normal ' + (currentGeneralType === 'STATS' ? 'App-general-tab-hightlight' : '') } 
                        _ga_event={ "general.stats" }
                        onClick={ (e)=> setCurrentGeneralDetail('STATS') }>
                        <span>STATS</span>
                        {
                            (!currentGeneral.able && activeStats.able_count < activeStats.max_able_count) 
                            && <span className="App-redpoint" style={{ margin: '-5px 0 0 5px' }}>able upgrade</span>
                        }
                    </div>
                    <div 
                        className={ 'App-general-tab-normal ' + (currentGeneralType === 'STRENGTHEN' ? 'App-general-tab-hightlight' : '') } 
                        _ga_event={ "general.strengthen" }
                        onClick={ (e)=> setCurrentGeneralDetail('STRENGTHEN') }>
                        <span>STRENGTHEN</span>
                        {
                            (currentGeneral.level < general_max_level && currentGeneral.able && canUpgrade) 
                            && <span className="App-redpoint" style={{ margin: '-5px 0 0 5px' }}>city upgrade</span>
                        }
                    </div>
                    <div
                        className={ 'App-general-tab-normal ' + (currentGeneralType === 'SKILLS' ? 'App-general-tab-hightlight' : '') } 
                        _ga_event={ "general.skills" }
                        onClick={ (e)=> setCurrentGeneralDetail('SKILLS') }>
                        <span>SKILLS</span>
                        {
                            (currentGeneral.able && skillCanUpgrade) 
                            && <span className="App-redpoint" style={{ margin: '-5px 0 0 5px' }}>skill upgrade</span>
                        }
                    </div>
                </div>

                {(currentGeneralType === 'STATS') ? (<>
                    <div className="App-stats-list">
                        <div className="App-stats-item">
                            <img src={ generalSkillImagePath('product') } />
                            <div className="stats">
                                <label>{ Texts['dialog.general.skill.1.name'] }</label>
                                <span>{ currentGeneral.qualification.qualification_silver_product }</span>
                            </div>
                            <div className="text">
                                { Texts['dialog.general.skill.1.desc']} 
                            </div>
                        </div>
                        <div className="App-stats-item">
                            <img src={ generalSkillImagePath('recruit') } />
                            <div className="stats">
                                <label>{ Texts['dialog.general.skill.3.name'] }</label>
                                <span>{ currentGeneral.qualification.qualification_troop_recruit }</span>
                            </div>
                            <div className="text">
                                { Texts['dialog.general.skill.3.desc']} 
                            </div>
                        </div>
                        <div className="App-stats-item">
                            <img src={ generalSkillImagePath('attack') } />
                            <div className="stats">
                                <label>{ Texts['dialog.general.skill.5.name'] }</label>
                                <span>{ currentGeneral.qualification.qualification_attack }</span>
                            </div>
                            <div className="text">
                                { Texts['dialog.general.skill.5.desc']} 
                            </div>
                        </div>
                        <div className="App-stats-item">
                            <img src={ generalSkillImagePath('defense') } />
                            <div className="stats">
                                <label>{ Texts['dialog.general.skill.7.name'] }</label>
                                <span>{ currentGeneral.qualification.qualification_defense }</span>
                            </div>
                            <div className="text">
                                { Texts['dialog.general.skill.7.desc']} 
                            </div>
                        </div>
                        <div className="App-stats-item">
                            <img src={ generalSkillImagePath('load') } />
                            <div className="stats">
                                <label>{ Texts['dialog.general.skill.9.name'] }</label>
                                <span>{ currentGeneral.qualification.qualification_load }</span>
                            </div>
                            <div className="text">
                                { Texts['dialog.general.skill.9.desc']} 
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign:'center', padding:'20px' }}>
                    {(!currentGeneral.able && activeStats.max_able_count > activeStats.able_count) ? (
                    <span className="App-general-btn App-general-btn-green" 
                        _ga_event={ "general.recruit" }
                        onClick = { (e) => { 
                            setWaitingPop(true);
                            ableGeneral(currentGeneral)
                        }}>
                        { Texts['button.recruit'] }
                    </span>) : (
                    <span
                        _ga_event={ "general.recruit.disable" }
                        onClick={ ()=>{
                            setNotifyPop(true);
                        } }  
                        style={{ display: 'none' }}
                        className="App-general-btn App-general-btn-green App-btn-disable">
                        { Texts['button.recruit'] }
                    </span>)}
                    </div>
                    { notifyPop && <ToastTip text={ 
                        Texts2['dialog.general.recruit_fail_reason'] 
                    } callback={ ()=>{ setNotifyPop(false) } } />}

                    { (waitingPop && generalStatusChangeResult.back) && <NotifyPop tips={{
                        loading: '',
                        success: Texts2['dialog.general.recruit_success'],
                        fail: Texts2['dialog.general.recruit_fail']
                    }} data={ generalStatusChangeResult } callback={ ()=>{
                        dispatch(resetGeneralStatusChangeResult());
                        setWaitingPop(false);
                    }} />}
                </>): (<></>)}

                {(currentGeneralType === 'SKILLS') ? (<div style={{ padding: '10px' }}>
                    <SkillList currentGeneral={ currentGeneral } />
                </div>): (<></>)}

                {(currentGeneralType == 'STRENGTHEN') ? (<>
                <div className="App-general-data" style={{ margin: '0 20px' }}>
                    <div className="App-general-logis-item App-general-logis-header">
                        <LogisIcon type='empty' />
                        <div className="stats">{ Texts['common.character'] }</div>
                        <div className="current">{ Texts['common.current'] }</div>
                        <div className="next" style={{ color: '#d9b154' }}>{ 
                            currentGeneral.level >= general_max_level ? 'Max' : Texts['common.next'] 
                        }</div>
                    </div>
                    <div className="App-general-logis-item" key={ 'silver_product' }>
                        <LogisIcon type={ 'product' } style={{ marginLeft: '-20px' }} />
                        <div className="stats">{ Texts['dialog.general.skill.1.name'] }</div>
                        <div className="current">{ numberFormat(currentStrength.silver_product) }</div>
                        <div className="next">{ 
                            currentGeneral.level >= general_max_level 
                            ? numberFormat(currentStrength.silver_product)
                            : numberFormat(nextStrength.silver_product) 
                        }</div>
                    </div>
                    <div className="App-general-logis-item" key={ 'troop_product' }>
                        <LogisIcon type={ 'hire' } style={{ marginLeft: '-20px' }} />
                        <div className="stats">{ Texts['common.recruit'] }</div>
                        <div className="current">{ numberFormat(currentStrength.troop_product) }</div>
                        <div className="next">{ 
                            currentGeneral.level >= general_max_level 
                            ? numberFormat(currentStrength.troop_product)
                            : numberFormat(nextStrength.troop_product) 
                        }</div>
                    </div>
                    <div className="App-general-logis-item" key={ 'attack' }>
                        <LogisIcon type={ 'attack' } style={{ marginLeft: '-20px' }} />
                        <div className="stats">{ Texts['dialog.general.skill.5.name'] }</div>
                        <div className="current">{ numberFormat(currentStrength.attack) }</div>
                        <div className="next">{ 
                            currentGeneral.level >= general_max_level 
                            ? numberFormat(currentStrength.attack)
                            : numberFormat(nextStrength.attack) 
                        }</div>
                    </div>
                    <div className="App-general-logis-item" key={ 'defense' }>
                        <LogisIcon type={ 'defense' } style={{ marginLeft: '-20px' }} />
                        <div className="stats">{ Texts['dialog.general.skill.7.name'] }</div>
                        <div className="current">{ numberFormat(currentStrength.defense) }</div>
                        <div className="next">{ 
                            currentGeneral.level >= general_max_level 
                            ? numberFormat(currentStrength.defense)
                            : numberFormat(nextStrength.defense) 
                        }</div>
                    </div>
                    <div className="App-general-logis-item" key={ 'load' }>
                        <LogisIcon type={ 'load' } style={{ marginLeft: '-20px' }} />
                        <div className="stats">{ Texts['dialog.general.skill.9.name'] }</div>
                        <div className="current">{ numberFormat(currentStrength.load) }</div>
                        <div className="next">{ 
                            currentGeneral.level >= general_max_level 
                            ? numberFormat(currentStrength.load)
                            : numberFormat(nextStrength.load) 
                        }</div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', margin: '0 40px' }}>
                    {(currentGeneral.level >= general_max_level) 
                    ? <div className="App-general-data-op">Max Level { general_max_level }</div>
                    : <div className="App-general-data-op">
                        { Texts['dialog.skill_need'] }: <CreateIcon type="silver" size="20" /> 
                        <span style={{ fontSize:'12px',zoom:0.8,margin: '0 10px' }}>x</span>
                        { upgradeSilver }
                    </div>}
                    {(currentGeneral.able && canUpgrade) ? (
                    <span className="App-general-btn App-general-btn-green" 
                        _ga_event={ "general.qualification.update" }
                        onClick = { (e)=>{ 
                            let upgradeCost = upgradeGeneralCost[currentGeneral.id + '-' + (currentGeneral.level/1 + 2)] || 0;
                            if(upgradeCost <= silvers.value && general_max_level - currentGeneral.level >= 2){
                                seIsLevelToPop(true);
                                return;
                             }
                            setWaitingPop(true);
                            dispatch(generalUpdated({
                                id: currentGeneral.id,
                                levelTo: currentGeneral.level/1 + 1
                            }));
                            dispatch(getGeneralQuaValue({
                                id: currentGeneral.qualification.general_id,
                                level: currentGeneral.level/1 + 1
                            }))
                        }}
                    >{ Texts['button.upgrade'] }</span>) : (
                    (currentGeneral.level >= general_max_level) ? <></> : <span
                        _ga_event={ "general.level.update" }
                        onClick={ ()=>{
                            setNotifyPop(true);
                        } } 
                        className="App-general-btn App-general-btn-green App-btn-disable">
                        { Texts['button.upgrade'] }
                    </span>)}
                </div>

                { isLevelToPop && <GeneralLevelTo data={{
                    id: currentGeneral.qualification.general_id,
                    level: currentGeneral.level,
                    levelTo: currentGeneral.level/1 + 1,
                    levelMax: general_max_level/1
                }} onClose={ (res)=>{
                    dispatch(getGeneralQuaValue({
                        id: res.id,
                        level: res.level
                    }));
                    seIsLevelToPop(false);
                } } />}

                { notifyPop && <ToastTip text={ 
                    Texts2['dialog.general.upgrade_fail_reason'] 
                } callback={ ()=>{ setNotifyPop(false) } } />}

                { waitingPop && <NotifyPop tips={{
                    loading: '',
                    success: Texts2['dialog.general.upgrade_success'],
                    fail: Texts2['dialog.general.upgrade_fail']
                }} data={ generalUpdatedResult } callback={ ()=>{
                    setWaitingPop(false);
                }} />}
                </>): (<></>)}
            </div>
        </div>
    </>);
}
