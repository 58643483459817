import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Lannister from './assets/lannister.png'
import vIcon from './assets/victory.png'
import failIcon from './assets/failure.png'
import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath
} from '../../utils/ui-res'
import { numberFormat, getStamina } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import { CreateIcon, GoBack, LogisIcon, Stars, PersonNumber, ConfirmTip } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import SkillList from './skill-list'
import GeneralPortrait from './general-portrait'
import GeneralPortraitGPT from './general-gpt'

import GatherMutiTimes from '../block/gather-muti'

import { 
    generalInit,
    getGeneralQuaValue,
    getGeneralBattleInfo,
    generalListUpdated,
    battleOver,
    getUseTroops,
    getDefenseBlockGenerals
} from '../../services/slice-general'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

function isSameAddress(addr1, addr2){
    addr1 = addr1 || '';
    addr2 = addr2 || '';
    return addr1.toLowerCase() === addr2.toLowerCase();
}
export default function BattleGenerals(props){
    const dispatch = useDispatch(); 
    const onBattle = props.onBattle; 
    const battleType = props.battleType || ''; 
    const defenseList = props.defenseList || [];
    const codType = props.codType || '';

    const [isConfirm, setIsConfirm] = useState(true);
    const [isNoAbleGeneral, setIsNoAbleGeneral] = useState(true);
    const [currentGeneralIndex, setCurrentGeneralIndex] = useState(0);
    const generalList = useSelector(function(state){
        return state.generals.list || [];
    });

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { codGeneralIds } = useSelector(function(state){
        return state.generals;
    });
    const { defenseCityGeneralId, defenseBlockGenerals } = useSelector(function(state){
        return state.generals;
    });
    let ableGenerals = [];
    let defenseAbleGenerals = [];
    let defenseObj = {
        [defenseCityGeneralId]: 1
    };
    defenseList.forEach(function(item){
        if(isSameAddress(item.username, currentUser.username)){
            defenseObj[item.generalId] = item;             
        }
    });
    defenseBlockGenerals.forEach(function(item){
        if(isSameAddress(item.username, currentUser.username)){
            defenseObj[item.generalId] = item;             
        } 
    });
    generalList.forEach(function(general){
        let generalId = general.qualification.general_id;
        if(general.able && !defenseObj[generalId] && !codGeneralIds[generalId]){
            defenseAbleGenerals.push(general);
        }
        if(general.able && !defenseObj[generalId] && !codGeneralIds[generalId]){
            ableGenerals.push(general);
        }
    });

    let isCod = battleType === 'assembly';
    let isDefense = battleType.indexOf('defense') > -1;
    if(isDefense){
        ableGenerals = defenseAbleGenerals;
    }
    if(battleType == 'guide'){
        ableGenerals = generalList;
    }
    // console.log({defenseObj, defenseList, generalList, ableGenerals}, 'isDefense');
    
    const { generalReady } = useSelector(function(state){
        return state.generals;
    });

    const { skillList, id, level } = useSelector(function(state){
        return state.generals.detail;
    });

    const currentGeneral = ableGenerals[currentGeneralIndex] || { qualification: {} };
    const battleInfo = currentGeneral.battleInfo || {};
    logTrace({generalList, currentGeneral, skillList }, 'battle.general.select');

    const { cityReady, resources } = useSelector(function(state){
        return state.city;
    });
    let troops = resources.troop.value;

    const { troopsCanUse } = useSelector(function(state){
        return state.generals;
    });
    // console.log(troopsCanUse)

    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(ableGenerals.length === 0);

    function setCurrentGeneralInfo(index){
        setCurrentGeneralIndex(index);
    }
    useEffect(()=>{
        setCurrentGeneralDetail();
    }, [currentGeneralIndex]);

    function setCurrentGeneralDetail(){
        dispatch(getGeneralQuaValue({
            id: currentGeneral.qualification.general_id,
            level: currentGeneral.level
        }));
        dispatch(getGeneralBattleInfo((generalList[currentGeneralIndex] || {}).qualification));
    }

    const [isStartAttackConfirm, setIsStartAttackConfirm] = useState(false);
    function isPowerInsufficient(){
        let firstDefense = props.firstDefense;
        let _general = {
            attack: battleInfo.sum.attack,
            defense: battleInfo.sum.defense,
            troops: troopsCanUse['attack']
        };
        return getBattleIndex(_general) < getBattleIndex(firstDefense);

        function getBattleIndex(data){
            let {attack = 0, defense =0, troops =0 } = data || {};
            return (attack - 0) * (defense - 0) * (troops - 0);
        }
    }
    function genaralSelectedConfirm(times){
        console.log('genaralSelectedConfirm', {
                times, 
                general: currentGeneral, 
                flag: true
            });
        let isNeedConfirm = isPowerInsufficient();
        if(battleType === 'attackBlock' && isNeedConfirm){
            setIsStartAttackConfirm(true);
        }else{
            setIsConfirm(false);
            props.onSelected({
                times, 
                general: currentGeneral, 
                flag: true
            });
        }
    }
    
    function cancelSelectModal(){
        props.onClose && props.onClose();
    }

    useEffect(()=>{
        dispatch(getUseTroops());
        dispatch(generalListUpdated());
        dispatch(getDefenseBlockGenerals());
        dispatch(getGeneralQuaValue({
            id: currentGeneral.qualification.general_id,
            level: currentGeneral.level
        }));
        dispatch(getGeneralBattleInfo((generalList[currentGeneralIndex] || {}).qualification));
    }, []);

    // console.log({defenseList, defenseObj, generalList, ableGenerals, notifyPop, battleType});

    const [gatherTimesPop, setGatherTimesPop] = useState(false);
    const [notifySelectPop, setNotifySelectPop] = useState(false);
    const [notifyNoGeneralPop, setNotifyNoGeneralPop] = useState(false);
    function afterPop(){
        if(battleType === 'defenseCity' && ableGenerals.length > 0){
            setNotifySelectPop(true);
        }
        if(ableGenerals.length === 0){
            setNotifyNoGeneralPop(true);
        }
    }

    return (<>
        { notifyNoGeneralPop && <ToastTip text={ 
            Texts2['dialog.battle.attack_fail_no_general'] 
        } callback={ ()=>{ setNotifyNoGeneralPop(false) } } />}


        { notifySelectPop && <ToastTip text={ 
            Texts2['dialog.defense_city.defense_success'] 
        } callback={ ()=>{ setNotifySelectPop(false) } } />}
        
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '930px',
                    height: '550px',
                    marginLeft: '-450px',
                    marginTop: '-250px'
                }
            }}
            className = { 'App-game-modal-general-select App-game-modal-gray' }
            isOpen={ isConfirm }
            onAfterOpen={  ()=>{ afterPop() }  }
            onRequestClose={ ()=>{ cancelSelectModal() } }
            contentLabel="SelectGeneral Modal">
            <div style={{ position: 'relative',height:'500px' }}>
                <div  _ga_event={ "general.selector.close" } onClick={ ()=>{ cancelSelectModal(); }}>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ lineHeight: '360%'}}>{ Texts['dialog.choose_general'] }</div>
                <div className="App-general-detail" style={{ 
                    padding: '30px 20px 30px',
                    display: ableGenerals.length === 0 ? 'none' : 'flex'
                }}>
                    <div className="App-general-teams" style={{ width: '300px' }}>
                        <div className="App-general-persons" style={{ margin: 0 }}>
                            {ableGenerals.length >0 && ableGenerals.map((item, index)=>
                                <div className={ 'App-general-person ' + (item.able ? '': 'App-general-person-disable') } key={ item.qualification.general_id } 
                                    _ga_event={ "general.selector.select" }
                                    onClick={ (e) => { setCurrentGeneralInfo(index) }}>
                                    <GeneralPortrait generalId={ item.qualification.general_id } />
                                    <span className="App-general-type">
                                        <img src={ generalTypeImagePath(item.qualification.general_type) } />
                                    </span>
                                    <span className="App-general-level">{ item.level }</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="App-general-current" style={{ width: '200px', margin: '0 50px' }}>
                        <div className="big-body">
                            <GeneralPortraitGPT generalId={ currentGeneral.qualification.general_id } />
                        </div>
                        <div className="App-general-detail-energy">
                            <span>{ currentGeneral.stamina }/{ currentGeneral.qualification.stamina }</span>
                        </div>
                        <div className="App-general-detail-troops">
                            <label>{ Texts['dialog.send_troops'] }:</label>
                            <span><span className="can-use">
                                { isDefense ? troopsCanUse['defense'] : troopsCanUse['attack'] }
                            </span>/{ Math.round(troops) }</span>
                        </div>
                        <div className="App-general-detail-main">
                            <img className="type" 
                                style={{ marginLeft: '60px' }}
                                src={ generalTypeImagePath(currentGeneral.qualification.general_type) } />
                            <div style={{ display: 'inline-block' }}>
                                <div className="App-general-detail-main-name">{ Texts['dialog.general.card.name.' + currentGeneral.qualification.general_id ]}</div>
                                <div className="App-general-degree">Lv { currentGeneral.level }</div>
                            </div>
                        </div>
                    </div>

                    <div className="App-general-logis" style={{ margin: '0 0 0', width: '320px' }}>
                        {(!!battleInfo.base) && (<div className="App-general-data" style={{ margin: '20px' }}>
                            <div className="App-general-logis-item" key={ 'attack' }>
                                <LogisIcon type={ 'attack' } style={{ marginLeft: '-20px' }} />
                                <div className="stats">{ 'attack'.toUpperCase() }</div>
                                <div className="current" style={{ width: '40%' }}>
                                    {numberFormat(battleInfo.base.attack) }
                                    <span className="plus">+{ numberFormat(battleInfo.sum.attack - battleInfo.base.attack) }</span>
                                </div>
                            </div>
                            <div className="App-general-logis-item" key={ 'defense' }>
                                <LogisIcon type={ 'defense' } style={{ marginLeft: '-20px' }} />
                                <div className="stats">{ 'defense'.toUpperCase() }</div>
                                <div className="current" style={{ width: '40%' }}>
                                    {numberFormat(battleInfo.base.defense) }
                                    <span className="plus">+{ numberFormat(battleInfo.sum.defense - battleInfo.base.defense) }</span>
                                </div>
                            </div>
                            <div className="App-general-logis-item" key={ 'load' }>
                                <LogisIcon type={ 'load' } style={{ marginLeft: '-20px' }} />
                                <div className="stats">{ 'load'.toUpperCase() }</div>
                                <div className="current" style={{ width: '40%' }}>
                                    {numberFormat(battleInfo.base.load) }
                                    <span className="plus">+{ numberFormat(battleInfo.sum.load - battleInfo.base.load) }</span>
                                </div>
                            </div>
                        </div>)}

                        <SkillList skillList={ skillList } currentGeneral={ currentGeneral } from="battle" />

                        <div className="App-game-modal-general-select-btn" style={{ position:'absolute',right:'100px', bottom:'-10px'}} _id="attackBlock,defenseBlock,attackCity,defenseCity">

                        { battleType === 'defenseCity' && (
                            currentGeneral.able ? 
                            (<span className="App-general-btn App-general-btn-green" 
                                style={{ margin: '40px 0 0 30px' }}
                                _ga_event={ "general.selector.confirm" }
                                onClick = { (e) => { 
                                    genaralSelectedConfirm(1) 
                                }}>
                                { Texts['common.def'] }
                            </span>)
                            : (<span className="App-general-btn App-general-btn-green App-btn-disable" 
                                _ga_event={ "general.selector.confirm.disable" }
                                onClick={ ()=>{
                                    setNotifyPop(true);
                                } } 
                                style={{ margin: '20px 0 0 30px' }}>
                                { Texts['common.def'] }
                            </span>)
                            ) 
                        }

                        { (battleType === 'assembly') &&
                            ((((codType === 'create' && currentGeneral.stamina >= getStamina(battleType) || codType !== 'create') && currentGeneral.able && troops > 0) ? 
                            (<span className="App-general-btn App-general-btn-green" 
                                style={{ margin: '40px 0 0 30px' }}
                                _ga_event={ "general.selector.confirm" }
                                onClick = { (e) => { 
                                    genaralSelectedConfirm(1) 
                                }}>
                                { Texts['common.assembly'] }
                                { codType === 'create' && <>&nbsp; <span className="heart">❤</span>-{ getStamina(battleType) }</> }
                            </span>)
                            : (<span className="App-general-btn App-general-btn-green App-btn-disable" 
                                _ga_event={ "general.selector.confirm.disable" }
                                onClick={ ()=>{
                                    setNotifyPop(true);
                                } } 
                                style={{ margin: '20px 0 0 30px' }}>
                                { Texts['common.assembly'] }
                                { codType === 'create' && <>&nbsp; <span className="heart">❤</span>-{ getStamina(battleType) }</> }
                            </span>)))
                        }

                        {gatherTimesPop && <GatherMutiTimes data={{
                            battleType,
                            total: currentGeneral.stamina,
                            unit: getStamina(battleType)
                        }} 
                        onSet={ (res)=>{
                            setGatherTimesPop(false);
                            let { times } = res;
                            genaralSelectedConfirm(times)
                        } }
                        onClose={ ()=>{
                            setGatherTimesPop(false);
                        } } /> }
                        { (battleType !== 'defenseCity' && battleType !== 'assembly') &&
                            (((currentGeneral.stamina >= getStamina(battleType) && currentGeneral.able && troops > 0) ? 
                            (<span className="App-general-btn App-general-btn-green" 
                                style={{ margin: '40px 0 0 30px' }}
                                _ga_event={ "general.selector.confirm" }
                                onClick = { (e) => { 
                                    if(currentGeneral.stamina/getStamina(battleType) >= 2 && battleType === 'miningSilvers'){
                                        setGatherTimesPop(true);
                                        return;
                                    }
                                    genaralSelectedConfirm(1);
                                }}>
                                { battleType.indexOf('defense') > -1 && Texts['common.def'] }
                                { battleType.indexOf('attack') > -1 && Texts['common.atk'] }
                                { battleType === 'miningSilvers' && Texts['button.block_gather'] }
                                &nbsp; <span className="heart">❤</span>-{ getStamina(battleType) } 
                            </span>)
                            : (<span className="App-general-btn App-general-btn-green App-btn-disable" 
                                _ga_event={ "general.selector.confirm.disable" }
                                onClick={ ()=>{
                                    setNotifyPop(true);
                                } } 
                                style={{ margin: '20px 0 0 30px' }}>
                                { battleType.indexOf('defense') > -1 && Texts['common.def'] }
                                { battleType.indexOf('attack') > -1 && Texts['common.atk'] }
                                { battleType === 'miningSilvers' && Texts['button.block_gather'] }
                                &nbsp; <span className="heart">❤</span>-{ getStamina(battleType) } 
                            </span>)))
                        }
                        </div>

                        {isStartAttackConfirm && <ConfirmTip 
                            texts={{
                                title: Texts['popup.low_power_attention.title'],
                                content: Texts['popup.low_power_attention.desc'],
                                cancel: Texts['popup.map_defense_cancel'],
                                confirm: Texts['popup.map_defense_confirm']
                            }}
                            confirm={ ()=>{
                                setIsConfirm(false);
                                props.onSelected({general:currentGeneral, flag:true});
                                setIsStartAttackConfirm(false);
                            } }
                            cancel = { ()=>{
                                setIsStartAttackConfirm(false);
                            }}
                        />}

                        { notifyPop && <ToastTip text={ 
                            currentGeneral.stamina < getStamina(battleType)
                            ? Texts2['dialog.battle.general_no_stamina'] 
                            : Texts2['dialog.battle.general_no_troop']
                        } callback={ ()=>{ setNotifyPop(false) } } />}
                    </div>
                </div>
            </div>
        </Modal>
    </>);
}