import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resourceUpdate } from '../../services/slice-city'
import { 
    getTestResourceCoolDownTime, 
    resourceRefresh, addTestResource 
} from '../../services/slice-city'

import MoraleBtn from '../morale/morale-btn';

import ResourceStat from './stat';

import { CreateIcon, ToastTip } from '../../components'

import { numberFormat } from '../../utils/utils'
import { isProd } from '../../utils/ui-res'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import './resource.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ResourceTips(props){
    const dispatch = useDispatch(); 
    const [statPop, setStatPop] = useState(false);

    const { cityReady, resources, resourceCoolDownTime, resourceResult, golds } = useSelector(function(state){
        return state.city;
    });
    // console.log('golds', golds);
    const [resourcePop, setResourcePop] = useState(resourceResult);

    let data = resources || {};
    let troop = data.troop || {};
    let silver = data.silver || {};
    
    useEffect(()=>{
        dispatch(resourceUpdate());
        dispatch(getTestResourceCoolDownTime());
    }, [cityReady]);

    useEffect(()=>{
        dispatch(getTestResourceCoolDownTime());
        dispatch(resourceRefresh());
    }, [resourceResult]);

    return(<>
        <div className="App-topbar-middle">
            <div className="App-mertrial-item" _ga_event="resource.open" onClick={ ()=>{ setStatPop(true) } }>
                <CreateIcon type="silver" size="20" /> 
                <span className="App-mertrial-item-value">
                    { numberFormat(silver.value) }
                </span>
            </div>
            <div className="App-mertrial-item" _ga_event="resource.open" onClick={ ()=>{ setStatPop(true) } }>
                <CreateIcon type="silver" size="20" /> 
                <span className="App-mertrial-item-value">
                    { numberFormat(silver.production) }/h
                </span>
            </div>
            <div className="App-mertrial-item" _ga_event="resource.open" onClick={ ()=>{ setStatPop(true) } }>
                <CreateIcon type="troop" size="20" /> 
                <span className="App-mertrial-item-value">
                    { numberFormat(troop.value) }
                </span>
            </div>
            <div className="App-mertrial-item" _ga_event="resource.open" onClick={ ()=>{ setStatPop(true) } }>
                <CreateIcon type="troop" size="20" /> 
                <span className="App-mertrial-item-value">
                    { numberFormat(troop.production) }/h
                </span>
            </div>

            {(resourceCoolDownTime === 0) && (<div style={{ 
                display: isProd ? 'none' : 'block',
                width: '2rem', cursor: 'pointer' }} _ga_event="resource.add" onClick={ (e)=> {
                dispatch(addTestResource());
            }}>+</div>)}
            { resourcePop && <ToastTip text={ 
                'Resources add success. Please note only once a day.'
            } callback={ ()=>{ setResourcePop(false) } } />}

            {troop.value < data.maintainNeedTroop && <div className="App-game-modal-tips" _ga_event="resource.stat.open" onClick={ ()=>{ setStatPop(true) } }>
                <span style={{ position: 'relative', top: '-5px' }}><CreateIcon type="info-tips" size="36" /></span>
                <span className="App-Resources-warning">{ Texts['popup.troop_attention.title'] }</span>
            </div>}
            {statPop && <ResourceStat data={ data } close={ ()=> {setStatPop(false) } } />}
        </div>

        <MoraleBtn coins={{
            silver: silver.value,
            golds: golds
        }} />
    </>);
}