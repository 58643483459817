import React, { useEffect, useState } from 'react';
import { redirect, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ClipboardJS from 'clipboard';

import * as DASlices from '../../da/slice';
import * as EthFuns from '../../wallets/ethereum';
import * as DAContract from '../../da/contract';

import SeasonMap from '../map/cover';
import SignUpSteps from '../signup/signup-steps';
import SignUpStats from '../signup/signup-stat';
import RewardInfo from '../reward-pool/reward-info';

import WalletSelect from '../../wallets/wallet-select';
import PrivateKeyExport from '../../wallets/private-key';

import { CreateIcon, ClockCounter, Loading, LoadingBtn, Ranks, ToastTip } from '../../components'
import { Texts, Texts2, DATexts, GameTexts, Chains, getChainInfo, RewardCoins } from '../../const'

import { numberFormat, dateFormat, getWalletSignErrMessage, showPriceData } from '../../utils/utils'
import { logTrace } from '../../utils/log';
import * as cache from '../../utils/cache'

import './season.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export function getSeasonId(sid){
    //2024-02-25-1
    //test-zeta_main-2024-02-25-1-5

    let ids = sid.split('-');
    // return [ids[1], ids[2]].join('-');
    return [ids[2], ids[3], ids[4], ids[5]].join('-');
}

function applyTotalCount(arr){
    let total = 0;
    arr.forEach(function(item){
        total += item/1 || 0;
    });
    return total;
}

export default function SeasonItem(props){
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const seasonBasicInfo = JSON.parse(JSON.stringify(props.seasonBasicInfo));
    const chain = JSON.parse(JSON.stringify(props.chain));
    const chainInfo = Chains[seasonBasicInfo.chain];

    const seasonId = seasonBasicInfo.sid;
    const contractAddr = seasonBasicInfo['contractAddr'].toLowerCase();
    const rewardSymbol = seasonBasicInfo.chain;

    const mapInfo = seasonBasicInfo['map_info'] || {};

    const [ account, setAccount ] = useState('');
    const [ seasonStatus, setSeasonStatus ] = useState({});
    const [ privateKey, setPrivateKey ] = useState(false);

    const { daSeasons, seasonHis, unionInfo, gameInfo, gameStart } = useSelector(function(state){
        return state.dauction;
    });
    let _unionInfo = unionInfo[seasonId] || {};
    let _gameInfo = gameInfo[seasonId] || {};

    let unionId = _unionInfo.unionId || '0';
    let isSigned = unionId !== '0';

    const [ unionInfoLen, setUnionInfoLen ] = useState(0);
    const [ isRightChain, setIsRightChain ] = useState(false);

    const [selectWalletPop, setSelectWalletPop] = useState(false);
    const [signupStepPop, setSignupStepPop] = useState(false);
    const [playerStatPop, setPlayerStatPop] = useState(false);
    const [infoPop, setInfoPop] = useState(false);
    const [rankPop, setRankPop] = useState(false);

    useEffect(()=>{
        setUnionInfoLen(JSON.stringify(unionInfo).length);
    }, [unionInfo]);
    useEffect(()=>{
        if(!account){
            return;
        }
        // props.onLogin && props.onLogin(account);

        let params = {
            unionId,
            seasonId,
            address: account
        };
        // console.log('DASlices', params);
        dispatch(DASlices.getUnionInfo(params));
        dispatch(DASlices.getGameInfo(params));
        dispatch(DASlices.getLands({ seasonId }));

        dispatch(DASlices.setGameStart({
            seasonInfo: seasonBasicInfo,
            unionId,
            address: account,
            isStart: false
        }));
    }, [account, seasonId]);

    async function linkToWallet(wallet, chain){
        // console.log('walletSelected 2', wallet, chain)
        if(wallet.keyName === 'walletConnect' || wallet.keyName === 'binanceQrcode'){
            EthFuns.login(seasonBasicInfo, async function(res){
                if(res.code !== 'ok'){
                    return;
                }
                setAccount(res.account);
                cache.set('accountCacheKey', res.account);
                let contract = await DAContract.init(seasonBasicInfo, wallet, chain);
            });
            return;
        }
        EthFuns.detect(chain, function(result){
            setIsRightChain(result);
            // console.log('walletSelected 3', result)
            if(result){
                localStorage.currentChainName = chain.name;
                EthFuns.login(seasonBasicInfo, async function(res){
                    if(res.code !== 'ok'){
                        return;
                    }
                    setAccount(res.account);
                    cache.set('accountCacheKey', res.account);
                    await DAContract.init(seasonBasicInfo, wallet, chain);
                    // console.log('contract walletSelected 4', res, seasonBasicInfo, wallet, chain)
                });
                return;
            }

            EthFuns.transChain(chain, function(res1){
                // console.log('walletSelected 5', chain, res1)
                localStorage.currentChainName = chain.name;
                if(!res1){
                    return;
                }
                EthFuns.login(seasonBasicInfo, async function(res){
                    // console.log('walletSelected 6', seasonBasicInfo, res)
                    if(res.code !== 'ok'){
                        return;
                    }
                    setAccount(res.account);
                    cache.set('accountCacheKey', res.account);
                    // dispatch(DASlices.getAllRewards({seasonHis, userInfo: { address: res.account }}));
                    await DAContract.init(seasonBasicInfo, wallet, chain);
                });
            });
        });
    }

    const { opResults } = useSelector(function(state){
        return state.dauction;
    });
    const [notifyPop, setNotifyPop] = useState(false);
    useEffect(()=>{
        if(!account){
            return;
        }
        setNotifyPop(true);
        if(opResults.type !== 'joinUnion'){
            return;
        }
        dispatch(DASlices.getUnionInfo({
            unionId,
            seasonId,
            address: account
        }));
    }, [opResults]);

    function signupToGame(data){
        let { unionId } = data;
        let params = {
            seasonId,
            unionId,
            address: account,
            value: seasonBasicInfo.registryFee
        }
        dispatch(DASlices.joinUnion(params));
    }

    const [copied, setCopied] = useState(false);
    useEffect(()=>{
        new ClipboardJS('.sid-copy');
    }, []);

    useEffect(()=>{
        setSeasonStatus(getSeasonStatus(seasonBasicInfo));
    }, [seasonBasicInfo.sid, unionId]);

    function getSeasonIndex(seasonId){
        let arr = seasonId.split('-');
        return arr[arr.length - 1];
    }

    async function walletSelected(info){
        // console.log('walletSelected', info);
        let { wallet } = info;
        let chain = info.chain || chainInfo;
        let res = await EthFuns.setWallet(wallet, chain);
        linkToWallet(wallet, chain);
        setSelectWalletPop(false);
    }

    return (<>
        {selectWalletPop && <WalletSelect seasonBasicInfo={ seasonBasicInfo } 
            onSelect={ (info)=>{ walletSelected(info); } }
            onClose={ ()=>{ setSelectWalletPop(false) } }
        />}
        <div className={"App-season-item" + (seasonStatus.end ? ' App-season-item-end' : '')}>
        <a className={ "App-social-ethereum " + seasonBasicInfo.chain }>
            {(rewardSymbol == 'aZETA') && <CreateIcon type={ rewardSymbol } size="36" />}
        </a>
        <div className="App-season-featured" top={ unionInfoLen }>Featured</div>
        <div className="chain-season-time">
            <h3 className="name">
                { 
                    seasonBasicInfo.cross_chain_protocol 
                    ? Texts2['dialog.season.cross']
                    : Texts2['dialog.season.' + seasonBasicInfo.chain]
                }
            </h3>
            <span className="time">Start Time: { dateFormat(seasonBasicInfo.timestamp, 'yyyy/MM/dd hh:mm:ss') }</span>
            {(isSigned) && <span className="registered">{ Texts2['dialog.registered'] }</span>}
        </div>

        <div className="chain-season-map-body">
            <div style={{ display: JSON.stringify(seasonStatus) === '{}' ? 'none': 'block' }} className={ 
                "App-season-banner chain-season-timer " + 
                (seasonStatus.apply && !_gameInfo.gameEnded ? 'App-season-banner-apply' : '') +
                ((seasonStatus.end || _gameInfo.gameEnded) ? ' App-season-banner-end' : '')}>
                { seasonStatus.prepare && !_gameInfo.gameEnded && <span className="content preparing">{ Texts['dialog.login.status.preparing'] }</span> }
                { seasonStatus.apply && !_gameInfo.gameEnded && <span className="content apply" style={{ letterSpacing: '-1px' }}>
                    { Texts['dialog.season.start_countdown'] } &nbsp;
                    <ClockCounter 
                        onEnd = { ()=>{
                            setSeasonStatus(getSeasonStatus(seasonBasicInfo));
                        } }
                        time={ Math.abs(new Date().getTime()/1000 - seasonBasicInfo.start_ts) } />
                </span> }
                { seasonStatus.running && !_gameInfo.gameEnded && <span className="content running">{ Texts['dialog.login.status.running'] }</span> }
                { (seasonStatus.end || _gameInfo.gameEnded) && <span className="content end">{ Texts['dialog.login.status.end'] }</span> }
            </div>

            <div className="chain-season-map">
                <SeasonMap seasonId={ seasonId } mapInfo={ mapInfo } />
                <div className="chain-season-map-btn">
                    <span
                        onClick={ ()=>{
                            setCopied(true);
                            setTimeout(function(){
                                setCopied(false);   
                            }, 2000);
                        }}
                        _ga_event="auction.season.sid.copy" 
                        data-clipboard-text={ seasonBasicInfo.sid }
                        className="time short-sid sid-copy">
                        ID:{ seasonBasicInfo.sid || getSeasonId(seasonBasicInfo.sid) }
                        { copied && <span className="chain-season-map-copied">copied</span> }
                    </span>
                </div>
            </div>

            <div className="chain-season-pool" _sid={ seasonId }>
                {_unionInfo.hasOwnProperty('unionMemberCnt') && <div className="chain-season-overview" onClick={()=>setPlayerStatPop(false)}>
                    <div className="chain-season-signups">
                        { Texts['dialog.applicants'] }: { applyTotalCount(_unionInfo.unionMemberCnt || []) }
                    </div>
                </div>}
                <span className="name">{ Texts['dialog.season_activity.victory_reward.name'].toUpperCase() }</span> 
                <span className="total">
                    <span style={{ top: (seasonBasicInfo.chain == 'bsctest' ? '-2px' : '0'), position: 'relative' }}>
                        <CreateIcon type={ rewardSymbol } size="20" />
                    </span>
                    &nbsp; { account ? showPriceData(_gameInfo.totalUnionPoolValue || 0, 18, 4) : '--' }
                </span> 
                <span className="name">{ DATexts['dialog.season_activity.individual_reward.name'].toUpperCase() }</span> 
                <span className="total">
                    <span style={{ top: (seasonBasicInfo.chain == 'bsctest' ? '-2px' : '0'), position: 'relative' }}>
                        <CreateIcon type={ rewardSymbol } size="20" />
                    </span>
                    &nbsp; { account ? showPriceData(_gameInfo.totalFinalPoolValue || 0, 18, 4) : '--' }
                </span> 
            </div>
        </div>

        <div className="chain-season-btns" style={{ display: (seasonStatus.end || _gameInfo.gameEnded) ? 'none' : 'block' }}>
            {!account && <span 
                _ga_event="auction.login.login"
                onClick={ (e)=> {
                    setSelectWalletPop(true);
                    return;
                    // linkToMetaMask(seasonBasicInfo);
                }}
                className="App-general-btn App-general-btn-green">
                { Texts['button.web.how_to_play.login'] }
            </span>}

            { account && isSigned && <span 
                _ga_event="auction.login.play"
                onClick={ (e)=> {
                    if(cache.get('openedPvpSeasons')){
                        alert('Please donot running 2 seasons in sametime.');
                        return;
                    }
                    dispatch(DASlices.setGameStart({
                        seasonInfo: seasonBasicInfo,
                        unionId,
                        address: account,
                        isStart: true
                    }));
                    cache.set('openedPvpSeasons', seasonId);
                    window.onbeforeunload = function(){
                        cache.remove('openedPvpSeasons');
                    }
                }}
                className="App-general-btn App-general-btn-green">
                { Texts['button.web.play'] }
            </span>}

            {(account && !isSigned) && <><span 
                _ga_event="auction.login.signup"
                onClick={ (e)=>{
                    setSignupStepPop(true);
                } } 
                className="App-general-btn App-general-btn-green">
                {seasonBasicInfo.registryFee/1 > 0 && <>
                    <span style={{ top: '-1px', position: 'relative' }}>
                        <CreateIcon type="zeta" size="16" />
                    </span>
                    <span style={{ margin: '0 10px 0 4px' }}>
                        { showPriceData(seasonBasicInfo.registryFee/1 || 0, 18, 4) }
                    </span>
                </>}
                { Texts['button.web.sign_up'] }
            </span> <span 
                _ga_event="auction.login.play"
                style={{ marginLeft: '90px', marginRight: '0px' }}
                className="App-general-btn App-general-btn-green App-btn-disable">
                { Texts['button.web.play'] }
            </span></>}
            {signupStepPop && <SignUpSteps data={ {
                    address: account, 
                    seasonId: seasonId,
                    unionInfo: _unionInfo,
                    chain: seasonBasicInfo.chain,
                    seasonBasicInfo: seasonBasicInfo
                } } 
                onSubmit={ (data)=>{
                    signupToGame(data);
                    setSignupStepPop(false);
                } 
            } />}
            { (opResults.type === 'joinUnion' && opResults.back && notifyPop) && <ToastTip 
                text={ opResults.result ? Texts['signup_ok'] : getWalletSignErrMessage(opResults.msg, 'sign') } 
                callback={ ()=>{ 
                    setNotifyPop(false);
                    dispatch(DASlices.resetResult()); 
                }
            } />}
        </div>

        <div className="chain-season-btns" style={{ display: (seasonStatus.end || _gameInfo.gameEnded) ? 'block' : 'none' }}>
            {account ? <>
            <span 
                _ga_event="auction.season.reword"
                onClick={()=>setInfoPop(true)}
                style={{ marginLeft: '30px', marginRight: '50px' }}
                className="App-general-btn App-general-btn-blue">
                { 'Info' }
            </span>
            <span 
                _ga_event="auction.season.reword"
                onClick={()=>setRankPop(true)}
                style={{ marginLeft: '30px', marginRight: '0px' }}
                className="App-general-btn App-general-btn-blue">
                { 'Rank' }
            </span>
            </> : <span 
                _ga_event="auction.login.login"
                onClick={ (e)=> {
                    setSelectWalletPop(true);
                    return;
                    // linkToMetaMask(seasonBasicInfo);
                }}
                className="App-general-btn App-general-btn-green">
                { Texts['button.web.how_to_play.login'] }
            </span>}

            {rankPop && <SignUpStats from="login" address={ account } seasonId={ seasonId } close={()=>{ setRankPop(false) }} />}
            {infoPop && <RewardInfo from="login" address={ account } seasonId={ seasonId } close={()=>{ setInfoPop(false) }} />}
        </div>
    </div></>);
}

function getSeasonStatus(seasonBasicInfo){
    let seasonId = seasonBasicInfo.sid;
    if(seasonBasicInfo.end){
        return {
            seasonId,
            prepare: false,
            apply: false,
            running: false,
            living: false,
            canSignup: false,
            end: true
        }
    }

    let start = seasonBasicInfo.timestamp;
    let endTs = seasonBasicInfo.endedAt || 0;
    let now = new Date().getTime();

    let isEnd = endTs > 0 ? now/1000 >= endTs : false;
    let living = !isEnd || now >= start;

    return {
        seasonId,
        prepare: false,
        apply: false,
        running: !isEnd,
        living: !isEnd,
        canSignup: !isEnd,
        end: isEnd
    };
}