import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MapCustom } from '../map-custom/map-custom';

import { isProd } from '../../utils/ui-res'
import { logTrace } from '../../utils/log'

import { Texts2, GameTexts } from '../../const';

export default function Header(props){
    const dispatch = useDispatch(); 
    const { isHistorySeason, address } = props;

    return (<>
        {isHistorySeason 
        ? <div className="App-season-menus-left"></div> 
        : <div className="App-season-menus-left">
            <a href='/?type=end' target="_blank" className="App-loading-btn App-menu-link">
                <span className="App-general-btn App-general-btn-blue">
                    { Texts2['button.history'] }
                </span>
            </a>
            {!isProd && <MapCustom />}

            <a href={ GameTexts.guidebook } target="_blank" className="App-loading-btn App-menu-link" style={{ marginLeft: '25px'}}>
                <span className="App-general-btn App-general-btn-blue">
                    { Texts2['button.guidebook'] }
                </span>
            </a>
        </div>}
    </>);
}

