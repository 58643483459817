import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import { getTokenPriceInfo } from '../../services/slice-map';

import { colors, unionImagePath } from '../../utils/ui-res'
import { numberFormat } from '../../utils/utils'

import { Buffs, GoBack, CreateIcon, LogisIcon, ShowPercent } from '../../components'
import { Texts, Texts2 } from '../../const/';

import './unions.scss';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function UnionBonus(){
    const dispatch = useDispatch();

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const { tokenPriceInfos } = useSelector(function(state){
        return state.map;
    });
    // console.log('tokenPriceInfos', tokenPriceInfos, currentUser)

    const [isBonusOpen, setIsBonusOpen] = useState(false);
    const [currentUnion, setCurrentUnion] = useState(currentUser.unionId);
    const [currentBuff, setCurrentBuff] = useState(0);

    function afterOpenModal(){
    }

    useEffect(()=>{
        tokenPriceInfos.forEach(function(tokenPrice){
            if(tokenPrice.id === currentUser.unionId){
                setCurrentBuff(tokenPrice.changeValue);
            }
        });
        return (()=>{
        });
    }, [tokenPriceInfos]);

    useEffect(()=>{
        dispatch(getTokenPriceInfo())
    }, []);

    return (<>
        <div className="App-preview-map-btn App-union-bonus-btn" 
            _ga_event={ "bonus.open" }
            onClick={()=>setIsBonusOpen(!isBonusOpen)}>
            <span className="text">{ Texts2['dialog.camp_bonus.button'] }</span>
        </div>

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '760px',
                    height: '400px',
                    margin: '-220px 0 0 -380px'
                }
            }}
            className = { 'App-game-modal-bonus App-game-modal-gray' }
            isOpen={ isBonusOpen }
            onAfterOpen = { afterOpenModal }
            _ga_event={ "bonus.close" }
            onRequestClose = { () => setIsBonusOpen(false) }
            contentLabel="Union Bonus Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event={ "bonus.close" } onClick={ ()=>setIsBonusOpen(false) }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ padding: '5px', lineHeight: '2' }}>
                    { Texts2['dialog.camp_bonus.title'] }
                </div>
                <div className="App-game-modal-content2" 
                    style={{ 
                        display: 'flex',
                        padding:'10px', 
                        backgroundColor: 'RGBA(27, 20, 11, 1)',
                        borderRadius: '15px',
                        margin: '1.3rem 1rem 1rem'
                    }}>
                    <div className="App-union-bonus-list">
                        <div className="App-union-bonus-item">
                            <div className="union">{ Texts2['dialog.camp_bonus.camp'] }</div>
                            <div className="init">{ Texts2['dialog.camp_bonus.initial'] }</div>
                            <div className="current">{ Texts2['dialog.camp_bonus.current'] }</div>
                            <div className="change">{ Texts2['dialog.camp_bonus.change'] }</div>
                        </div>
                        {tokenPriceInfos.map((item, index) => (
                            <div
                                _ga_event={ "bonus.select." + item.id }
                                onClick={ ()=>{ 
                                    setCurrentUnion(item.id);
                                    setCurrentBuff(item.changeValue)
                                }} 
                                className={ "App-union-bonus-item App-union-bonus-overview" + (currentUnion === item.id ? ' App-union-bonus-current' : '')} 
                                key={ index }>
                                <div className="union">
                                    <img src={ unionImagePath(item.id) } />
                                    { item.name }
                                </div>
                                <div className="init">{ item.v1 || 0 }</div>
                                <div className="current">{ item.v2 || 0 }</div>
                                <div className="change">{ ShowPercent(item.changeValue) } &nbsp;&nbsp;</div>
                            </div>)
                        )}
                    </div>
                    <div className="App-union-bonus-detail" style={{ width: '300px' }}>
                        <Buffs buffs={{
                            product: ShowPercent(currentBuff),
                            troop_product: ShowPercent(currentBuff),
                            attack: ShowPercent(currentBuff),
                            defense: ShowPercent(currentBuff),
                            load: ShowPercent(currentBuff)
                        }} />
                    </div>
                </div>
                <div style={{ 
                    textAlign: 'left', margin: '1rem 0 0 1rem', 
                    padding: '0 0 0 2.5rem', 
                    position: 'relative' }}>
                    <span style={{ top: '-2px', left: '0', padding: '3px', position: 'absolute' }}>
                        <CreateIcon type="info-tips" size="32" />
                    </span> 
                    <span className="App-tips-info">{ Texts2['dialog.camp_bonus.rule.desc'] }</span>
                </div>
            </div>
        </Modal>
    </>);
}