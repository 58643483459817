import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import * as DASlices from '../../da/slice';
import * as EthFuns from '../../wallets/ethereum';
import * as DAContract from '../../da/contract';

import { GoBack, CreateIcon } from '../../components'

import { Texts, Texts2, DATexts } from '../../const/';
import { zetaTransactionLink } from '../../const';
import { unionImagePath, shortString } from '../../utils/ui-res';
import { numberFormat, showPriceData, dateFormat } from '../../utils/utils';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function RewardInfo(props){
    const dispatch = useDispatch();
    const { seasonId, address } = props;

    let types = ['All', 'Mine'];

    const { buyLandEvents } = useSelector(function(state){
        return state.dauction;
    });
    const { records = [], total = 0, isBack = false } = buyLandEvents[seasonId] || {};
    // console.log(records, seasonId, buyLandEvents)

    const [pageNumber, setPageNumber] = useState(0);
    const [currentType, setCurrentType] = useState(types[0]);

    let pageSize = 50;
    useEffect(()=>{
        setPageNumber(0);
        dispatch(DASlices.resetBuyRecords({ seasonId }))
    }, [currentType]);

    useEffect(()=>{
        let params = { 
            seasonId,
            pageSize,
            pageNumber
        };
        if(currentType === 'Mine'){
            params.address = address;
        }
        dispatch(DASlices.getAllBuyLandEvents(params))
    }, [pageNumber, currentType]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                height: '500px',
                marginTop: '-250px'
            }
        }}
        className = { 'App-game-modal-sign-overview App-game-modal-gray' }
        isOpen={ true }
        onRequestClose = { () =>{ props.close() } }
        contentLabel="Camp Overview">
        <div style={{ position: 'relative' }}>
            <div _ga_event={ "auction.signup.stat.close" } onClick={ ()=>{ props.close() } }>
                <GoBack size="60px" />
            </div>
            <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>Info</div>
            <div className="App-general-logis" style={{ margin: '20px 0 0',  width: '100%', textAlign: 'center' }}>
                <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
                {types.map((item, index)=>
                    <div key={ item } 
                        className={ 'App-general-tab-normal ' + (currentType === item ? 'App-general-tab-hightlight' : '') } 
                        _ga_event={ "auction.signup.stat.union" }
                        onClick={ (e)=> {setCurrentType(item); } }>
                        <span>{ item }</span>
                    </div>
                )}
                </div>
            </div>

            <div className="App-game-modal-content1" style={{ margin: '10px 0', height: '350px', overflow: 'auto' }}>
                {records.map((item, index) => {
                    let args = item.args;
                    let dividend = item.dividend || {};
                    return <div className="App-buyland-record-item" key={ 'record-' + index } 
                        style={{ position: 'relative' }}>
                        <img alt="" 
                            style={{ width: '18px',borderRadius: '10px', marginRight: '5px', verticalAlign: 'middle' }} 
                            src={ unionImagePath(args.unionId) } />
                        <span>{ shortString(args.owner, 14) }</span> 
                        
                        <span style={{ margin: '0 10px' }}>spent</span>
                        
                        <span className="price">
                            <CreateIcon type="zeta" size="20" /> { ' ' }
                            { showPriceData(args.currentPrice, 18, 4) } 
                        </span>
                        
                        <span style={{ margin: '0 0 0 5px' }}>biding for </span>
                        
                        <span className="plot">
                            { currentType === 'Mine' && <> 
                                {item.lastOwner === address && <>your </> }
                                {item.lastOwner && item.lastOwner !== address && <span style={{ margin: '0 5px 0 0' }}>
                                    { shortString(item.lastOwner, 14) }
                                </span> }
                            </>}
                            { currentType !== 'Mine' && item.lastOwner && <span style={{ margin: '0 5px 0 0' }}>
                                { shortString(item.lastOwner || '', 14) }
                            </span>}
                            <CreateIcon type="plot" size="18" />
                            plot({args.x},{args.y}) 
                        </span>

                        at { dateFormat(item.timestamp, 'yyyy.MM.dd hh:mm:ss') }.

                        { currentType === 'Mine' && (args.owner !== address) && <>
                            { item['refund'] &&
                                <span style={{ margin: '0 0 0 5px' }}>
                                    <span className="price">
                                        <CreateIcon type="zeta" size="20" /> { ' ' } 
                                        { showPriceData(item['refund'], 18, 4) } 
                                    </span>
                                    transfer to your wallet
                                </span>
                            }
                            { dividend[address] && <> 
                                { item['refund'] ? ' and you ' : ' You ' }
                                received <span className="price">
                                <CreateIcon type="zeta" size="20" /> { ' ' }
                                { showPriceData(dividend[address], 18, 4) } 
                                </span> stock dividend.
                            </>}
                        </>}

                        <a className="App-zetaTransactionLink" 
                            style={{ 
                                margin: '-5px 0 0 10px', 
                                verticalAlign: 'middle',
                                position: 'absolute',
                                right: '20px',
                                top: '15px'
                            }}
                            href={ zetaTransactionLink + item.hash } rel="noreferrer" target="_blank">
                            { shortString(item.hash, 20) }
                        </a>
                    </div>
                })}
                { records.length === 0 && isBack && <div className="App-buyland-record-more">
                    <br /><br /><br /><br /><br />
                    You didn`t buy any land.
                </div>}
                { records.length < total && <div className="App-buyland-record-more" onClick={ ()=>{
                    setPageNumber(pageNumber + 1);
                    } }>
                    <span className={"App-general-btn App-general-btn-blue"}>
                        more
                    </span>
                </div>}
            </div>
        </div>
    </Modal>);
}