import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import * as DASlices from '../../da/slice';
import * as EthFuns from '../../wallets/ethereum';
import * as DAContract from '../../da/contract';

import { colors, unionImagePath, getNickName } from '../../utils/ui-res'
import { GoBack, CreateIcon } from '../../components'

import { Texts, Texts2, DATexts } from '../../const/';

import { numberFormat, showPriceData } from '../../utils/utils'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function PlayersRank(props){
    const dispatch = useDispatch();

    let types = [ 'Stock Count', 'Income' ];

    const [rankHidden, setRankHidden] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [currentType, setCurrentType] = useState(types[0]);
    const [listCurrent, setListCurrent] = useState(0);

    const { gameStart, rewords = [] } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, address } = gameStart;
    const seasonId = seasonInfo.sid;

    useEffect(()=>{
        dispatch(DASlices.getDARewords({ seasonId }))
    }, [rankHidden, showDetail]);

    const [lists, setLists] = useState({ 0:[], 1:[] });

    // console.log(rewords, lists);

    useEffect(()=>{
        let list0 = [];
        let list1 = [];
        rewords.forEach(function(item){
            if(item.shares >= 0){
                list0.push(item);
            }
            if(item.divident >= 0){
                list1.push(item);
            }
        });
        list0 = list0.sort(function(a, b){
            return a.shares/1 > b.shares/1 ? -1 : 1;
        });
        list1 = list1.sort(function(a, b){
            return a.divident/1 > b.divident/1 ? -1: 1;
        })
        setLists({
            0: list0,
            1: list1
        });
        // setRankHidden(list1.length === 0);
    }, [rewords, listCurrent, showDetail]);

    if(rewords.length >0 && lists[1].length === 0){
        return (<></>);
    }
    
    // <div className="App-preview-map-btn App-player-rank-btn" id="App-preview-map-btn" 
    //         _ga_event="auction.rank.close"
    //         style={{ left: '20px', right: 'auto' }}
    //         onClick={()=>setRankHidden(false)}>
    //         <span className="text">Reward</span>
    //     </div>

    return (<>
        {rankHidden ? <div
            onClick={ ()=>{ setRankHidden(false) } } 
            className="App-world-records-fold"
            style={{ top: 'auto', bottom: '300px' }}>
            close
        </div>
        : <div className="App-acution-season-status-pop">
            <div className="App-world-records-unfold" _ga_event="acution.season.status.close" onClick={ ()=>{
                setRankHidden(true);
            } }>close</div>
            <div className="App-acution-events-content" style={{ height: '330px', width: '310px', padding: '20px 10px 0 30px', overflow: 'visible' }}>
                <div
                    onClick={ ()=>{ setRankHidden(true) } } 
                    className="App-world-records-fold" 
                    style={{
                        position: 'absolute',
                        right: '-106px',
                        top: '230px'
                    }}>
                    close
                </div>
                <div className="App-game-table App-game-table-rank" style={{ padding: 0, height: '310px' }}><table>
                    <tbody>
                    {lists[1].map((item, index) => (
                        <tr key={ 'rank' + index }>
                            <td className="center" style={{ padding: '4px 0 4px 10px'}}><span className="index">{ index + 1 }</span></td>
                            <td>
                                <span 
                                    style={{ 
                                        display:'inline-block', width: '6rem', 
                                        paddingTop: '5px 5px 5px 0 ', overflow:'hidden',
                                        textOverflow:'ellipsis',
                                        color: '#fff'
                                    }} 
                                    title={ item.name || item.username }>
                                    { item.owner || item.username }
                                </span>
                            </td>
                            <td className="center" style={{ padding: '6px 0 0' }}> 
                                <img alt="" style={{ width: '20px',borderRadius: '10px' }} src={ unionImagePath(item.unionId) } /> 
                            </td>
                            <td className="right" style={{ color: '#fff' }}>
                                <CreateIcon type="zeta" size="22" /> 
                                { ' ' + showPriceData(item.divident || 0, 18, 4) } &nbsp;
                            </td>
                        </tr>)
                    )}
                    </tbody>
                </table></div>
                <div style={{ textAlign: 'center', cursor: 'pointer', color: '#F0C067' }} onClick = { () =>{ setShowDetail(true) } }>OVERVIEW</div>
            </div>
        </div>}

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '500px',
                    marginTop: '-250px'
                }
            }}
            className = { 'App-game-modal-sign-overview App-game-modal-gray' }
            isOpen={ showDetail }
            onRequestClose = { () =>{ setShowDetail(false) } }
            contentLabel="Camp Overview">
            <div style={{ position: 'relative' }} className="App-dagame-modal-sign-overview">
                <div _ga_event={ "auction.signup.stat.close" } onClick={ ()=>{ setShowDetail(false) } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>Camp Overview</div>
                <div className="App-general-logis" style={{ margin: '20px 0 0',  width: '100%', textAlign: 'center' }}>
                    <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
                    {types.map((item, index)=>
                        <div key={ item } 
                            className={ 'App-general-tab-normal ' + (currentType === item ? 'App-general-tab-hightlight' : '') } 
                            _ga_event={ "auction.signup.stat.union" }
                            onClick={ (e)=> {setCurrentType(item); setListCurrent(index)} }>
                            <span>{ item }</span>
                        </div>
                    )}
                    </div>
                </div>

                <div className="App-game-modal-content1" style={{ padding: '0', height: '340px', overflow: 'auto' }}>
                    <div className="App-game-table App-game-table-rank"><table>
                        <thead><tr>
                            <td className="center rank">{ Texts['dialog.rank'] }</td>
                            <td className="player">{ Texts['dialog.player'] }</td>
                            <td className="center">{ Texts2['dialog.camp'] }</td>
                            <td className="right">{ currentType } &nbsp;&nbsp;</td>
                        </tr></thead>
                        <tbody>
                        {lists[listCurrent].map((item, index) => (
                            <tr key={ 'rank' + index }>
                                <td className="center"><span className="index">{ index + 1 }</span></td>
                                <td><span style={{ display:'inline-block', width: '10rem', paddingTop: '5px', overflow:'hidden',textOverflow:'ellipsis' }} title={ item.name || item.username }>
                                    { item.owner || item.username }
                                </span></td>
                                <td className="center" style={{ paddingTop: '8px' }}> 
                                    <img alt="" style={{ width: '20px',borderRadius: '10px' }} src={ unionImagePath(item.unionId) } /> 
                                </td>
                                {listCurrent === 0 ? <td className="right"> 
                                    <CreateIcon type="camp" /> &nbsp;
                                    { item.shares || 0 } &nbsp;&nbsp;
                                </td> : <td className="right"> 
                                    <CreateIcon type="zeta" /> &nbsp;
                                    { showPriceData(item.divident || 0, 18, 4) } &nbsp;&nbsp;
                                </td>}
                            </tr>)
                        )}
                        </tbody>
                    </table></div>
                </div>

                <div className="App-sign-content" style={{ textAlign:'center' }}></div>
            </div>
        </Modal>
    </>);
}