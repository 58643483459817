import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ToastTip(props){
    const [ pop, setPop ] = useState(true);
    // console.log({pop})

    let data = props.data || {};
    let tips = props.text || 'Loading';
    let w = tips.length * 14;
        w = Math.max(300, w);
        w = Math.min(840, w);
    function afterOpenModal(){
        props.callback();
        setPop(false);
    }

    const [ opac, setOpac ] = useState(0.05);
    const [ topPercent, setTopPercent ] = useState(0);
    useEffect(()=>{
        if(window.LeagueOfThronesConfig.isMobile){
            setOpac(100);
            setTopPercent(50);
            setTimeout(afterOpenModal, 2000);
            return;
        }

        let _opca = 0.05;
        let tp = setInterval(function(){
            _opca += 0.03;
            setOpac(_opca);
            if(_opca >= 1){
                clearInterval(tp);
            }
        }, 10);
        let value = 30;
        let t1 = null, t2 = null, t3 = null;
        t1 = setInterval(function(){
            value += 1.5;
            setTopPercent(value);
            if(value >= 50){
                clearInterval(t1);
                setTimeout(function(){
                    t2 = setInterval(function(){
                        value -= 1;
                        setTopPercent(value);
                        _opca -= 0.1;
                        setOpac(_opca);

                        if(value <= 47){
                            clearInterval(t2);
                            t3 = setInterval(function(){
                                _opca -= 0.1;
                                setOpac(_opca);
                                value += 1;
                                setTopPercent(value);
                                if(value >= 51){
                                    clearInterval(t3);
                                    setTimeout(afterOpenModal, 50);
                                }
                            }, 20);
                        }
                    }, 40);
                }, 1000);
            }
        }, 15);
    }, []);

	return(<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)', opacity: opac },
            content: {
                top: topPercent + '%',
                left: '50%',
                // width: w + 'px',
                height: '60px',
                // marginLeft: (0 - w/2) + 'px',
                marginLeft: '-120px',
                marginTop: '-25px'
            }
        }}
        className = { 'App-game-modal-toast ' + (!!data.result ? 'App-game-modal-toast-ok': 'App-game-modal-toast-fail') }
        isOpen={ pop }
        onRequestClose={ ()=>{ return;afterOpenModal(); } }
        contentLabel="Toast">
        <div style={{ maxWidth: '800px', overflow: 'hidden' }} onClick={()=>{ return;afterOpenModal() }}>
            { tips }
        </div>
    </Modal>);
}