import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { randomCampReward, getVipSilverBuff } from 'throne-underlying';
import { getUserLevel } from '../../services/slice-user'
import { getSeasonDetail, getUserNFTs, getSeasonInfo, getAllUnionPlayers } from '../../services/slice-user';
import { getUnionOverView } from '../../services/slice-map';

import UserScoreBuffs from '../buffs/vip-buff'

import { CreateIcon, ClockCounter, Loading } from '../../components'
import { subs } from '../../utils/utils';
import { logTrace } from '../../utils/log';
import {
    unionImagePath, generalBigImagePath, generalImagePath, getNftImageUrl
} from '../../utils/ui-res'

import { Texts, Texts2, NFTConfig, Chains } from '../../const/';

import './steps.scss';
import diceImg from './steps/dice.png';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SignUpSteps(props){
    const dispatch = useDispatch();
    const { address, seasonId, chain, seasonBasicInfo } = props.data;

    const [ unionId, setUnionId ] = useState(0);
    const [ step, setStep ] = useState(1);

    const { seasonDetail, userNFTs } = useSelector(function(state){
        return state.user;
    });
    let currentSeasonDetail = seasonDetail[seasonId] || {};
    let NFTs = userNFTs[seasonId] || [];
    
    const seasonStatus = currentSeasonDetail.seasonStatus || {};
    const unionsPlayerNum = seasonStatus.unionsPlayerNum || [];
    const maxDiff = (seasonStatus.maxUnionDiffNum || 1)/1;

    function isSignUpFull(){
        let { apply_count, player_limit } = seasonBasicInfo;
        let signCount = 0;
        unionsPlayerNum.forEach(function(number){
            signCount += number/1;
        })
        return signCount >= player_limit
    }

    function notAllowSelect(unionId){
        let maxNumber = unionsPlayerNum[unionId - 1]/1;
        let minNumber = unionsPlayerNum[unionId - 1]/1;
        let count = unionsPlayerNum[unionId - 1]/1;
        for(var id = 0; id < 4; id++){
            maxNumber = Math.max(unionsPlayerNum[id]/1, maxNumber);
            minNumber = Math.min(unionsPlayerNum[id]/1, minNumber);
        }
        return count === maxNumber && maxNumber - minNumber >= maxDiff;
    }

    const [ nftMapping, setNftMapping ] = useState([]);
    useEffect(()=>{
        let nftSupportMap = {};
        let nftSupportTypes = Chains[chain].nfts;
        NFTs.forEach(function(NFT, index){
            if(NFT.type){
                nftSupportMap[NFT.type] = { 
                    id: NFT['id'] ? index + 1 : 0,
                    avatar: NFT['image'] || ''
                };                
            }
        });
        let list = [];
        nftSupportTypes.forEach(function(nftType){
            let nftItem = JSON.parse(JSON.stringify(NFTConfig.nfts[nftType] || {}));
            nftItem.order = nftSupportMap[nftType] ? nftSupportMap[nftType]['id'] : 5;
            nftItem.avatar = nftItem.avatar || (nftSupportMap[nftType] && nftSupportMap[nftType]['avatar']);
            nftItem.support = !!nftSupportMap[nftType];
            list.push(nftItem);
        });
        setNftMapping(list);
    }, [userNFTs]);

    useEffect(()=>{
        if(!seasonId || !address){
            return;
        }
        // dispatch(getSeasonDetail({
        //     seasonId,
        //     address
        // }));

        dispatch(getUserNFTs({
            seasonId,
            address
        }));

        dispatch(getUserLevel({ address }));
    }, [ seasonId, address ]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                width: '800px',
                height: '370px',
                margin: '-150px 0 0 -350px'
            }
        }}
        className = { 'App-game-modal-signsteps App-game-modal-gray-headless' }
        isOpen={ true }
        contentLabel="Preview Map Modal">
        {isSignUpFull() 
            ? <div style={{ position: 'relative', textAlign: 'center', paddingTop: '150px' }}>
                <span className="tips">
                    <span style={{ top: '-1px', right: '5px', position: 'relative' }}>
                        <CreateIcon type="info-tips" size="18" />
                    </span> 
                    { Texts2['dialog.signup.full'] }
                </span>
                <div className="App-sign-btns" style={{  paddingTop: '50px' }}>
                    <span 
                        _ga_event="signup.full.close"
                        onClick={ ()=>{ props.onClose() } }
                        className="App-general-btn App-general-btn-green">
                        { Texts2['dialog.signup.close'] }
                    </span>
                </div>
            </div>
        : <div style={{ position: 'relative' }}>
            <div className="App-sign-steps">
                <div className={ "App-sign-step" + (step === 1 ? ' current': '')} _ga_event="signup.step.1" onClick={ ()=>{ setStep(1) } }>{ Texts2['dialog.signup.step1.title'] }</div>
                <div className={ "App-sign-step" + (step === 2 ? ' current': '')} _ga_event="signup.step.2" onClick={ ()=>{ setStep(2) } }>{ Texts2['dialog.signup.step2.title'] }</div>
                <div className={ "App-sign-step" + (step === 3 ? ' current': '')}>{ Texts2['dialog.signup.step3.title'] }</div>
            </div>
            {step === 1 && <div className="App-sign-step1">
                <div className="App-sign-nfts App-sign-nftConfig">
                    {nftMapping.map((nft, index) => (
                        <div key={ index }
                            style={{ order: nft.order, minWidth: '25%' }} 
                            className={"App-sign-nft" + (nft.support ? ' current' : ' disable')}>
                            <h2>
                                { nft.name }
                                {!nft.support && ' (' + Texts2['dialog.signup.nft.disable'] + ')' }
                            </h2>
                            <div className="role"><img src={ nft.avatar } /></div>
                            <span style={{ paddingLeft: '8px' }}><CreateIcon type="greenArr" size="16" /></span>
                            <div className="role"><NFTImage nftData={ nft } /></div>
                        </div>
                    ))}
                </div>
                <div className="App-sign-content">{ Texts2['dialog.signup.step1.content'] }</div>
                <div className="App-sign-btns">
                    <span 
                        _ga_event="signup.step.1"
                        onClick={ ()=>{ setStep(2) } }
                        className="App-general-btn App-general-btn-green">
                        { Texts2['dialog.signup.step.next'] }
                    </span>
                </div>
            </div>}

            {step === 2 && <div className="App-sign-step2">
                <UserScoreBuffs address={ address } />
                <div className="App-sign-content">{ Texts2['dialog.signup.step2.content'] }</div>
                <div className="App-sign-btns">
                    <span 
                        _ga_event="signup.step.2"
                        onClick={ ()=>{ setStep(3) } }
                        className="App-general-btn App-general-btn-green">
                        { Texts2['dialog.signup.step.next'] }
                    </span>
                </div>
            </div>}

            {step === 3 && <div className="App-sign-step3">
                <div className="App-sign-nfts">
                    {[1,2,3,4].map((_unionId, index) => (
                        <div  
                            _ga_event={ "signup.step.union.select." + _unionId }
                            onClick={ ()=>{ if(notAllowSelect(_unionId)){return;}  ;setUnionId(_unionId) } }
                            className={
                                "App-sign-nft App-sign-camp" + (unionId === _unionId ? ' current' : '')
                                + (notAllowSelect(_unionId) ? ' App-sign-camp-disable' : '' )
                            } 
                            key={ index }>
                            <h2>{ Texts['dialog.camp_' + _unionId + '.name'] }</h2>
                            <img src={ unionImagePath(_unionId) } />
                            <span>{ unionsPlayerNum[_unionId - 1] || 0 }/50</span>
                        </div>
                    ))}
                    <div 
                        _ga_event={ "signup.step.union.select.random"  }
                        onClick={ ()=>{ setUnionId(0) } }
                        className={"App-sign-nft App-sign-camp" + (unionId === 0 ? ' current' : '')} >
                        <h2>Random</h2>
                        <img src={ diceImg } />
                        <span>
                            <CreateIcon size="20" type="coin" /> { randomCampReward.value }
                        </span>
                    </div>
                </div>
                <div className="App-sign-content">{ subs(Texts2['dialog.signup.step3.content'],{gold: randomCampReward.value }) }</div>
                <div className="App-sign-btns">
                    <span 
                        _ga_event={ "signup.step.submit" }
                        onClick={ ()=>{ props.onSubmit({
                            unionId,
                            NFTs
                        }) } }
                        className="App-general-btn App-general-btn-green">
                        { Texts2['dialog.signup.step.finish'] }
                    </span>
                </div>
            </div>}
        </div>}
    </Modal>);
}


function NFTImage(props){
    let { nftData } = props;
    if(nftData.nftId){
        return <img className="nft-image" src={ getNftImageUrl(nftData.nftType, nftData.nftId)  } /> 
    }
    return <img className="empty" src={ NFTConfig.emptyImg } />
}
