const transactionTypes = {
    1 : "UpgradeFacility",
    2 : "Recruit",
    3 : "ExcuteStrategy",
    4 : "HireGeneral",
    5 : "EnhanceGeneral",
    6 : "AttackEnemy",
    7 : "MarchToPos",
    8 : "AbleGeneral",
    9 : "DisableGeneral",
    10 : "UpgradeGeneral",
    11 : "UpgradeGeneralSkill",
    12 : "SetDefenseGeneral",
    13 : "ReceiveTroop",
    14 : "Battle",
    15 : "DefenseBlock",
    16 : "AttackBlock",
    17 : "CancelDefenseBlock",
    18 : "SetUnionId",
    19 : "SetUnionWin",
    20 : "SetSeasonEnd",
    21 : "StartSeason",
    22 : "SetIconId",
    23 : "Recharge",
    24 : "AddTestResource",
    25 : "RecoverMorale",
    26 : "BuyStrategyPoint",
    27 : "StrategyBuySilver",
    28 : "StrategyBuyTroop",
    29 : "StrategyBuyMorale",
    30 : "StrategyBuyProtect",
    31 : "StrategyBuyStore",
    32 : "MiningBlock",
    33 : "InitUserStates",
    34 : "InitGlobalStates",
    35 : "DonateSilver",
    36 : "RegularTask",
    37 : "SetGuideStep",
    38 : "FirstLogin",
    39 : "StrategyBuyProtect1",
    40 : "OutChainUserActivity",
    41: "HealTroops",
    42: "SpyEnamy",
    43: "BuyOffer",
    44: "CreateCod",
    45: "CancelCod",
    46: "JoinCod",
    47: "QuitCod",
    48: "AttackBlockCod",
    49: "CodCreatorDetail"
};

const noticeTpls = {
    "1": "%{player_name} upgrade %{building_name} level to %{building_level}",
    "2": "%{player_name} train %{troops_count} troops",
    "8" : "%{player_name} recruited %{general_name}", 
    "10": "%{player_name} upgrade %{general_name} level to %{general_level}",
    "11": "%{player_name} upgrade %{general_name}'s %{skill_name} level to %{skill_level}",
    "13": "%{player_name} claim %{troops_count} troops",
    "12": "%{player_name} set %{general_name} for defense city",
    "14": "%{player_name} battle",
    "15": "%{player_name} defense plot",
    "16": "%{player_name} attack plot",
    "17": "%{player_name} cancel defense plot",
    "22": "%{player_name} set avatar",
    "23": "%{player_name} get %{gold}gold coin from recharge",
    "24": "%{player_name} add test resources",
    "25.silver": "%{player_name} consumed %{silver} silver coins to recover %{morale} points of morale",
    "25.gold": "%{player_name} consumed %{gold} gold coins to recover %{morale} points of morale",
    "26": "%{player_name} consumed %{gold}gold coin to buy %{stamina} points of stamina",
    "27": "%{player_name} use %{order_name}",
    "28": "%{player_name} use %{order_name}",
    "29": "%{player_name} use %{order_name}",
    "30": "%{player_name} use %{order_name}",
    "31": "%{player_name} use %{order_name}",
    "32": "%{player_name} sent %{general_name} to gather %{silver} silver coins on the plot %{block_name}",
    "35": "%{player_name} donate silvers",
    "38": "%{player_name} first login",
    "39": "%{player_name} use %{order_name}",
    "40": "%{player_name} share for stamaina success.",
    "41": "%{player_name} heal troops",
    "42": "%{player_name} spy enamy",
    "43": "%{player_name} buy %{offer_name} ",
    "44": "%{player_name} create assembly ",
    "45": "%{player_name} cancel assembly ",
    "46": "%{player_name} join assembly ",
    "47": "%{player_name} quit assembly "
};

export {
    transactionTypes,
    noticeTpls
}