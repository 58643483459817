import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NumericFormat from 'react-number-format';

import * as DASlices from '../../da/slice';

import { GoBack, CreateIcon, ToastTip } from '../../components'
import { Texts, Texts2, DATexts, Chains, RewardCoins } from '../../const/';
import { showPriceData, dateFormat } from '../../utils/utils';
import { getSeasonId } from '../season/season';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function RewardList(props){
    const dispatch = useDispatch(); 

    let { rewards, address } = props;
    let { seasonHis, opResults } = useSelector(function(state){
        return state.dauction;
    });
    const [claimed, setClaimed] = useState({});
    console.log(rewards, opResults, claimed);

    // useEffect(()=>{
    //     dispatch(DASlices.getAllRewards({
    //         seasonHis, 
    //         userInfo: { 
    //             address, 
    //             type: 'reward-list' 
    //         }
    //     }));
    // }, [opResults]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                width: '900px',
                height: '500px',
                margin: '-250px 0 0 -470px'
            }
        }}
        className = { 'App-game-modal-reward App-game-modal-gray' }
        isOpen={ true }
        onRequestClose = { () =>{ return; props.onClose() } }
        contentLabel="Preview Map Modal">
        <div style={{ position: 'relative' }}>
            <div _ga_event="auction.reward.close" onClick={ () => {
                props.onClose();
            } }><GoBack size="60px" /></div>
            <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>Reward Withdraw</div>
            <div className="App-game-table App-game-table-rank App-game-pvp-table"><table>
                <thead><tr>
                    <td className="center rank">{ DATexts['dialog.reward.id'] }</td>
                    <td className="player">{ DATexts['dialog.reward.start'] }</td>
                    <td className="center">{ DATexts['dialog.reward.type'] }</td>
                    <td className="right">{ DATexts['dialog.reward.reward'] }</td>
                    <td className="right reward"></td>
                </tr></thead>
                {rewards.map((item, index) => {
                    let { shareInfo, gameInfo, rewardItems } = item;
                    let season = seasonHis[index];

                    let { conquest, individual, stock, seasonId } = rewardItems;
                    let reward1 = conquest + individual;
                    let reward2 = stock;

                    return <tbody key={ 'item' + index }>{(conquest > 0 || individual > 0) && <tr key={ 'pvp-reward-1' + index } 
                        style={{ padding: '30px 10px 0' }}>
                        <td className="center" style={{ whiteSpace: 'nowrap' }}>{ seasonId.replace('test-zeta_main-', '') }</td>
                        <td className="center">{ dateFormat(season.startTs/0.001 || season.timestamp, 'yyyy.MM.dd') }</td>
                        <td className="center">
                            { Texts['dialog.season_activity.victory_reward.name'].toUpperCase().replace(' REWARD', '') }
                            &nbsp;  + &nbsp;  
                            { DATexts['dialog.season_activity.individual_reward.name'].toUpperCase().replace(' REWARD', '') }
                        </td>
                        <td className="left" style={{ paddingTop: '5px', width: '200px' }}> 
                            <CreateIcon type="zeta" size="20" /> &nbsp; 
                            <NumericFormat value={ showPriceData(conquest || 0, 18, 4) } displayType="text" thousandSeparator="," />
                            &nbsp;  + &nbsp; 
                            <CreateIcon type="zeta" size="20" /> &nbsp; 
                            <NumericFormat value={ showPriceData(individual || 0, 18, 4) } displayType="text" thousandSeparator="," />
                        </td>
                        <td className="right reward" style={{ padding: '5px 15px' }}> 
                            {!claimed[seasonId + '-withdrawFinal'] ? <span 
                                style={{ zoom: '0.8' }}
                                className="App-general-btn App-general-btn-green"
                                onClick={ ()=>{
                                    dispatch(DASlices.withdrawFinal({
                                        address,
                                        seasonId
                                    }));
                                } }>
                                Withdraw
                            </span> : <span 
                                style={{ zoom: '0.8' }}
                                className="App-general-btn App-general-btn-green App-btn-disable">
                                Withdraw
                            </span>}
                        </td>
                    </tr>}

                    {reward2 > 0 && <tr key={ 'pvp-reward-2' + index } 
                        style={{ padding: '30px 10px 0' }}>
                        <td className="center" style={{ whiteSpace: 'nowrap' }}>{ seasonId }</td>
                        <td className="center">{ dateFormat(season.startTs/0.001 || season.timestamp, 'yyyy.MM.dd') }</td>
                        <td className="center">STOCK</td>
                        <td className="left"> 
                            <CreateIcon type="zeta" size="20" /> &nbsp; 
                            <NumericFormat value={ showPriceData(reward2 || 0, 18, 4) } displayType="text" thousandSeparator="," />
                        </td>
                        <td className="right reward" style={{ padding: '5px 15px' }}> 
                            {!claimed[seasonId + '-withdrawDividends'] ? <span 
                                style={{ zoom: '0.8' }}
                                className="App-general-btn App-general-btn-green"
                                onClick={ ()=>{
                                    dispatch(DASlices.withdrawDividends({
                                        address,
                                        seasonId
                                    }));
                                } }>
                                Withdraw
                            </span> : <span 
                                style={{ zoom: '0.8' }}
                                className="App-general-btn App-general-btn-green App-btn-disable">
                                Withdraw
                            </span>}
                        </td>
                    </tr>}</tbody>
                    }
                )}
            </table></div>
        </div>

        {opResults.back && <ToastTip 
            text={ opResults.result ? 'withdraw successful.' : 'withdraw fail.' } 
            callback={ ()=>{
                let { data, type } = opResults;
                if(opResults.result){
                    claimed[data.seasonId + '-' + type] = true;
                    setClaimed(claimed);   
                    dispatch(DASlices.getAllRewards({seasonHis, userInfo: { address, type: 'reward-list' }}));
                }
                dispatch(DASlices.resetResult()); 
            }
        } />}
    </Modal>);
}