import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GoBack, CreateIcon, LogisIcon } from './'
import { Texts } from '../const/'

export default function Buffs(props){
    const dispatch = useDispatch();

    const buffs = props.buffs;

    useEffect(()=>{
    }, []);

    return (<>
        <div className="App-general-data" style={{ margin: '0 20px' }}>
            <div className="App-general-logis-item App-general-logis-item-general">
                <span style={{ position: 'relative', left: '-10px' }}><CreateIcon type={ 'general-buff' } size="29" /></span>
                <div className="stats" style={{ marginLeft: '-10px' }}>{ Texts['dialog.general.skill.0.name'] }</div>
                <div className="next">+{ buffs.generalCount || '0' }</div>
            </div>
            <div className="App-general-logis-item">
                <LogisIcon type={ 'product' } style={{ marginLeft: '-20px' }} />
                <div className="stats">{ Texts['dialog.general.skill.1.name'] }</div>
                <div className="next">{ buffs.product }</div>
            </div>
            <div className="App-general-logis-item" key={ 'troop_product' }>
                <LogisIcon type={ 'hire' } style={{ marginLeft: '-20px' }} />
                <div className="stats">{ Texts['common.recruit'] }</div>
                <div className="next">{ buffs.troop_product }</div>
            </div>
            <div className="App-general-logis-item" key={ 'attack' }>
                <LogisIcon type={ 'attack' } style={{ marginLeft: '-20px' }} />
                <div className="stats">{ Texts['dialog.general.skill.5.name'] }</div>
                <div className="next">{ buffs.attack }</div>
            </div>
            <div className="App-general-logis-item" key={ 'defense' }>
                <LogisIcon type={ 'defense' } style={{ marginLeft: '-20px' }} />
                <div className="stats">{ Texts['dialog.general.skill.7.name'] }</div>
                <div className="next">{ buffs.defense }</div>
            </div>
            <div className="App-general-logis-item" key={ 'load' }>
                <LogisIcon type={ 'load' } style={{ marginLeft: '-20px' }} />
                <div className="stats">{ Texts['dialog.general.skill.9.name'] }</div>
                <div className="next">{ buffs.load }</div>
            </div>
        </div>
    </>);
}
