import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resourceRefresh } from '../../services/slice-city'
import { 
    recoverMorale,
    getMorale
} from '../../services/slice-general'

import { CreateIcon, LogisIcon } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import './morale.scss';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import { numberFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function MoraleDetail(props){
    const dispatch = useDispatch(); 
    const coins = props.coins;
    const { morale, recoverMoraleResult } = useSelector(function(state){
        return state.generals;
    });
    let buff = morale.buff || {};
    let recoverInfo = morale.recoverInfo || {};

    // console.log({morale, recoverMoraleResult}, 'MoraleDetail')

    const max = 120;
    let w = morale.value*100/max + '%';

    const [type, setType] = useState('');
    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    function moraleLevel(v){
        if(v <= 90){
            return Texts2['dialog.morale_1'];
        }else if(v <= 110){
            return Texts2['dialog.morale_2'];
        }else{
            return Texts2['dialog.morale_3'];
        }
    }

    useEffect(()=>{
        dispatch(getMorale());
        dispatch(resourceRefresh());
    }, []);

    useEffect(()=>{
        dispatch(getMorale());
        dispatch(resourceRefresh());
    }, [recoverMoraleResult]);

    return (<div className="App-morale-detail">
            <div className="entire">
                <div className="entire-top">
                    <div style={{ position: 'absolute',margin: '-13px 0 0 -20px' }}>
                        <CreateIcon type="morale" size="40" data="greenArr" />
                    </div>
                    <span className="progress">
                        <span className="bar" style={{ width: w }}>
                        </span>
                    </span>
                </div>
                <div className="entire-desc">
                    <span className="title">{ Texts2['dialog.morale'] }</span>
                    <span className="value">{ morale.value } ({ moraleLevel(morale.value) })</span>
                </div>
            </div>

            <div className="App-morale-detail-list">
                <div className="App-general-logis-item" key={ 'silver_product' }>
                    <LogisIcon type={ 'product' } style={{ marginLeft: '-20px' }} />
                    <div className="stats">{ Texts['dialog.general.skill.1.name'] }</div>
                    <div className={buff.silver_product < 1 ? "next red": "next"}>
                        { Math.floor(buff.silver_product*100 - 100) + '%' }
                    </div>
                </div>
                <div className="App-general-logis-item" key={ 'troop_product' }>
                    <LogisIcon type={ 'hire' } style={{ marginLeft: '-20px' }} />
                    <div className="stats">{ Texts['common.recruit'] }</div>
                    <div className={buff.troop_product < 1 ? "next red": "next"}>
                        { Math.floor(buff.troop_product*100 - 100) + '%' }
                    </div>
                </div>
                <div className="App-general-logis-item" key={ 'attack' }>
                    <LogisIcon type={ 'attack' } style={{ marginLeft: '-20px' }} />
                    <div className="stats">{ Texts['dialog.general.skill.5.name'] }</div>
                    <div className={buff.attack < 1 ? "next red": "next"}>
                        { Math.floor(buff.attack*100 - 100) + '%' }
                    </div>
                </div>
                <div className="App-general-logis-item" key={ 'defense' }>
                    <LogisIcon type={ 'defense' } style={{ marginLeft: '-20px' }} />
                    <div className="stats">{ Texts['dialog.general.skill.7.name'] }</div>
                    <div className={buff.defense < 1 ? "next red": "next"}>
                        { Math.floor(buff.defense*100 - 100) + '%' }
                    </div>
                </div>
                <div className="App-general-logis-item" key={ 'load' }>
                    <LogisIcon type={ 'load' } style={{ marginLeft: '-20px' }} />
                    <div className="stats">{ Texts['dialog.general.skill.9.name'] }</div>
                    <div className={buff.load < 1 ? "next red": "next"}>
                        { Math.floor(buff.load*100 - 100) + '%' }
                    </div>
                </div>

                <div className="App-morale-detail-tips">
                    <CreateIcon type="info-tips" size="36" />
                    <span style={{ margin: '-5px 10px 0' }}>
                        { Texts2['dialog.morale_desc'] }
                    </span>
                </div>
            </div>

            {recoverInfo.silverUse > 0 && (<div className="App-morale-detail-btns">
                {coins.silver >= recoverInfo.silverUse ? (<span className="App-general-btn App-general-btn-blue -App-btn-disable" 
                _ga_event={ "morale.recharge.silver" }
                onClick={()=>{
                    setWaitingPop(true);
                    setType('silver');
                    dispatch(recoverMorale({
                        resourceType: 'silver'
                    }));
                }}>
                    <CreateIcon type="silver" size="20" />
                    { numberFormat(recoverInfo.silverUse) }
                    &nbsp; RECHARGE
                </span>) : (<span 
                    className="App-general-btn App-general-btn-blue App-btn-disable"
                    _ga_event={ "morale.recharge.silver.disable" }
                    onClick={ ()=>{
                        setType('silver');
                        setNotifyPop(true);
                    } } >
                    <CreateIcon type="silver" size="20" />
                    &nbsp; RECHARGE
                </span>)}

                {coins.golds >= recoverInfo.goldUse  ? (<span className="App-general-btn App-general-btn-blue -App-btn-disable" 
                _ga_event={ "morale.recharge.gold" }
                onClick={()=>{
                    setWaitingPop(true);
                    setType('gold');
                    dispatch(recoverMorale({
                        resourceType: 'gold'
                    }));
                }}>
                    <CreateIcon type="coin" size="20" />
                    { numberFormat(recoverInfo.goldUse) }
                    &nbsp; RECHARGE
                </span>) : (<span 
                    _ga_event={ "morale.recharge.gold.disable" }
                    onClick={ ()=>{
                        setType('gold');
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-blue App-btn-disable">
                    <CreateIcon type="coin" size="20" />
                    &nbsp; RECHARGE
                </span>)}
            </div>)}

            { notifyPop && <ToastTip text={ 
                Texts2['dialog.morale.recovery_no_' + type ] 
            } callback={ ()=>{ setNotifyPop(false) } } />}

            { waitingPop && <NotifyPop tips={{
                loading: '',
                success: Texts2['dialog.morale.recovery_success'],
                fail: Texts2['dialog.morale.recovery_fail']
            }} data={ recoverMoraleResult } callback={ ()=>{
                setWaitingPop(false);
            }} />}
        </div>
    );
}