import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import { rewardConfig } from 'throne-underlying';

import { pointUpdate } from '../../services/slice-strategies'
import { resourceRefresh } from '../../services/slice-city'
import { 
    mapInit, getOccupiedTiles, getTileInfo, 
    attackTile, getDefenseList, defenseTile, resetBattleResult
} from '../../services/slice-map';
import { generalInit, getSkillInfo, getDefenseBlockGenerals } from '../../services/slice-general'
import { setShareRecord, getShareRecords } from '../../services/slice-user'

import SendTile from '../chat/send-tile'
import CodCreate from '../cod/cod-create'

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, timeFormat, subs, getStamina } from '../../utils/utils'
import { unionImagePath, generalTypeImagePath } from '../../utils/ui-res'
import { getMapId, getMapConfig } from '../../maps/map-res'
import * as cache from '../../utils/cache'

import AppGame from '../../const/app.json';
import mainFortress from './assets/fortress.png';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import { logTrace } from '../../utils/log'

import SelectGeneral from '../generals/generals-selector'
import BattleResult from '../battle/battle-result'
import DefenseResult from '../battle/defense-result'
import ProtectWarning from '../battle/protect-warning'
import SilverMining from './silver-mining'

import SnShare from '../share/share'

import Modal from 'react-modal';

export default function Defense(props) {
    const dispatch = useDispatch(); 
    // console.log('props.tile', props)
    const {  detail, tileData } = props;
    
    const { sign, seasonDetail } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const mapId = getMapId(seasonDetail[currentSeasonId].seasonBasicInfo);
    const mapConfig = getMapConfig(mapId);

    const [attackBattleOver, setAttackBattleOver] = useState(false);
    const [defenseBattleOver, setDefenseBattleOver] = useState(false);
    const [tipPop, setTipPop] = useState(false);

    const xyIndex = (tileData.xyIndex || '').split('^');
    const x_id = xyIndex[0]/1;
    const y_id = xyIndex[1]/1;

    const isInitBlock = (Math.abs(x_id) === mapConfig.cols - 1 && Math.abs(y_id) === (mapConfig.rows - 2)/2);

    const { 
        mapReady,
        defenseList,
        defenseResult
    } = useSelector(function(state){
        return state.map;
    });

    logTrace({tileData, detail, defenseList, defenseResult }, 'map.tile.1')

    const { defenseBlockGenerals } =  useSelector(function(state){
        return state.generals;
    });
    logTrace(defenseBlockGenerals, 'map.tile.defenseBlockGenerals')

    const { appReady, currentUser } = useSelector(function(state){
        return state.app;
    });
    // logTrace({currentUser, appReady }, 'map.tile.unionId 1')

    const myUnionId = currentUser.unionId;
    const occupiederUnionId = (detail.belong || {}).unionId || 0;
    let isNotMine = myUnionId !== occupiederUnionId;

    // console.log('miningResult' ,{detail, isNotMine, isInitBlock, xyIndex, myUnionId, occupiederUnionId});

    let isDefensing = false;
    let defenseBlockGeneralsMap = {};
    let defensingGeneralId = 0;
    defenseBlockGenerals.forEach(function(item){
        //item = {generalId: 1,x_id: 1, y_id: 1} 
        if(item.x_id === x_id && item.y_id === y_id){
            isDefensing = true;
            defensingGeneralId = item.generalId;
        }
    });

    // logTrace({ currentUser, defenseBlockGenerals}, 'map.tile.2')

    const [rulePop, setRulePop] = useState(false);
    const [isStartDefense, setIsStartDefense] = useState(false);

    function getBattleResultInfo(data, type){
        let record = data.record || {};
        let attackInfo = record.attackInfo || {};
        let defenseInfo = record.defenseInfo || {};
        // console.log({record, attackInfo, data, type}, 111111);
        if(!data.record){
            return {
                type: type,
                error: data.error || '',
                attackGloryGet: data.gloryGet || 0,
                durabilityReduce: data.durabilityReduce,
                result: data.result,
                win: data.result
            }
        }
        return {
            type: type,
            error: data.error || '',
            attackGloryGet: attackInfo.gloryGet,
            attackTroopReduce: attackInfo.troopReduce,
            defenseGloryGet: defenseInfo.gloryGet,
            defenseTroopReduce: defenseInfo.troopReduce,
            result: record.result,
            win: record.result
        }
    }

    const [ noNeighbor, setNoNeighbor ] = useState({ pop: false });
    const [ notifyPop, setNotifyPop ] = useState(false);
    const [waitingPop, setWaitingPop] = useState(false);
    const [sharePop, setSharePop] = useState(false);

    const { strategyReady, info } = useSelector(function(state){
        return state.strategies;
    });

    const [ attackInfo, setAttackInfo ] = useState({});

    function startDefense() {
        if(isNotMine){
            setNoNeighbor({
                pop: true,
                tip: 'defense should be your tile.'
            });
            return;
        }
        setIsStartDefense(true);
    }
    function informDefense(general){
        let generalId = general.qualification.general_id;
        // console.log(x_id, y_id, generalId);
        dispatch(defenseTile({
            x: x_id,
            y: y_id,
            generalId: generalId
        }));
        dispatch(getDefenseBlockGenerals());
    }

    useEffect(()=>{
        dispatch(pointUpdate());
    }, [strategyReady]);

    useEffect(()=>{
        dispatch(getDefenseBlockGenerals());
        if(detail.buff_id){
            dispatch(getSkillInfo({ skill_id: detail.buff_id }));            
        }
    }, []);

    return (<>
        {((!isNotMine && !isInitBlock)) && (<><span 
            style={{ verticalAlign: 'top', marginTop: '15px' }}
            className="App-general-btn App-general-btn-blue"
            _ga_event="block.defense"
            onClick={ (e)=>{ startDefense() } }>
            { Texts['button.block_defense_force.defense'] }
            &nbsp; <span className="heart">❤</span>-{ getStamina('defensePlots') }
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {detail.remainSilver > 0 && (<SilverMining tileData={ tileData } afterMining={ ()=>{
            dispatch(getTileInfo({
                x: x_id,
                y: y_id
            }));
        }} />)}
        </>)}

        {isStartDefense && <SelectGeneral 
            battleType="defenseBlock" 
            defenseList={ defenseList.concat(defenseBlockGenerals) } 
            onClose={ ()=>{ setIsStartDefense(false) } }
            onSelected={ (res)=>{
                let { times, general, flag } = res;
                logTrace({general, flag}, 'map.defenseTile.selected');
                setIsStartDefense(false);
                if(flag){
                    informDefense(general);
                    setDefenseBattleOver(true); 
                }
            } 
        } />}
        { (!defenseResult.back && defenseBattleOver) && <Loading
            type="headless" /> }
        { (defenseResult.back && defenseBattleOver) && <DefenseResult 
            result={ defenseResult } 
            overConfirm={ ()=>{ 
                setDefenseBattleOver(false); 
                setNotifyPop(false);
                dispatch(resetBattleResult());
                // console.log('tileclose 2');
                props.close && props.close();   
            } 
        } /> }
    </>);
}