import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { logTrace } from '../utils/log'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ConfirmTip(props){
    const dispatch = useDispatch();
    useEffect(()=>{
    }, []);

    return (<>
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '500px',
                    height: '260px',
                    margin: '-130px 0 0 -250px'
                }
            }}
            className = { 'App-game-modal-confirm App-game-modal-gray-headless' }
            isOpen={ true }
            onRequestClose = { () => { props.cancel(); } }
            contentLabel="Preview Map Modal">
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <div className="App-game-modal-header" style={{ padding: '10px', lineHeight: '2', background: 'rgb(30, 22, 12)',margin: '20px 0' }}>
                    { props.texts.title }
                </div>
                <div className="App-power-insuff" style={{ padding:'10px 0', color: '#c4b596' }}>
                    { props.texts.content }
                </div>

                <div style={{ textAlign: 'center' }}>
                    <span 
                        style={{ verticalAlign: 'top', margin: '25px' }}
                        className="App-general-btn App-general-btn-blue"
                        onClick={ (e)=>{ props.cancel(); } }>
                        { props.texts.cancel }
                    </span>

                    <span 
                        style={{ verticalAlign: 'top', margin: '25px' }}
                        className="App-general-btn App-general-btn-green"
                        onClick={ (e)=>{ props.confirm(); } }>
                        { props.texts.confirm }
                    </span>
                </div>
            </div>
        </Modal>
    </>);
}