import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CreateIcon, GoBack } from '../components'
import { chainIcon } from '../utils/ui-res'

import './wallets.scss';

import Modal from 'react-modal';

import { Texts, Texts2, GameTexts, Chains, getChainInfo, RewardCoins } from '../const'

import { wallets } from './wallet-list';

export default function WalletSelect(props) {
    let dispatch = useDispatch();
    const { seasonBasicInfo } = props;
    let cross_chain_config = seasonBasicInfo.cross_chain_config || {}; 
    let support_chains = cross_chain_config.support_chains || [];
    // console.log('wallets', wallets, seasonBasicInfo, support_chains)
    let supportChains = [];
    support_chains.forEach(function(item){
        let chain_name = item.chain_name;
        let chain = Chains[chain_name] || {};
            chain = JSON.parse(JSON.stringify(chain))
            chain.corssChain = item;
        if(chain_name === 'mumbaitest'){
            chain.disabled = true;
        }
        supportChains.push(chain);
    });
    if(seasonBasicInfo.cross_chain_protocol === 'zeta'){
        let item = Chains[seasonBasicInfo.chain];
        item = JSON.parse(JSON.stringify(item))
        item.corssChain = {
            chain_id: parseInt(item.chainId),
            chain_name: seasonBasicInfo.chain,
            recharge_fee: '0',
            signup_fee: '0'
        };
        supportChains.push(item);
    }

    const [walletIndex, setWalletIndex] = useState(-1);
    const [chainIndex, setChainIndex] = useState(-1);
    const [walletPop, setWalletPop] = useState(true);

    useEffect(()=>{
    }, []);
    
    function isSelected(){
        if(supportChains.length == 0){
            return walletIndex != -1;
        }else{
            return walletIndex != -1 && chainIndex != -1;
        }
    }
    function afterOpenModal(){
        // setWalletPop(false);
        props.onClose && props.onClose();
    }

    return (<>
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: supportChains.length > 0 ? '800px' : '500px',
                    marginLeft: supportChains.length > 0 ? '-400px' : '-250px',
                    height: '460px',
                    marginTop: '-200px',
                    zIndex: '99999999'
                }
            }}
            className = { 
                'App-game-modal-avatar-select App-game-modal-gray-headless App-game-modal-wallet-select' 
                + (supportChains.length > 0 ? ' App-game-modal-wallet-select2' : '')
            }
            isOpen={ walletPop }
            onRequestClose={ afterOpenModal }
            contentLabel="Wallets">
            <div className="App-signup-selects">
            <div className="App-signup-select" style={{ marginLeft: '16px' }}>
                <div className="App-wallet-title">
                    Select a Wallet
                </div>
                <div className="App-wallet-content">
                    {wallets.map((item, index)=>
                        <div className={ 
                            "App-wallets-item" 
                            + (' App-wallets-item-' + item.keyName)
                            + (walletIndex === index ? ' App-wallets-item-selected' : '') 
                            + (item.available ? '' : ' App-btn-disable')
                            } key={ index }
                            onClick={ ()=>{ 
                                if(!item.available){
                                    window.open(item.homepage);
                                    return;
                                }
                                setWalletIndex(index); 
                            } } 
                            _ga_event={ "wallets.select" + item.name }>
                            <img src={ item.icon } style={{ width: '50px' }} />
                            <div>{ item.name }</div>
                            {item.available 
                                ? <span className="not-installed">&nbsp;</span>
                                : <a href={ item.homepage } target="_blank" className="not-installed">install</a>
                            }                               
                        </div>
                    )}
                </div>
            </div>
            {supportChains.length > 0 && <div className="App-signup-select">
                <div className="App-wallet-title">
                    Select a Chain
                </div>
                <div className="App-wallet-content App-wallet-split">
                    {supportChains.map((item, index)=>
                        <div className={ 
                            "App-wallets-item" 
                            + (chainIndex === index ? ' App-wallets-item-selected' : '')
                            + (item.disabled ? ' App-btn-disable' : '') } 
                            key={ index }
                            onClick={ ()=>{ 
                                if(item.disabled){
                                    return;
                                }
                                setChainIndex(index); 
                            } } 
                            _ga_event={ "wallets.select" + item.name }>
                            <img src={ chainIcon(item.chainId) } style={{ width: '50px' }} />
                            <div>{ item.chainName }</div>                                 
                        </div>
                    )}
                </div>
            </div>}
            </div>

            <div className="App-wallets-btns" style={{ textAlign: 'center' }}>
                <span 
                    _ga_event="wallet.confirm"
                    style={{ margin: supportChains.length > 0 ? '15px 0 0 120px' : '15px 0 0 15px' }}
                    onClick={ (e)=> {
                        if(!isSelected()){
                            return;
                        }
                        props.onSelect({
                            wallet: wallets[walletIndex],
                            chain: supportChains[chainIndex]
                        });
                    }}
                    className={"App-general-btn App-general-btn-green" + ( isSelected() ? '' : ' App-btn-disable' )}>
                    { Texts['button.web.how_to_play.login'] }
                </span>
            </div>
        </Modal>
    </>);
}
