import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import WidgetBot from '@widgetbot/react-embed'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function DiscordWidget(){
    const [discordPop, setDiscordPop] = useState(false);

    //prod:  1026794499013357578
    //test:  1016321617237917796
    //https://docs.widgetbot.io/embed/react-embed/
    // const url = 'https://discord.com/widget?id=1026794499013357578&theme=dark';
    const url = '/discord.html';

    function toggleDiscord(){
        setDiscordPop(!discordPop);
    }

    if(!discordPop){
        return <div 
            className="App-auction-discord-bar" 
            onClick={ ()=>{ toggleDiscord() }}>
        </div>
    }

    return (<Modal
        style = {{
            overlay: { 
                backgroundColor: 'transparent'
            },
            content: {
                left: '0',
                top: '0',
                bottom: '0',
                width: '350px',
                height: '100%',
                margin: '0'
            }
        }}
        className = { 'App-game-modal-transparent' }
        isOpen={ discordPop }
        onRequestClose={ ()=>{ toggleDiscord();} }
        contentLabel="Acution Recent">
        <iframe src={ url } width="350" height="100%" 
            allowtransparency="true" frameBorder="0" 
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
        </iframe>
    </Modal>);
}