import * as cache from '../utils/cache'
// import tp from 'tp-js-sdk';
import Web3 from 'web3'
import { ethers } from "ethers";
import { getEvmProvider } from "./wallet-list";

var web3 = new Web3(window.ethereum);
var { location } = window;

var ethereum = window.ethereum;

async function setWallet(wallet, chain){
    let keyName = wallet.keyName || '';
    let pageUrl = wallet['homepage'];
    
    ethereum = await getEvmProvider(wallet, chain);

    web3 = new Web3(ethereum);

    if (typeof ethereum === "undefined") {
        window.open(pageUrl, "_blank");
    }

    // console.log('getEvmProvider', ethereum)
    await connect();
    let accounts = await ethereum.request({ 
        method: "eth_requestAccounts" 
    });
    return accounts;
}

async function connect(){
    if(!ethereum || !ethereum.connect){
        return;
    }
    try{
        await ethereum.connect();
        await ethereum.request({
            method: "eth_requestAccounts" 
        });
    }catch(err){
        console.log('walletConnect err', err);
    }
}

function addChain(chainInfo, callback){
    if (typeof ethereum === "undefined") {
        return;
    }

    let config = {
        chainId: chainInfo.chainId, // A 0x-prefixed hexadecimal string
        chainName: chainInfo.chainName,
        nativeCurrency: chainInfo.nativeCurrency,
        rpcUrls: chainInfo.rpcUrls
    };
    ethereum.request({ 
        method: "wallet_addEthereumChain",
        params: [config]
    }).then((result) => {
        callback(true);
    }).catch((error) => {
        callback(false);
    });
}

function getCurrentChain(callback){
    if (typeof ethereum === "undefined") {
        return;
    }

    ethereum.request({ 
        method: 'eth_chainId' 
    }).then((chainId)=>{
        callback(chainId);      
    }).catch((error) => {
        callback('')
    });
}

function detect(chainInfo, callback){
    if (typeof ethereum === "undefined") {
        return;
    }

    ethereum.request({ 
        method: 'eth_chainId' 
    }).then((chainId)=>{
        callback(chainInfo.chainId === chainId);      
    }).catch((error) => {
        callback(false);
    });
    addEventLinseners();
}

function addEventLinseners(){
    let isBinance = window.ethereum && window.ethereum.isBinance;
    if(isBinance){
        return;
    }
    
    ethereum.on('chainChanged', (accounts) => {
        window.localStorage.clear();
        location.reload(true);
    });
    ethereum.on('accountsChanged', (accounts) => {
        setTimeout(function(){
            location.reload(true);
        },10);
    });
}

function login(seasonBasicInfo, callback){
    if (typeof ethereum === "undefined") {
        return;
    }

    // ethereum.request({ 
    //     method: 'eth_chainId' 
    // }).then((chainId)=>{
        ethereum.request({ 
            method: "eth_requestAccounts" 
        }).then((accounts) => {
            const account = accounts[0] || '';
            cache.setKeyNS('L-season-' + account + '-' + (seasonBasicInfo.sid || '') + '-');
            callback({ code: 'ok', account });
        }).catch((error) => {
            console.error(error);
            callback({ code: 'fail', chainId: '', account: '' });
        });  
    // }).catch((error) => {
    //     callback({ chainId: '', account: '' });
    // });  
}

function transChain(chainInfo, callback){
    if (typeof ethereum === "undefined") {
        return;
    }

    ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainInfo.chainId}]
    }).then((res)=>{
        // console.log('walletSelected 51', chainInfo, res)
        // if(!res){
        //     addChain(chainInfo, function(result){
        //         callback(result);
        //     });
        //     return;
        // }
        // location.reload(true);
        callback(true);
    }).catch((error)=>{
        if(error && typeof error !== 'string'){
            try{
                error = JSON.stringify(error);
            }catch(err){

            }
        }
        if(error.indexOf('rejected ') > -1){
            callback(false);
            return;
        } 
        console.log('walletSelected 52', error)
        addChain(chainInfo, function(result){
            console.log('walletSelected 53', result)
            callback(result);
        });
    });
}

function getUserAddress(callback){
    if (typeof ethereum === "undefined") {
        return;
    }

    ethereum.request({ 
        method: "eth_requestAccounts" 
    }).then((accounts) => {
        const account = accounts[0];
        callback({ account, result: 'ok' });
    }).catch((error) => {
        callback({ account: '', result: 'fail' });
    }); 
}

function personalSign(data, callback){
    if (typeof ethereum === "undefined") {
        return;
    }

    let { message, address } = data;
    ethereum.request({
        method: 'personal_sign',
        params: [message, address],
    }).then(function(sign){
        callback({code: 'ok', data: sign});
    }).catch((error) => {
        callback({code: 'fail', data: ''});
    });
}


async function sendTransaction(params, callback){
    let { data, wallet = {}, value = 0 } = params;
    // value = web3.utils.toWei(value.toString(), 'ether');

    let times = 200000;
    if(wallet.keyName == 'xfi'){
        times = 1;
    }
    let gasLimit = 30000 * times;
    // let gasPrice = 20000 * times;

    let gasPrice = await ethereum.request({
      "method": "eth_gasPrice",
      "params": []
    });

    let transactionParameters = {
        to:  params.to,
        value: web3.utils.toHex(web3.utils.toWei(value + '', 'ether')),
        data: data,
        gasLimit: web3.utils.toHex(gasLimit),
        gasPrice: gasPrice,
        from: params.from
    };

    ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
    })
    .then((txHash) => callback({code: 'ok', result: true, message: '', data: txHash}))
    .catch((error) => callback({code: 'fail', result: false, message: error.message || 'err'}));
}

function prepareData(contract, types, args){ 
    const params = prepareParams(types, args);
    return `${contract}${params.slice(2)}`;

    function prepareParams(types, args){
      const abiCoder = ethers.utils.defaultAbiCoder;
      for (let i = 0; i < args.length; i++) {
        if (types[i] === "bytes32") {
          args[i] = ethers.utils.hexlify(ethers.utils.zeroPad(args[i], 32));
        }
      }
      return abiCoder.encode(types, args);
    };
}

export {
    setWallet,
    connect,
    detect,
    addEventLinseners,
    getCurrentChain,
    login,
    transChain,
    getUserAddress,
    personalSign,
    sendTransaction,
    prepareData
};