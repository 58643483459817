import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GoBack, CreateIcon } from './'
import loading from './assets/loading.png'

export default function LoadingBtn(props){
    const dispatch = useDispatch(); 
    const { size } = props;
    const [ deg, setDeg ] = useState(0);

    function afterOpenModal(){
    }

    function setPop(){
        props.back && props.back()
    }

    let type = props.type || '';
    let start = 0;
    useEffect(()=>{
        let t = setInterval(function(){
            start += 10;
            setDeg(start);
        }, 50);
        return()=>{
            clearInterval(t);
        };
    }, []);

	return(<div style={{ position: 'relative', zoom: size/140 }}>
        <div className="App-loading-content" style={{ paddingTop: '40px' }}>
            <img src={ loading } style={{ transform: 'rotate(' + deg + 'deg)', width: '80px', height: '80px' }} />
            <div style={{ lineHeight: '20px' }}>{ props.tip || '' }</div>
        </div>
    </div>);
}