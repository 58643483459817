import React, { useEffect, useState } from 'react';

import icon1 from './assets/progress.png'
import icon2 from './assets/progress-blue.png'
//blue
export default function ProgressBar(props){
    let data = props.data;
    let type = props.type || 'dark';

    let cls = 'App-Resources-lasting-number App-progress-' + type;

    let styles = {
        backgroundColor: "#000",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto 20px",
        borderRadius: "15px",
        height: "20px",
        lineHeight: "20px",
        border: "1px solid #5c493b",
        color: "#ffffc2",
        paddingLeft: "10px",
        margin: "0"
    };

    if(type == 'dark'){
        styles.backgroundImage = 'url(' + icon1 + ')';
    }else{
        styles.backgroundImage = 'url(' + icon2 + ')';
    }

    let prg = data.value;
    prg = 0 + prg;
    styles.backgroundPosition = prg + '% 0px';

    return (
        <div className={ cls } style = { styles }>
            <span>{ data.text }</span>
        </div>
    );
}