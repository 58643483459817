import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ChatBox from './chat'
import { 
    checkNewMessage, setNewMessageRead
} from '../../services/slice-city'

export default function (props){
    const dispatch = useDispatch();

	const { currentUser } = props;
    const [ chatboxPop, setChatboxPop ] = useState(false);

    const { cityReady, newMessages } = useSelector(function(state){
        return state.city;
    });

    useEffect(()=>{
        dispatch(checkNewMessage());
    }, [cityReady]);

	return (<>
		<div className="App-preview-map-btn App-chat-map-btn" _ga_event="chat.open" onClick={()=>setChatboxPop(true)}>
        	{(newMessages['1'] || newMessages['2']) && <span className="App-redpoint" style={{ margin: '-70px 0 0 45px' }}>new</span>}
            <span className="text">{ 'CHAT' }</span>
        </div>
        {chatboxPop  && (<ChatBox currentUser={ currentUser } onClose={ ()=>{
            setChatboxPop(false);
        } } />)}
    </>)
}