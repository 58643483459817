import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import { rewardConfig } from 'throne-underlying';

import { pointUpdate } from '../../services/slice-strategies'
import { resourceRefresh } from '../../services/slice-city'
import { 
    mapInit, getOccupiedTiles, getTileInfo, 
    attackTile, getDefenseList, defenseTile
} from '../../services/slice-map';
import { generalInit, getSkillInfo, getDefenseBlockGenerals } from '../../services/slice-general'
import { setShareRecord, getShareRecords } from '../../services/slice-user'

import SendTile from '../chat/send-tile'
import CodCreate from '../cod/cod-create'

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, timeFormat, subs, getStamina } from '../../utils/utils'
import { unionImagePath } from '../../utils/ui-res'
import { getMapId, getMapConfig } from '../../maps/map-res'
import * as cache from '../../utils/cache'

import mainFortress from './assets/fortress.png';
import mainFortress3 from './assets/castle.png';
import portImg from './assets/port.png';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import { logTrace } from '../../utils/log'

import ProtectWarning from '../battle/protect-warning'
import createBlockView from './view'

import Modal from 'react-modal';

export default function BlockBasic(props) {
    const dispatch = useDispatch(); 
    const detail = props.detail;

    const keys = {
        name: 'dialog.map.block_' + detail.type + '_' + (detail.parameter || 0) + '.name',
        buff: 'dialog.staff.skill.' + detail.buff_id
    }

    const { sign, seasonDetail } = useSelector(function(state){
        return state.user;
    });
    
    const { currentUser } = useSelector(function(state){
        return state.app;
    });

    const myUnionId = currentUser.unionId;
    const occupiederUnionId = (detail.belong || {}).unionId || 0;

    const currentSeasonId = sign.seasonId;
    const mapId = getMapId(seasonDetail[currentSeasonId].seasonBasicInfo);
    const mapConfig = getMapConfig(mapId);

    const { x_id, y_id } = detail;

    const blockView = createBlockView(x_id, y_id, mapConfig);
  
    return (<>
        <div className="App-Resources-stat" style={{ display: 'flex' }}>
            <div className="App-Resources-stat-list" style={{ width: '180px',overflow: 'hidden' }}>
                <div className="App-Resources-stat-title">{ Texts['dialog.block_stats'] }</div>
                <div className="App-Resources-stat-item">
                    <span style={{ position: 'absolute' }}><CreateIcon type="tile-status" size="32" /></span>
                    <dt>{ Texts['dialog.block_stats'] }</dt>
                    { 0 !== occupiederUnionId && (<dd style={{ paddingTop: '5px' }}>
                        <img style={{ width: '20px', borderRadius: '50%', display: 'inline-block' }} src={ unionImagePath(occupiederUnionId) } />
                        <span style={{ verticalAlign: 'top', margin: '0 0 0 5px', lineHeight: '1.8' }}>{ Texts['dialog.block_stats_be_occupy'] }</span>
                    </dd>)}
                    <dd>{ 0 === occupiederUnionId && Texts['dialog.block_stats_neutral'] }</dd>
                </div>
                <div className="App-Resources-stat-item">
                    <span style={{ position: 'absolute' }}><CreateIcon type="tile-buff" size="32" /></span>
                    <dt>{ Texts['dialog.block_buff'] }</dt>
                    <dd>{ Texts[keys.buff] || keys.buff }</dd>
                </div>
                <div className="App-Resources-stat-item">
                    <span style={{ position: 'absolute' }}><CreateIcon type="tile-coins" size="32" /></span>
                    <dt>{ Texts['dialog.block_attack.mine_left'] }</dt>
                    <dd c="silver_total_number">{ numberFormat(detail.remainSilver) }</dd>
                </div>
                <div className="App-Resources-stat-item">
                    <span style={{ position: 'absolute' }}><CreateIcon type="tile-roseSpeed" size="32" /></span>
                    <dt>{ Texts['dialog.block_mining_speed'] }</dt>
                    <dd>{ numberFormat(detail.gather_silver_speed) }/h</dd>
                </div>
            </div>
            <div className="App-Resources-tile" style={{ verticalAlign:'top', paddingTop: '40px' }}>
                { detail.type === 2 && (<img src={ mapConfig['capitalType'] == 'separate' ? mainFortress3 : mainFortress } />) }
                { detail.type === 8 && (<img src={ portImg } style={{ width: '70%' }} />) }
                {(detail.type !== 2 && detail.type !== 8) && (<div id="App-single-tile" style={{ marginTop: '30px', marginLeft: '30px', zoom: 0.7 }}>
                    <div style={ blockView.bg }><div style={ blockView.map }></div></div>
                </div>)}
            </div>
        </div>
    </>);
}
