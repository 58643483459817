import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as Contract from '../../wallets/contract';

import { resourceRefresh, getGold, getGoodsList, recharge, getRechargeInfo, resetChargeResult } from '../../services/slice-city'

import { CreateIcon, GoBack } from '../../components'
import { NotifyPop } from '../../components'
import * as cache from '../../utils/cache'

import { Texts2, Chains } from '../../const'
import { getWalletSignErrMessage } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import goodsImage from './assets/goods.png';
import './charge.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ChargeCoin() {
    let dispatch = useDispatch();

    const { seasonDetail, sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    let currentSeasonDetail = seasonDetail[currentSeasonId] || {};
    let seasonBasicInfo = currentSeasonDetail['seasonBasicInfo'] || {};

    let chainName = seasonBasicInfo.chain;
    let walletSelectedData = localStorage['walletSelected-' + currentSeasonId];
    let crossInfo = {};
    if(walletSelectedData && seasonBasicInfo.cross_chain_protocol){
        walletSelectedData = JSON.parse(walletSelectedData);
        // console.log('cross_chain_protocol walletSelectedData', walletSelectedData);
        crossInfo = walletSelectedData.chain || {};
        if(crossInfo.name){
            chainName = crossInfo.name;
        }
    }
    let priceUnit = (Chains[chainName] || {}).priceUnit || 1000000;

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { walletSelectedInfo } = useSelector(function(state){
        return state.user;
    });
    const { golds, goodsList, chargeResult, rechargeInfo } = useSelector(function(state){
        return state.city;
    });
    // console.log('cross_chain_protocol golds', { golds }, chargeResult)
    
    const [chargePop, setChargePop] = useState(false);
    const [chargeResultPop, setChargeResultPop] = useState(true);

    const [waitingPop, setWaitingPop] = useState(false);

    useEffect(()=>{
        dispatch(getRechargeInfo({
            seasonId: currentSeasonId,
            player: currentSeasonDetail.address
        }));
    }, []);

    useEffect(()=>{
        // Contract.init(seasonDetail.seasonBasicInfo);
    }, [seasonDetail]);

    useEffect(()=>{
        dispatch(getGold());
        dispatch(resourceRefresh());
        dispatch(getGoodsList({
            chainName: chainName
        }));
        console.log(golds)
    }, [chargeResult, chargePop]);
    
    function afterOpenModal(){
        setChargePop(false);
    }

    function chargeStart(goods){
        // alert(Texts['dialog.recharge_success']);
        let { wallet, chain } = walletSelectedInfo;
        dispatch(recharge({
            isCrossChain: chain.name !== seasonBasicInfo.chain,
            seasonId: currentSeasonId,
            rechargeId: goods.internal_id,
            amount: goods.price/priceUnit,
            address: currentSeasonDetail.address,
            seasonBasicInfo: seasonBasicInfo,
            crossInfo: crossInfo.corssChain,
            from: currentUser.username,
            wallet: wallet
        }));
    }

    function showAmount(amount){
        amount = amount || 0;
        return amount/1000000/1000000/1000000;
    }

    return (<>
        <div className="App-topbar-right" _ga_event={ "charge.open" } onClick={ ()=>{
                setChargePop(true);
            } }>
            <span style={{ marginTop: '-23px', display: 'inline-block' }}>
                <CreateIcon type="coin" size="32" />
            </span>
            <span className="App-item-value" style={{ paddingLeft: '10px' }} >
                { Math.floor(golds) }
            </span>
            <span className="App-btn-coin-add"></span>
        </div>

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '840px',
                    marginLeft: '-400px',
                    height: '400px',
                    marginTop: '-210px'
                }
            }}
            className = { 'App-game-modal-charge App-game-modal-gray' }
            isOpen={ chargePop }
            onRequestClose={ afterOpenModal }
            contentLabel="COIN PURCHASE">
            <div style={{ position: 'relative' }}>
                <div _ga_event={ "charge.close" } onClick={ afterOpenModal }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ lineHeight: '2.5rem' }}>{ Texts2['dialog.gold'] }</div>
                <div className="App-purchase-desc">
                    <div style={{ opacity: seasonBasicInfo.cross_chain_protocol === 'zeta' ? 0 : 1 }}>
                        { Texts2['dialog.total_season_recharge'] }: &nbsp; 
                        <span style={{ position: 'relative', top: '2px' }}><CreateIcon type={ chainName } size="20" />  { showAmount(rechargeInfo[1]) } </span>
                    </div>
                    <div style={{ display: 'none' }}>
                        { Texts2['dialog.next_season_pool_increase'] }  { showAmount(rechargeInfo[0]) }  <CreateIcon type={ chainName } size="20" /> &nbsp;
                        { Texts2['dialog.next_season'] }  
                    </div>
                </div>
                {goodsList.map((goods, index) => 
                    <div className="App-purchase-item" key={ index }>
                        { goods.internal_icon === 1 && (<span className="hot"><CreateIcon type="hot" size="40" /></span>)}
                        <img src={ goodsImage } alt="" />
                        <div className="App-purchase-item-desc">
                            <strong>{ goods.gold } { Texts2['dialog.gold'] }</strong>
                            <span>{ Texts2['dialog.recharge_gold.desc'] } +{ goods.extra_gold }</span>
                        </div>
                        <span className="App-general-btn App-general-btn-green"
                            style={{ verticalAlign: 'top', marginTop:'.8rem',marginLeft:0 }}
                             _ga_event={ "charge.goods." + goods.internal_id }
                            onClick = { (e) => { 
                                setWaitingPop(true);
                                chargeStart(goods) 
                            }}>
                            <span style={{ top: '-2px', position:'relative' }}>
                                <CreateIcon type={ chainName } size="20" bitType={ goods.token } />
                            </span> { goods.price/priceUnit }
                        </span>
                    </div>
                )}

                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    <span style={{ top: '-2px', right: '5px', position: 'relative' }}>
                        <CreateIcon type="info-tips" size="24" />
                    </span> 
                    <span className="App-tips-warning">{ Texts2['dialog.recharge.gold_clear_attention'] }</span>
                </div>
            </div>
        </Modal>

        { waitingPop && <NotifyPop tips={{
            _loading: Texts2['dialog.gold.recharge_success'],
            success: Texts2['dialog.gold.recharge'],
            fail: getWalletSignErrMessage(chargeResult, 'recharge') || Texts2['dialog.gold.recharge_fail']
        }} data={ chargeResult } callback={ ()=>{
            setWaitingPop(false);
            dispatch(resetChargeResult())
        }} />}

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    height: '240px',
                    marginTop: '-120px',
                    width: '400px',
                    marginLeft: '-200px'
                }
            }}
            className = { 'App-game-modal-gray-headless' }
            isOpen={ false }
            onRequestClose = { () => {
                setChargeResultPop(false);
            }}
            contentLabel="Buy Gold">
            <div style={{ padding: '50px 50px 0' }} _ga_event={ "charge.result" } onClick={ () => {
                setChargeResultPop(false);
            }}>{ Texts2['dialog.recharge_success'] }</div>
        </Modal> 
    </>);
}