import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    generalInit,
    generalListUpdated, 
    getGeneralQuaValue, generalStatusChange, generalUpdated, generalSkillUpdate 
} from '../../services/slice-general'

import { CreateIcon, LogisIcon, Stars, PersonNumber } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath
} from '../../utils/ui-res'
import { numberFormat, subs } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';


export default function SkillList(props){
    const dispatch = useDispatch(); 

    const { general_max_level, skill_max_level } = useSelector(function(state){
        return state.generals.config;
    });
    const { cityReady, golds } = useSelector(function(state){
        return state.city;
    });

    let currentGeneral = props.currentGeneral;

    const { skillList } = useSelector(function(state){
        return state.generals.detail;
    });
    const { generalSkillUpdateResult } = useSelector(function(state){
        return state.generals;
    });
    let list = skillList;

    const [reasonType, setReasonType] = useState({});
    const [skillIndex, setSkillIndex] = useState(0);
    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    logTrace({list, currentGeneral}, 'general.skills');
    // console.log({list, currentGeneral, generalSkillUpdateResult}, 'general.skills');

    useEffect(()=>{
        // console.log(currentGeneral.id === generalSkillUpdateResult.generalId, 'general.skills1');
        if(currentGeneral.id === generalSkillUpdateResult.generalId){
            // console.log(1111111);
            dispatch(getGeneralQuaValue({
                id: currentGeneral.id,
                level: currentGeneral.level
            }))
        }
    }, [generalSkillUpdateResult]);

    if(!list.skill_id){
        return;
    }

    let from = props.from || '';
    let content = [];
    for (let i = 0; i < list.skill_id.length; i++) {
        content.push(<div className="App-skill-item" key={ i }>
            <div className="level">
                <img src={ generalSkillImagePath(list.skillInfos[i].buff_type) } />
                <span>Lv { list.skill_level[i] }</span>
            </div>
            <div className="buff">
                <h2>{ Texts['dialog.general.skill.' +  list.skill_id[i] + '.name']}</h2>
                <p>
                    { Texts['dialog.general.skill.' +  list.skill_id[i] + '.desc']} 
                    <span>
                        { (list.value_type[i] === 1) ? Math.floor(list.value[i]*10000)/100 + '%' : Math.floor(list.value[i]*100)/100}
                    </span>
                </p>
            </div>
            {(from !== 'battle') && (<div className="op">
                {(skill_max_level === list.skill_level[i]) ? 
                    (<span className="max-level">MAX</span>):
                    (<>
                        {
                            (currentGeneral.able && list.check_upgrade[i]) ?
                            (<button
                                _ga_event={ "general.skill.update" } 
                                onClick={ ()=> { 
                                    setSkillIndex(i);
                                    setWaitingPop(true);
                                    dispatch(generalSkillUpdate({
                                        generalId: currentGeneral.qualification.general_id,
                                        skillIndex: i
                                    }) ) } }>
                                    { Texts['button.skill_upgrade'] }
                            </button>) :
                            (<button 
                                _ga_event={ "general.skill.update.disable" } 
                                onClick={ ()=>{
                                    var skillLevel = list.skill_level[i];
                                    var generalLevel = currentGeneral.level;
                                    var skillLevelLimited = Math.floor(generalLevel/5) + 1;
                                    var type = 'gold';
                                    if(skillLevel >= skillLevelLimited){
                                        type = 'level';
                                    }
                                    setReasonType({
                                        type: type,
                                        skillLevel,
                                        skillLevelLimited
                                    });
                                    setNotifyPop(true);
                                } } 
                                className="disable">
                            { Texts['button.skill_upgrade'] }</button>)
                        }
                        <div style={{ textAlign:'center' }}>
                            {(list.skill_level[i] >= Math.floor(currentGeneral.level/5) + 1) 
                            ? <span>{ subs(Texts2['dialog.general_skill_levelup.fail'], { general_level: (list.skill_level[i])*5  }) }</span>
                            : <><CreateIcon type="coin" size="16" />
                            <span> { numberFormat(list.upgrade_need[i]) } </span></>}
                        </div>
                    </>)
                }
            </div>)}

            { notifyPop && <ToastTip text={ 
                reasonType.type === 'level'
                ? subs(Texts2['dialog.general_skill_levelup.fail'], { general_level: (reasonType.skillLevel)*5  })
                : Texts2['dialog.general_skill.upgrade_fail_reason'] 
            } callback={ ()=>{ setNotifyPop(false) } } />}

            { skillIndex === i && waitingPop && <NotifyPop tips={{
                loading: '',
                success: Texts2['dialog.general_skill.upgrade_success'],
                fail: Texts2['dialog.general_skill.upgrade_fail']
            }} data={ { 
                result: currentGeneral.able && list.check_upgrade[i] 
            } } callback={ ()=>{
                setWaitingPop(false);
            }} />}
        </div>);
    }
    return content;
}