import Phaser from 'phaser';
import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getMapConfig } from '../../maps/map-res'

export default function SeasonMap(props){
    const dispatch = useDispatch(); 
    const { mapInfo } = props;
    const mapId = mapInfo['map_result'] || 1;
    const mapCanvasId = 'App-phaser-parent-' + mapId + Math.ceil(Math.random()*9876543);

    let mapConfig = getMapConfig(mapId);

    let isUseImage = true;
    return (<>  
        <div className="App-season-map-canvas App-seaon-map-canvas-rotate" id={ mapCanvasId }>
            <img src={ mapConfig.mapCover } />
        </div> 
    </>)
}