import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import { GoBack, ToastTip, CreateIcon } from '../../components'

import { logTrace } from '../../utils/log'
import { gaClickEvent } from '../../utils/ga'

import './map-custom.scss';
import { Texts, Texts2, MapCustomTexts } from '../../const/';
import Modal from 'react-modal';
Modal.setAppElement('#root');

let rootDir = process.env.PUBLIC_URL;

export const MapCustom = (props) => {
    const dispatch = useDispatch();

    const [mapCustomPop, setMapCustomPop] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(25);

    function mapGenerate(){

    }

    useEffect(() => {
    }, []); 

    return (<div className="App-map-custome-wrapper">
        <span className="App-general-btn App-general-btn-blue App-map-custome-btn App-menu-link" _ga_event={ "map.custom.open" } onClick={ ()=>{ setMapCustomPop(true) } }>
            { MapCustomTexts['dialog.custom_map'] }
        </span>

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '700px',
                    height: '600px',
                    margin: '-300px 0 0 -350px'
                }
            }}
            className = { 'App-game-modal-morale App-game-modal-gray App-game-modal-map-custom' }
            isOpen={ mapCustomPop }
            onRequestClose = { () => setMapCustomPop(false) }
            contentLabel="Map Custome">
            <div style={{ position: 'relative' }}>
                <div _ga_event={ "mapc.ustom.close" } onClick={ ()=>{ setMapCustomPop(false) } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ padding: '5px 10px' }}>{ MapCustomTexts['dialog.custom_map'] }</div>
                <div className="App-map-custom-current">
                    <img src={ rootDir + '/realms/' + currentIndex + '.svg' } />

                    <span style={{ margin: '0 50px' }}>
                        <CreateIcon type="greenArr" size="36" /> 
                    </span> 

                    <img src={ rootDir + '/map-config/3-cover.png' } />
                </div>

                <div className="App-map-custom-nft">{ MapCustomTexts['dialog.custom_map.nft_list'] }</div>
                <div className="App-map-custom-nft-list">
                    {[...Array(9)].map((x, index) => <div className="App-map-custom-nft-item" key={ index } onClick={ ()=>{ setCurrentIndex(index + 1) } }>
                            <img src={ rootDir + '/realms/' + (index + 1) + '.svg' } />
                            <div className="App-map-custom-status">{ MapCustomTexts['dialog.custom_map.mapping'] }</div>
                        </div>
                    )}
                    <div className="App-map-custom-no_nft" style={{ display: 'none' }}>
                        { MapCustomTexts['dialog.nft_no_nft'] }
                    </div>
                </div>
                <div className="App-map-custom-rule">
                    { MapCustomTexts['dialog.custom_map.rule'] }
                </div>
                <div className="App-map-custom-generate">
                    <span className="App-general-btn App-general-btn-blue" _ga_event={ "map.custom.generate" } onClick={ ()=>{ mapGenerate() } }>
                        { MapCustomTexts['button.custom_map.generate'] }
                    </span>
                </div>
            </div>
        </Modal>
    </div>)
}