import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import { getOriginIndex } from '../../maps/map-res'

export default function createBlockView(x_id, y_id, mapConfig){
    // const bgIds = [5,5,5,5,5,3,3,3,3,4,3,6,5,5,5,6,3,3,3,3,4,0,5,5,5,6,5,3,3,4,3,3,3,5,5,6,5,5,3,3,4,3,3,0,6,5,5,5,5,3,3,3,4,3,3,5,5,6,5,5,3,4,3,3,3,0,5,5,5,6,5,3,3,3,4,3,3,6,5,5,6,5,3,4,3,3,4,0,6,5,5,5,5,1,4,3,3,3,3,5,5,5,5,1,1,9,9,9,9,0,5,5,5,5,5,1,9,9,9,9,9,5,5,5,5,1,1,9,9,9,9,0,8,7,7,7,8,1,9,9,9,9,10,8,7,7,7,8,9,9,10,9,9,0,7,7,7,8,7,7,9,9,9,9,9,8,7,7,8,7,9,9,9,9,10,0,7,7,8,7,7,7,9,9,9,9,9,7,7,7,7,7,9,9,10,9,9,0,7,8,7,7,8,7,9,10,9,10,10,7,8,7,7,8,9,9,9,10,9,0,7,8,7,7,7,7,9,9,9,10,9,0,0,0,0,0,0,0,0,0,0,0];
    // const mapIds = [0,0,25,0,0,3,0,0,25,5,0,10,0,0,26,12,0,26,0,0,6,0,0,0,0,12,0,3,0,5,0,0,0,25,26,11,0,27,27,0,5,26,25,0,10,0,0,27,0,28,0,27,4,0,0,0,0,11,0,0,0,6,0,0,0,0,25,0,0,12,27,3,27,0,5,0,25,12,0,0,11,0,0,4,0,0,6,0,11,26,0,27,0,0,4,27,0,26,0,9,9,9,9,0,0,21,21,21,21,0,9,9,28,9,9,0,21,21,28,21,21,9,9,9,9,0,0,21,21,21,21,0,16,26,0,27,17,0,0,27,0,26,23,17,0,0,0,18,0,0,22,0,0,0,25,0,0,17,27,15,27,0,0,0,25,16,0,0,16,0,0,0,0,0,23,0,0,0,17,27,0,28,0,27,0,0,0,25,26,0,0,27,27,0,23,26,25,0,0,16,0,0,16,15,0,23,0,24,22,0,17,0,26,18,0,26,0,22,0,0,0,16,25,0,0,15,0,0,25,22,0,0,0,0,0,0,0,0,0,0,0,0];

    const bgIds = mapConfig.bgIndexs;
    const mapIds = mapConfig.mapIndexs;
    const cols = mapConfig.cols;
    // console.log('createBlockView', mapConfig)

    // let {x, y} = tileData;
    let {x, y} = getOriginIndex(x_id, y_id, mapConfig);
    let tileWidth = mapConfig.tileWidth;
    let pos = y*cols + x;

    let bgImages = mapConfig.bg.images;
    let mapImages = mapConfig.map.images;

    // console.log('createBlockView', bgIds, mapIds, pos, {x, y, x_id, y_id})

    let bgIndex = bgIds[pos] - 1;
    let mapIndex = mapIds[pos] - 1;

    let bgPos = (tileWidth*bgIndex*-1) + 'px 0';
    let mapPos = tileWidth*mapIndex*-1 + 'px 0';

    return {
        bg: {
            backgroundImage: 'url(' + bgImages + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: bgIndex >= 0 ? bgPos : '0 -200px',
            backgroundSize: 'auto 100%',
            height: tileWidth + 'px',
            width: (tileWidth + 0) + 'px'
        },
        map: {
            backgroundImage: 'url(' + mapImages + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: mapIndex >= 0 ? mapPos : '0 -200px',
            backgroundSize: 'auto 100%',
            height: tileWidth + 'px',
            width: (tileWidth + 0) + 'px'
        }
    }
}