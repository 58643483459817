import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import NumericFormat from 'react-number-format';
import RewardInSeason from './reward-in-season';
import * as DASlices from '../../da/slice';

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, timeFormat, subs, showPriceData } from '../../utils/utils'
import { unionImagePath, shortString } from '../../utils/ui-res'

import Modal from 'react-modal';

export default function RewardPool(props) {
    const dispatch = useDispatch(); 
  
    const { gameStart, gameInfo } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;
    let _gameInfo = gameInfo[seasonId] || {};

    useEffect(()=>{
        if(!address || !seasonId || !unionId){
            return;
        }

        let params = {
            unionId,
            seasonId,
            address
        };
        dispatch(DASlices.getGameInfo(params));
    }, [gameStart]);

    const [poolInfoPop, setPoolInfoPop] = useState(false);

    return (<>
        <div className="App-reward-bar App-reward-pool" onClick={ ()=>{
            setPoolInfoPop(true);
            } }>
            <div className="App-reward-pool-item">
                <label>Conquest Reward</label>
                <span>
                    <CreateIcon type="zeta" />&nbsp;
                    <NumericFormat value={ showPriceData(_gameInfo.totalUnionPoolValue || 0, 18, 4) } displayType="text" thousandSeparator="," /> &nbsp;&nbsp;
                </span>
            </div>
            <div className="App-reward-pool-item-barry"></div>
            <div className="App-reward-pool-item">
                <label>Individual Reward</label>
                <span>
                    <CreateIcon type="zeta" /> &nbsp;
                    <NumericFormat value={ showPriceData(_gameInfo.totalFinalPoolValue || 0, 18, 4) } displayType="text" thousandSeparator="," /> &nbsp;&nbsp;
                </span>
            </div>
        </div>

        <div className="App-reward-bar App-da-MyRewards">
            <RewardInSeason address={ address } season={ seasonInfo } onWalletSelect={ ()=>{ 
            } } />
        </div>

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '370px',
                    width: '600px',
                    margin: '-200px 0 0 -300px'
                }
            }}
            className = { 'App-game-modal-sign-overview App-da-modal' }
            isOpen={ poolInfoPop }
            onRequestClose = { () =>{ setPoolInfoPop(false) } }
            contentLabel="Camp Overview">
            <div className="App-game-modal-sign-overview-content">    
                <div _ga_event={ "auction.signup.stat.close" } 
                    style={{ position: 'absolute', margin: '-20px 0 0 20px' }}
                    onClick={ ()=>{ setPoolInfoPop(false) } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-content1" style={{ padding: '40px' }}>
                    There are three prize pools: the plot owners pool, the winning DAO pool, and Trail Blazer pool. <br /><br />
                    Current dividend pool: players are given dividends in real time according to the equity ratio of all players in the current season. <br /><br />
                    Conquest reward pool: after the season ends, all players in the winning camp will receive dividends according to the equity ratio. <br /> <br />
                    Individual award pool : after the season ends, the players who occupy the capital will receive all the final grand prize poolsInjection ratio: <br />
                    <div style={{ height: '6px' }}></div>
                    Current dividend pool: 39% <br />
                    Conquest reward pool: 11% <br />
                    Individual award pool: 45% <br />
                    Developer pool: 5% <br />
                </div>
            </div>
        </Modal>
    </>);
}
