import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as DASlices from '../../da/slice';

import { CreateIcon, ClockCounter, Loading } from '../../components'
import { subs } from '../../utils/utils';
import { logTrace } from '../../utils/log';
import {
    unionImagePath, generalBigImagePath, generalImagePath, getNftImageUrl
} from '../../utils/ui-res'

import { Texts, Texts2, NFTConfig, Chains, DATexts } from '../../const/';

import diceImg from './steps/dice.png';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SignUpSteps(props){
    const dispatch = useDispatch();
    const { address, seasonId, chain, unionInfo } = props.data;

    const { unionMemberCnt = [] } = unionInfo;

    const [ unionId, setUnionId ] = useState(unionInfo.unionId || '0');
    const [ step, setStep ] = useState(3);

    let unionsPlayerNum = {};

    useEffect(()=>{
        if(!address || !seasonId){
            return;
        }
        dispatch(DASlices.getUnionInfo({
            address,
            seasonId
        }));
    }, [seasonId, address]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                width: '600px',
                height: '340px',
                margin: '-150px 0 0 -300px'
            }
        }}
        className = { 'App-game-modal-signsteps App-game-modal-gray-headless App-pvp-modal-signsteps' }
        isOpen={ true }
        contentLabel="Preview Map Modal">
        <div style={{ position: 'relative' }}>
            <div className="App-sign-steps">
                <div className={ "App-sign-step" + (step === 3 ? ' current': '')}>Choose Camp</div>
            </div>

            {step === 3 && <div className="App-sign-step3">
                <div className="App-sign-nfts">
                    {[1,2,3,4].map((_unionId, index) => (
                        <div  
                            _ga_event={ "auction.signup.step.union.select." + _unionId }
                            onClick={ ()=>{ setUnionId(_unionId) } }
                            style={{ minWidth: '18%' }}
                            className={
                                "App-sign-nft App-sign-camp" + (unionId === _unionId ? ' current' : '')
                            } 
                            key={ index }>
                            <h2>{ Texts['dialog.camp_' + _unionId + '.name'] }</h2>
                            <img src={ unionImagePath(_unionId) } />
                            <span>{ unionMemberCnt[_unionId - 1] || 0 }</span>
                        </div>
                    ))}
                </div>
                <div className="App-sign-content" style={{ height: 'auto' }}>
                    { DATexts['dialog.signup.rule'] }
                </div>
                <div className="App-sign-btns">
                    {unionId > 0 ? <span 
                        _ga_event={ "auction.signup.step.submit" }
                        onClick={ ()=>{ props.onSubmit({
                            unionId
                        }) } }
                        className="App-general-btn App-general-btn-green">
                        { Texts['button.web.sign_up'] }
                    </span> : <span 
                        className="App-general-btn App-general-btn-green App-btn-disable">
                        { Texts['button.web.sign_up'] }
                    </span>}
                </div>
            </div>}
        </div>
    </Modal>);
}
