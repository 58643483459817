import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import GeneralPortrait from './general-portrait'
import { GoBack, CreateIcon } from '../../components'
import { 
    generalImagePath
} from '../../utils/ui-res'

import { 
    getAllGenerals,
    generalListUpdated
} from '../../services/slice-general'

import Texts from '../../const/texts.json';

import { logTrace } from '../../utils/log'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Generals(props){
    const dispatch = useDispatch(); 

    let userAvatar = props.userAvatar || 0;
    const [generalId, setGeneralId] = useState(userAvatar);

    const { generalReady, all } = useSelector(function(state){
        return state.generals;
    });
    const generalList = useSelector(function(state){
        return state.generals.list || [];
    });
    let ableGenerals = [];
    generalList.forEach(function(general){
        if(general.able){
            ableGenerals.push(general);
        }
    });

    useEffect(()=>{
        dispatch(getAllGenerals());
        dispatch(generalListUpdated());
    }, [generalReady]);

    function cancelSelectModal(){
        props.onConfirm();
    }

    return (
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '580px',
                    height: '350px',
                    marginLeft: '-280px',
                    marginTop: '-170px'
                }
            }}
            className = { 'App-game-modal-avatar-select App-game-modal-gray' }
            isOpen={ true }
            onRequestClose={ ()=>{ cancelSelectModal() } }
            contentLabel="SelectGeneral Modal">
            <div style={{ position: 'relative',height:'500px' }}>
                <div _ga_event="general.close" onClick={ ()=>{ cancelSelectModal(); }}>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header">{ Texts['dialog.choose_general'] }</div>
                {generalList.length === 0 ? (<div style={{ textAlign: 'center', padding: '100px 0 0'}}>no avilable general.</div>) : (<div>
                    <div style={{ height: '230px', overflow: 'auto' }}>
                        {generalList.map((item, index)=>
                            <div className={"App-general-person" + (generalId === item.id ? ' selected': '')} key={ item.id } 
                                _ga_event={ "general.select" + item.id }
                                onClick={ (e) => { setGeneralId(item.id) }}>
                                <GeneralPortrait generalId={ item.id } />
                                {(generalId === item.id) && (<span style={{ position:'absolute', right: '-5px', bottom: 0 }}>
                                    <CreateIcon type="selected" size="32" />
                                </span>) }                                   
                            </div>
                        )}
                    </div>
                    <div className="App-select-panal">
                        {generalId === 0 ? (<span className="App-general-btn App-general-btn-blue App-btn-disable"
                            style={{ verticalAlign: 'top',marginTop:'.8rem' }}>
                            { Texts['button.confirm'] }
                        </span>) : (<span className="App-general-btn App-general-btn-blue"
                            style={{ verticalAlign: 'top',marginTop:'.8rem' }}
                            _ga_event={ "general.select.confirm" + generalId }
                            onClick = { (e) => { props.onConfirm(generalId) }}>
                            { Texts['button.confirm'] }
                        </span>)}
                    </div>
                </div>)}
            </div>
        </Modal>
    );
}