export default function ShowPercent(v){
    v = v || 0;
    if(v === 0){
        return <span className="zero">0%</span>
    }
    v = Math.ceil(v*100*100)/100;
    if(v >= 0){
        return <span className="green">{ v }%</span>
    }
    return <span className="red">{ v }%</span>;
}