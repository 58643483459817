import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rewardConfig } from 'throne-underlying';

import { GoBack, CreateIcon, ClockCounter, NotifyPop, ToastTip } from '../../components'

import { Texts, Texts2 } from '../../const/';
import { logTrace } from '../../utils/log'
import { subs, timeFormat, dateFormat } from '../../utils/utils'
import { numberFormat, getStamina } from '../../utils/utils'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function GatherMutiTimes(props) {
    const dispatch = useDispatch(); 

    let { total, unit, battleType } = props.data;

    let maxTimes = Math.floor(total/unit);
    let minTimes = 1;

    const [times, setTimes] = useState(minTimes);
    function setSliderValueClick(value){
        value = Math.min(value, maxTimes);
        value = Math.max(value, minTimes);
        setTimes(value/1);
    }

    useEffect(()=>{
    }, []);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'transparent' },
            content: {
                width: '580px',
                height: '350px',
                marginLeft: '-280px',
                marginTop: '-170px'
            }
        }}
        className = { 'App-game-modal-avatar-point App-game-modal-gray' }
        isOpen={ true }
        onRequestClose={ ()=>{ props.onClose && props.onClose(); } }
        contentLabel="General Selector Modal">
        <div style={{ position: 'relative',height:'500px' }}>
            <div _ga_event="general.selector.times" onClick={ ()=>{ props.onClose && props.onClose(); }}>
                <GoBack size="60px" />
            </div>
            <div className="App-game-modal-header" style={{ lineHeight: 4.4 }}> &nbsp; </div>

            <div className="App-policy-points-panel">
                <div style={{ color: '#FFF8CA' }}>{ Texts2['dialog.general.upgrade'] || 'Set the numbers of gather times' }</div>
                <div style={{ position: 'relative',margin: '30px auto 10px', paddingBottom: '5px', width:'220px' }}>
                    <div style={{ margin: '-20px 0 10px', fontSize: '14px' }}>Max: { maxTimes }</div>
                    <div className="App-slider-minis" _ga_event="general.upgrade.slider.minis" onClick={()=>{ setSliderValueClick(times - 1) }}></div>
                    <input type="number" maxLength="3" min="1" value={ times } disabled={ times === 0 } onChange={ (e)=> { setSliderValueClick(e.target.value) }} />
                    <div className="App-slider-plus" _ga_event="general.upgrade.slider.plus" onClick={()=>{ setSliderValueClick(times + 1) }}></div>
                </div>
            </div>
            
            <div className="App-point-btns" style={{ textAlign: 'center' }}>
                <div className="App-point-btn-item">
                <span className="App-general-btn App-general-btn-green"
                    _ga_event="general.upgrade.levelup"
                    onClick = { (e) => { 
                        props.onSet && props.onSet({ times });
                    }}>
                    { Texts['button.block_gather'] }
                    &nbsp; <span className="heart">❤</span>-{ getStamina(battleType)*times } 
                </span>
                </div>
            </div>
        </div>
    </Modal>);
}


