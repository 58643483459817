import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    generalTypeImagePath
} from '../../utils/ui-res'

import { GoBack, CreateIcon } from '../../components'
import RecruiTroops from './recruit'
import HealTroops from './heal'
import HealTroopsNotify from './heal-notify'
import SkillRadar from './skill-radar'

import './troops.scss';
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

export default function Training(props){
    const dispatch = useDispatch(); 
    const [type, setType] = useState('recruit');

    return (
        <div className="App-troops-content">
            <div
                _ga_event="troops.training"
                onClick={ ()=>{setType('recruit')} } 
                className={"App-modal-tab-normal" + (type === 'recruit' ? " App-modal-tab-hightlight" : " App-modal-tab-hide")}>
                <div className="App-modal-tab-menu">
                    <CreateIcon type="training" size="48" />
                </div>
                <div className="App-modal-tab-text">{ Texts['dialog.recruit'] }</div>
            </div>
            <div
                _ga_event="troops.heal"
                onClick={ ()=>{setType('heal')} } 
                className={"App-modal-tab-normal" + (type === 'heal' ? " App-modal-tab-hightlight" : " App-modal-tab-hide")}>
                <div className="App-modal-tab-menu">
                    <CreateIcon type="heal" size="48" />
                </div>
                <div className="App-modal-tab-text">{ Texts2['dialog.hospital.title'] || 'Hospital' }</div>
                <HealTroopsNotify type={ type } />
            </div>
            <div className="App-troops-main">
                <div className="App-troops-list">
                    <div className="App-troops-item">
                        <img alt="" className="type" src={ generalTypeImagePath(1) } style={{ marginTop: '-2px' }} />
                        <span>{ Texts['button.army.troops_1'] }</span>
                    </div>
                </div>

                <SkillRadar />

                {type === 'recruit' && <RecruiTroops />}
                {type === 'heal' && <HealTroops />}
            </div>
        </div>
    );
}
