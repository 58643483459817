import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Texts from '../const/texts.json';
import Texts2 from '../const/texts2.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ActionResultPop(props){
    const [isOver, setIsOver] = useState(true);
    let { info } = props;
    function resultModalHide(){
        props.overConfirm();
    }

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                height: '150px',
                marginTop: '-75px'
            }
        }}
        className = { 'App-game-modal-transparent App-game-modal-action-esult' }
        isOpen={ isOver }
        onRequestClose={ ()=>{ resultModalHide(); } }
        contentLabel="Battle Result Modal">
        <div style={{ position: 'relative' }} onClick={ ()=>{ resultModalHide()} }>
            <div className={ "App-battle-over-info " + (info.result ? 'App-battle-over-victory' : 'App-battle-over-lost') }>
                <div className="App-battle-over-info-inner">
                    <div className="App-battle-over-title">{ info.result ? 'SUCCESS' : 'FAIL' }</div>
                    <div className="App-battle-over-details" style={{ fontSize: '16px', color: '#fff' }}>
                       <div className="App-battle-over-detail reward">
                            <label>{ info.label }</label>
                            <span>{ info.text }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>);
}