import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    sendMessage,
    resetMessageResult
} from '../../services/slice-city'

import { CreateIcon, NotifyPop, ToastTip, GoBack } from '../../components'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SendTile(props){
    const dispatch = useDispatch();
    const { detail } = props;
    const keys = {
        name: 'dialog.map.block_' + detail.type + '_' + (detail.parameter || 0) + '.name',
        buff: 'dialog.staff.skill.' + detail.buff_id
    }

    const [ sendBoxPop, setSendBoxPop ] = useState(false);
    const [waitingPop, setWaitingPop] = useState(false);

    const { messageResult } = useSelector(function(state){
        return state.city;
    });

    function send(channel){
        let detail2 = {...detail};
        detail2.msgType = 'block';
        const content = JSON.stringify(detail2);        
        dispatch(sendMessage({
            channel: channel,
            content: content
        }));
        setWaitingPop(true);
    }

    useEffect(()=>{
    }, []);
    useEffect(()=>{
        if(messageResult.result){
            dispatch(resetMessageResult());
        }
    }, [messageResult]);

	return (<>
        <span
            _ga_event="share.block.open"
            onClick={()=>{setSendBoxPop(true);}}>
            <CreateIcon type="share" size="20" />
        </span>
        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    width: '400px',
                    height: '200px',
                    margin: '-100px 0 0 -200px'
                }
            }}
            className = { 'App-game-modal-gray-headless App-game-modal-chat-sendblock' }
            isOpen={ sendBoxPop }
            onRequestClose = { () => setSendBoxPop(false) }
            contentLabel="Chat">
            <div style={{ position: 'relative' }}>
                <span _ga_event="share.block.close" onClick={ () => setSendBoxPop(false) }><GoBack size="48px" /></span>
                <div style={{ textAlign: 'center', padding: '50px 0' }}>
                    { Texts2['dialog.share.btn'] } { Texts[keys.name] || keys.name }({detail.x_id},{detail.y_id})
                </div>
                <div style={{ textAlign: 'center' }}>
                    <span className="App-loading-btn">
                        <span 
                        _ga_event="share.block.world"
                        onClick={ ()=>send(1) } 
                        className="App-general-btn App-general-btn-blue" style={{ marginRight:'2rem' }}>
                        { Texts2['dialog.chat.world'] }
                    </span></span>

                    <span className="App-loading-btn">
                        <span 
                        _ga_event="share.block.camp"
                        onClick={ ()=>send(2) } 
                        className="App-general-btn App-general-btn-blue">
                        { Texts2['dialog.camp'] }
                    </span></span>
                </div>
            </div>
        </Modal>

        { waitingPop && <ToastTip text={
            Texts2['dialog.share.successful']
        } callback={ ()=>{
            setWaitingPop(false);
            setSendBoxPop(false);
        }} />}
    </>)
}