import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { spyEnamy, resetSpyResult } from '../../services/slice-general';

import Generals from '../generals/generals-spy'
import { NotifyPop, ToastTip, ActionResultPop } from '../../components'

import { logTrace } from '../../utils/log'
import { numberFormat, subs, getStamina } from '../../utils/utils'

import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath
} from '../../utils/ui-res'
import './spy.scss'

import { Texts, Texts2, SpyTexts } from '../../const/';
import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SpyEnamy(props){
    const dispatch = useDispatch(); //must in component function

    const { spyResult } = useSelector(function(state){
        return state.generals;
    });
    const [isSpying, setIsSpying] = useState(false);
    const [spyNotifyPop, setSpyNotifyPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);
    const [spyGeneral, setSpyGeneral] = useState({});

    let player = props.enamy || {};
    const isPlayerCanBattle = function(){
        // return true;
        return player.username && !player.isProtected;
    }
    // console.log(player, spyResult, 'spyResult');

    useEffect(() => {
        if(spyResult.code === 0){
            setIsSpying(false);            
        }
    }, [spyResult]); 

    return (<>  
        <span 
            style={{ marginRight: '3rem' }}
            className={ "App-general-btn App-general-btn-blue" + (isPlayerCanBattle() ? '' : ' App-btn-disable')}
            _ga_event="battle.spy.open"
            onClick = { (e) => { 
                if(isPlayerCanBattle()){
                    setIsSpying(true);
                }else{
                    setNotifyPop(true);
                }
            }}>
            { SpyTexts['button.spy'] } <span className="heart">❤</span>-{ getStamina('spy') }
        </span>

        { notifyPop && <ToastTip text={ 
            player.isProtected 
            ? SpyTexts['dialog.player_under_protection'] 
            : SpyTexts['dialog.battle.spy']
        } callback={ ()=>{ setNotifyPop(false) } } />}

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    height: '310px',
                    marginTop: '-185px',
                    width: '600px',
                    marginLeft: '-190px',
                    padding: '30px',
                    borderRadius: '20px',
                    backgroundColor: 'rgba(30, 17, 3, 0.8)'
                }
            }}
            className = { 'App-game-modal-noborder App-game-modal-spy' }
            isOpen={ isSpying }
            onRequestClose = { () => { setIsSpying(false); } }
            contentLabel="Season End">
            <div style={{ position: 'relative' }}>
                <div className="App-spy-generals" 
                    _ga_event="battle.spy.close"
                    onClick={ ()=>{ setIsSpying(false); } }>
                    { SpyTexts['dialog.spy.title'] }
                </div>
                <Generals onSelected={ (generalId)=>{ setSpyGeneral({id:generalId}) } } spyResult={ spyResult } />
                {(spyGeneral && spyGeneral.id) && <div style={{ textAlign: 'right', zoom: 0.8 }}>
                    <span 
                        style={{ marginTop: '1rem', marginRight: '8.5rem' }}
                        className={ "App-general-btn App-general-btn-blue" + ((spyGeneral && spyGeneral.id) ? '' : ' App-btn-disable')}
                        _ga_event="battle.spy.player"
                        onClick = { (e) => { 
                            dispatch(spyEnamy({
                                username: player.username,
                                generalId: spyGeneral.id
                            }));
                            setSpyNotifyPop(true);
                        }}>
                        { SpyTexts['dialog.spy.btn'] } <span className="heart">❤</span>-{ getStamina('spy') }
                    </span>


                    {(spyNotifyPop && !spyResult.Type) && <NotifyPop tips={{
                        loading: ''
                    }} data={{ t: 1}} />}

                    {(spyNotifyPop && spyResult.Type) && <ActionResultPop 
                        info={{
                            result: spyResult.result
                        }} 
                        overConfirm={ ()=>{
                            setSpyNotifyPop(false);
                            dispatch(resetSpyResult());
                        } } 
                    />}
                </div>}
            </div>
        </Modal> 
    </>)
}