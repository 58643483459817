import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import Generals from '../generals/defense-city';
import MapPreview from './preview';
import SceneBase from './scene-base';
import WillWin from '../battle/win'
import TilePop from '../block/block-pop';
import { GoBack, ToastTip } from '../../components'

import { getOccupiedTiles } from '../../services/slice-map';
import { getCodList } from '../../services/slice-general';

import { getMapId, getMapConfig, createOccupieds } from '../../maps/map-res'
import { logTrace } from '../../utils/log'
import { gaClickEvent } from '../../utils/ga'

import './map.scss';
import Texts from '../../const/texts.json';
import Modal from 'react-modal';
Modal.setAppElement('#root');

export const MainMap = (props) => {
    const dispatch = useDispatch(); //must in component function

    const { sign, seasonInfo, seasonDetail } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const mapId = getMapId(seasonDetail[currentSeasonId].seasonBasicInfo);

    let { isLandscape, isMobile } = window.LeagueOfThronesConfig;
    const isRotate = isLandscape && isMobile;
    const mapConfig = getMapConfig(mapId, isRotate);
    // console.log('mapConfig', mapId, mapConfig);

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    
    const { codsAll } = useSelector(function(state){
        return state.generals;
    });
    const { occupieds } = useSelector(function(state){
        return state.map;
    });
    // console.log('occupieds', occupieds, codsAll)

    const [isMnt, setIsMnt] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [mapImage, setMapImage] = useState('');

    let noTile = {
        pop: false,
        data: {},
        codId: '',
        accupied: {
            type: ''
        },
        target: null
    };
    const [tile, setTile] = useState(noTile);

    useEffect(() => {
        if(window.game){
            window.game.events.emit("codsChanged", {
                cods: codsAll,
                from: 'map'
            });
        }
    }, [isReady, codsAll]); 

    useEffect(() => {
        // let gameWrapper = document.getElementById('App-phaser-parent');
        if(window.game){
            window.game.events.emit("occupiedsChanged", {
                occupieds: occupieds,
                from: 'map'
            });
        }
    }, [isReady, occupieds]); 

    useEffect(() => {
        gameRender();

        let target = document.querySelector('.App-mobile #root #App-phaser-parent');
        if(target){
            const screenW = document.documentElement.clientWidth;
            const screenH = document.documentElement.clientHeight;
            let offset = screenW/2/screenH;
            target.style.transformOrigin = '50% ' + (offset * 100) + '%';            
        }

        let t = setInterval(()=>{
            dispatch(getOccupiedTiles());
            dispatch(getCodList());
        }, 5000)

        return () => {
            clearInterval(t);
        }
    }, []); 

    function autoZoomMap(){
        const width = mapConfig.width;
        const height = mapConfig.height;
        let target = document.querySelector('#App-phaser-parent canvas');
        let windowWidth = document.documentElement.clientWidth;
        let mapWidth = width;
        if(windowWidth > width){
            let ratio = windowWidth/width;
            target.style.zoom = ratio;
        }
    }

    function gameRender(){
        if(isReady){
            return true;
        }

        const config = {
            callbacks: {
                preBoot: (game)=> {
                },
            },
            type: Phaser.AUTO,
            disableContextMenu: true,
            title: 'League of Thrones',
            parent: "App-phaser-parent",
            width: mapConfig.mapWidth,
            height: mapConfig.mapHeight,
            dom2: {
                createContainer: true
            },
            transparent: true,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 100 },
                    debug: false
                }
            },
            scene: [ new SceneBase({occupieds, codsAll, mapConfig }, false) ]
        }
        window.game = new Phaser.Game(config);
        // Triggered when game is fully READY.

        window.game.events.on("READY", function(){
            setIsReady(true);
            props.onShow && props.onShow();
        });

        window.game.events.on("TileClick", function(res) {
            console.log('TileClick', res)
            // if(res.data.isBlock){
            //     setIsMnt(true);
            //     return;
            // }
            setTile({
                pop: true,
                data: res
            });
            gaClickEvent({
                eventName: 'map.block.open',
                userId: currentUser.username
            });
        });
    }

    return (<>
        <div className={"App-phaser-parent-wrapper App-phaser-map-" + mapConfig.capitalType}>
            <div id="App-phaser-parent" className="App-seaon-map-canvas-rotate"></div> 
        </div>
        
        <Generals openTile={ (data)=>{
            console.log('block_2', data);
            setTile({
                pop: true,
                data: { 
                    data: data
                }
            });
        } } />  

        {tile.pop && <TilePop tileInfo={ tile } close={ () => {setTile(noTile)} } />}
   
        {isReady && <MapPreview />}
                
        <WillWin />
    </>)
}