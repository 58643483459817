import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import * as DASlices from '../../da/slice';

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'
import UserCard from '../../features/players/user-card';

import { numberFormat, timeFormat, dateFormat, subs } from '../../utils/utils'
import { unionImagePath, shortString } from '../../utils/ui-res'
import { zetaTransactionLink } from '../../const'

import Modal from 'react-modal';

import './events.scss';

export default function Events(props) {
    const dispatch = useDispatch();

    const [eventPop, setEventPop] = useState(false);

    const { gameStart, events } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;

    useEffect(()=>{
        let t2 = setInterval(function(){
            dispatch(DASlices.getDAEvents({
                seasonId
            }))
        }, 10000);
    }, []);

    // if(!events.isRecent){
    //     return (<></>);
    // }
  
    if(!eventPop){
        return (<div className="App-acution-events"><div className="App-world-records-notify-wrapper" style={{
                    maxHeight: (Math.min(events.recents.length, 4)*80 + 40) + 'px'
                }}>
                <div className="App-world-records-notify">
                    {events.recents.map((item, index)=>
                        <NewEventItem data={ item } key={ index } />
                    )}
                    <div className="App-acution-more" onClick={ ()=>{ setEventPop(true) } }>...</div>
                </div>
            </div>
        </div>);
    }

    return (<Modal
        style = {{
            overlay: { 
                backgroundColor: 'transparent'
            },
            content: {
                left: '0',
                top: 'auto',
                bottom: '70px',
                width: '300px',
                height: '400px',
                margin: '-200px 0 0 0'
            }
        }}
        className = { 'App-game-modal-noborder App-dagame-modal-event' }
        isOpen={ eventPop }
        onRequestClose={ ()=>{ setEventPop(false);} }
        contentLabel="Acution Recent">
        <div className="App-acution-events-content">
            {events.list.map((item, index)=>
                <NewEventItem data={ item } key={ index } />
            )}
        </div>
    </Modal>);
}

function NewEventItem(props){
    const { data } = props;
    const { args = {} } = data;

    return (
        <div className={"App-world-record-item"}>
            <div _ga_event="auction.tx_record.player.open"><UserCard userInfo={{
                avatar: -1,
                unionId: args.unionId || 0,
                username: args.owner
            }} /></div> 
            <div className="App-world-record-item-text">
                <a href={ zetaTransactionLink + data.hash } rel="noreferrer" target="_blank" style={{ color: '#f5f5f5' }}>{ shortString(args.owner, 10) }</a> 
                &nbsp; buy a block({ args.x + ',' + args.y }) 
                <div className="time">{ dateFormat(data.timestamp) }</div>
            </div>
        </div>
    );
}