import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rewardConfig } from 'throne-underlying';

import { resourceRefresh } from '../../services/slice-city'
import { pointUpdate, resetBuyResult, refreshPoints } from '../../services/slice-strategies'
import { buySilver, buyTroop, buyMorale, buyProtect, buyStore, buyProtect1 } from '../../services/slice-strategies'

import { GoBack, CreateIcon, ClockCounter, NotifyPop, ToastTip } from '../../components'

import { Texts, Texts2 } from '../../const/';
import { logTrace } from '../../utils/log'
import { subs, timeFormat, dateFormat } from '../../utils/utils'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function GetResourceMutiTimes(props) {
    const dispatch = useDispatch(); 

    let { mutiTimesType } = props.data;
    const [type, setType] = useState('');

    const { pointInfo, need, info, buyResult } = useSelector(function(state){
        return state.strategies;
    });

    let strategyPoint = pointInfo.strategyPoint;
    let pointsUnit = need[mutiTimesType];
    let maxTimes = Math.floor(strategyPoint/pointsUnit);

    const [times, setTimes] = useState(1);
    function setSliderValueClick(value){
        value = Math.min(value, maxTimes);
        value = Math.max(value, 1);
        setTimes(value/1);
    }

    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    useEffect(()=>{
        dispatch(refreshPoints());
        dispatch(pointUpdate());
    }, [buyResult]);

    function mutiTimesorder(){
        if(mutiTimesType === 'buyTroop'){
            setType('get_troops');
            dispatch(buyTroop(times));
        }
        if(mutiTimesType === 'buySilver'){
            setType('get_silver');
            dispatch(buySilver(times));
        }
    }

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'transparent' },
            content: {
                width: '580px',
                height: '350px',
                marginLeft: '-280px',
                marginTop: '-170px'
            }
        }}
        className = { 'App-game-modal-avatar-point App-game-modal-gray' }
        isOpen={ true }
        onRequestClose={ ()=>{ props.onClose && props.onClose(); } }
        contentLabel="Order Times Modal">
        <div style={{ position: 'relative',height:'500px' }}>
            <div _ga_event={"order.times." + mutiTimesType } onClick={ ()=>{ props.onClose && props.onClose(); }}>
                <GoBack size="60px" />
            </div>
            <div className="App-game-modal-header" style={{ lineHeight: 4.4 }}> &nbsp; </div>

            <div className="App-policy-points-panel">
                <div style={{ color: '#FFF8CA' }}>{ Texts2['dialog.general.upgrade'] || 'Set the numbers of order times' }</div>
                <div style={{ position: 'relative',margin: '30px auto 10px', paddingBottom: '5px', width:'220px' }}>
                    <div style={{ margin: '-20px 0 10px', fontSize: '14px' }}>Max: { maxTimes }</div>
                    <div className="App-slider-minis" _ga_event="general.upgrade.slider.minis" onClick={()=>{ setSliderValueClick(times - 1) }}></div>
                    <input type="number" maxLength="3" min="1" value={ times } disabled={ times === 0 } onChange={ (e)=> { setSliderValueClick(e.target.value) }} />
                    <div className="App-slider-plus" _ga_event="general.upgrade.slider.plus" onClick={()=>{ setSliderValueClick(times + 1) }}></div>
                </div>
            </div>
            
            <div className="App-point-btns" style={{ textAlign: 'center' }}>
                <div className="App-point-btn-item">
                {(times > maxTimes ) 
                ? (<span 
                    _ga_event="general.upgrade.disable"
                    onClick={ ()=>{
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                     x{ times }
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="general.upgrade.levelup"
                    onClick = { (e) => { 
                        setWaitingPop(true);
                        mutiTimesorder();
                    }}>
                    <span style={{ position: 'absolute', top: '-3px',left: '-14px' }}><CreateIcon type="lighting" size="30" /></span>
                     x{ times } 
                </span>)}
                </div>
            </div>
        </div>

        { notifyPop && <ToastTip text={ 
            (info[mutiTimesType] || {}).able ? 'Already under protecting now.'
            : subs(Texts2['dialog.order.buy_order_no_gold'], { count: (info[mutiTimesType + 'Count'] || 0) })
        } callback={ ()=>{ setNotifyPop(false) } } />}

        {waitingPop && <NotifyPop tips={{
            loading: '',
            success: subs(Texts2['dialog.order.' + type + '_success'], { count: times*(info[mutiTimesType + 'Count'] || 0) }),
            fail: subs(Texts2['dialog.order.' + type + '_fail'], { count: times*(info[mutiTimesType + 'Count'] || 0) })
        }} data={ buyResult } callback={ ()=>{
            setWaitingPop(false);
            dispatch(resetBuyResult());
            setTimes(1);
        }} />}
    </Modal>);
}


