import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCodList, getCodGeneralIds, getCodCreatorDetail, resetCodResult, createCod, cancelCod, joinCod, quitCod } from '../../services/slice-general'
import { getSeasonConfig, getTokenPriceInfo, getDefenseList, getTileInfo, getOccupiedTiles } from '../../services/slice-map'
import { 
    sendMessage,
    resetMessageResult
} from '../../services/slice-city'
import { resourceRefresh } from '../../services/slice-city'

import SelectGeneral from '../generals/generals-selector'

import { CreateIcon, GoBack } from '../../components'
import { NotifyPop, ToastTip, ConfirmTip } from '../../components'
import * as cache from '../../utils/cache'

import { Texts, Texts2, Chains, CodTexts } from '../../const'
import { numberFormat, getStamina } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import './cod.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function CodCreate(props) {
    let dispatch = useDispatch();
    let { blockInfo, detail, isNeighbor } = props;
    const codId = 'block_' + blockInfo.x_id + '_' + blockInfo.y_id;

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { codsAll, codGeneralIds, codResult } = useSelector(function(state){
        return state.generals;
    });
    const { defenseList } = useSelector(function(state){
        return state.map;
    });
    let currentCod = codsAll[codId] || {};
    // let membersMap = currentCod.membersMap || {};
    let membersMap = {};
    let members = currentCod.members || [];
    members.forEach(function(item){
        membersMap[item.username] = item;
    })

    let isCodCreated = !!currentCod['creator'];
    let isCodCreator = currentCod['creator'] === currentUser.username;
    let isCodJoined = !!membersMap[currentUser.username];

    // console.log('cod create', { codResult, codGeneralIds, currentCod, membersMap, currentUser}, { isCodCreated ,isCodCreator, isCodJoined });

    const [codType, setCodType] = useState('');
    const [isStartCod, setIsStartCod] = useState(false);
    const [isJoinCod, setIsJoinCod] = useState(false);
    const [isCancelConfirm, setIsCancelConfirm] = useState(false);
    const [noGoldPop, setNoGoldPop] = useState(false);

    useEffect(()=>{
        dispatch(getCodList());
        dispatch(getCodGeneralIds());
        dispatch(resourceRefresh());
        dispatch(getDefenseList({
            x: blockInfo.x_id,
            y: blockInfo.y_id
        }));
    }, []);

    useEffect(()=>{
        if(codResult.result && codResult.op === 'create assembly'){
            const content = {
                msgType: 'cod',
                detail: detail,
                codId: codId
            }; 
            dispatch(sendMessage({
                channel: 2,
                content: JSON.stringify(content)
            }));
        }
    }, [codResult]);

    
    function afterOpenModal(){
        dispatch(resetCodResult())
    }

    if(isCodCreated && currentUser.unionId !== currentCod.unionId){
        return (<></>);
    }

    if(!isNeighbor){
        return (<>
            <span
                style={{ verticalAlign: 'top', marginTop: '15px' }} 
                className={ "App-general-btn App-general-btn-green" }
                _ga_event="assembly.open"
                onClick = { (e) => {
                    setIsStartCod(true);
                }}>
                { CodTexts['button.assembly'] }
                &nbsp; <span className="heart">❤</span>-{ getStamina('assembly') } 
            </span>

            { isStartCod && <ToastTip text={ Texts['dialog.block_attack.rule'] } callback={ ()=>{ setIsStartCod(false) } } />}
        </>);
    }

    return (<>
        {(!isCodCreated) && <span
            style={{ verticalAlign: 'top', marginTop: '15px' }} 
            className={ "App-general-btn App-general-btn-green" }
            _ga_event="assembly.create"
            onClick = { (e) => {
                setCodType('create');
                setIsStartCod(true);
            }}>
            { CodTexts['button.assembly'] }
            &nbsp; <span className="heart">❤</span>-{ getStamina('assembly') } 
        </span>}

        {(isCodCreated && !isCodCreator && !isCodJoined) && <span
            style={{ verticalAlign: 'top', marginTop: '15px' }} 
            className={ "App-general-btn App-general-btn-green" }
            _ga_event="assembly.join"
            onClick = { (e) => {
                setIsStartCod(true);
                setCodType('join');
            }}>
            { CodTexts['button.assembly'] }
        </span>}

        {(isCodCreator || isCodJoined) && <><span
            style={{ verticalAlign: 'top', marginTop: '15px' }} 
            className={ "App-general-btn App-general-btn-green App-btn-disable" }
            _ga_event="assembly.cancel"
            onClick = { (e) => {
                return;
                setCodType('cancel');
                setIsCancelConfirm(true);
            }}>
            { CodTexts['dialog.assembling'] }
            <div style={{ position: 'absolute', margin: '10px 0 0 -30px' }}>You already in assembly.</div>
        </span>
        </>}

        {isCancelConfirm && <ConfirmTip 
            texts={{
                title: CodTexts['dialog.assembling'],
                content: CodTexts['dialog.assembly_cancel.desc'],
                cancel: Texts['popup.map_defense_cancel'],
                confirm: Texts['popup.map_defense_confirm']
            }}
            confirm={ ()=>{
                setIsCancelConfirm(false);
                dispatch(cancelCod({
                    codId: codId
                }));
            } }
            cancel = { ()=>{
                setIsCancelConfirm(false);
            }}
        />}

        {isStartCod && <SelectGeneral 
            battleType="assembly"
            codType={ codType }
            firstDefense={ defenseList[0] || {} }
            codGeneralIds={ codGeneralIds }
            defenseList={ defenseList } 
            onClose={ ()=>{ setIsStartCod(false) } }
            onSelected={ (res)=>{
                let { times, general, flag } = res;
                setCodType('');
                if (!flag) { 
                    return;
                }
                setIsStartCod(false);
                setIsJoinCod(true);
                let generalId = general.id;
                console.log('codList assembly', {general, flag}, blockInfo);
                if(!isCodCreated){
                    blockInfo.generalId = generalId;
                    dispatch(createCod(blockInfo));
                }
                if(isCodCreated && !isCodJoined){
                    dispatch(joinCod({
                        codId: codId,
                        generalId: generalId
                    }));
                }
            } 
        } />}

        {(codResult.back && isJoinCod) && <NotifyPop tips={{
            success: codResult.op + ' success',
            fail: codResult.error || CodTexts['dialog.assembly_error']
        }} data={ codResult } callback={ ()=>{
            dispatch(resetCodResult());
            dispatch(getCodCreatorDetail(codId));
            setIsJoinCod(true);
        }} />}

        { noGoldPop && <ToastTip text={ Texts2['dialog.general_skill.upgrade_fail_reason'] } callback={ ()=>{ setNoGoldPop(false) } } />}
    </>);
}
