import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as Contract from '../../wallets/contract';

import { getGold, getOfferList, buyOffer, resetOfferResult, resourceRefresh } from '../../services/slice-city'
import { getSeasonConfig } from '../../services/slice-map'

import { CreateIcon, GoBack } from '../../components'
import { NotifyPop, ToastTip } from '../../components'
import * as cache from '../../utils/cache'

import { Texts2, Chains, OfferTexts } from '../../const'
import { numberFormat } from '../../utils/utils'
import { cityImagePath, generalImagePath } from '../../utils/ui-res'
import { logTrace } from '../../utils/log'

import './offers.scss'
import title from './assets/title.png';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Offers() {
    let dispatch = useDispatch();

    const { golds, offers, offerResult } = useSelector(function(state){
        return state.city;
    });
    const availableOffers = GetAvailableOffers();
    const realOffers = availableOffers;
    // console.log('getOfferList', offers, realOffers, golds, offerResult)
    
    const [buyOfferPop, setBuyOfferPop] = useState(false);
    const [noOfferPop, setNoOfferPop] = useState(false);
    const [noGoldPop, setNoGoldPop] = useState(false);
    const [waitingPop, setWaitingPop] = useState(false);
    const [page, setPage] = useState(0);
    const total = realOffers.length;
    const offerItem = realOffers[page] || {};
    const offer_icon = offerItem.offer_icon || [];

    useEffect(()=>{
        dispatch(getGold());
        dispatch(resourceRefresh());
        dispatch(getOfferList());
        dispatch(getSeasonConfig());
    }, [offerResult]);
    
    function afterOpenModal(){
        setBuyOfferPop(false);
    }

    function getFontSize(offer_id){
        let header = OfferTexts['dialog.offer.name.' + offerItem.offer_id] || '';
        let len = header ? header.length : 45;
        return Math.min(100/len, 2.5) + 'rem'
    }

    if(realOffers.length === 0){
        return (<></>)
    }

    return (<>
		<div className="App-preview-map-btn App-offers-btn" 
            _ga_event="offers.open"
            onClick={()=>{ setBuyOfferPop(true); }}>
        	<span className="App-redpoint" style={{ margin: '-70px 0 0 65px' }}>new</span>
            <span className="text">{ OfferTexts['button.offer'] }</span>
        </div>

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '880px',
                    marginLeft: '-440px',
                    height: '600px',
                    marginTop: '-320px'
                }
            }}
            className = { 'App-game-modal-gift' }
            isOpen={ buyOfferPop }
            onRequestClose={ afterOpenModal }
            contentLabel="Offers">
            <div className={ "App-offer-content App-offer-content-" + offerItem.offer_background }>
                <div className="App-offer-content-title" 
                    style={{ fontSize: getFontSize(offerItem.offer_id) }}>
                    { OfferTexts['dialog.offer.name.' + offerItem.offer_id] }
                </div>
                <div className="App-offer-upgrade">
                    <h2>{ OfferTexts['dialog.offer.desc.' + offerItem.offer_id] }</h2>
                    <div className="App-offer-upgrade-title">
                        {offer_icon.map((icon, index)=>
                            <div className="App-offer-upgrade-item" key={ index }>
                                {
                                    !parseInt(icon) 
                                    ? <img src={ cityImagePath({ type: icon }) } />
                                    : <img src={ generalImagePath({ generalId: icon }) } />
                                }
                                <span>{ OfferTexts['dialog.offer.desc_' + (index + 1) + '.' + offerItem.offer_id] }</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="App-offer-op">
                    <div className="App-offer-reward">
                        { OfferTexts['dialog.reward'] } &nbsp;&nbsp;
                        {(offerItem.offer_reward_sliver > 0) && <div className="App-mertrial-item">
                            <CreateIcon type="silver" size="20" /> 
                            <span className="App-mertrial-item-value">
                                { numberFormat(offerItem.offer_reward_sliver) }
                            </span>
                        </div>}
                        {(offerItem.offer_reward_troops > 0) && <div className="App-mertrial-item">
                            <CreateIcon type="troop" size="20" /> 
                            <span className="App-mertrial-item-value">
                                { numberFormat(offerItem.offer_reward_troops) }
                            </span>
                        </div>}
                    </div>

                    <span className={ "App-general-btn App-general-btn-green" + (golds >= offerItem.offer_gold ? '' : ' App-btn-disable')}
                        _ga_event="offers.buy"
                        onClick = { (e) => { 
                            if(golds >= offerItem.offer_gold){
                                dispatch(buyOffer(offerItem));
                                setWaitingPop(true);
                                return;
                            }
                            setNoGoldPop(true);
                        }}>
                    	{ OfferTexts['button.buy_offer'] }
                    </span>
                    <div style={{ padding: '10px' }}>
                        <span style={{ display: 'inline-block' }}>
                            <CreateIcon type="coin" size="32" />
                        </span>
                        <span className="App-item-value" style={{ paddingLeft: '10px' }} >
                            { offerItem.offer_gold }
                        </span>
                    </div>
                </div>

                {total > 1 && <div className="App-gallery-index chain-seasons-pages">
                    {[...Array(total)].map((x, index) =>
                        <span 
                            style={{ marginTop: '2rem' }}
                            _ga_event="offers.page.num"
                            onClick={ ()=>{ setPage(index); } }
                            className={ index === page ? 'normal current' : 'normal' } 
                            key={ index }>
                            { index + 1 }
                        </span>
                    )}
                    <div className="App-gallery-prev"
                        style={{
                            left: '-90px',
                            top: '240px'
                        }}
                        _ga_event="offers.page.prev"
                        onClick={ ()=>{
                            let current = page - 1;
                            if(current < 0){
                                current = total - 1;
                            }
                            setPage(current);
                        } 
                    }>prev</div>
                    <div className="App-gallery-next"
                        style={{
                            right: '-90px',
                            top: '240px'
                        }}
                        _ga_event="offers.page.next"
                        onClick={ ()=>{
                            let current = page + 1;
                            if(current >= total){
                                current = 0;
                            }
                            setPage(current);
                        } 
                    }>next</div>
                </div>}
            </div>
        </Modal>

        { noGoldPop && <ToastTip text={ Texts2['dialog.general_skill.upgrade_fail_reason'] } callback={ ()=>{ setNoGoldPop(false) } } />}

        {(offerResult.back && waitingPop) && <NotifyPop tips={{
            success: OfferTexts['dialog.offer.success'],
            fail: offerResult.error || OfferTexts['dialog.offer.fail']
        }} data={ offerResult } callback={ ()=>{
            setWaitingPop(false);
            dispatch(resetOfferResult())
        }} />}
    </>);
}


function GetAvailableOffers(){
    const { seasonConfig } = useSelector(function(state){
        return state.map;
    });
    const { season_open } = seasonConfig;   //seconds
    const timeNow = new Date().getTime()/1000;
    const dayLong = 24*60*60;
    const dayCount = Math.ceil(timeNow/dayLong) - Math.ceil(season_open/dayLong) + 1;
    // console.log(Math.ceil(timeNow/dayLong), Math.ceil(season_open/dayLong))

    const generalList = useSelector(function(state){
        return state.generals.list || [];
    });
    var maxGeneralLevel = 0;
    generalList.forEach(function(general){
        if(general.able){
            maxGeneralLevel = Math.max(maxGeneralLevel, general.level)
        }
    });

    const { resources, offers, buyOfferRecords } = useSelector(function(state){
        return state.city;
    });
    const { troop, silver } = resources; 

    var list = [];
    offers.forEach(function(item){
        let { offer_trigger_1, offer_trigger_2 = 0, offer_trigger_value } = item;
        // console.log('GetRealOffers', { item, troop, silver, maxGeneralLevel, dayCount });

        let compareVars = {
            '1': silver.production,
            '2': maxGeneralLevel,
            '3': troop.production
        };

        let isReal = !buyOfferRecords[item.offer_id] && dayCount >= offer_trigger_1;
        if(offer_trigger_2){
            isReal = isReal && compareVars[offer_trigger_2] < offer_trigger_value;
        }
        if(isReal){
            list.push(item);
        }
    });
    return list.sort(function(a, b){
        return a.offer_order >= b.offer_order ? 1 : -1;
    });
}
