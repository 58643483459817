import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate, useParams } from "react-router-dom";

import * as EthFuns from '../../wallets/ethereum';
import * as Contract from '../../wallets/contract';

import { getSeasonList, getHisSeasonList } from '../../services/slice-user';
import { getMySeasons } from '../../services/api';

import WalletSelect from '../../wallets/wallet-select';
import Header from './header';
import MyRewards from './my-rewards';
import SeasonItem from '../season/season'
import { CreateIcon } from '../../components'
import { Texts, Texts2, Chains } from '../../const'

import { logTrace } from '../../utils/log';
import * as cache from '../../utils/cache'

const isHistorySeason = window.location.href.indexOf('?type=end') > -1;

export default function SeasonList(props){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    let address = cache.get('accountCacheKey') || '';
    const [ account, setAccount ] = useState('');
    var chainNameList = ['zeta', 'bsc', 'emerald'];

    const [ showSubMenu, setShowSubMenu ] = useState(false);
    const [menuName, setMenuName] = useState('');
    const [chainId, setChainId] = useState('');

    const [currentChainName, setCurrentChainName] = useState('');

    let { seasonList, seasonHisList } = useSelector(function(state){
        return state.user;
    });
    const [mySeasons, setMySeasons] = useState({});
    const [allSeasons, setAllSeasons] = useState(seasonList);
    const [seasonMaps, setSeasonMaps] = useState({});
    const [seasonIds, setSeasonIds] = useState([]);

    const [feeFilterType, setFeeFilterType] = useState('Freemium');
    const [filterType, setFilterType] = useState('all');
    let feeFileters = ['Freemium', 'Premium'];
    let filters = [
        { name: Texts2['button.all'], value: 'all'},
        { name: Texts2['button.my'], value: 'mine'},
        { name: Texts2['button.running'], value: 'running'},
        { name: Texts2['button.signup'], value: 'signup'},
    ];
    if(isHistorySeason){
        filters = filters.splice(0, 2);
    }
    // console.log({mySeasons, seasonList, seasonHisList, filterType, currentChainName});

    var pager = 4;
    if(window.LeagueOfThronesConfig.isMobile){
        pager = 1;
    }
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [pageMap, setPageMap] = useState({});

    function getPagerMap(page){
        let _total = 5;
        let pageMap = {};
        let start = 0;
        if(page > 2){
            start = page - 2;
        }
        if(total - page <= _total){
            start = total - _total;
        }
        // start = Math.min(total - 4, start);
        start = Math.max(0, start);
        for(var i=0; i< _total; i++){
            let index = start + i;
            if(index <= total){
                pageMap[index] = true;
            }
        }
        return pageMap;
    }
    useEffect(()=>{
        let result = getPagerMap(page);
        setPageMap(result);
    }, [page, seasonList, seasonHisList]);

    useEffect(()=>{
        let { list, ids } = getSeasonsByChain(currentChainName);
        setTimeout(function(){
            setSeasonMaps(ids);
            setAllSeasons(list);
        }, 0);
        window.localStorage.currentChainName = currentChainName;
    }, [seasonList, seasonHisList, currentChainName]);

    useEffect(()=>{
        let list = getSeasonsByFilters(allSeasons, filterType, feeFilterType);
        // console.log(list, pageMap, total, seasonHisList, allSeasons, filterType, feeFilterType);
        setTimeout(function(){
            setSeasonIds(list);
            let _total = Math.ceil(list.length/pager);
            setTotal(_total);
        }, 0);
    }, [allSeasons, filterType, feeFilterType]);

    useEffect(()=>{
        setPage(0);
    }, [currentChainName, filterType, feeFilterType]);

    useEffect(()=>{
        setFilterType('all');
    }, [currentChainName]);

    function getSeasonsByChain(chainName){
        chainName = chainName || '';
        let allSeasons = [];
        if(isHistorySeason){
            allSeasons = seasonHisList;
        }else{
            allSeasons = seasonList;            
        }

        let list = [];
        let ids = {};
        allSeasons.forEach(function(season){
            let item = JSON.parse(JSON.stringify(season));
            item.lastSign = item.player_limit - item.apply_count;
            if(!chainName || item.chain === chainName){
                list.push(item);
                ids[season.sid] = item;
            }
        });
        list.sort(function(a, b){
            return a.lastSign < b.lastSign ? 1 : -1;
        });
        return { list, ids };
    }

    function getSeasonsByFilters(allSeasons, statusFilterType, feeFilterType){
        let list = [];
        let ids = {};

        if(isHistorySeason){
            allSeasons.forEach(function(season){
                list.push(season.sid);
                ids[season.sid] = season;
            });
            return list;
        }

        allSeasons.forEach(function(season){
            let isCanByFee = false;
            let registery_fee = season.registery_fee || 0;
            if(feeFilterType === 'Premium'){
                isCanByFee = registery_fee/1 > 0;
            }else{
                isCanByFee = registery_fee/1 == 0;
            }
            if(statusFilterType === 'all' && isCanByFee){
                list.push(season.sid);
                ids[season.sid] = season;            
            }

            let start = season.start_ts/0.001;
            let apply = season.apply_ts/0.001;
            let end = season.end_ts/0.001;
            let now = new Date().getTime();
            let isRunning = now >= start && now < end;

            if(statusFilterType === 'running' && isRunning && isCanByFee){
                list.push(season.sid);
                ids[season.sid] = season;                    
            }

            if(statusFilterType === 'mine' && mySeasons[season.sid] && isCanByFee){
                list.push(season.sid);
                ids[season.sid] = season;                     
            }

            if(statusFilterType === 'signup' && season.lastSign > 0 && isCanByFee){
                list.push(season.sid);
                ids[season.sid] = season;                     
            }
        });
        return list;
    }

    function getSeasonOrder(season){
        let { apply_ts, start_ts, end_ts } = season;
        let now = new Date().getTime()/1000;

        let apply = now >= apply_ts && now < start_ts;
        let running = now >= start_ts && now < end_ts;
        let end = !!season.end;

        if(apply){
            return apply_ts * 3;
        }
        if(running){
            return apply_ts * 2;
        }
        if(end){
            return apply_ts;
        }
    }

    const [refreshSignUpCount, setRefreshSignUpCount] = useState(0);
    const [selectWalletPop, setSelectWalletPop] = useState(false);

    useEffect(()=>{
        if(isHistorySeason){
            dispatch(getHisSeasonList());
        }else{
            dispatch(getSeasonList());            
        }
    }, [account, refreshSignUpCount]);

    useEffect(()=>{
        if(isHistorySeason){
            dispatch(getHisSeasonList());
        }
    }, [seasonList]);

    useEffect(()=>{
        if(address || account){
            getMySeasons({
                chainName: currentChainName,
                address: address || account
            }, function(res){
                setMySeasons(res);
            });
        }
    }, [address, account, filterType, feeFilterType]);

    useEffect(()=>{
        EthFuns.detect({chainId}, function(result){
            //just for reload
        });

        if(isHistorySeason){
            dispatch(getHisSeasonList());
        }else{
            dispatch(getSeasonList());            
        }

        if(address || account){
            getMySeasons({
                chainName: currentChainName,
                address: address || account
            }, function(res){
                setMySeasons(res);
            });
        }

        let chainNameCache = window.localStorage.currentChainName || chainNameList[0];
        if(chainNameCache){
            setCurrentChainName(chainNameCache);
            return;
        }

        EthFuns.getCurrentChain(function(chainId){
            let chainIds = {
                '0xa516': 'emerald',
                '0x38': 'bsc',
                '0x61': 'bsctest',
                '0x1b59': 'zetatest',
                '0x1b58': 'zeta'
            };
            let name = chainIds[chainId] || '';
            if(name){
                setCurrentChainName(name);                
            }
        });
    }, []);

	return(<>
        <div className="App-season-menus"> 
            <Header isHistorySeason={ isHistorySeason } address={ address || account } />

            {seasonList.length > 0 && <div className="App-season-menus-right">
                <MyRewards address={ address } season={ seasonList[0] } onWalletSelect={ ()=>{ 
                    if(address){return}
                    setSelectWalletPop(true) 
                } } />
            </div>}
        </div>

        <div className="App-general-logis" style={{ margin: '10px 0 0',  width: '100%', textAlign: 'center' }}>
            <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
            {chainNameList.map((name, index)=>
                <div key={ 'chain-' + index } 
                    className={ 
                        'App-general-tab-normal ' 
                        + (currentChainName === name ? 'App-general-tab-hightlight' : '') 
                    } 
                    _ga_event={ "menu.chain." + name }
                    onClick={ (e)=> {
                        setCurrentChainName(name);
                        setMenuName(Chains[name].chainName);
                    } }>
                    <span style={{ margin: '-2px 5px 0 0' }}>
                        <CreateIcon type={ Chains[name].name } size="16" />
                    </span>
                    <span>
                        { Chains[name].isCrossChain ? Chains[name].chainName : Chains[name].chainName }
                    </span>
                </div>
            )}
            </div>
        </div>
        {selectWalletPop && <WalletSelect seasonBasicInfo={ {} } 
            onSelect={ async(info)=>{ 
                let { wallet } = info;
                let res = await EthFuns.setWallet(wallet);
                await EthFuns.connect();
                EthFuns.login({}, function(res){
                    if(!res.account){
                        return;
                    }
                    setAccount(res.account);
                    cache.set('accountCacheKey', res.account)
                    setSelectWalletPop(false);
                });
                await Contract.init(seasonList[0], wallet);
            } }
            onClose={ ()=>{ setSelectWalletPop(false) } }
        />}

        <div className="App-season-list">
            {[...Array(pager)].map((x, index) => {
                let seasonId = seasonIds[page*pager + index];
                let seasonInfo = seasonMaps[seasonId];
                return <div style={{ display: 'inline' }} key={  seasonId + (index + '') }>
                {seasonInfo && <SeasonItem 
                onLogin={ (account)=>{ 
                    if(!account){ return; }
                    setAccount(account); 
                    cache.set('accountCacheKey', account);
                } }
                isMine={ filterType === 'mine' || mySeasons[seasonInfo.sid] } 
                onCountFresh={ ()=>{
                    setRefreshSignUpCount(new Date().getTime()); 
                } } seasonBasicInfo={ seasonInfo } chain={ {
                    chainId: chainId, 
                    name: currentChainName
                } } />}</div>
                }
            )}

            <div
                style={{ display: (isHistorySeason ? 'none' : 'block')}}
                className="App-season-filter">
                <div className="App-season-filter-title">
                    Seasons
                </div>
                <div className={"App-season-filter-item"}>
                    <a href="/dutch-auction/" target="_blank" style={{ display: 'block' }}>
                        <span className="filter-radio-current"></span>
                        PVP
                    </a>
                </div>
                {feeFileters.map((type, index) => <div key={ 'fee-' + type } onClick={ ()=>{ 
                    setFeeFilterType(type) 
                    } } 
                    className={"App-season-filter-item"}>
                    {
                        type === feeFilterType 
                        ? <span className="filter-radio"></span>
                        : <span className="filter-radio-current"></span>
                    }
                    { type }
                </div>)}

                <div style={{ height: '20px' }}></div>

                <div className="App-season-filter-title">
                    Filter
                </div>
                {filters.map((type, index) => <div key={ 'type-' + type.value } onClick={ ()=>{ 
                    if(type.value === 'mine' && !account){
                        setSelectWalletPop(true);
                        return;
                    }
                    setFilterType(type.value) 
                    } } 
                    className={"App-season-filter-item" + ((type.value === 'mine' && !account) ? ' App-season-filter-item-disable' : '') }>
                    {
                        type.value === filterType 
                        ? <span className="filter-radio"></span>
                        : <span className="filter-radio-current"></span>
                    }
                    { type.name }
                </div>)}
            </div>

            {seasonIds.length > pager && <div className="App-gallery-index chain-seasons-pages">
                <div
                    _ga_event={ "season.list.page.prev" }
                    onClick={ ()=>{
                        let current = page - 1;
                        if(current < 0){
                            current = total - 1;
                        }
                        setPage(current);
                    }} 
                    className="App-gallery-index-prev">
                    prev
                </div>
                {!pageMap[0] && <><span 
                    _ga_event={ "season.list.page.number" }
                    onClick={ ()=>{ setPage(0); } }
                    className={ 0 === page ? 'normal current' : 'normal' } 
                    key={ 'page1-0' }>
                    { 1 }
                </span>…</>}
                {[...Array(total)].map((x, index) =>
                    <span 
                        _ga_event={ "season.list.page.number" }
                        onClick={ ()=>{ setPage(index); } }
                        style={{ display: (pageMap[index] ? 'inline-block': 'none') }}
                        className={ index === page ? 'normal current' : 'normal' } 
                        key={ 'page-' + index }>
                        { index + 1 }
                    </span>
                )}
                {!pageMap[total - 1] && <>…<span 
                    _ga_event={ "season.list.page.number" }
                    onClick={ ()=>{ setPage(total - 1); } }
                    className={ total === page ? 'normal current' : 'normal' } 
                    key={ 'page2-' + total }>
                    { total }
                </span></>}
                <div 
                    onClick={ ()=>{
                        let current = page + 1;
                        if(current >= total){
                            current = 0;
                        }
                        setPage(current);
                    }}
                    _ga_event={ "season.list.page.next" }
                    className="App-gallery-index-next">
                    next
                </div>
            </div>}
        </div>
    </>);
}