import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';

import { useSelector, useDispatch } from 'react-redux';

import mapList from '../../maps/map_list.json';

import * as API from '../../services/api';
import { CreateIcon, NotifyPop, ToastTip } from '../../components'

import { Texts, Texts2 } from '../../const/';
import { isProd } from '../../utils/ui-res'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SeasonVote(props){
    const dispatch = useDispatch(); 
    const { seasonId, seasonStatus, account, currentMapId, voteData, onSelected, isVote } = props;

    const [selectMapId, setSelectMapId] = useState(currentMapId);
    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);
    const [voteResut, setVoteResut] = useState(false);
    const [votes, setVotes] = useState({});
    const [voteId, setVoteId] = useState(voteData[account] || -1);

    function resetVotes(vote){
        let votes = {};
        for(var address in vote){
            let mapId = vote[address] + '';
            votes[mapId] = votes[mapId] || 0;
            votes[mapId] += 1;
        }
        setVotes(votes);
    }

    function setSeasonVote(){
        setNotifyPop(true);
        let data = {
            address: account,
            map_id: selectMapId,
            season_id: seasonId,
        }
        API.setSeasonVote(data, function(res){
            let mapInfo = res['map_info'] || {};
            let voteData = mapInfo['votes'] || {};
            console.log(voteData);
            resetVotes(voteData);
            setVoteId(voteData[account] || -1);
            setVoteResut(true);
        });
    }

    useEffect(() => {
        resetVotes(voteData);
    }, []); 

    return (<>
        <div className="App-game-map-view-sider" style={{ width: '320px' }}>
            <div className="App-game-map-title">{ Texts['dialog.map_list'] }</div>
            <div className="App-game-map-list">
                {mapList.map((mapItem, index)=>
                <div key={ index } 
                    className={ 'App-game-map-item' + (selectMapId === mapItem.map_id ? ' App-game-map-item-selected' : '') } 
                    style={{ 
                        color: (currentMapId === mapItem.map_id ? '#54ff00' : ''),
                        display: (isProd && mapItem.map_id == 3) ? 'none' : 'block'
                    }}
                    onClick={ (e)=> {
                        setSelectMapId(mapItem.map_id);
                        onSelected(mapItem.map_id);
                    } }>
                    <span className="name">{ Texts['dialog.map.name.' + mapItem.map_id] }</span>
                    <span className="vote">{ isVote ? (votes[mapItem.map_id] || 0) : '' }</span>
                    {currentMapId === mapItem.map_id &&  <CreateIcon type="check" size="20" />}
                </div>
                )}
            </div>
            <div className="App-map-vote-desc">
                { (Texts['dialog.map.desc.' + selectMapId] || '').split('\n').map((str, index)=>{
                    return <p key={index}>{ str }</p>
                }) }
            </div>
            {isVote && <div style={{ textAlign: 'center' }}>
                {
                    (voteId < 1 && seasonStatus.apply) 
                    ? <span 
                        onClick={ (e)=>{
                            setSeasonVote();
                        } } 
                        className="App-general-btn App-general-btn-blue">
                        { Texts['button.vote'] }
                    </span>
                    : <span 
                        onClick={ (e)=>{
                            setWaitingPop(true)
                        } } 
                        className="App-general-btn App-general-btn-blue App-btn-disable">
                        { Texts['button.vote'] }
                    </span>
                }
            </div>}
        </div>

        {waitingPop  && <ToastTip text={ Texts['dialog.vote.not'] } 
        callback={ ()=>{ setWaitingPop(false) } } />}

        { notifyPop && <NotifyPop data={{ result: voteResut }} tips={{
            loading: '',
            success: Texts['dialog.vote.success'],
            fail: Texts['dialog.vote.fail']
        }} callback={ ()=>{
            setNotifyPop(false);
        }} />}
    </>);
}