import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUnionWinInfo } from '../../services/slice-map';

import { ClockCounter } from '../../components'

import { logTrace } from '../../utils/log'
import { unionImagePath } from '../../utils/ui-res'

import Texts from '../../const/texts.json';
import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function WillWin(props){
    const dispatch = useDispatch(); //must in component function
    let { remainTime, unionId, content, status } = props.data;
    status = status || 'WaitToWin';

    useEffect(() => {
        if(status === 'WaitToWin'){
            window.gamePVP && window.gamePVP.events.emit("gameout", true);            
        }
    }, []); 

    return (<>
        {(status === 'WaitToWin') && (<div className="App-modal-tips">
            {!!unionId && <img alt="" src={ unionImagePath(unionId) } style={{ 
                    borderRadius: '50%', 
                    verticalAlign:'bottom',
                    height: '22px',
                    marginRight: '10px' 
                }}/> 
            }
            { content } &nbsp;
            <ClockCounter time={ remainTime } onEnd={ ()=>{
                props.onEnd && props.onEnd();
            } } />
        </div>)}

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '140px',
                    marginTop: '-70px',
                    width: '440px',
                    marginLeft: '-220px'
                }
            }}
            className = { 'App-game-modal-willwon App-game-modal-gray-headless' }
            isOpen={ status === 'HaveWin' }
            onRequestClose = { () => { window.location.reload(true) } }
            contentLabel="Season End">
            <div style={{ position: 'relative' }} _ga_event="auction.season.win" onClick={ () => {} }>
               <div style={{ textAlign: 'center', paddingTop: '60px' }}>
                    <img alt="" src={ unionImagePath(unionId) } style={{ verticalAlign:'bottom',height: '20px',marginRight:'10px' }}/>
                    { Texts['dialog.season_victory'] }
               </div>
            </div>
        </Modal> 
    </>)
}