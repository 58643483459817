import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as DAContract from '../../da/contract';
import * as cache from '../../utils/cache'
import { dateFormat } from '../../utils/utils';
import { idDev, isProd } from '../../utils/ui-res';

export default function CreateSeason(props){
    const dispatch = useDispatch(); 

    let preEnv = isProd ? 'prod' : 'test';
    let defaultSid = preEnv + '-zeta_main-' + dateFormat(new Date(), 'yyyy-MM-dd') + '-xxx';

    const [ seasonId, setSeasonId ] = useState(defaultSid);
    const [ registryFee, setRegistryFee ] = useState('0');
    const [ value, setValue ] = useState('100000000000000000');

    let now = Math.floor(new Date().getTime()/1000);
    const [ startTs, setStartTs ] = useState(Math.floor(now));

    return (<div className="App-season-create" style={{ 
            position:'fixed', zIndex: '9999999999', width: '340px', height: '200px', 
            background: '#fff',
            color: '#333',
            padding: '20px 10px',
            bottom: '100px', right: '10px' }
        }>
        seasonId &nbsp;&nbsp;&nbsp;&nbsp;<input type="text" name="seasonId" placeholder="seasonId"
            value={ seasonId }
            style={{ width: '200px' }}
            onKeyUp= { (e)=>{
                let content = e.target.value || '';
                setSeasonId(content);
            }}
            onChange={ (e)=>{
                let content = e.target.value || '';
                setSeasonId(content);
            } 
        } /> <br /><br />

        registryFee  &nbsp;&nbsp;&nbsp;&nbsp;<input type="number" name="registryFee" placeholder="registryFee"
            value={ registryFee }
            style={{ width: '140px' }}
            onKeyUp= { (e)=>{
                let content = e.target.value || '';
                setRegistryFee(content);
            }}
            onChange={ (e)=>{
                let content = e.target.value || '';
                setRegistryFee(content);
            } 
        } /> { registryFee/10**18 } zeta <br /><br />

        reward pool &nbsp;&nbsp;<input type="text" name="value" placeholder="reward pool"
            value={ value }
            style={{ width: '180px' }}
            onKeyUp= { (e)=>{
                let content = e.target.value || '';
                setValue(content);
            }}
            onChange={ (e)=>{
                let content = e.target.value || '';
                setValue(content);
            } 
        } /> { value/10**18 } zeta <br /><br />

        start time &nbsp;&nbsp;<input type="text" name="startTs" placeholder="startTs"
            value={ startTs }
            style={{ width: '100px' }}
            onKeyUp= { (e)=>{
                let content = e.target.value || '';
                setStartTs(content);
            }}
            onChange={ (e)=>{
                let content = e.target.value || '';
                setStartTs(content);
            } 
        } /> now <br /><br />

        <button style={{ padding: '2px 10px' }}
            onClick={ async()=>{
            if(!seasonId || !startTs){
                alert('please input season id or startTs.');
                return;
            }
            if(seasonId.indexOf('x') > -1){
                alert('please input a legal seasonId.');
                return;
            }
            let address = cache.get('accountCacheKey') || '';
            let res = await DAContract.createSeason({ seasonId, startTs, address, registryFee, value });
            if(res.result){
                alert('create season ok');
            }else{
                alert('create season fail');
            }
        }}>create season</button>
    </div>);
}

