import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GoBack, CreateIcon } from '../../components'
import Texts from '../../const/texts.json';

import { numberFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function ResourceStat(props) {
    let data = props.data;

    let tdAlign = {
        textAlign: 'right'
    };

    return (<>
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '600px',
                    marginLeft: '-300px',
                    height: '370px',
                    marginTop: '-180px'
                }
            }}
            className = { 'App-game-modal-restat App-game-modal-gray' }
            isOpen={ true }
            onRequestClose={ (e)=>props.close() }
            contentLabel="Stat Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event="resource.stat.close" onClick={ (e)=>{props.close()} }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header">{ Texts['dialog.resource.info'] }</div>
                <div className="App-game-table" style={{ clear: 'both', backgroundColor: 'RGBA(27, 20, 11, 1)', borderRadius: '10px',padding: '0 20px', marginTop: '15px' }}>
                    <table className="App-Resources-content-table">
                        <thead><tr>
                            <td>{ Texts['dialog.name'] }</td>
                            <td style={tdAlign}><CreateIcon type="silver" size="20" /></td>
                            <td style={tdAlign}><CreateIcon type="troop" size="20" /></td>
                        </tr></thead>
                        <tbody>
                        <tr>
                            <td>{ Texts['dialog.have_resource'] }</td>
                            <td style={tdAlign}>{ numberFormat(data.silver.value) }</td>
                            <td style={tdAlign}>{ numberFormat(data.troop.value) }</td>
                        </tr>
                        <tr>
                            <td>{ Texts['dialog.resource_speed'] }</td>
                            <td style={tdAlign} className={ data.silver.maintain ? '': 'warning' }>{ numberFormat(data.silver.production) }/h</td>
                            <td style={tdAlign} className={ data.troop.maintain ? '': 'warning' }>{ numberFormat(data.troop.production) }/h</td>
                        </tr>
                        <tr>
                            <td>{ Texts['dialog.resource_protect'] }</td>
                            <td style={tdAlign}>{ numberFormat(data.protectSilver) }</td>
                            <td style={tdAlign}>-</td>
                        </tr>
                        <tr>
                            <td>{ Texts['dialog.maintain_city'] }</td>
                            <td style={tdAlign}>-</td>
                            <td style={tdAlign}>{ numberFormat(data.maintainNeedTroop) }</td>
                        </tr>
                        <tr>
                            <td>{ Texts['dialog.troop_consume'] }</td>
                            <td style={tdAlign}>{ numberFormat(data.troopUseSilver) }/h</td>
                            <td style={tdAlign}>-</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <span style={{ position: 'relative', top: '-3px' }}><CreateIcon type="info-tips" size="20" /> </span>
                    <span style={{ lineHeight: 2, marginLeft:"5px" }}>{ Texts['popup.troop_attention.title'] }</span>
                </div>
            </div>
        </Modal>
    </>);
}