import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { numberFormat } from '../utils/utils'

export default function Slider(props){
    const dispatch = useDispatch(); 

    const { total, unit = 5, value = 0, disable = 0 } = props;

    let pct = value;
    if(total === 0){
        pct = 0;
    }
    const [sliderValue1, setSliderValue1] = useState(pct); //percents

    function setSliderValueClick(value){
        value = Math.min(value, 100);
        value = Math.max(value, 0);
        setSliderValue1(value);
    }
    
    useEffect(() => {
        setSliderValue1(pct);
    }, [value]);
    useEffect(() => {
        props.onChange && props.onChange(sliderValue1);
    }, [sliderValue1]);

    return (<>
        <div className="App-slider-wrapper">
            {!!disable && <div className="App-slider-wrapper-mask" />}
            <div className="App-slider-value">{ numberFormat(Math.floor(total*sliderValue1/100)) }</div>
            <div className="App-progress" style={{ margin: '0 60px' }}>
                <div className="App-progress-blue" style={{ width: sliderValue1 + '%' }}></div>
            </div>
            <div className="App-slider" style={{ margin: '0 20px' }}>
                <input type="range" max="100" min="1" value={ sliderValue1 } onChange={ (e)=> { 
                    setSliderValue1(e.target.value) 
                }} />
            </div>
            <div className="App-slider-minis" onClick={()=>{ 
                setSliderValueClick(sliderValue1 - unit) 
            }}></div>
            <div className="App-slider-plus" onClick={()=>{ 
                setSliderValueClick(sliderValue1 + unit) 
            }}></div>
        </div>
    </>);
}


