import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter, HashRouter,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";

import DutchAuctionSeason from './dutch-auction';

import SeasonAll from './season-list/season-all';
import DiscordWidget from './discord/discord';

var qs = window.location.search;
var isMain = qs.indexOf('?sid=') === -1 && qs.indexOf('?sid=') === -1;

function DutchAuctionApp(props) {
    const dispatch = useDispatch(); 

    const { gameStart } = useSelector(function(state){
        return state.dauction;
    });

    useEffect(() => {
        document.title += ' ' + window.LeagueOfThronesConfig.version;
    }, []); 

    if(gameStart.isStart){
        return (<DutchAuctionSeason />);
    }

    return (<>
        <div className="App" id="App">
            <SeasonAll />
        </div>
    </>);
}

export default DutchAuctionApp;
