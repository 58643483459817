import React, { useEffect, useState } from 'react';
import Loading from './loading'
import ToastTip from './toast-tip'

export default function NotifyPop(props){
    let { data, tips } = props;
    // console.log({ data, tips });

    useEffect(()=>{
        if(data.hasOwnProperty('result')){
            setTimeout(function(){
                props.callback();
            }, 2000);
        }
    }, []);

    if(!data.hasOwnProperty('result')){
        return (
            <Loading type="headless" tip={ tips.loading || 'Loading……' } />
        );   
    }

    return (
        <ToastTip data={ data } text={ data.result ? tips.success : tips.fail } callback={ ()=>{
            props.callback();
        }} />
    );
}