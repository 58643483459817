import Phaser from 'phaser';
import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SceneBase from '../map/scene-base';
import { getMapConfig } from '../../maps/map-res'

export default function SeasonMap(props){
    const dispatch = useDispatch(); 
    const { mapInfo } = props;
    const mapId = mapInfo['map_result'] || 1;
    const mapCanvasId = 'App-phaser-parent-' + mapId + Math.ceil(Math.random()*9876543);

    let mapConfig = getMapConfig(mapId);

    let isUseImage = true;

    useEffect(() => {
        if(isUseImage){
            return;
        }
        gameRender(); 

        return (()=>{
            window.gamePreview && window.gamePreview.destroy();
        });
    }, []); 

    function gameRender(){
        const config = {
            callbacks: {
                preBoot: (game)=> {
                },
            },
            type: Phaser.CANVAS,
            disableContextMenu: true,
            title: 'League of Thrones',
            parent: mapCanvasId,
            width: mapConfig.width,
            height: mapConfig.height,
            dom: {
                createContainer: true
            },
            transparent: true,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 100 },
                    debug: false
                }
            },
            scene: [ new SceneBase({occupieds: {}, codsAll: {}, mapConfig: mapConfig}, true) ]
        }
        window.gamePreview = new Phaser.Game(config);
    }

    if(!isUseImage){
        return (<>  
            <div className={"App-seaon-map-canvas App-seaon-map-canvas" + mapId} id={ mapCanvasId }></div> 
        </>)
    }

    return (<>  
        <div className="App-season-map-canvas App-seaon-map-canvas-rotate" id={ mapCanvasId }>
            <img src={ mapConfig.mapCover } />
        </div> 
    </>)
}