import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rewardConfig } from 'throne-underlying';

import { resourceRefresh } from '../../services/slice-city'
import { getBuyStrategyPointNeed, buyStrategyPoint, resetBuyResult, refreshPoints } from '../../services/slice-strategies'
import { setShareRecord, getShareRecords } from '../../services/slice-user'

import SnShare from '../share/share'

import { GoBack, CreateIcon, ClockCounter, NotifyPop, ToastTip } from '../../components'

import Texts2 from '../../const/texts2.json';
import AppGame from '../../const/app.json';

import { logTrace } from '../../utils/log'
import { subs, timeFormat, dateFormat } from '../../utils/utils'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function StrategyPoints(props) {
    const dispatch = useDispatch(); 
    let { golds } = props.data;

    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const { pointInfo, strategyReady, buyResult, pointNeed, remainTime } = useSelector(function(state){
        return state.strategies;
    });
    logTrace({ buyResult, pointNeed, pointInfo, remainTime }, 'strategy.points')
    let maxPointToday = pointInfo.maxBuyTimes - pointInfo.buyTimes; //wrong def;
    let maxPointUser = pointInfo.maxStrategyPoint - pointInfo.strategyPoint;
    let maxPoint = Math.min(maxPointToday, maxPointUser);
    const [point, setPoint] = useState(maxPoint);
    function setSliderValueClick(value){
        value = Math.min(value, maxPoint);
        value = Math.max(value, 0);
        setPoint(value);
    }

    function cancelSelectModal(){
        props.onClose();
    }

    const [isTipPop, setIsTipPop] = useState('');
    const [sharePop, setSharePop] = useState(false);
    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    useEffect(()=>{
        dispatch(getBuyStrategyPointNeed({
            amount: point
        }));
        dispatch(refreshPoints());
    }, [dispatch, strategyReady, point]);

    useEffect(()=>{
        dispatch(resourceRefresh());
        dispatch(refreshPoints());
        setPoint(maxPoint);
    }, [ dispatch, maxPoint, buyResult ]);


    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { shareRecords } = useSelector(function(state){
        return state.user;
    });

    useEffect(()=>{
        dispatch(getShareRecords({ 
            address: currentUser.username,
            seasonId: currentSeasonId,
            season_id: currentSeasonId
        }));
    }, [sharePop]);

    function isSharedToday(){
        // const oneDaySeconds = 24*60*60;
        const shareTime = shareRecords.accquire_energy || 0;
        const today = dateFormat(new Date(), 'yyyy-MM-dd');
        var remainTime = (new Date(today + ' 00:00:00').getTime()/1000 + 24*60*60 + new Date().getTimezoneOffset()*60) - shareTime;
        if(remainTime < 0){
            remainTime += 24*60*60;
        }
        return {
            isShared: shareTime > 0 && remainTime < 24*60*60,
            remainTime: remainTime
        };
    }
    // console.log(isSharedToday(), shareRecords, 'isSharedToday')

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'transparent' },
            content: {
                width: '580px',
                height: '350px',
                marginLeft: '-280px',
                marginTop: '-170px'
            }
        }}
        className = { 'App-game-modal-avatar-point App-game-modal-gray' }
        isOpen={ true }
        onRequestClose={ ()=>{ cancelSelectModal() } }
        contentLabel="SelectGeneral Modal">
        <div style={{ position: 'relative',height:'500px' }}>
            <div _ga_event="user.policy.close" onClick={ ()=>{ cancelSelectModal(); }}>
                <GoBack size="60px" />
            </div>
            <div className="App-game-modal-header" style={{ lineHeight: 2.4 }}>{ Texts2['dialog.stamina_buy'] }</div>

            <div className="App-policy-points-title">
                <span className="App-policy-points-item">
                    { Texts2['dialog.stamina_buy_limit'] }:&nbsp;
                    <span className="time"><em>{ pointInfo.buyTimes/1 }</em>/{ pointInfo.maxBuyTimes }</span>
                    <span style={{ top: '-2px', left: '5px', position: 'relative' }} _ga_event="user.policy.buy_time" onClick={ ()=> { setIsTipPop('buyTimes') }}>
                        <CreateIcon type="info-tips" size="18" />
                    </span>
                    {isTipPop === 'buyTimes' && <ShowTips 
                        updateTime={ pointInfo.buyTimesLastUpdate }
                        onClose={ ()=>{ setIsTipPop('') } } 
                    />}
                </span>
                <span className="App-policy-points-item">
                    { Texts2['dialog.stamina_recover_time'] }:&nbsp;
                    <span className="time"><ClockCounter time={ remainTime } /></span>
                </span>
            </div>

            <div className="App-policy-points-panel">
                <div style={{ color: '#FFF8CA' }}>{ Texts2['dialog.stamina_buy'] }</div>
                <div style={{ position: 'relative',margin: '30px auto 10px', paddingBottom: '5px', width:'220px' }}>
                    <div className="App-slider-minis" _ga_event="user.policy.slider.minis" onClick={()=>{ setSliderValueClick(point - 1) }}></div>
                    <input type="number" maxLength="3" min="1" value={ point } disabled={ point === 0 } onChange={ (e)=> { setPoint(e.target.value) }} />
                    <div className="App-slider-plus" _ga_event="user.policy.slider.plus" onClick={()=>{ setSliderValueClick(point + 1) }}></div>
                </div>
            </div>
            
            <div className="App-point-btns" style={{ textAlign: 'center' }}>
                <div className="App-point-btn-item">
                    <span className={"App-general-btn App-general-btn-blue" + ((isSharedToday().isShared || maxPointUser === 0) ? ' App-btn-disable' : '')}
                        _ga_event="user.policy.share"
                        onClick = { (e) => { 
                            if(isSharedToday().isShared || maxPointUser === 0){ return; }
                            setSharePop(true);
                        }}>
                        { Texts2['dialog.order.free_stamina'] } 
                    </span>
                    <div style={{ paddingTop: '10px' }}>{
                        isSharedToday().isShared ?
                        <ClockCounter time={ isSharedToday().remainTime } onEnd={ ()=>{} } />
                        : <>{ Texts2['dialog.order.free_stamina_desc'] }</>
                    }</div>
                </div>

                {sharePop && <SnShare 
                    content={<div className="App-sns-share">
                        <div className="intro">{ Texts2['dialog.order.free_stamina_content'] }</div>
                        <div>
                            <span style={{ marginTop: '-23px', display: 'inline-block' }}>
                                <CreateIcon type="lighting" size="22" />
                            </span>
                            <span className="App-item-value" style={{ paddingLeft: '10px' }} >
                                { rewardConfig.stamina_share.value }
                            </span>
                        </div>
                    </div>}
                    params = { AppGame }
                    facebook={ AppGame.prod }
                    twitter={ Texts2['dialog.share.content.stamina'] } 
                    onShare={ (data)=>{
                        dispatch(setShareRecord({
                            address: currentUser.username,
                            action: 'accquire_energy',
                            platform: data.platform,
                            seasonId: currentSeasonId,
                            season_id: currentSeasonId
                        }));
                    }}
                    onClose={ ()=>{ 
                        setSharePop(false);
                        dispatch(refreshPoints());
                    } }
                />}

                <div className="App-point-btn-item">
                {(point/1 === 0 || golds < pointNeed ||  pointInfo.strategyPoint >= pointInfo.maxStrategyPoint ) 
                ? (<span 
                    _ga_event="user.policy.buy.disable"
                    onClick={ ()=>{
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    { Texts2['dialog.stamina_buy'] } 
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="user.policy.buy"
                    onClick = { (e) => { 
                        setWaitingPop(true);
                        dispatch(buyStrategyPoint({amount: point}));
                    }}>
                    { Texts2['dialog.stamina_buy'] } 
                </span>)}
                <div style={{ textAlign: 'center',zoom:0.8}}>
                    <span style={{ marginTop: '15px', display: 'inline-block' }}>
                        <CreateIcon type="coin" size="32" />
                    </span> { pointNeed }
                </div>
                </div>
            </div>
        </div>

        { notifyPop && <ToastTip text={ 
            Texts2['dialog.order.buy_order_select_quantity'] 
        } callback={ ()=>{ setNotifyPop(false) } } />}

        { waitingPop && <NotifyPop tips={{
            loading: '',
            success: Texts2['dialog.order.buy_order_success'],
            fail: Texts2['dialog.order.buy_order_fail']
        }} data={ buyResult } callback={ ()=>{
            setWaitingPop(false);
            dispatch(resetBuyResult());
        }} />}
    </Modal>);
}

function ShowTips(props){
    let { updateTime, onClose } = props;
    let remainTime = 24*60*60 - updateTime%(24*60*60);
    return (
        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'rgba(0,0,0,0.2)'
                },
                content: {
                    height: '200px',
                    width: '420px',
                    marginTop: '-100px',
                    marginLeft: '-200px'
                } 
            }}
            className = { 'App-game-modal-tips App-game-modal' }
            isOpen = { true }
            onAfterOpen={ ()=>{} }
            onRequestClose={ ()=>{ onClose(''); } }
            contentLabel="Troops Tips Modal">
            <div style={{ position: 'relative', paddingTop: '2rem' }}>
                <div _ga_event="user.policy.tips.close" onClick={ ()=>onClose('') }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ color: '#fff' }}> { 'purchase stamina times'.toUpperCase() }</div>
                <div className="App-game-season-rule-pop">
                    { Texts2['dialog.stamina.purchase_desc'] }
                    {updateTime > -1 && <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                        Update times in: <ClockCounter time={ remainTime } />
                    </div>}
                </div>
            </div>
        </Modal>
    )
}


