import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CreateIcon from './icons'

import { Texts, Texts2, Chains, RewardCoins } from '../const/';

import { numberFormat, numberFormatDicimal } from '../utils/utils'
import { unionImagePath, getNickName } from '../utils/ui-res'
import * as cache from '../utils/cache'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Ranks(props){
    let ranks = props.data;
    let seasonId = props.seasonId;

    const { seasonDetail, currentSeasonId } = useSelector(function(state){
        return state.user;
    });
    let currentSeasonDetail = seasonDetail[currentSeasonId || seasonId] || {};
    let seasonBasicInfo = currentSeasonDetail['seasonBasicInfo'] || {};
    // console.log({seasonDetail, currentSeasonDetail, seasonBasicInfo, seasonId, currentSeasonId});
    const chainName = seasonBasicInfo.chain;
    const reward_token_addr = (seasonBasicInfo['reward_token_addr'] || '').toLowerCase();
    const rewardSymbol = (RewardCoins[reward_token_addr] || {}).reward || (Chains[chainName] || {}).reward || '';

    const { allPlayers } = useSelector(function(state){
        return state.user;
    });

    function show2percent(number){
        let times = 100;

        number = number || 0;
        let n = Math.ceil(number*times)/times;
        return n;
    }

    return (<>
        <div className="App-game-modal-content1" style={{ padding: '0', height: '300px', overflow: 'auto' }}>
            <div className="App-game-table App-game-table-rank"><table>
                <thead><tr>
                    <td className="center rank">{ Texts['dialog.rank'] }</td>
                    <td className="player">{ Texts['dialog.player'] }</td>
                    <td className="center">{ Texts2['dialog.camp'] }</td>
                    <td className="right">{ Texts['dialog.glory'] }</td>
                    <td className="right reward">{ Texts['dialog.reward'] }</td>
                </tr></thead>
                <tbody>
                {ranks.map((item, index) => (
                    <tr key={ 'rank' + index }>
                        <td className="center"><span className="index">{ index + 1 }</span></td>
                        <td><span style={{ display:'inline-block', width:'9.2em',overflow:'hidden',textOverflow:'ellipsis' }} title={ item.username }>
                            { getNickName(allPlayers, item.username) }
                        </span></td>
                        <td className="center" style={{ paddingTop: '5px' }}> 
                            <img alt="" style={{ width: '20px', borderRadius: '50%' }} src={ unionImagePath(item.unionId) } /> 
                        </td>
                        <td className="right"> { item.glory || 0 } </td>
                        <td className="right reward"> 
                            <span style={{ position: 'relative', top: '-3px' }}><CreateIcon type={ rewardSymbol } size="22" /></span>&nbsp;
                            <span style={{ display: 'inline-block', paddingTop: '5px'}}>x { rewardSymbol === 'usdt' ? show2percent(item.count || (item.reward || {}).count || 0) : numberFormatDicimal(item.count || (item.reward || {}).count, 2) }</span> 
                        </td>
                    </tr>)
                )}
                </tbody>
            </table></div>
        </div>
    </>);
}