import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSeasonList } from '../../services/slice-user';
import { mapInit, getSeasonInfo, getSeasonConfig, getSeasonRankResult, getExpectUnionReward } from '../../services/slice-map';
import { getGloryAndRank } from '../../services/slice-general';

import { GoBack, CreateIcon, ClockCounter, Ranks } from '../../components'

import { Texts, Texts2, Chains, RewardCoins } from '../../const/';
import mapImage from './assets/map.png';
import './season-rule.scss';

import { numberFormat, timeFormat, dateFormat, createRadarSvg } from '../../utils/utils'
import * as cache from '../../utils/cache'
import { logTrace } from '../../utils/log'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SeasonRule(props){
    const dispatch = useDispatch(); 

    const [rewardTip, setRewardTip] = useState(false);
    const [isRankPop, setIsRankPop] = useState(false);
    const [isTipPop, setIsTipPop] = useState('');

    const { seasonDetail } = useSelector(function(state){
        return state.user;
    });
    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    let currentSeasonDetail = seasonDetail[currentSeasonId] || {};
    const seasonBasicInfo = currentSeasonDetail.seasonBasicInfo;
    const chainName = seasonBasicInfo.chain;
    const reward_token_addr = seasonBasicInfo['reward_token_addr'].toLowerCase();
    const rewardSymbol = (RewardCoins[reward_token_addr] || {}).reward || Chains[chainName].reward;

    const { seasonConfig, seasonRank } = useSelector(function(state){
        return state.map;
    });
    logTrace({ seasonConfig, seasonRank }, 'season');

    const { generalReady, seasonGlory } = useSelector(function(state){
        return state.generals;
    });

    let result = getSeasonClock(seasonConfig);

    /*
    season_end: 1661853600
    season_open: 1660557600
    season_ready: 1660554000
    season_reservation: 1660514400
    */ 
    function getSeasonClock(data){
        let { season_end, season_open, season_ready, season_reservation } = data;
        let timeNow = new Date().getTime();

        let result = {};

        timeNow = timeNow/1000;
        if(timeNow < season_ready){
            result = {
                toReady: true,
                title: Texts['dialog.season.announcement']
            };
        }else if(timeNow < season_open){
            result = {
                toOpen: true,
                title: Texts['dialog.season.start_ready']
            }
        }else if(timeNow < season_end){
            result = {
                opening: true,
                title: Texts['dialog.season.open']
            }
        }else{
            result = {
                end: true,
                title: Texts['dialog.season.end']
            }
        }
        return result;
    }

    function getTimeDiff(time){
        let n = new Date().getTime();
        return time - n/1000;
    }

    useEffect(()=>{
        // dispatch(mapInit());
        dispatch(getSeasonList());
        dispatch(getSeasonRankResult());
        dispatch(getSeasonConfig());
        dispatch(getGloryAndRank());
        return () => {
        }
    }, []);


    const [isConquestRankPop, setIsConquestRankPop] = useState(false);
    const priceUnit = 1000000;
    const { rewardExpect } = useSelector(function(state){
        return state.map;
    });
    let { myInfo = {}, topInfo = [] } = rewardExpect;
    let conquestRank = [];
    topInfo.forEach(function(item){
        let _item = JSON.parse(JSON.stringify(item));
        _item.count = _item.count || _item.reward;
        // _item.count = _item.count/priceUnit;
        conquestRank.push(_item);
    });
    // console.log('rewardExpect', rewardExpect, conquestRank, topInfo);
    useEffect(()=>{
        dispatch(getExpectUnionReward(chainName));
        return () => {
        }
    }, []);

    return (<>
        <div className="App-game-modal-header">{ result.title }</div>
        <div className="App-game-season-rule-wrapper">
            <div className="App-game-season-rule">
                <img src={ mapImage } />
                <div className="App-game-season-title">{ Texts['dialog.season.introduction'] }</div>
                <p>1. { Texts['dialog.season.introduction.desc_1'] }</p>
                <p>2. { Texts['dialog.season.introduction.desc_2'] }</p>
                <p>3. { Texts['dialog.season.introduction.desc_3'] }</p>
            </div>

            <div className="App-game-season-rank">
                {result.toReady && (<div className="App-game-season-clockwise">
                    { Texts['dialog.season.announcement'] } : &nbsp;
                    <span className="time">
                        { dateFormat(seasonConfig.season_open*1000, 'M.d') } - 
                        { dateFormat(seasonConfig.season_end*1000, 'M.d') }
                    </span>
                </div>)}
                {result.toOpen && (<div className="App-game-season-clockwise">
                    { Texts['dialog.season.start_countdown'] } : &nbsp;
                    <span className="time">
                        <ClockCounter time={ getTimeDiff(seasonConfig.season_open) } />
                    </span>
                </div>)}
                {result.opening && (<div className="App-game-season-clockwise">
                    { Texts['dialog.season.close_countdown'] } : &nbsp;
                    <span className="time">
                        <ClockCounter time={ getTimeDiff(seasonConfig.season_end) } />
                    </span>
                </div>)}

                <div className="App-game-season-rule-item">
                    <div className="App-game-season-rule-intro">
                        <span className="tips" _ga_event="season.rule.victory" onClick={ ()=> { setIsTipPop('victory') }}>
                            <span style={{ top: '-2px', left: '-2px', position: 'relative' }}>
                                <CreateIcon type="info-tips" size="18" />
                            </span> 
                            { Texts['dialog.season.info'] }
                        </span>
                        <span className="title">{ Texts['dialog.season_activity.victory_reward.name'] }</span>
                        <div className="App-game-season-rule-text">
                            { Texts['dialog.season_activity.victory_reward.desc'] }
                        </div>
                    </div>
                    <div className="App-game-season-rule-reward">
                        <CreateIcon type={ rewardSymbol } size="60" />
                        <span className="total">{ numberFormat(seasonBasicInfo.reward_amount_1 || 0) }</span> 
                    </div>
                </div>

                <div className="App-game-season-rule-item">
                    <div className="App-game-season-rule-intro">
                        <span className="tips" _ga_event="season.rule.glory_rank" onClick={ ()=> { setIsTipPop('glory_rank') }}>
                            <span style={{ top: '-2px', left: '-2px', position: 'relative' }}>
                                <CreateIcon type="info-tips" size="18" />
                            </span> 
                            { Texts['dialog.season.info'] }
                        </span>
                        <span className="title">{ Texts['dialog.season_activity.glory_rank_reward.name'] }</span>
                        <div className="App-game-season-rule-text">
                            { Texts['dialog.season_activity.glory_rank_reward.desc'] }
                        </div>
                    </div>
                    <div className="App-game-season-rule-reward">
                        <CreateIcon type={ rewardSymbol } size="60" />
                        <span className="total">{ numberFormat(seasonBasicInfo.reward_amount_2 || 0) }</span>
                    </div>
                </div>


                <div className="App-game-season-data">
                    <label>{ Texts['dialog.season.my_glory'] }</label> 
                    <span>{ numberFormat(seasonGlory.glory) }</span> 

                    <label>{ 'Conquest rank' }</label> 
                    <span>{ numberFormat(myInfo.rank) }</span> 

                    <label>{ Texts['dialog.season.my_glory_rank'] }</label> 
                    <span>{ numberFormat(seasonGlory.rank) }</span> 
                </div>

                {currentSeasonDetail.status !== 'end1' && (<div style={{ textAlign: 'center' }}>
                    <span
                        style={{ marginRight: '2rem' }} 
                        className="App-general-btn App-general-btn-blue" 
                        _ga_event="season.rule.conquest_rank"
                        onClick={()=>setIsConquestRankPop(true)}>
                        { 'Conquest rank' }
                    </span>
                    <span className="App-general-btn App-general-btn-blue" 
                        _ga_event="season.rule.glory_rank" 
                        onClick={()=>setIsRankPop(true)}>
                        { Texts['button.season_activity.glory_rank_reward.check'] }
                    </span>
                </div>)}
                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    <span style={{ top: '-2px', right: '5px', position: 'relative' }}>
                        <CreateIcon type="info-tips" size="24" />
                    </span> 
                    <span className="App-tips-warning">{ Texts2['dialog.received_reward_attention'] }</span>
                </div>
            </div>
        </div>

        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'rgba(0,0,0,0.2)'
                },
                content: {
                    height: '240px',
                    width: '400px',
                    marginTop: '-120px',
                    marginLeft: '-200px'
                } 
            }}
            className = { 'App-game-modal-tips App-game-modal' }
            isOpen = { !!isTipPop }
            onAfterOpen={ ()=>{} }
            onRequestClose={ ()=>{ setIsTipPop(''); } }
            contentLabel="Troops Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event="season.rule.tips.close" onClick={ ()=>setIsTipPop('') }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ color: '#fff', paddingTop: '1.5rem' }}> { Texts['dialog.tip'] }</div>
                <div className="App-game-season-rule-pop">
                { (Texts['dialog.season_activity.' + isTipPop + '_reward.desc'] || '').split('\\n').map((str, index)=>{
                    return <p key={index}>{ str }</p>
                }) }

                { isTipPop === 'victory' && <div style={{ paddingTop: '2rem' }}>
                        <span style={{ top: '-2px', right: '5px', position: 'relative' }}>
                            <CreateIcon type="info-tips" size="24" />
                        </span> 
                        <span className="App-tips-warning">{Texts['dialog.season_activity.no_victory_reward']}</span>
                    </div> }
                </div>
            </div>
        </Modal>

        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'rgba(0,0,0,0.2)' 
                },
                content: {
                    height: '420px'
                }
            }}
            className = { 'App-game-modal-strategy App-game-modal-gray' }
            isOpen={ isRankPop }
            onRequestClose = { () => setIsRankPop(false) }
            contentLabel="Preview Map Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event="season.rule.rank.close" onClick={ () => setIsRankPop(false) }><GoBack size="60px" /></div>
                <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>{ 'Glory Rank' }</div>
                <Ranks data={ seasonRank } />
                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    <span style={{ top: '-2px', right: '5px', position: 'relative' }}>
                        <CreateIcon type="info-tips" size="24" />
                    </span> 
                     <span className="App-tips-warning">{ Texts2['dialog.received_conquest_reward_attention'] }</span>
                </div>
            </div>
        </Modal>

        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'rgba(0,0,0,0.2)' 
                },
                content: {
                    height: '420px'
                }
            }}
            className = { 'App-game-modal-strategy App-game-modal-gray' }
            isOpen={ isConquestRankPop }
            onRequestClose = { () => setIsConquestRankPop(false) }
            contentLabel="Preview Map Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event="season.rule.conquest_rank.close" onClick={ () => setIsConquestRankPop(false) }><GoBack size="60px" /></div>
                <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>{ 'Conquest Rank' }</div>
                <Ranks data={ conquestRank } />
                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    <span style={{ top: '-2px', right: '5px', position: 'relative' }}>
                        <CreateIcon type="info-tips" size="24" />
                    </span> 
                    <span className="App-tips-warning">{ Texts2['dialog.received_conquest_reward_attention'] }</span>
                </div>
            </div>
        </Modal>
    </>);
}