import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import * as cache from '../../utils/cache'
import { numberFormat, timeFormat, subs, getStamina } from '../../utils/utils'

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { Texts } from '../../const/';

import { getMapId, getMapConfig } from '../../maps/map-res'

import Attack from './attack'
import Defense from './defense'

export default function BlockBtns(props) {
    const dispatch = useDispatch(); 
    // console.log('props.tile', props)

    const { detail, tileData } = props;

    const { sign, seasonDetail } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    const mapId = getMapId(seasonDetail[currentSeasonId].seasonBasicInfo);
    const mapConfig = getMapConfig(mapId);

    const xyIndex = (tileData.xyIndex || '').split('^');
    const x_id = xyIndex[0]/1;
    const y_id = xyIndex[1]/1;

    const isInitBlock = (Math.abs(x_id) === mapConfig.cols - 1 && Math.abs(y_id) === (mapConfig.rows - 2)/2);

    const { currentUser } = useSelector(function(state){
        return state.app;
    });

    const myUnionId = currentUser.unionId;
    const occupiederUnionId = (detail.belong || {}).unionId || 0;
    let isNotMine = myUnionId !== occupiederUnionId;

    return (<>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <div className="App-Resources-lasting">
                <span style={{ position: 'absolute', left: '-10px', top: '5px', zIndex: 99 }}>
                    <CreateIcon type="tile-lasting" size="40" />
                </span>
                <div className="App-Resources-lasting-title">
                    { Texts['dialog.block_attack.block_duration'] }
                </div>
                <ProgressBar data={{ 
                    text: numberFormat(detail.now_durability) + '/' + numberFormat(detail.durability), 
                    value: 100*detail.now_durability/detail.durability
                }} type="dark" />
                {detail.protect_time > 0 && (<>
                    <div className="App-Resources-lasting-tips">
                        { Texts['dialog.block_attack.block_protect_time_left'] }: &nbsp;
                        <ClockCounter time={ detail.protect_time } />
                    </div>
                    <div className="App-Resources-lasting-text">
                        { Texts['dialog.block_attack.block_be_protect'] }
                    </div>
                </>)}
            </div>

            <Attack detail={ detail } tileData={ tileData } />
            
            <Defense detail={ detail } tileData={ tileData } /> 
        </div>
    </>);
}