import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';

import { getVipSilverBuff } from 'throne-underlying';

import { getUserLevel } from '../../services/slice-user'
import { getGold } from '../../services/slice-city'
import { setUserAvatar, getUserAvatar, getUserVipBuffs } from '../../services/slice-general'
import { pointUpdate, refreshPoints } from '../../services/slice-strategies'

import Generals from '../generals/general-list'
import StrategyPoints from '../strategies/points';
import PlayerPop from './player-pop';

import { CreateIcon, ToastTip } from '../../components'

import { unionImagePath, generalImagePath, getNickName} from '../../utils/ui-res'
import * as cache from '../../utils/cache'
import { numberFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import Texts2 from '../../const/texts2.json';
import './current-user.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function CurrentUser(props){
    const dispatch = useDispatch(); 
    const [userInfoPop, setUserInfoPop] = useState(false);
    const [strategyPointPop, setStrategyPointPop] = useState(false);
    const [userLevelPop, setUserLevelPop] = useState(false);

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { allPlayers } = useSelector(function(state){
        return state.user;
    });

    const loginUserUnionId = cache.get('LoginUserUnionId') || '';
    // console.log(currentUser);
    const { userAvatar, userVipBuffs } = useSelector(function(state){
        return state.generals;
    });
    const { strategyReady, pointInfo } = useSelector(function(state){
        return state.strategies;
    });
    const { golds } = useSelector(function(state){
        return state.city;
    });
    // console.log(pointInfo)

    useEffect(()=>{
        dispatch(getUserAvatar());
        dispatch(getGold());
        dispatch(pointUpdate());
    }, [strategyReady]);

    useEffect(()=>{
        dispatch(refreshPoints());
    }, [strategyPointPop]);

    const [ notifyPop, setNotifyPop ] = useState(false);

    const { vipBuffs = {}} = userVipBuffs;

    useEffect(()=>{
        dispatch(getUserVipBuffs({ 
            username: currentUser.username 
        }));
    }, [currentUser.username]);

    return(<>
        <div className="App-userInfo">
            <div className="App-general-item">
                <span className="App-general-item-avatar" _ga_event="user.avatar" onClick={ ()=>{
                    setUserInfoPop(true);
                } }>
                    <img src={ generalImagePath({generalId: userAvatar}) } />
                </span>
                <img className="union" src={ unionImagePath(loginUserUnionId || currentUser.unionId) } title={ currentUser.username }/>
                <span className="App-game-modal-title name">
                    { getNickName(allPlayers, currentUser.username) }
                </span>
                <span className="App-game-modal-title policy-point" _ga_event="user.policy" onClick={ ()=>{
                    setStrategyPointPop(true);
                } }>
                    <span style={{ position: 'absolute', top: '-3px',left: '28px' }}>
                        <CreateIcon type="lighting" size="30" />
                    </span>
                    <span>{ numberFormat(pointInfo.strategyPoint) }/{ pointInfo.maxStrategyPoint }</span> 
                    <div className="App-slider-plus"></div>
                </span>

                <span className="App-game-modal-title policy-point App-user-level" _ga_event="user.level" onClick={ ()=>{
                    setUserLevelPop(true);
                } }>
                    V{ vipBuffs.vip_level }
                </span>
                {userLevelPop && <PlayerPop userInfo={ currentUser } onClose={()=>{
                    setUserLevelPop(false);
                }} />}
            </div>
        </div>
        { userInfoPop && <Generals userAvatar={ userAvatar } onConfirm={ (generalId)=>{
            if(generalId){
                dispatch(setUserAvatar({
                    avatar: generalId
                })); 
            }
            setUserInfoPop(false);
        } } />}
        { notifyPop && <ToastTip text={ 
            Texts2['dialog.change_hero_head']
        } callback={ ()=>{ setNotifyPop(false) } } />}

        { strategyPointPop && <StrategyPoints data={ {pointInfo, golds } } onClose={ ()=>{
            setStrategyPointPop(false);
        } } />}
    </>);
}