import React, { useEffect, useState } from 'react';

import backImg from './btn/btn-back.png';

export default function GoBack(props){
    let size = props.size;
    let styles = {
        width: size, 
        height: size, 
        cursor: 'pointer',
        position: 'absolute',
        left: '0',
        top: '-10px'
    }
    return (
        <img src={ backImg } style={ styles } />
    );
}