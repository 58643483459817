import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Texts } from '../const/';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function DisConnect(props){
    const dispatch = useDispatch();
    const { wsErr = {} } = props;
    function reloadPage(){
        props.refresh();
    }

    useEffect(()=>{
        return ()=>{
        }
    }, []);

	return(<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 999999999999 },
            content: {
                height: '200px',
                width: '400px',
                margin: '-50px 0 0 -200px'
            }
        }}
        className = { 'App-game-modal-gray-disconnect App-game-modal-gray-headless' }
        isOpen={ true }
        onRequestClose = { () => reloadPage() }
        contentLabel="DisConnect">
        <div style={{ position: 'relative' }}>
            <div style={{ paddingTop: '60px', textAlign: 'center' }}>
                <div style={{ lineHeight: 3 }}>
                { 
                    wsErr.TransID === 'kickout' 
                    ? Texts['dialog.account.login_elsewhere'] 
                    : 'Server Disconnected, reconnecting....'
                }
                </div>
                {wsErr.TransID === 'kickout' && <span onClick={ () => reloadPage() }
                    className="App-general-btn App-general-btn-green">
                    Reload
                </span>}
            </div>
        </div>
    </Modal>);
}