import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createList, randomStr, randomArray } from '../../utils/create-data';
import { generalImagePath } from '../../utils/ui-res'

import { 
    mapInit, getOccupiedTiles, getTileInfo, 
    getDefenseList, defenseTile, defenseTileCancel, resetDefenseCancelResult
 } from '../../services/slice-map'

import { 
    generalInit,
    getDefenseBlockGenerals,
    getCodGeneralIds
} from '../../services/slice-general'

import { NotifyPop, ToastTip, ClockCounter } from '../../components'

import './generals.scss';
import { Texts, Texts2, CodTexts } from '../../const/';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Generals(props){
    const dispatch = useDispatch(); 

    const { defenseCancelResult } = useSelector(function(state){
        return state.map;
    });

    let { codGeneralIds = {}, defenseBlockGenerals } = useSelector(function(state){
        return state.generals;
    });

    const [blockOp, setBlockOp] = useState(false);
    const [general, setGeneral] = useState({});
    let removeGeneralId = '';
    let list = [];
    defenseBlockGenerals.forEach(function(item){
        // let id = (defenseCancelResult.data || {}).generalId || '';
        // if(item.generalId !== id){
            list.push(item);
        // }
    });

    // list = defenseBlockGenerals;
    for(let generalId of Object.keys(codGeneralIds)){
        list.push(codGeneralIds[generalId]);
    }
    // console.log('codGeneralIds', codGeneralIds, defenseBlockGenerals, list)

    function toDefensingTile(){
        let openTile = props.openTile;

        let x_id = general.x_id;
        let y_id = general.y_id;

        const screenW = Math.max(document.documentElement.clientWidth, 800);
        const screenH = Math.max(document.documentElement.clientHeight, 500);
        let scrollX = x_id*128/2 - screenW/2;
        let scrollY = (21 - y_id)*128*0.875/2 - screenH/2;
        // window.game.events.emit("mapMove", {
        //     scrollX: scrollX,
        //     scrollY: scrollY
        // });

        // console.log('ssss', y + '-' + x);

        setTimeout(function(){
            openTile({
                x: x_id,
                y: y_id,
                xyIndex: x_id + '^' + y_id,
                target: {}
            });
        },100);
    }

    function informCancelDefense(){
        removeGeneralId = general.generalId;
        dispatch(defenseTileCancel({
            x: general.x_id,
            y: general.y_id,
            generalId: general.generalId
        }));
    }

    const [waitingPop, setWaitingPop] = useState(false);
    // console.log(defenseCancelResult, waitingPop);
    useEffect(()=>{
        // dispatch(generalInit());
        dispatch(getDefenseBlockGenerals());
        dispatch(getCodGeneralIds());
        let t = setInterval(function(){
            dispatch(getDefenseBlockGenerals());
            dispatch(getCodGeneralIds());
        },100);
        return()=>{
            clearInterval(t);
        }
    }, [defenseCancelResult]);

    function openBlock(codItem){
        if(!window.game){
            return;
        }
        let { blockInfo } = codItem;
        window.game.events.emit("TileClick", {
            pop: true,
            data: {
                x: blockInfo.x_id,
                y: blockInfo.y_id,
                isBlock: false,
                xyIndex: blockInfo.x_id + '^' + blockInfo.y_id,
                target: null
            }
        });
    }

    function getLastTime(codItem){
        if(!codItem.lastTime){
            return -1;
        }
        let n = new Date().getTime();
        let time = Math.floor(codItem.createTime + codItem.lastTime - n/1000);
        return time;
    }

    return (<div className="App-general-list">
            {list.map(
                (_general, index) => 
                    <div key={ index } className="App-general-item" _ga_event="defense.city.open" onClick={ ()=>{
                            if(_general['lastTime']){
                                openBlock(_general);
                                return;
                            }
                            setBlockOp(true);
                            setGeneral(_general) 
                        } }>
                        <span className="App-general-item-avatar">
                            <img src={ generalImagePath(_general) } />
                        </span>
                        <span className="App-general-item-name">
                            { Texts['dialog.general.card.name.' + _general.generalId ] || 'name.' + _general.generalId}
                        </span>
                        {getLastTime(_general) > 0 && <div className="App-general-cod-lastTime">
                            <label>{ CodTexts['dialog.assembling'] }</label>
                            <span><ClockCounter time={ getLastTime(_general) } onEnd={ ()=>{
                                dispatch(getCodGeneralIds());
                            }} /></span>
                        </div>}
                    </div>
                )
            }

            <Modal
                style = {{
                    overlay: { backgroundColor: 'transparent' },
                    content: {
                        height: '240px',
                        marginTop: '-120px',
                        width: '440px',
                        marginLeft: '-220px'
                    }
                }}
                className = { 'App-game-modal-gray-headless' }
                isOpen={ blockOp }
                onRequestClose = { () => setBlockOp(false) }
                contentLabel="NoNeighbor">
                <div style={{ position: 'relative' }} _ga_event="defense.city.close" onClick={ () => setBlockOp(false) }>
                    <div style={{ backgroundColor:'rgb(30, 22, 12)',margin:'15px -10px 0', lineHeight:2, textAlign:'center' }}>{ Texts['dialog.tip'] }</div>
                    <div style={{ margin: '30px 20px 0' }}>{ Texts['dialog.map_defense.desc'] }</div>
                    <div style={{ paddingTop: '30px', textAlign: 'center' }}>
                        <span 
                            style={{ marginRight: '2rem' }}
                            className="App-general-btn App-general-btn-blue"
                            _ga_event="defense.city.block.open"
                            onClick={ (e)=>{ toDefensingTile() } }>
                            { Texts['popup.map_defense_find'] }
                        </span>
                        <span 
                            style={{ marginRight: '2rem' }}
                            className="App-general-btn App-general-btn-green"
                            _ga_event="defense.city.cancel"
                            onClick={ (e)=>{ 
                                setWaitingPop(true);
                                informCancelDefense() 
                            } }>
                            { Texts['popup.map_defense_cancel'] }
                        </span>
                    </div>
                </div>
            </Modal> 

            { (defenseCancelResult.back && waitingPop) && <NotifyPop tips={{
                loading: '',
                success: Texts2['dialog.cancel_defense_plot_success'],
                fail: Texts2['dialog.cancel_defense_plot_fail']
            }} data={ defenseCancelResult } callback={ ()=>{
                setWaitingPop(false);
                dispatch(resetDefenseCancelResult())
            }} />}
        </div>
    );
}