import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClipboardJS from 'clipboard';

import { getSeasonList } from '../../services/slice-user';
import { getSeasonInfo, getSeasonDetail, enterGame, signUpGame, getAllPlayers, resetUserResult, setWalletSelectedInfo } from '../../services/slice-user';
import * as EthFuns from '../../wallets/ethereum';
import * as Contract from '../../wallets/contract';

import SignUpStats from '../signup/signup-stat';
import SignUpSteps from '../signup/signup-steps';
import SignUpInfo from '../signup/signup-info';
import SeasonReward from './reward';
import SeasonMapViewer from '../map/cover-pop';
import SeasonMap from '../map/cover';

import WalletSelect from '../../wallets/wallet-select';
import PrivateKeyExport from '../../wallets/private-key';

import { CreateIcon, ClockCounter, Loading, LoadingBtn, Ranks, ToastTip } from '../../components'
import { Texts, Texts2, GameTexts, Chains, getChainInfo, RewardCoins } from '../../const'

import { numberFormat, dateFormat, getWalletSignErrMessage } from '../../utils/utils'
import { logTrace } from '../../utils/log';
import * as cache from '../../utils/cache'

import './season.scss'

import Modal from 'react-modal';
Modal.setAppElement('#root');

function getSeasonId(sid){
    //2024-02-25-1
    //test-zeta_main-2024-02-25-1-5
    let ids = sid.split('-');
    return [ids[2], ids[3], ids[4], ids[5]].join('-');
}

export default function SeasonItem(props){
    const dispatch = useDispatch();
    const seasonBasicInfo = JSON.parse(JSON.stringify(props.seasonBasicInfo));
    const chain = JSON.parse(JSON.stringify(props.chain));
    const chainInfo = Chains[seasonBasicInfo.chain];
    const seasonId = seasonBasicInfo.sid;
    const reward_token_addr = seasonBasicInfo['reward_token_addr'].toLowerCase();
    const rewardSymbol = (RewardCoins[reward_token_addr] || {}).reward || chainInfo.reward;

    const mapInfo = seasonBasicInfo['map_info'] || {};

    const [ account, setAccount ] = useState('');
    const [ seasonStatus, setSeasonStatus ] = useState({});
    const [ privateKey, setPrivateKey ] = useState(false);

    const { seasonDetail, sign } = useSelector(function(state){
        return state.user;
    });

    let currentSeasonDetail = seasonDetail[seasonId] || {};
    let { signUpInfo = {} } = currentSeasonDetail;
    let unionId = signUpInfo.unionId || '0';
    let isSigned = unionId !== '0';
    // console.log(sign, currentSeasonDetail)

    let generalIds = (signUpInfo.generalIds || []).join(',');

    const [playerStatPop, setPlayerStatPop] = useState(false);
    const [signupStepPop, setSignupStepPop] = useState(false);
    const [signupInfoPop, setSignupInfoPop] = useState(false);

    const [ isRightChain, setIsRightChain ] = useState(true);

    const [notifyPop, setNotifyPop] = useState(false);
    const [notifyPlayPop, setNotifyPlayPop] = useState(false);
    const { signUpSuccess, walletSelectedInfo } = useSelector(function(state){
        return state.user;
    });
    let currentSignUpSuccess = signUpSuccess[seasonId] || {};
    // console.log(signUpSuccess);

    const [seasonMapView, setSeasonMapView] = useState(false);
    const [selectWalletPop, setSelectWalletPop] = useState(false);
    const [crossInfo, setCrossInfo] = useState({});

    useEffect(()=>{
        props.onLogin && props.onLogin(account);
    }, [account]);

    async function linkToWallet(wallet, chain){
        // console.log('walletSelected 2', wallet, chain)
        if(wallet.keyName === 'walletConnect' || wallet.keyName === 'binanceQrcode'){
            EthFuns.login(seasonBasicInfo, async function(res){
                if(res.result == 'fail'){
                    return;
                }
                setAccount(res.account);
                cache.set('accountCacheKey', res.account);
                let contract = await Contract.init(seasonBasicInfo, wallet, chain);
            });
            return;
        }
        EthFuns.detect(chain, function(result){
            setIsRightChain(result);
            // console.log('walletSelected 3', result)
            if(result){
                localStorage.currentChainName = chain.name;
                EthFuns.login(seasonBasicInfo, async function(res){
                    // console.log('walletSelected 4', res)
                    if(res.result == 'fail'){
                        return;
                    }
                    setAccount(res.account);
                    cache.set('accountCacheKey', res.account);
                    await Contract.init(seasonBasicInfo, wallet, chain);
                });
                return;
            }

            EthFuns.transChain(chain, function(res1){
                // console.log('walletSelected 5', chain, res1)
                localStorage.currentChainName = chain.name;
                if(!res1){
                    return;
                }
                EthFuns.login(seasonBasicInfo, async function(res){
                    // console.log('walletSelected 6', seasonBasicInfo, res)
                    if(res.result == 'fail'){
                        return;
                    }
                    setAccount(res.account);
                    cache.set('accountCacheKey', res.account);
                    await Contract.init(seasonBasicInfo, wallet, chain);
                });
            });
        });
    }

    const [copied, setCopied] = useState(false);
    useEffect(()=>{
        new ClipboardJS('.sid-copy');
    }, []);
    // useEffect(()=>{
    //     EthFuns.detect(chainInfo, function(result){
    //         setIsRightChain(result);
    //     });
    // }, [seasonBasicInfo.sid]);

    useEffect(()=>{
        props.onCountFresh && props.onCountFresh();
    }, [playerStatPop]);

    useEffect(()=>{
        setSeasonStatus(getSeasonStatus(seasonBasicInfo));
    }, [seasonBasicInfo.sid, unionId]);

    useEffect(()=>{
        let address = cache.get('accountCacheKey') || account || '';
        if(!address || !seasonId){
            return;
        }
        dispatch(getSeasonDetail({  
            address,
            seasonId: seasonBasicInfo.sid,
            seasonBasicInfo
        }));
        return ()=>{}
    }, [account, seasonBasicInfo.sid]);

    useEffect(()=>{
        let address = cache.get('accountCacheKey') || account || '';
        if(!address || !seasonBasicInfo.sid){
            return;
        }
        let t1 = setInterval(function(){
            dispatch(getSeasonDetail({
                address,
                seasonId: seasonBasicInfo.sid,
                seasonBasicInfo
            }));
        }, 5000);
        if(isSigned){
            return;
            clearInterval(t1);
        }
        return ()=>{
            clearInterval(t1);
        }
    }, [currentSignUpSuccess.back, isSigned, account]);

    function getSeasonIndex(seasonId){
        let arr = seasonId.split('-');
        return arr[arr.length - 1];
    }

    async function walletSelected(info){
        // console.log('walletSelected', info);
        let { wallet } = info;
        let chain = info.chain || chainInfo;
        let res = await EthFuns.setWallet(wallet, chain);
        setCrossInfo(chain.corssChain);
        linkToWallet(wallet, chain);
        setSelectWalletPop(false);
        
        // delete info.wallet.provider;
        // localStorage['walletSelected-' + seasonId] = JSON.stringify(info);
        dispatch(setWalletSelectedInfo(info));
    }

    function getRegisteryFee(){
        // let info = localStorage['walletSelected-' + seasonId] || '{}';
        // let { wallet, chain = chainInfo } = JSON.parse(info);
        let { wallet, chain = chainInfo } = walletSelectedInfo;

        let chainName = chain.name;
        let icon = chain.icon;
        let decimals = 1;
        if(Chains[chainName]){
            decimals = Chains[chainName].nativeCurrency.decimals;
        }
        let registery_fee = seasonBasicInfo.registery_fee || 0;

        if(chainName === 'bsc'){
            registery_fee = registery_fee/400;
        }

        // console.log({chainName, decimals, registery_fee, icon});
        return {
            icon,
            config: Math.ceil(registery_fee),
            value: registery_fee/Math.pow(10, decimals)
        }
    }

    function signupToGame(data){
        // let info = localStorage['walletSelected-' + seasonId] ;
        // let { wallet, chain } = JSON.parse(info);
        let { wallet, chain } = walletSelectedInfo;
        dispatch(signUpGame({
            isCrossChain: chain.name !== seasonBasicInfo.chain,
            seasonId: seasonId,
            registeryFee: getRegisteryFee(),
            userInfo: currentSeasonDetail,
            NFTs: data.NFTs,
            unionId: data.unionId,
            chain: seasonBasicInfo.chain,
            crossInfo: crossInfo,
            seasonBasicInfo: seasonBasicInfo,
            from: account,
            wallet: wallet
        }));
        setSignupStepPop(false);
    }

    return (<>
        {selectWalletPop && <WalletSelect seasonBasicInfo={ seasonBasicInfo } 
            onSelect={ (info)=>{ walletSelected(info); } }
            onClose={ ()=>{ setSelectWalletPop(false) } }
        />}
        <div className={"App-season-item" + (seasonStatus.end ? ' App-season-item-end' : '')}>
        <a className={ "App-social-ethereum " + seasonBasicInfo.chain }>
            {(rewardSymbol == 'aZETA') && <CreateIcon type={ rewardSymbol } size="36" />}
        </a>
        <div className="App-season-featured" style={{ display: 'none' }}>Featured</div>
        <div className="chain-season-time">
            <h3 className="name">
                { 
                    seasonBasicInfo.cross_chain_protocol 
                    ? Texts2['dialog.season.cross']
                    : Texts2['dialog.season.' + seasonBasicInfo.chain]
                }
            </h3>
            <span className="time">{ dateFormat(seasonBasicInfo.start_ts/0.001, 'yyyy/MM/dd') } - { dateFormat(seasonBasicInfo.end_ts/0.001, 'yyyy/MM/dd') }</span>
            {(isSigned || props.isMine) && <span className="registered">{ Texts2['dialog.registered'] }</span>}
        </div>

        <div className="chain-season-map-body">
            <div style={{ display: JSON.stringify(seasonStatus) === '{}' ? 'none': 'block' }} className={ 
                "App-season-banner chain-season-timer " + 
                (seasonStatus.apply ? 'App-season-banner-apply' : '') +
                (seasonStatus.end ? ' App-season-banner-end' : '')}>
                { seasonStatus.prepare && <span className="content preparing">{ Texts['dialog.login.status.preparing'] }</span> }
                { seasonStatus.apply && <span className="content apply" style={{ letterSpacing: '-1px' }}>
                    { Texts['dialog.season.start_countdown'] } &nbsp;
                    <ClockCounter 
                        onEnd = { ()=>{
                            setSeasonStatus(getSeasonStatus(seasonBasicInfo));
                        } }
                        time={ Math.abs(new Date().getTime()/1000 - seasonBasicInfo.start_ts) } />
                </span> }
                { seasonStatus.running && <span className="content running">{ Texts['dialog.login.status.running'] }</span> }
                { seasonStatus.end && <span className="content end">{ Texts['dialog.login.status.end'] }</span> }
            </div>

            <div className="chain-season-map">
                <SeasonMap seasonId={ seasonId } mapInfo={ mapInfo } />
                <div className="chain-season-map-btn">
                    <span
                        onClick={ ()=>{
                            setCopied(true);
                            setTimeout(function(){
                                setCopied(false);   
                            }, 2000);
                        }}
                        _ga_event="season.sid.copy" 
                        data-clipboard-text={ getSeasonId(seasonBasicInfo.sid) }
                        className="time short-sid sid-copy">
                        ID:{ getSeasonId(seasonBasicInfo.sid) }
                        { copied && <span className="chain-season-map-copied">copied</span> }
                    </span>
                    <div className="chain-season-map-btn-pop" onClick={ ()=>{
                        // let walletSelectedData = localStorage['walletSelected-' + seasonId];
                        if(walletSelectedInfo.wallet){
                            // walletSelectedData = JSON.parse(walletSelectedData);
                            walletSelected(walletSelectedInfo);
                            setSeasonMapView(true);
                            return;
                        }
                        if(!account){
                            setSelectWalletPop(true);
                            return;
                            // linkToMetaMask(seasonBasicInfo);
                            // return;
                        }
                        setSeasonMapView(true);
                        }}>
                        { ( (!seasonStatus.running && !seasonStatus.end && mapInfo.map_config === "vote") ? Texts['button.map_choose'] : Texts['button.map_preview']) + ' >' }
                    </div>
                </div>
                {seasonMapView && <SeasonMapViewer seasonId={ seasonId } seasonStatus={ seasonStatus } account={ account } mapInfo={ mapInfo } onClose={ ()=>{ setSeasonMapView(false) } } />}
            </div>

            <div className="chain-season-pool" _sid={ seasonId }>
                <div className="chain-season-overview" onClick={()=>setPlayerStatPop(true)}>
                    <div className="chain-season-signups">
                        { Texts['dialog.applicants'] }: { seasonBasicInfo.apply_count }/{ seasonBasicInfo.player_limit } &nbsp;
                    </div>
                    <span className="overview" _ga_event="login.overview">{ Texts['dialog.login.overview'] } ></span>
                </div>
                {playerStatPop && <SignUpStats from="login" seasonId={ seasonId } close={()=>{ setPlayerStatPop(false) }} />}

                <span className="name">{ Texts['dialog.season_activity.victory_reward.name'].toUpperCase() }</span> 
                <span className="total">
                    <span style={{ top: (seasonBasicInfo.chain == 'bsctest' ? '-2px' : '0'), position: 'relative' }}>
                        <CreateIcon type={ rewardSymbol } size="20" />
                    </span>
                    &nbsp; { seasonBasicInfo.reward_amount_1 }
                </span> 
                <span className="name">{ Texts['dialog.season_activity.glory_rank_reward.name'].toUpperCase() }</span> 
                <span className="total">
                    <span style={{ top: (seasonBasicInfo.chain == 'bsctest' ? '-2px' : '0'), position: 'relative' }}>
                        <CreateIcon type={ rewardSymbol } size="20" />
                    </span>
                    &nbsp; { seasonBasicInfo.reward_amount_2 }
                </span> 
            </div>
        </div>

        <div className="chain-season-btns" style={{ display: (account ? 'none' : 'block') }}>
            <span 
                _ga_event="login.login"
                onClick={ (e)=> {
                    setSelectWalletPop(true);
                    return;
                    // linkToMetaMask(seasonBasicInfo);
                }}
                className="App-general-btn App-general-btn-green">
                { Texts['button.web.how_to_play.login'] }
            </span>
        </div>

        <div className="chain-season-btns" style={{ display: ((account && !currentSeasonDetail.ready) ? 'block' : 'none') }}>
            <LoadingBtn size="38" />
        </div>
        <div className="chain-season-btns" style={{ display: ((account && currentSeasonDetail.ready) ? 'block' : 'none') }}>
            { privateKey && <PrivateKeyExport account={ account } seasonDetail={ currentSeasonDetail } onClose={ ()=>{ setPrivateKey(false) } } /> }
            {(isSigned && seasonBasicInfo.cross_chain_protocol === 'zeta') && <span 
                _ga_event="privateKey.export"
                onClick={ (e)=>{
                    setPrivateKey(true);
                } } 
                style={{ marginLeft: '0' }}
                className="App-general-btn App-general-btn-blue">
                { Texts['button.web.privateKey'] }
            </span>}
            {(isSigned && !seasonStatus.end) && <span 
                _ga_event="login.gpt"
                onClick={ (e)=>{
                    setSignupInfoPop(true);
                } } 
                className="App-general-btn App-general-btn-blue">
                { Texts['button.web.gpt'] }
            </span>}
            {signupInfoPop && <SignUpInfo data={ {
                chain: seasonBasicInfo.chain,
                address: account, 
                seasonId: seasonId
                } } 
                onConfirm={ (data)=>{
                    setSignupInfoPop(false);
                } 
            } />}

            {((seasonStatus.canSignup) && !isSigned) && <span 
                _ga_event="login.signup"
                onClick={ (e)=>{
                    setSignupStepPop(true);
                } } 
                className="App-general-btn App-general-btn-green">
                {seasonBasicInfo.registery_fee/1 > 0 && <><span style={{ top: '-1px', position: 'relative' }}><CreateIcon type={ getRegisteryFee().icon } size="16" /></span>
                <span style={{ margin: '0 10px 0 4px' }}>{ getRegisteryFee().value }</span></>}
                { Texts['button.web.sign_up'] }
            </span>}
            {signupStepPop && <SignUpSteps data={ {
                    address: account, 
                    seasonId: seasonId,
                    chain: seasonBasicInfo.chain,
                    seasonBasicInfo: seasonBasicInfo
                } } 
                onSubmit={ (data)=>{
                    signupToGame(data);
                    setNotifyPop(true);
                } 
            } />}

            { (currentSignUpSuccess.back && notifyPop) && <ToastTip 
                text={ currentSignUpSuccess.result ? Texts['signup_ok'] : getWalletSignErrMessage(currentSignUpSuccess, 'sign') } 
                callback={ ()=>{ setNotifyPop(false);dispatch(resetUserResult()); }
            } />}

            {(seasonStatus.living) && <span 
                _ga_event="login.play"
                onClick={ (e)=>{
                    if(!seasonStatus.running || !isSigned){
                        return;
                    }
                    if(seasonBasicInfo.cross_chain_protocol && signUpInfo.origin_chain_id !== crossInfo.chain_id){
                        return;
                    }
                    cache.removeOtherSeason({seasonId, account});
                    dispatch(enterGame({
                        address: account,
                        seasonId: seasonId,
                        message: currentSeasonDetail.Message
                    }, crossInfo));
                    setNotifyPlayPop(true);
                } } 
                style={{ marginLeft: '90px', marginRight: '0' }}
                className={
                    "App-general-btn App-general-btn-green" 
                    + ((seasonStatus.running && isSigned) ? '' : ' App-btn-disable')
                    + ((seasonBasicInfo.cross_chain_protocol && seasonStatus.running && isSigned && signUpInfo.origin_chain_id !== crossInfo.chain_id) ? ' App-btn-disable' : '')}>
                { Texts['button.web.play'] }
            </span>}
            { (sign.back && notifyPlayPop) && <ToastTip 
                text={ sign.ready ? Texts['play_ok'] : getWalletSignErrMessage(sign, 'play') } 
                callback={ ()=>{ setNotifyPlayPop(false);dispatch(resetUserResult()); }
            } />}

            { seasonStatus.end && <SeasonReward seasonId={ seasonId } account={ account } seasonInfo={ currentSeasonDetail } /> }
        </div>
    </div></>);
}

function getSeasonStatus(seasonBasicInfo){
    let seasonId = seasonBasicInfo.sid;
    if(seasonBasicInfo.end){
        return {
            seasonId,
            prepare: false,
            apply: false,
            running: false,
            living: false,
            canSignup: false,
            end: true
        }
    }

    let start = seasonBasicInfo.start_ts/0.001;
    let apply = seasonBasicInfo.apply_ts/0.001;
    let end = seasonBasicInfo.end_ts/0.001;
    let now = new Date().getTime();

    let living = now >= apply && now < end;
    let notFull = seasonBasicInfo.apply_count < seasonBasicInfo.player_limit;

    let isEnd = now >= end;

    return {
        seasonId,
        prepare: isEnd ? false : now < apply,
        apply: isEnd ? false : (now >= apply && now < start),
        running: isEnd ? false : (now >= start && now < end),
        living: isEnd ? false : living,
        canSignup: isEnd ? false : (living && notFull),
        end: isEnd
    };
}