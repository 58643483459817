import React, { useEffect, useState } from 'react';

import icon1 from './assets/progress.png'
import icon2 from './assets/progress-blue.png'
//blue
export default function ProgressBar(props){
    let data = props.data;
    let color = props.color || 'rgba(0, 129, 211, 1)';

    let styles1 = {
        height: '16px',
        background: '#000000',
        border: '1px solid #5D493B',
        // borderImage: 'linear-gradient(0deg, #2B1F14, #5D493B) 1 1',
        borderRadius: '8px',
        fontSize: '12px',
        lineHeight: "16px",
        textAlign: 'center',
        width: '100%',
        position: 'relative',
        overflow: 'hidden'
    };
    let styles2 = {
        height: '16px',
        background: 'rgba(0, 129, 211, 1)',
        color: '#fff',
        position: 'absolute',
        width: data.width
    };

    return (
        <div className='App-progress-bar' style = { styles1 }>
            <div style = { styles2 }></div>
            <span style={{ position: 'relative', zIndex: 10, zoom: 0.9 }}>{ data.text }</span>
        </div>
    );
}