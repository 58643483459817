import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { numberFormat, timeFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log';

import './msg.scss'
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import UserCard from '../players/user-card';
import PlayerPop from '../players/player-pop';
import CodMessage from './msg-cod';

export default function MessageOne(props){
    let { currentUser, message } = props;
    const userInfo = {
        username: currentUser.username,
        avatar: message.iconId || -1,
        iconId: message.iconId || -1,
        unionId: currentUser.unionId
    };
    const [userPop, setUserPop] = useState(false);
    // console.log('mewMessage', userInfo);
    useEffect(()=>{
    }, []);

	return(
        <div className={ 'App-chat-message' + ( currentUser.username === message.sender ? ' App-chat-message-mine' : '')}>
            <div className="App-chat-userInfo" _ga_event="chat.message.player" onClick={()=>{
                setUserPop(false);
            }} >
                <UserCard userInfo={{
                    username: message.sender,
                    avatar: message.iconId || -1,
                    unionId: message.senderCamp
                }} />
            </div>
            {userPop && <PlayerPop userInfo={ userInfo } onClose={()=>{
                setUserPop(false);
            }} />}

            <div className="App-chat-message-content">
                <div className="App-chat-message-arr"></div>
                <RenderContent content={ message.content } sentTime={ message.ts } onClose={ ()=>{
                    props.onClose();
                }} />
            </div>
            <div className="App-chat-message-time">
                { chatTime(message.ts/0.001).time }
            </div>
        </div>
	);
}

function RenderContent(props){
    const { content, sentTime } = props;
    var detail = '';
    try{
        detail = JSON.parse(content);
    }catch(err){}

    var isMap = typeof detail === 'object';

    var isCod = typeof detail === 'object' && detail.msgType === 'cod';
    var codId = detail.codId;

    if(isCod){
        return <CodMessage codId={ codId } detail={ detail } sentTime={ sentTime } onClose={ props.onClose } />
    }
    if(isMap){
        const keys = {
            name: 'dialog.map.block_' + detail.type + '_' + (detail.parameter || 0) + '.name',
            buff: 'dialog.staff.skill.' + detail.buff_id
        }
        return (
            <span style={{ cursor: 'pointer' }} _ga_event="chat.message.block" onClick={
                ()=>{
                    if(!window.game){
                        return;
                    }
                    window.game.events.emit("TileClick", {
                        pop: true,
                        data: {
                            x: detail.x_id,
                            y: detail.y_id,
                            isBlock: false,
                            xyIndex: detail.x_id + '^' + detail.y_id,
                            target: null
                        }
                    });
                    props.onClose && props.onClose();
                }
            }>
                <span className="block-share">{ Texts2['dialog.share.btn'] }</span>
                <span className="blockinfo">
                    { Texts[keys.name] || keys.name } ( {detail.x_id},{detail.y_id} )
                </span>
            </span>
        )
    }
    return (<>{ content }</>);
}

function chatTime(date){
    var time;
    var type = getDateDiff(date);
    if(type==1 || type==2){
        time=dayjs(date).format("H:mm");
    }else if(type==3 || type==4){
        time=dayjs(date).format("M.D AH:mm");
    }else if(type==5){
        time=dayjs(date).format("YYYY.M.D. AH:mm");
    }
    return { time, type };
}

function getDateDiff(date) {
    var nowDate=dayjs(new Date());
    var oldDate=dayjs(new Date(date)); 
    var result;
    if (nowDate.year()-oldDate.year() >= 1) {
        result=5;
    } else if (nowDate.month()-oldDate.month()>=1 || nowDate.date()-oldDate.date()>=2) {
        result=4;
    } else if (nowDate.date()-oldDate.date()>=1) {
        result=3;
    } else if (nowDate.hour()-oldDate.hour()>=1 || nowDate.minute()-oldDate.minute()>=5) {
        result=2;
    } else {
        result=1;
    }
    return result;
}


