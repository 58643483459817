import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { unionImagePath, generalImagePath, getNickName } from '../../utils/ui-res'

export default function UserCard(props){
    let { userInfo } = props;
    // console.log(userInfo, 'userInfo');

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { userAvatar } = useSelector(function(state){
        return state.generals;
    });
    const { allPlayers } = useSelector(function(state){
        return state.user;
    });

    useEffect(()=>{
    }, []);

	return(
        <div className="App-user-card">
            <div className="App-chat-userInfo">
                <img className="avartar" src={ 
                    generalImagePath({
                        generalId: userInfo.username.toLowerCase() === currentUser.username ? userAvatar : userInfo.avatar
                    }) 
                } />
                {userInfo.unionId > 0 && <img className="camp" style={{ marginBottom: 0 }} src={ unionImagePath(userInfo.unionId)  } />}
                <span className="name" style={{ lineHeight: 2 }}>{ getNickName(allPlayers, userInfo.username) }</span>
            </div>
        </div>
	);
}