import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getVipSilverBuff } from 'throne-underlying';

import { getUserLevel } from '../../services/slice-user'
import { GoBack, CreateIcon, LogisIcon, Buffs, ShowPercent } from '../../components'
import { subs } from '../../utils/utils';
import { Texts2 } from '../../const/'
import * as API from '../../services/api'

export default function UserScoreBuffs(props){
    const dispatch = useDispatch();

    const { address } = props;
    const { userLevel } = useSelector(function(state){
        return state.user;
    });
    const { accountRating = 0, accountRatingToken = 0, accountRatingNFT = 0, vipBuffs = {}} = userLevel;

    useEffect(()=>{
        // 0x42b9df65b219b3dd36ff330a4dd8f327a6ada990
        dispatch(getUserLevel({ address }));
    }, [address]);

    return (<>
        <div className="App-sign-account-rating">
            <div className="App-sign-account-rating-value">
                <h2>{ Texts2['dialog.signup.account.rating'] }:  { Texts2['dialog.signup.account.unit'] } { accountRating }</h2>
                <div className="App-sign-account-rating-values">
                    <div className="App-sign-account-rating-value-item">
                        <label>{ Texts2['dialog.signup.account.rating.token'] }</label>
                        <span>{ Texts2['dialog.signup.account.unit'] } { accountRatingToken }</span>
                    </div>
                    <div className="App-sign-account-rating-value-item">
                        <label>{ Texts2['dialog.signup.account.rating.nft'] }</label>
                        <span>{ Texts2['dialog.signup.account.unit'] } { accountRatingNFT }</span>
                    </div>
                </div>
                <div>
                    <CreateIcon type="info-tips" size="20" /> &nbsp;&nbsp;
                    { Texts2['dialog.account_rating.link'] } &nbsp;
                    <a href="https://zerion.io" className="App-link" target="_blank">https://zerion.io</a>
                </div>
            </div>
            <span style={{ margin: '0 30px 0 40px' }}>
                <CreateIcon type="greenArr" size="32" /> 
            </span> 
            <div className="App-sign-account-rating-buff">
                <Buffs buffs={{
                    generalCount: (vipBuffs.add_general_id || []).length,
                    product: ShowPercent(vipBuffs.product),
                    troop_product: ShowPercent(vipBuffs.recruit),
                    attack: ShowPercent(vipBuffs.attack),
                    defense: ShowPercent(vipBuffs.defense),
                    load: ShowPercent(vipBuffs.load)
                }} />
            </div>
        </div>
    </>);
}


