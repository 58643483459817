import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addConfig, resetConfig } from '../../services/throne.init.js'
import { 
    getGeneralQuaValue, generalStatusChange, generalUpdated,
} from '../../services/slice-general'
import { 
    cityInit, getRecruitState, recruitTroops, buildingUpdate, 
    resourceRefresh, resourceUpdate
} from '../../services/slice-city'
import { setGuideStep, getGuideStep } from '../../services/slice-city';

import { ActionResultPop, GoBack, ClockCounter } from '../../components'
import Tile from './tile';
import SelectGeneral from '../generals/generals-selector'
import BattleResult from '../battle/battle-result'

import { subs } from '../../utils/utils'
import { unionImagePath } from '../../utils/ui-res'
import { getMapId, getMapConfig } from '../../maps/map-res'

import './beginner.scss'
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import gray from './assets/gray.png';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function BeginnerSeasonGuide(props){
    const dispatch = useDispatch(); 

    let type = 'season';
    let stepCount = 26;
    let { oprators } = props;
    const { avatar, currentUser } = useSelector(function(state){
        return state.app;
    });
    const { currentSeasonId, seasonInfo } = useSelector(function(state){
        return state.user;
    });

    const mapId = getMapId(seasonInfo[currentSeasonId]);
    const mapConfig = getMapConfig(mapId);

    const { cityReady, guideStep, guideStepResult } = useSelector(function(state){
        return state.city;
    });
    const [showMask, setShowMask] = useState(true);
    const [stepNumber, setStepNumber] = useState(1);

    const [isProtect, setIsProtect] = useState(false);

    const [showMiner, setShowMiner] = useState(false);
    const [showTile, setShowTile] = useState(false);
    const [isStartAttack, setIsStartAttack] = useState(false);
    const [resultTip, setResultTip] = useState({
        show: false,
        data: {}
    });
    const [waitToWin, setWaitToWin] = useState({
        show: false
    });

    // console.log(stepNumber, guideStep, 'guideStep.get');

    function toMainCity(){
        let w1 = document.documentElement.offsetWidth;
        let w2 = document.documentElement.clientWidth;
        let h1 = document.documentElement.offsetHeight; 
        let h2 = document.documentElement.clientHeight;
        const screenW = Math.max(w1, w2, 800);
        const screenH = Math.max(h1, h2, 500);
        let scrollX = mapConfig.width/2 - screenW/2 - 60;
        let scrollY = mapConfig.height/2 - screenH/2 - 60;
        window.game.events.emit("mapMove", {
            scrollX: scrollX,
            scrollY: scrollY
        });
    }
    function toStartCamp(){
        window.game.events.emit("mapMove", {
            scrollX: -100,
            scrollY: -100
        });
    }
    function setSeasonGuideStep(data){
        data.type = type;
        dispatch(setGuideStep(data));
        setStepNumber(data.step);
    }

    function skipGuide(){
        setSeasonGuideStep({step: 31});
        dispatch(addConfig({
            'guide-season': true,
            'seasonId': currentSeasonId
        }))
        console.log('skipGuide');
    }

    useEffect(()=>{
        dispatch(getGuideStep());
        // setSeasonGuideStep({step:1});
    }, []);

    useEffect(()=>{
        setStepNumber(guideStep.season);
        // setStepNumber(1);
        if(guideStep.season > 2){
            oprators.showZetachain(false);
        }
        if(guideStep.season === 4){
            setShowTile(true);
        }
        if(6 < guideStep.season &&  guideStep.season < 9){
            setShowTile(false);
            setIsStartAttack(true);
        }
        if(guideStep.season === 10){
            setShowTile(true);
            setIsProtect(true);
        }
        if(guideStep.season === 16){
            setWaitToWin({
                show: true,
                unionId: 1,
                remainTime: 8*60*60
            });
        }
        if(guideStep.season === 18){
            let btn = document.querySelector('#App-preview-map-btn');
            btn.click();
        }
        if(guideStep.season === 19){
            oprators.setHighlightType('season');
        } 
        if(guideStep.season === 21 || guideStep.season === 22 || guideStep.season === 23){
            oprators.openSeason();
        }
    }, [guideStep.back]);

    const { resources, cities } = useSelector(function(state){
        return state.city;
    });

    const [spotConfig, setSpotConfig] = useState({
        gray: gray,
        x: '0px',
        y: '0px',
        w: '0px',
        h: '0px'
    });
    let bg = 'url(%{gray}) repeat-y 0 0,'
            + 'url(%{gray}) repeat-y calc(%{x} + %{w}) 0,'
            + 'url(%{gray}) no-repeat %{x} 0,'
            + 'url(%{gray}) no-repeat %{x} calc(%{y} + %{h})';
    let bgSize = '%{x} 100%,'
            + 'calc(100% - %{x} - %{w}) 100%,'
            + '%{w} %{y},'
            + '%{w} calc(100% - %{y} - %{h})';
    let styles = {
        background: subs(bg, spotConfig),
        backgroundSize: subs(bgSize, spotConfig),
        zIndex: 399
    };

    function setNewConfig(selector){
        return;
        let data = document.querySelector(selector).getClientRects()[0];
        setSpotConfig({
            gray: gray,
            x: data.x + 'px',
            y: data.y + 'px',
            w: data.width + 'px',
            h: data.height + 'px'
        });
    }
    const { debugConfig } = useSelector(function(state){
        return state.app;
    });
    if(guideStep.season >= 25 || debugConfig['guide-season']){
        return (<></>);   
    }
        
    return(<Modal
        style = {{
            overlay1: { backgroundColor: 'rgba(0,0,0,0.1)', zIndex: 399 },
            overlay: styles,
            content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                outline: 'none'
            }
        }}
        className = { 'App-modal-beginner' }
        isOpen={ showMask || stepNumber < 25 }
        contentLabel="BeginnerGuide Modal">
        <div className="App-guide-skip"
            _ga_event="beginner.season.skip"
            onClick={ ()=> {skipGuide()} }>
            { Texts2['button.skip'] }
        </div>
        <div style={{ position: 'relative' }}>
            <Modal
                style = {{
                    overlay: { backgroundColor: 'transparent' },
                    content: {
                        height: '440px'
                    }
                }}
                className = { 'App-game-modal-gray' }
                isOpen={ showTile }
                onRequestClose = { () => setShowTile(false) }
                contentLabel="Tile Modal">
                <div style={{ position: 'relative' }}>
                    <div _ga_event="beginner.season.block" onClick={ () => setShowTile(false) }><GoBack size="60px" /></div>
                    <Tile 
                        isProtect = { isProtect }
                        close = { () => setShowTile(false) }
                    /> 
                </div>
            </Modal>
            {isStartAttack && <SelectGeneral battleType="guide"  
                defenseList={ [] } 
                onClose={ ()=>{ setIsStartAttack(false) } }
                onSelected={ (res)=>{
                    let { general, flag } = res;
                } } 
            />}
            {resultTip.show && <BattleResult 
                type="block"
                result={ resultTip.data }
                overConfirm={ ()=>{} }
            />}
            {showMiner && <ActionResultPop 
                info={{
                    result: true,
                    label: 'silvers:',
                    text: 50000
                }} 
                overConfirm={ ()=>{} } 
            />}

            {stepNumber <= 2 && (<div className="App-beginner-step App-beginner-step1">
                <div className="App-beginner-scroll">
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.' + stepNumber + '_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:stepNumber + 1});
                        toStartCamp();
                    }}>
                </div>
            </div>)}


            {stepNumber === 3 && (<div className="App-beginner-step App-season-beginner-step3">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.3_1'] }
                </div>
                <div className="App-beginner-cover-btn"
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:4});
                        setShowTile(true);
                        setNewConfig('.App-season-beginner-step3 .App-beginner-cover-btn');
                    }}>
                </div>
            </div>)}

            {stepNumber === 4 && (<div className="App-beginner-step App-season-beginner-step4">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.4_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:5});
                    }}>
                </div>
            </div>)}

            {stepNumber === 5 && (<div className="App-beginner-step App-season-beginner-step5">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.5_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:6});
                    }}>
                </div>
            </div>)}

            {stepNumber === 6 && (<div className="App-beginner-step App-season-beginner-step6">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.6_1'] }
                </div>
                <div className="App-beginner-cover-btn" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:7});
                        setShowTile(false);
                        setIsStartAttack(true);
                    }}>
                </div>
            </div>)}

            {stepNumber === 7 && (<div className="App-beginner-step App-season-beginner-step7">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.7_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:8});
                    }}>
                </div>
            </div>)}

            {stepNumber === 8 && (<div className="App-beginner-step App-season-beginner-step8">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.8_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-btn" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:9});
                        setIsStartAttack(false);
                        setIsProtect(true);
                        setResultTip({
                            show: true,
                            data: {
                                type: 'attack',
                                attackGloryGet: 99,
                                attackTroopReduce: 199,
                                durabilityReduce: 3000,
                                result: true,
                                win: true
                            }
                        });
                    }}>
                </div>
            </div>)}

            {stepNumber === 9 && (<div className="App-beginner-step App-season-beginner-step9">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.9_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:10});
                        setShowTile(true);
                        setResultTip({
                            show: false,
                            data: {}
                        });
                    }}>
                </div>
            </div>)}

            {stepNumber === 10 && (<div className="App-beginner-step App-season-beginner-step10">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.10_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:11});
                    }}>
                </div>
            </div>)}

            {stepNumber === 11 && (<div className="App-beginner-step App-season-beginner-step11">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.11_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-btn" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:12});
                        setShowTile(false);
                        setShowMiner(true);
                    }}>
                </div>
            </div>)}

            {stepNumber === 12 && (<div className="App-beginner-step App-season-beginner-step12">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.12_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:13});
                        setShowMiner(false);
                        toMainCity();
                    }}>
                </div>
            </div>)}

            {stepNumber === 13 && (<div className="App-beginner-step App-season-beginner-step13">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.13_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask"
                    _ga_event={ "beginner.season." + stepNumber } 
                    onClick={ ()=>{
                        setSeasonGuideStep({step:14});
                    }}>
                </div>
            </div>)}

            {stepNumber === 14 && (<div className="App-beginner-step App-season-beginner-step14">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.14_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:15});
                    }}>
                </div>
            </div>)}

            {stepNumber === 15 && (<div className="App-beginner-step App-season-beginner-step15">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.15_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:16});
                        setWaitToWin({
                            show: true,
                            unionId: 1,
                            remainTime: 8*60*60
                        });
                    }}>
                </div>
            </div>)}

            {stepNumber === 16 && (<div className="App-beginner-step App-season-beginner-step16">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.16_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:17});
                    }}>
                </div>  
                {waitToWin.show && (<div className="App-modal-tips">
                    <img src={ unionImagePath(currentUser.unionId) } style={{ verticalAlign:'bottom', borderRadius: '50%', height: '22px',marginRight: '10px' }}/> 
                    will win in&nbsp;
                    <ClockCounter time={ waitToWin.remainTime } />
                </div>)}
            </div>)}


            {stepNumber === 17 && (<div className="App-beginner-step App-season-beginner-step16-2">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.16_2'] }
                </div>
                <div className="App-beginner-cover-btn"
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        oprators.showZetachain(true); 
                        setSeasonGuideStep({step:18});
                        setNewConfig('.App-season-beginner-step2 .App-beginner-cover-btn');
                    }}>
                </div>
                <div className="App-beginner-arr"></div>
            </div>)}

            {stepNumber === 18 && (<div className="App-beginner-step App-season-beginner-step16-3">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.16_3'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        oprators.showZetachain(false);
                        setSeasonGuideStep({step:stepNumber + 1});
                    }}>
                </div>
            </div>)}

            {stepNumber === 19 && (<div className="App-beginner-step App-season-beginner-step17">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.17_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:20});
                        let btn = document.querySelector('#App-preview-map-btn');
                        btn.click();
                    }}>
                </div>
            </div>)}

            {stepNumber === 20 && (<div className="App-beginner-step App-season-beginner-step18">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.18_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:21});
                    }}>
                </div>
            </div>)}

            {stepNumber === 21 && (<div className="App-beginner-step App-season-beginner-step19">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.19_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:22});
                        let btn = document.querySelector('#App-preview-map-btn');
                        btn.click();
                        oprators.openSeason();
                    }}>
                </div>
            </div>)}
            
            {stepNumber === 22 && (<div className="App-beginner-step App-season-beginner-step20">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.20_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:23});
                    }}>
                </div>
            </div>)}
            
            {stepNumber === 23 && (<div className="App-beginner-step App-season-beginner-step21">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.21_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:24});
                    }}>
                </div>
            </div>)}
            
            {stepNumber === 24 && (<div className="App-beginner-step App-season-beginner-step22">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.22_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask"
                    _ga_event={ "beginner.season." + stepNumber } 
                    onClick={ ()=>{
                        setSeasonGuideStep({step:25});
                    }}>
                </div>
            </div>)}
            
            {stepNumber === 25 && (<div className="App-beginner-step App-season-beginner-step23">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.23_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:26});
                        oprators.closeGeneral();
                    }}>
                </div>
            </div>)}

            {stepNumber === 26 && (<div className="App-beginner-step App-beginner-step1 App-beginner-step24">
                <div className="App-beginner-scroll">
                    <div className="App-beginner-toast-index">{ stepNumber }/{ stepCount }: </div>
                    { Texts2['dialog.season_fte.step.24_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event={ "beginner.season." + stepNumber }
                    onClick={ ()=>{
                        setSeasonGuideStep({step:27});
                        setShowMask(false);
                    }}>
                </div>
            </div>)}
        </div>
    </Modal>);
}