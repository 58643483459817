import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllPlayers } from '../../services/slice-user';

import { numberFormat, subs } from '../../utils/utils'
import { logTrace } from '../../utils/log'
import * as cache from '../../utils/cache'

import { CreateIcon, GoBack, LogisIcon, Stars, PersonNumber, ClockCounter } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath,
    unionImagePath,
    getNickName
} from '../../utils/ui-res'

import { Texts, SpyTexts } from '../../const/';

import Modal from 'react-modal';
Modal.setAppElement('#root');

const emptyStr = '???';

export default function UnionList(props){
    const dispatch = useDispatch(); 
    let players = props.data;
    let onSelected = props.onSelected;

    let spyRecords = cache.get('spyRecords2') || [];
    let spyObj = {};
    spyRecords.forEach(function(item){
        spyObj[item.username] = item;
    });

    let uniSpyRecords = [];
    for(var uname in spyObj){
        uniSpyRecords.push(spyObj[uname]);
    }
    cache.set('spyRecords2', uniSpyRecords);
    console.log(spyRecords, spyObj, players);

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { allPlayers } = useSelector(function(state){
        return state.user;
    });

    const [player, setPlayer] = useState({});

    function selectSinglePlayer(item){
        setPlayer(item);
        onSelected(item);
    }

    function showValue(obj, key){
        if(!obj){
            return emptyStr;
        }
        if(obj.hasOwnProperty(key)){
            return numberFormat(obj[key]);
        }
        return emptyStr;
    }

    function showStoreValue(obj){
        if(!obj){
            return emptyStr;
        }
        let v = obj['store'] || {};
        return numberFormat(v.silver_save || 0);
    }

    const [protectTipPop, setProtectTipPop] = useState(false);

    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    useEffect(()=>{
        dispatch(getAllPlayers({
            seasonId: sign.seasonId
        }))
    }, [sign.seasonId]);

    return (<>
        <div className="App-game-table App-game-table-rank" style={{ height: '270px', overflow: 'auto' }}><table>
            <thead><tr>
                <td className="center rank">{ Texts['dialog.union'] }</td>
                <td className="player">{ Texts['button.player'] }</td>
                <td className="white right">{ Texts['dialog.attack'] }</td>
                <td className="white right">{ Texts['dialog.defense'] }</td>
                <td className="white right">{ Texts['dialog.defense_max_troop'] }</td>
                <td className="white right">{ Texts['dialog.store'] }</td>
                <td className="white right">{ Texts['dialog.union.silvers'] }</td>
                <td className="white right">{ Texts['dialog.troop'] }</td>
                <td className="white right"></td>
            </tr></thead>
            <tbody>
            {players.map((item, index) => (
                <tr key={ 'g' + index + item.generalId + '-' + item.generalLevel + '-' + item.generalType } 
                    className= { player.username == item.username ? 'selected' : '' }
                    style={{ display: item.username !== currentUser.username ? '': 'none',  cursor: 'pointer' }}
                    _ga_event={ "union.list.select" }
                    onClick={ (e)=>{ selectSinglePlayer(item); } }>
                    <td className="center" style={{ padding: '10px 3px' }}>
                        <span 
                            _ga_event={ "union.list.protect" }
                            onClick={ ()=>{setProtectTipPop(item.isProtected)} }
                            style={{ display: 'inline',cursor: 'pointer', opacity: item.isProtected? 1 : 0, position:'relative',top:'-1px',right:'5px' }}>
                            <CreateIcon type="protect" size="20" />
                        </span>
                        <img src={ unionImagePath(item.unionId) } style={{ width: '20px', borderRadius: '50%', verticalAlign: 'top' }} />
                    </td>
                    <td>
                        <span style={{ display:'inline-block', paddingTop:'5px', width:'6.2em',overflow:'hidden',textOverflow:'ellipsis' }} title={ item.username }>
                            { getNickName(allPlayers[currentSeasonId], item.username || item.id) }
                        </span>
                    </td>
                    <td className="right">{ showValue(spyObj[item.username], 'attack') }</td>
                    <td className="right">{ showValue(spyObj[item.username], 'defense') }</td>
                    <td className="right">{ showValue(spyObj[item.username], 'defenseMaxTroop') }</td>
                    <td className="right">{ showStoreValue(spyObj[item.username]) }</td>
                    <td className="right">{ showValue(spyObj[item.username], 'silver') }</td>
                    <td className="right">{ showValue(spyObj[item.username], 'troop') }</td>
                    <td className="right"></td>
                </tr>)
            )}
            </tbody>
        </table></div>


        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '560px',
                    height: '160px',
                    margin: '-80px 0 0 -280px'
                }
            }}
            className = { 'App-game-modal-gray-headless' }
            isOpen={ protectTipPop }
            onRequestClose = { () => setProtectTipPop(false) }
            contentLabel="Preview Map Modal">
            <div style={{ position: 'relative' }}>
                <div className="App-game-modal-content2">
                    <div _ga_event={ "union.list.protect.close" } onClick={ () => setProtectTipPop(false) }>
                        <GoBack size="60px" />
                    </div>
                    <div className="App-game-modal-content-desc" style={{ padding:'60px 0 0', textAlign:'center' }}>
                        { Texts['dialog.player_under_protection'] }
                    </div>
                </div>
            </div>
        </Modal>
    </>);
}