import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getRewardBalance, withdrawMyBalance, resetUserResult } from '../../services/slice-user';

import NumericFormat from 'react-number-format';
import { GoBack, CreateIcon, Slider, ClockCounter } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import { Texts, Texts2, GameTexts } from '../../const';

export default function MyRewards(props){
    const dispatch = useDispatch();
    // const address = '0x04C535c9F175cB8980B43617fB480412c7E341E4';
    const { address, season, onWalletSelect } = props; 
    const tokenAddress = season['reward_token_addr'];

    const { rewardBalance, lotBalance, withdrawMyBalanceResult } = useSelector(function(state){
        return state.user;
    });
    // console.log('withdrawMyBalanceResult', withdrawMyBalanceResult, {tokenAddress, rewardBalance, address});

    useEffect(()=>{
        if(!address){
            return;
        }
        dispatch(getRewardBalance({
            address,
            tokenAddress
        }));
    }, [address]);
    useEffect(()=>{
        if(!address){
            return;
        }
        dispatch(getRewardBalance({
            address,
            tokenAddress
        }));
    }, [withdrawMyBalanceResult]);

    return (<>
        <span className="App-reward-claim-area" onClick={ ()=>{ 
            if(address){return}
            onWalletSelect && onWalletSelect();
            } }>
            <CreateIcon type="lot" size="34" />
            <span className="App-reward-claim">
                <span className="reward-label">Reward</span>
                <span className="reward-total">
                    {
                        address ? <NumericFormat value={ lotBalance || 0 } displayType="text" thousandSeparator="," /> : '--'
                    }
                </span>
            </span> 
        </span>

        <span className="App-reward-claim-area" onClick={ ()=>{ 
            if(address){return}
            onWalletSelect && onWalletSelect();
            } }>
            <CreateIcon type="usdt" size="34" />
            <span className="App-reward-claim">
                <span className="reward-label">Reward</span>
                <span className="reward-total">
                    {
                        address ? <NumericFormat value={ rewardBalance } displayType="text" thousandSeparator="," /> : '--'
                    }
                </span>
            </span> 
        </span>

        <a className="App-loading-btn App-menu-link">
            {address
            ?  <span className={"App-general-btn App-general-btn-green" + (rewardBalance == 0 ? ' App-btn-disable' : '')} onClick={ ()=>{
                if(rewardBalance == 0){
                    return;
                }
                dispatch(withdrawMyBalance({
                    address,
                    tokenAddress
                }));
            } }>
                Withdraw
            </span>
            : <span 
                className="App-general-btn App-general-btn-green App-btn-disable" 
                onClick={ ()=>{ onWalletSelect && onWalletSelect(); } }>
                Withdraw
            </span>}
        </a>
        { withdrawMyBalanceResult.back && <ToastTip 
            text={ withdrawMyBalanceResult.result ? Texts2['withdraw.balance.ok'] : Texts2['withdraw.balance.fail'] } 
            callback={ ()=>{ dispatch(resetUserResult()) } } 
        />}
    </>);
}