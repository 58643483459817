import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    getGeneralQuaValue, generalStatusChange, generalUpdated,
} from '../../services/slice-general'
import { 
    cityInit, getRecruitState, recruitTroops, buildingUpdate, 
    resourceRefresh, resourceUpdate
} from '../../services/slice-city'
import { setGuideStep, getGuideStep } from '../../services/slice-city';

import { subs } from '../../utils/utils'

import './beginner.scss'
import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';
import gray from './assets/gray.png';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function BeginnerPlayGuide(props){
    const dispatch = useDispatch(); 

    let { oprators } = props;
    const { cityReady, guideStep, guideStepResult } = useSelector(function(state){
        return state.city;
    });
    const [showMask, setShowMask] = useState(true);
    const [stepNumber, setStepNumber] = useState(1);

    // step1
    const [step1, setStep1] = useState(1);
    const [step10, setStep10] = useState(1);
    const [step14, setStep14] = useState(1);

    // console.log(stepNumber, guideStep, 'guideStep.get');

    function setPlayGuideStep(data){
        data.type = 'play';
        dispatch(setGuideStep(data));
    }
    useEffect(()=>{
        dispatch(getGuideStep());
    }, [cityReady, guideStepResult]);
    useEffect(()=>{
        // setStepNumber(guideStep.play);
        // if(guideStep.play === 3){
        //     oprators.city();
        // }
        // if(guideStep.play === 4){
        //     oprators.closePop();
        //     oprators.setHighlightType('general');

        // } 
        // if(guideStep.play === 6){
        //     oprators.general();
        // }
        // if(guideStep.play === 7){
        //     recurit();
        // }
        // if(guideStep.play === 9){
        //     oprators.showStrengthen();
        // }
        // if(stepNumber === 12){
        //     oprators.troop();
        // }
        // if(stepNumber === 13){
        //     troopsRecruitStart();
        // }
    }, [guideStep]);

    const { resources, cities } = useSelector(function(state){
        return state.city;
    });
    let updateFortress = function(){
        dispatch(buildingUpdate(cities[0]));
    };
    const generalList = useSelector(function(state){
        return state.generals.list || [];
    });
    let recurit = function(){
        let currentGeneral = generalList[0];
        dispatch(generalStatusChange({
            id: currentGeneral.qualification.general_id,
            fn: 'ableGeneral'
        }));
    }
    let updateStrengthen = function(){
        let currentGeneral = generalList[0];
        dispatch(generalUpdated(currentGeneral.qualification.general_id));
        dispatch(getGeneralQuaValue({
            id: currentGeneral.qualification.general_id,
            level: currentGeneral.level + 1
        }))
    }

    let troopsRecruitStart = function(){
        let silvers = Math.floor(resources.silver.value || 0);
        let maxTroops = Math.floor(silvers/100);
        dispatch(recruitTroops({
            amount: 10
        }));
    }
    useEffect(()=>{
        dispatch(resourceUpdate());
    }, [cityReady]);

    const [spotConfig, setSpotConfig] = useState({
        gray: gray,
        x: '0px',
        y: '0px',
        w: '0px',
        h: '0px'
    });
    let bg = 'url(%{gray}) repeat-y 0 0,'
            + 'url(%{gray}) repeat-y calc(%{x} + %{w}) 0,'
            + 'url(%{gray}) no-repeat %{x} 0,'
            + 'url(%{gray}) no-repeat %{x} calc(%{y} + %{h})';
    let bgSize = '%{x} 100%,'
            + 'calc(100% - %{x} - %{w}) 100%,'
            + '%{w} %{y},'
            + '%{w} calc(100% - %{y} - %{h})';
    let styles = {
        background: subs(bg, spotConfig),
        backgroundSize: subs(bgSize, spotConfig),
        zIndex: 399
    };

    function setNewConfig(selector){
        return;
        let data = document.querySelector(selector).getClientRects()[0];
        setSpotConfig({
            gray: gray,
            x: data.x + 'px',
            y: data.y + 'px',
            w: data.width + 'px',
            h: data.height + 'px'
        });
    }
    
    const { debugConfig } = useSelector(function(state){
        return state.app;
    });
    if(guideStep.play <= 15){
        return (<></>);
    }
    if(debugConfig['guide-player']){
        return (<></>);        
    }
        
    return(<Modal
        style = {{
            overlay1: { backgroundColor: 'rgba(0,0,0,0.1)', zIndex: 399 },
            overlay: styles,
            content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                outline: 'none'
            }
        }}
        className = { 'App-modal-beginner' }
        isOpen={ showMask || guideStep.play < 15 }
        contentLabel="BeginnerGuide Modal">
        <div style={{ position: 'relative' }}>
            {stepNumber === 1 && (<div className="App-beginner-step App-beginner-step1">
                <div className="App-beginner-scroll">
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.1_' + step1] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event="beginner.play.1"
                    onClick={ ()=>{
                        let count = step1 + 1;

                        if(count > 3){
                            setStepNumber(2);
                            setPlayGuideStep({step:2});
                            oprators.setHighlightType('building');
                            setNewConfig('.App-item-icon-building');
                            return;
                        }
                        setStep1(count);
                    }}>
                </div>
            </div>)}

            {stepNumber === 2 && (<div className="App-beginner-step App-beginner-step2">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.2_1'] }
                </div>
                <div className="App-beginner-cover-btn"
                    _ga_event="beginner.play.2" 
                    onClick={ ()=>{
                        setStepNumber(3);
                        setPlayGuideStep({step:3});
                        oprators.city();
                    }}>
                </div>
            </div>)}

            {stepNumber === 3 && (<div className="App-beginner-step App-beginner-step3">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.3_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-btn" 
                    _ga_event="beginner.play.3"
                    onClick={ ()=>{
                        setStepNumber(4);
                        setPlayGuideStep({step:4});
                        updateFortress();
                    }}>
                </div>
            </div>)}

            {stepNumber === 4 && (<div className="App-beginner-step App-beginner-step4">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-toparr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.4_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event="beginner.play.4"
                    onClick={ ()=>{
                        setStepNumber(5);
                        setPlayGuideStep({step:5});
                        oprators.closePop();
                        oprators.setHighlightType('general');
                    }}>
                </div>
            </div>)}

            {stepNumber === 5 && (<div className="App-beginner-step App-beginner-step5">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.5_1'] }
                </div>
                <div className="App-beginner-cover-btn"
                    _ga_event="beginner.play.5" 
                    onClick={ ()=>{
                        setStepNumber(6);
                        setPlayGuideStep({step:6});
                        oprators.general();
                    }}>
                </div>
            </div>)}

            {stepNumber === 6 && (<div className="App-beginner-step App-beginner-step6">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.6_1'] }
                </div>
                <div className="App-beginner-cover-btn" 
                    _ga_event="beginner.play.6"
                    onClick={ ()=>{
                        setStepNumber(7);
                        setPlayGuideStep({step:7});
                        recurit();
                    }}>
                </div>
            </div>)}

            {stepNumber === 7 && (<div className="App-beginner-step App-beginner-step7">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.7_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event="beginner.play.7"
                    onClick={ ()=>{
                        setStepNumber(8);
                        setPlayGuideStep({step:8});
                    }}>
                </div>
            </div>)}

            {stepNumber === 8 && (<div className="App-beginner-step App-beginner-step8">
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-leftarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.8_1'] }
                </div>
                <div className="App-beginner-cover-btn" 
                    _ga_event="beginner.play.8"
                    onClick={ ()=>{
                        setStepNumber(9);
                        setPlayGuideStep({step:9});
                        oprators.showStrengthen();
                    }}>
                </div>
            </div>)}

            {stepNumber === 9 && (<div className="App-beginner-step App-beginner-step9">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.9_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-btn" 
                    _ga_event="beginner.play.9"
                    onClick={ ()=>{
                        setStepNumber(10);
                        setPlayGuideStep({step:10});
                        updateStrengthen();
                    }}>
                </div>
            </div>)}

            {stepNumber === 10 && (<div className="App-beginner-step App-beginner-step10">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.10_' + step10] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event="beginner.play.10"
                    onClick={ ()=>{
                        setStep10(2);
                        if(step10 === 2){
                            setStepNumber(11);
                            setPlayGuideStep({step:11});
                            oprators.closePop();
                            oprators.setHighlightType('troop');
                        }
                    }}>
                </div>
            </div>)}

            {stepNumber === 11 && (<div className="App-beginner-step App-beginner-step11">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.11_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-btn" 
                    _ga_event="beginner.play.11"
                    onClick={ ()=>{
                        setStepNumber(12);
                        setPlayGuideStep({step:12});
                        oprators.troop();
                    }}>
                </div>
            </div>)}

            {stepNumber === 12 && (<div className="App-beginner-step App-beginner-step12">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-rightarr"></div>
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.13_1'] }
                </div>
                <div className="App-beginner-arr"></div>
                <div className="App-beginner-cover-btn" 
                    _ga_event="beginner.play.12"
                    onClick={ ()=>{
                        setStepNumber(13);
                        setPlayGuideStep({step:13});
                        troopsRecruitStart();
                    }}>
                </div>
            </div>)}

            {stepNumber === 13 && (<div className="App-beginner-step App-beginner-step13">
                <div className="App-beginner-toast">
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.13_1'] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event="beginner.play.13"
                    onClick={ ()=>{
                        setStepNumber(14);
                        setPlayGuideStep({step:14});
                        oprators.closePop();
                    }}>
                </div>
            </div>)}

            {stepNumber === 14 && (<div className="App-beginner-step App-beginner-step1 App-beginner-step14">
                <div className="App-beginner-scroll">
                    <div className="App-beginner-toast-index">{ stepNumber }/14: </div>
                    { Texts2['dialog.fte.step.14_' + step14] }
                </div>
                <div className="App-beginner-cover-mask" 
                    _ga_event="beginner.play.14"
                    onClick={ ()=>{
                        setStep14(2);
                        if(step14 === 2){
                            setStepNumber(15);
                            setPlayGuideStep({step:15});
                            oprators.over();
                            setShowMask(false);
                        }
                    }}>
                </div>
            </div>)}
        </div>
    </Modal>);
}