import axios from 'axios';
import { subs } from '../utils/utils';

import { apiHost, wsHost, transactionLink } from '../const';

import { getMapGDS } from '../maps/map-res'

const getDASeasonList = (callback) => {
    let api = apiHost + '/dutch-auction/api/seasons?r=' + new Date().getTime();
    axios.get(api).then(function(res){
        res = res.data || {};
        res = res.data || [];
        res.sort(function(a, b){
            return a.startAt || 0 > b.startAt ? -1 : 1;
        });
        let now = new Date().getTime();
        let list1 = [];
        let list2 = [];
        res.forEach(function(item){
            if(item.endedAt && item.endedAt/0.001 <= now){
                list2.push(item);
            }else{
                list1.push(item);
            }
        });
        callback({
            seasonAll: res,
            seasons: list1,
            seasonHis: list2
        });
    });
}

const getHistorySeasons = async() => {
    let api = apiHost + '/dutch-auction/api/history_seasons?r=' + new Date().getTime();
    let res = await axios.get(api);
    res = res.data || {};
    res = res.data || [];
    // res.sort(function(a, b){
    //     return a.startAt > b.startAt ? -1 : 1;
    // });
    return res;
}

const refreshSeasonInfo = async(data) => {
    data.r = new Date().getTime();
    let api = apiHost + '/dutch-auction/api/season?sid=%{seasonId}&r=%{r}';
        api = subs(api, data);
    let res = await axios.get(api);
    res = res.data || {};
    res = res.data || {};
    return res;
}

const getLands = (season, callback) => {
    let api = apiHost + '/dutch-auction/api/lands?sid=' + season.seasonId + '&r=' + new Date().getTime();
    axios.get(api).then(function(res){
        res = res.data || {};
        res = res.data || [];

        // \"4\":{\"x_id\":5,\"y_id\":-5,\"x\":7,\"y\":15},
        // \"3\":{\"x_id\":-5,\"y_id\":-5,\"x\":2,\"y\":15},
        // \"2\":{\"x_id\":-5,\"y_id\":5,\"x\":2,\"y\":5}}"
        // "{\"1\":{\"x_id\":5,\"y_id\":5,\"x\":7,\"y\":5},

        let result = {
            '5^5': { unionId:1, protectEndTime:0, attackEndTime:0, isInit: true },
            '-5^5': { unionId:2, protectEndTime:0, attackEndTime:0, isInit: true },
            '-5^-5': { unionId:3, protectEndTime:0, attackEndTime:0, isInit: true },
            '5^-5': { unionId:4, protectEndTime:0, attackEndTime:0, isInit: true }
        };
        res.forEach(function(item){
            let now = new Date().getTime()/1000;
            let { lastUpdatedAt, protectTimeTs, hash, currentPrice, initPrice } = item;
            if(protectTimeTs > 0 && lastUpdatedAt + protectTimeTs <= now){
                protectTimeTs = 0;
            }

            let posId = item.posId.split('_').join('^');
            result[posId] = {
                unionId: item.unionId || 0,
                protectEndTime: protectTimeTs,
                attackEndTime: item.attackEndTime || 0,
                owner: (item.owner || '').toLowerCase(),
                hash,
                currentPrice,
                initPrice
            }
        });
        callback(result);
    });
}

const getLandInfo = async(data) => {
    data.r = new Date().getTime();
    let api = apiHost + '/dutch-auction/api/get-land-info?sid=%{seasonId}&x=%{x}&y=%{y}&r=%{r}';
        api = subs(api, data);
    let res = await axios.get(api);
    res = res.data || {};
    res = res.data || {};
    res = res.info || {};
    return res;
}

/*
"unlockedRingTs": {
    "0": 1721839392,
    "1": 1721837592,
    "2": 1721835792,
    "3": 1721833992,
    "4": 1721832192,
    "5": 1721830392
}
*/ 
const getLandsDetail = (seasonInfo, callback) => {
    let { sid, unlockedRingTs, unlockedRing } = seasonInfo;
    if(unlockedRing === -1){
        unlockedRing = 6;
    }
    var mapId = 7;
    var { mapGDS, distancesConfig } = getMapGDS(mapId);

    getLands({ seasonId: sid }, function(lands){
        for(var xyIndex in mapGDS){
            let distance = distancesConfig[xyIndex];
            // console.log({distance, xyIndex, unlockedRing}, lands);
            let now = new Date().getTime()/1000;
            let priceDownPeriod = 300;
            if(distance >= 0 && unlockedRing <= distance){
                let initPrice = (4 * (5 - distance) + 10) + '000000' + '000000' + '000000';
                let times = Math.floor((now - unlockedRingTs[distance])/priceDownPeriod);
                    times = Math.min(times, 9);
                let currentPrice = (4 * (5 - distance) + 10)*(10 - times) + '000000' + '000000' + '00000';
                lands[xyIndex] = lands[xyIndex] || {};
                lands[xyIndex].initPrice = lands[xyIndex].initPrice || initPrice;
                lands[xyIndex].currentPrice = lands[xyIndex].currentPrice || currentPrice;
                lands[xyIndex].shares = 40 * (5 - distance) + 10;
                lands[xyIndex].distance = distance;
                lands[xyIndex].xyIndex = xyIndex;
            }
        }
        callback(lands);
    });
}

const getAllLands = async(data) => {
    data.r = new Date().getTime();
    let api = apiHost + '/dutch-auction/api/land_events?sid=%{seasonId}&r=%{r}';
        api = subs(api, data);
    let res = await axios.get(api);
    res = res.data || {};
    res = res.data || [];
    return res;
}

const getDAEvents = (season, callback) => {
    let api = apiHost + '/dutch-auction/api/events?sid=' + season.seasonId + '&r=' + new Date().getTime();
    axios.get(api).then(function(res){
        res = res.data || {};
        res = res.data || [];

        let isRecent = false;
        let recents = [];
        res.forEach(function(item, index){
            if(new Date().getTime() - item.timestamp < 10*1000){
                isRecent = true;
                recents.push(item);
            }
        })
        callback({
            isRecent,
            list: res,
            recents
        });
    });
}

const getAllBuyLandEvents = async (data) => {
    data.r = new Date().getTime();
    // let api = apiHost + '/dutch-auction/api/all_events?sid=%{seasonId}&address=%{address}&page=%{pageNumber}&limit=%{pageSize}&r=%{r}';
    let api = apiHost + '/dutch-auction/api/buy_events?sid=%{seasonId}&address=%{address}&page=%{pageNumber}&limit=%{pageSize}&r=%{r}';
        api = subs(api, data);
    let res = await axios.get(api)
    res = res.data || {};
    let list = res.data || [];
    let total = res.totalCnt || 0;
    return { list, total };
}

const getClaimedReword = (data, callback) => {
    let { seasonId, address } = data;
    let api = apiHost + '/dutch-auction/api/applies?sid=' + seasonId + '&address=' + address + '&r=' + new Date().getTime();
    axios.get(api).then(function(res){
        res = res.data || {};
        res = res.data || [];
        res = res[0] || {};
        callback(res);
    });
}

const getDARewords = (season, callback) => {
    let api = apiHost + '/dutch-auction/api/applies?sid=' + season.seasonId + '&r=' + new Date().getTime();
    axios.get(api).then(function(res){
        res = res.data || {};
        res = res.data || [];
        callback(res);
    });
}

const getSeasonReword = async (season) => {
    let api = apiHost + '/dutch-auction/api/rewards?sid=' + season.seasonId + '&r=' + new Date().getTime();
    let res = await axios.get(api);
    res = res.data || {};
    res = res.data || [];
    res.sort(function(a, b){
        return a.unionReward/1 > b.unionReward/1 ? -1 : 1;
    });
    return res;
}

const getSeasonOwner = async(seasonId)=>{
    let api = apiHost + '/dutch-auction/api/lands?sid=' + seasonId + '&r=' + new Date().getTime();
    let res = await axios.get(api);
    res = res.data || {};
    res = res.data || [];

    let owner = {};
    res.forEach(function(item){
        if(item.posId === '0_0'){
            owner = item;
        }
    });
    return owner;
}

const getLandKLineData = async(data) => {
    data.r = new Date().getTime();
    let api = apiHost + '/dutch-auction/api/land_events?sid=%{seasonId}&x=%{x}&y=%{y}&r=%{r}';
        api = subs(api, data);
        // api = apiHost + '/dutch-auction/api/land_events?sid=test-zeta_main-2024-07-21-0-1&x=0&y=0';
    let res = await axios.get(api);
    res = res.data || {};
    res = res.data || [];
    return res;
}

export {
    getDASeasonList,
    getHistorySeasons,
    refreshSeasonInfo,
    getLands,
    getLandsDetail,
    getAllLands,
    getLandInfo,
    getLandKLineData,
    getDAEvents,
    getClaimedReword,
    getDARewords,
    getAllBuyLandEvents,
    getSeasonReword,
    getSeasonOwner
};