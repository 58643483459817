import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rewardConfig } from 'throne-underlying';

import { resourceRefresh } from '../../services/slice-city'
import { generalUpdated, getUpgradeGeneralCost } from '../../services/slice-general'

import { GoBack, CreateIcon, ClockCounter, NotifyPop, ToastTip } from '../../components'

import { Texts, Texts2 } from '../../const/';
import { logTrace } from '../../utils/log'
import { subs, timeFormat, dateFormat } from '../../utils/utils'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function GeneralLevelTo(props) {
    const dispatch = useDispatch(); 
    let { id, level, levelTo, levelMax } = props.data;

    const { resources } = useSelector(function(state){
        return state.city;
    });

    const { upgradeGeneralCost, generalUpdatedResult } = useSelector(function(state){
        return state.generals;
    });

    const maxTimes = levelMax - level;
    const [levelUped, setLevelUped] = useState(level);
    const [point, setPoint] = useState(1);
    function setSliderValueClick(value){
        value = Math.min(value, maxTimes);
        value = Math.max(value, 1);
        setPoint(value/1);
    }

    let silversNeed = upgradeGeneralCost[id + '-' + (point + level)] || 0;
    let silvers = (resources || {}).silver.value || 0;
    // console.log('upgradeGeneralCost', id + '-' + (point + level), generalUpdatedResult, upgradeGeneralCost, {levelTo, silversNeed, silvers});

    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    useEffect(()=>{
        dispatch(resourceRefresh());
        dispatch(getUpgradeGeneralCost({
            id, 
            level: level/1,
            levelTo: point + level/1
        }));
    }, []);

    useEffect(()=>{
        dispatch(getUpgradeGeneralCost({
            id, 
            level: level/1,
            levelTo: point + level/1
        }));
    }, [point, level]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'transparent' },
            content: {
                width: '580px',
                height: '350px',
                marginLeft: '-280px',
                marginTop: '-170px'
            }
        }}
        className = { 'App-game-modal-avatar-point App-game-modal-gray' }
        isOpen={ true }
        onRequestClose={ ()=>{ props.onClose && props.onClose({id, level: levelUped}); } }
        contentLabel="General LevelTo Modal">
        <div style={{ position: 'relative',height:'500px' }}>
            <div _ga_event="general.upgrade" onClick={ ()=>{ props.onClose && props.onClose({id, level: levelUped}); }}>
                <GoBack size="60px" />
            </div>
            <div className="App-game-modal-header" style={{ lineHeight: 3.4 }}> &nbsp; </div>

            <div className="App-policy-points-panel">
                <div style={{ color: '#FFF8CA' }}>{ Texts2['dialog.general.upgrade'] || 'Set the numbers of upgrade times' }</div>
                <div style={{ position: 'relative',margin: '30px auto 10px', paddingBottom: '5px', width:'220px' }}>
                    <div className="App-slider-minis" _ga_event="general.upgrade.slider.minis" onClick={()=>{ setSliderValueClick(point - 1) }}></div>
                    <input type="number" maxLength="3" min="1" value={ point } disabled={ point === 0 } onChange={ (e)=> { setSliderValueClick(e.target.value) }} />
                    <div className="App-slider-plus" _ga_event="general.upgrade.slider.plus" onClick={()=>{ setSliderValueClick(point + 1) }}></div>
                </div>
                <div style={{ margin: '-5px 0 10px', fontSize: '14px' }}>Max: { maxTimes }</div>
            </div>

            <div style={{ textAlign: 'center', width: '50%', margin: '0 auto' }}>
            {(point > maxTimes) 
            ? <div className="App-general-data-op"><CreateIcon type="empty" size="20" />  Max Level { levelMax }</div>
            : <div className="App-general-data-op">
                { Texts['dialog.skill_need'] }: <CreateIcon type="silver" size="20" /> 
                <span style={{ fontSize:'12px',zoom:0.8,margin: '0 10px' }}>x</span>
                { silversNeed }
            </div>}
            </div>
            
            <div className="App-point-btns" style={{ textAlign: 'center' }}>
                <div className="App-point-btn-item">
                {(silversNeed > silvers ) 
                ? (<span 
                    _ga_event="general.upgrade.disable"
                    onClick={ ()=>{
                        setNotifyPop(true);
                    } } 
                    className="App-general-btn App-general-btn-green App-btn-disable">
                    { Texts['button.upgrade'] } 
                </span>) : (<span className="App-general-btn App-general-btn-green"
                    _ga_event="general.upgrade.levelup"
                    onClick = { (e) => { 
                        setWaitingPop(true);
                        dispatch(generalUpdated({
                            id,
                            levelTo: point + level/1
                        }));
                    }}>
                    { Texts['button.upgrade'] } 
                </span>)}
                </div>
            </div>
        </div>

        { notifyPop && <ToastTip text={ 
            Texts2['dialog.general.upgrade_fail_reason'] 
        } callback={ ()=>{ setNotifyPop(false) } } />}

        { waitingPop && <NotifyPop tips={{
            loading: '',
            success: Texts2['dialog.order.buy_order_success'],
            fail: Texts2['dialog.order.buy_order_fail']
        }} data={ generalUpdatedResult } callback={ ()=>{
            setWaitingPop(false);
            dispatch(resourceRefresh());
            if(generalUpdatedResult.result){
                setLevelUped(point + level/1);
                setPoint(1);
            }
        }} />}
    </Modal>);
}


