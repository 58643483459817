import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as DASlices from '../../da/slice';

import NumericFormat from 'react-number-format';
import { GoBack, CreateIcon, Slider, ClockCounter, Loading, LoadingBtn } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import { Texts, Texts2, GameTexts } from '../../const';
import { showPriceData } from '../../utils/utils';
import { addEventLinseners } from '../../wallets/ethereum';

import RewardList from './reward-list';

addEventLinseners();

export default function MyRewards(props){
    const dispatch = useDispatch();
    const { seasons = [], onWalletSelect, address } = props; 

    const { seasonHis, gameStart, allRewards, opResults } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId } = gameStart;
    const seasonId = seasonInfo.sid;
    let { total = 0, rewards = []} = allRewards;
    console.log('getAllRewards', allRewards)

    const [ claimPop, setClaimPop ] = useState(false);

    useEffect(()=>{
        if(!address){
            return;
        }
        dispatch(DASlices.getAllRewards({
            seasonHis, 
            userInfo: { 
                address, 
                type: 'my-reward' 
            }
        }));
        dispatch(DASlices.getUnionInfo({
            address,
            seasonId
        }));
    }, [address, opResults, seasonHis]);

    useEffect(()=>{
        dispatch(DASlices.getHistorySeasons());
    }, []);

    return (<>
        <span className="App-reward-claim-area" style={{ margin: '0 10px 0 0' }} onClick={ ()=>{ 
            if(address){return}
            onWalletSelect && onWalletSelect();
            } }>
            <CreateIcon type="zeta" size="34" />
            <span className="App-reward-claim">
                <span className="reward-label">Reward</span>
                <span className="reward-total">
                    {
                        !address && '--'
                    }
                    {
                        (address && !allRewards.back)
                        && <span style={{ position: 'absolute' }}><LoadingBtn size="24" /></span>
                    }
                    {
                        (address && allRewards.back)
                        && <NumericFormat value={ showPriceData(total, 18, 4) } displayType="text" thousandSeparator="," />
                    }
                </span>
            </span> 
        </span>

        <a className="App-loading-btn App-menu-link">
            {address
            ?  <span 
                title =  { seasonId }
                className={"App-general-btn App-general-btn-green" + (total == 0 ? ' App-btn-disable' : '')} 
                onClick={ ()=>{
                    if(total == 0){
                        return;
                    }
                    setClaimPop(true);
                } }>
                Withdraw
            </span>
            : <span 
                className="App-general-btn App-general-btn-green App-btn-disable" 
                onClick={ ()=>{ onWalletSelect && onWalletSelect(); } }>
                Withdraw
            </span>}
        </a>

        {opResults.back && <ToastTip 
            text={ opResults.result ? 'withdraw successful.' : 'withdraw fail.' } 
            callback={ ()=>{ 
                dispatch(DASlices.resetResult()); 
            }
        } />}

        { claimPop && <RewardList address={ address } rewards={ rewards } onClose={ ()=>{ setClaimPop(false) } } /> }
    </>);
}