import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import { getTileInfo, getDefenseList } from '../../services/slice-map';
import { getInitBlockInfo } from '../../services/slice-map';
import { generalInit, getSkillInfo, getDefenseBlockGenerals } from '../../services/slice-general'

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, timeFormat } from '../../utils/utils'
import { unionImagePath } from '../../utils/ui-res'
import { getMapId, getMapConfig } from '../../maps/map-res'

import Texts from '../../const/texts.json';
import { logTrace } from '../../utils/log'

import ProtectWarning from '../battle/protect-warning'

import Modal from 'react-modal';

export default function Tile(props) {
    const dispatch = useDispatch(); 
    const x_id = 2;
    const y_id = 2;
    let { isProtect } = props;

    let tileData = {};

    const { currentSeasonId, seasonInfo } = useSelector(function(state){
        return state.user;
    });

    const mapId = getMapId(seasonInfo[currentSeasonId]);
    const mapConfig = getMapConfig(mapId);

    const { 
        mapReady,
        detailInit,
        defenseList
    } = useSelector(function(state){
        return state.map;
    });
    let detail = detailInit;
    let protect_time = 9*60*60;
    // console.log({ detail }, 'map.tile.1')

    const { defenseBlockGenerals } =  useSelector(function(state){
        return state.generals;
    });
    logTrace(defenseBlockGenerals, 'map.tile.defenseBlockGenerals')

    const keys = {
        name: 'dialog.map.block_' + detail.type + '_' + detail.parameter + '.name',
        buff: 'dialog.staff.skill.' + detail.buff_id
    }

    const { strategyReady, info } = useSelector(function(state){
        return state.strategies;
    });
    let protect = info.protect || {};
    let protecting = !!protect.able;

    useEffect(()=>{
        dispatch(getInitBlockInfo({
            x: x_id,
            y: y_id
        }));
        dispatch(getDefenseList({
            x: x_id,
            y: y_id
        }));

        let game = createTileMap(x_id, y_id, mapConfig);

        return ()=>{
            if(game){
                game.destroy();
            }
        }
    }, [mapReady]);

    return (<>
        <div className="App-game-modal-header">{ Texts[keys.name] || keys.name }({x_id},{y_id})</div>
        <div className="App-game-modal-content2">
        <div className="App-Resources-content">
            <div style={{ display: 'flex' }}>
                <div className="App-Resources-stat">
                    <div className="App-Resources-stat-list" style={{ width: '180px',overflow: 'hidden' }}>
                        <div className="App-Resources-stat-title">{ Texts['dialog.block_stats'] }</div>
                        <div className="App-Resources-stat-item">
                            <span style={{ position: 'absolute' }}><CreateIcon type="tile-status" size="32" /></span>
                            <dt>{ Texts['dialog.block_stats'] }</dt>
                            <dd>{ Texts['dialog.block_stats_neutral'] }</dd>
                        </div>
                        <div className="App-Resources-stat-item">
                            <span style={{ position: 'absolute' }}><CreateIcon type="tile-buff" size="32" /></span>
                            <dt>{ Texts['dialog.block_buff'] }</dt>
                            <dd>{ Texts[keys.buff] || keys.buff }</dd>
                        </div>
                        <div className="App-Resources-stat-item">
                            <span style={{ position: 'absolute' }}><CreateIcon type="tile-coins" size="32" /></span>
                            <dt>{ Texts['dialog.block_attack.mine_left'] }</dt>
                            <dd c="silver_total_number">{ numberFormat(detail.remainSilver) }</dd>
                        </div>
                        <div className="App-Resources-stat-item">
                            <span style={{ position: 'absolute' }}><CreateIcon type="tile-roseSpeed" size="32" /></span>
                            <dt>{ Texts['dialog.block_mining_speed'] }</dt>
                            <dd>{ numberFormat(detail.gather_silver_speed) }/h</dd>
                        </div>
                    </div>
                    <div className="App-Resources-tile" style={{ verticalAlign:'top', marginTop: '40px' }}>
                        <div id="App-single-tile" style={{ marginTop: '30px' }} />
                    </div>
                </div>
                
                <div className="App-Resources-troops">
                    <div className="App-Resources-stat-title">
                        { Texts['dialog.block_troops_count'] }: { detail.defense_list_len }
                    </div>
                    <div 
                        style={{ position: 'relative', float:'right', marginTop: '-30px', cursor: 'pointer' }}>
                        <CreateIcon type="info-tips" size="20" />
                        <span style={{ fontSize: '14px', marginLeft: '4px' }}>{ Texts['dialog.block_mining_introduce.name'] }</span>
                    </div>
                    <div className="App-game-table" style={{ clear: 'both', height: '200px', background: '#1e160c' }}><table>
                        <thead><tr>
                            <td>{ Texts['button.block_defense_force.name'] }</td>
                            <td className="right">{ Texts['button.block_defense_force.attack'] }</td>
                            <td className="right">{ Texts['button.block_defense_force.defense'] }</td>
                            <td className="right">{ Texts['button.block_defense_force.count'] }</td>
                        </tr></thead>
                        <tbody>
                        {defenseList.map(
                            (item, index) => (<tr key={ index }>
                                <td>{ Texts['dialog.general.card.name.' + item.generalId] || 'name.' + item.generalId }</td>
                                <td className="right">{ numberFormat(item.attack) }</td>
                                <td className="right">{ numberFormat(item.defense) }</td>
                                <td className="right">{ numberFormat(item.troops) }</td>
                            </tr>)
                        )}
                        </tbody>
                    </table></div>
                </div>
            </div>

            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <div className="App-Resources-lasting">
                    <span style={{ position: 'absolute', left: '-10px', top: '5px', zIndex: 99 }}>
                        <CreateIcon type="tile-lasting" size="40" />
                    </span>
                    <div className="App-Resources-lasting-title">
                        { Texts['dialog.block_attack.block_duration'] }
                    </div>
                    <ProgressBar data={{ 
                        text: numberFormat(detail.now_durability) + '/' + numberFormat(detail.durability), 
                        value: 100*detail.now_durability/detail.durability
                    }} type="dark" />
                    {isProtect && (<>
                        <div className="App-Resources-lasting-tips">
                            { Texts['dialog.block_attack.block_protect_time_left'] }: &nbsp;
                            <ClockCounter time={ protect_time } />
                        </div>
                    </>)}
                </div>

                {!isProtect ? (<span 
                    style={{ verticalAlign: 'top', marginTop: '15px' }}
                    className="App-general-btn App-general-btn-green">
                    { Texts['button.block_attack'] }
                    &nbsp; <span className="heart">❤</span>-1
                </span>) : (<><span 
                    style={{ verticalAlign: 'top', marginTop: '15px' }}
                    className="App-general-btn App-general-btn-blue">
                    { Texts['button.block_defense_force.defense'] }
                    &nbsp; <span className="heart">❤</span>-1
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span
                    style={{ verticalAlign: 'top', marginTop: '15px' }}
                    className="App-general-btn App-general-btn-blue">
                    Gather
                    &nbsp; <span className="heart">❤</span>-1
                </span>
                </>)}
            </div>
        </div>
        </div>
    </>);
}

function createTileMap(x_id, y_id, mapConfig){
    if(document.querySelector('#App-single-tile canvas')){
        return;
    }
    const config = {
        type: Phaser.AUTO,
        disableContextMenu: true,
        title: 'tile',
        parent: "App-single-tile",
        width: 128,
        height: 128,
        transparent: true,
        scene: {
            preload: function() {
                this.load.image('bgImages', mapConfig.bg.images);
                this.load.tilemapTiledJSON('bgConfig', mapConfig.bg.config);

                this.load.image('mapImages', mapConfig.map.images);
                this.load.tilemapTiledJSON('mapConfig', mapConfig.map.config);
            },
            create: function(){
                //draw maps
                let bgConfig = this.add.tilemap('bgConfig');
                let item = bgConfig.layers[0].data[y_id][x_id];
                    item.bottom = 116;
                    item.right = 204;
                    item.pixelX = 0;
                    item.pixelY = 0;
                    item.x = 0;
                    item.y = 0;
                    bgConfig.layers[0].width = 1;
                    bgConfig.layers[0].height = 1;
                    bgConfig.layers[0].data = [[ item ]];
                let tileset0 = bgConfig.addTilesetImage('bg', 'bgImages');
                let map0 = bgConfig.createLayer('map0', tileset0);

                let mapConfig = this.add.tilemap('mapConfig');
                let item2 = mapConfig.layers[0].data[y_id][x_id];
                    item2.bottom = 116;
                    item2.right = 204;
                    item2.pixelX = 0;
                    item2.pixelY = 0;
                    item2.x = 0;
                    item2.y = 0;
                    mapConfig.layers[0].width = 1;
                    mapConfig.layers[0].height = 1;
                    mapConfig.layers[0].data = [[ item2 ]];
                let tileset1 = mapConfig.addTilesetImage('map', 'mapImages');
                let map1 = mapConfig.createLayer('map1', tileset1);
                // map1.putTilesAt(tileData.target, 0, 0);       
            }
        }
    }
    return new Phaser.Game(config);
}