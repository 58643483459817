import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';

import { getBattleStatuses } from '../../services/slice-general'
import { getAllPlayers } from '../../services/slice-user';

import { numberFormat, dayTimeFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import { CreateIcon } from '../../components'
import UserScoreBuffsInfo from '../buffs/vip-buff-info'

import { generalImagePath, unionImagePath, getNickName, shortString } from '../../utils/ui-res'

import Texts from '../../const/texts.json';
import './player-pop.scss';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function PlayerPop(props){
    const dispatch = useDispatch();
    const { userInfo } = props;

    const { allPlayers } = useSelector(function(state){
        return state.user;
    });
    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    // console.log(currentUser);
    const { userAvatar } = useSelector(function(state){
        return state.generals;
    });

    const { battleStatuses } = useSelector(function(state){
        return state.generals;
    });
    const [userPop, setUserPop] = useState(true);
    const [copied, setCopied] = useState(false);

    const { sign } = useSelector(function(state){
        return state.user;
    });
    const currentSeasonId = sign.seasonId;
    useEffect(()=>{
        dispatch(getAllPlayers({
            seasonId: currentSeasonId
        }))
    }, [currentSeasonId]);

    const [battleStatus, setBattleStatus] = useState({});

    useEffect(()=>{
        setBattleStatus(battleStatuses[0] || {});
    }, [battleStatuses]);

    useEffect(()=>{
        dispatch(getBattleStatuses(userInfo));
    }, []);

    // console.log(battleStatus, userInfo);

    if(window.LeagueOfThronesConfig.isMobile){
        return (
            <Modal
                style = {{
                    overlay: { 
                        backgroundColor: 'transparent'
                    },
                    content: {
                        width: '420px',
                        height: '680px',
                        marginTop: '-320px',
                        marginLeft: '-200px'
                    }
                }}
                className = { 'App-game-modal-playerpop App-game-modal-gray-headless' }
                isOpen={ userPop }
                onRequestClose={ ()=>props.onClose() }
                contentLabel="Battles Status">
                <div style={{ position: 'relative' }}>
                    <span 
                        _ga_event="player.pop.close"
                        onClick={ ()=>props.onClose() }
                        style={{
                            position: 'absolute',
                            right: '-40px',
                            top: '-30px',
                            cursor: 'pointer'
                        }}>
                        <CreateIcon type="back" size="64" />
                    </span>

                    <div className="App-game-modal-playerpop-part">
                        <div className="App-user-status-basic">
                            <span className="App-general-item-avatar">
                                <img src={ generalImagePath({
                                    generalId: userInfo.username.toLowerCase() === currentUser.username ? userAvatar : userInfo.iconId
                                }) } />
                            </span>
                            <img className="union" src={ unionImagePath(userInfo.unionId) } title={ userInfo.username }/>
                            <h2>{ getNickName(allPlayers, userInfo.username) }</h2>
                        </div>
                        <div className="App-user-status-info">
                            <div className="App-user-status-detail">
                                <label>Castle Level</label><span>{ battleStatus.fortressLevel }</span>
                            </div>
                            <div className="App-user-status-detail">
                                <label>Glory</label><span>{ numberFormat(battleStatus.glory) }</span>
                            </div>
                        </div>
                        <div className="App-user-status-wallet">
                            <h2>Wallet Address</h2>
                            <div className="chain-content-wallet">
                                <div className="address">{ shortString(userInfo.username, 15) }</div>
                                {copied && (<span className="copied">Copied!</span>)}
                                <a className="chain-copy" _ga_event="player.pop.copy" onClick={ ()=>{
                                    copy(userInfo.username, {
                                        onCopy: function(){
                                            setCopied(true);
                                            setTimeout(function(){
                                                setCopied(false);   
                                            }, 2000);
                                        }
                                    });
                                }}>copy</a>
                            </div>
                        </div>
                    </div>

                    <div className="App-game-modal-playerpop-part">
                        <div className="App-user-status-info App-world-record-bottom">
                            <div className="App-mertrial-item">
                                <CreateIcon type="silver" size="34" /> 
                                <span className="App-mertrial-item-value">
                                    { numberFormat(battleStatus.silver) }
                                </span>
                            </div>
                            <div className="App-mertrial-item">
                                <CreateIcon type="troop" size="34" /> 
                                <span className="App-mertrial-item-value">
                                    { numberFormat(battleStatus.troop) }
                                </span>
                            </div>
                        </div>

                        <div className="App-user-status-buff">
                            <UserScoreBuffsInfo address={ currentUser.username } />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Modal
            style = {{
                overlay: { 
                    backgroundColor: 'transparent'
                },
                content: {
                    width: '420px',
                    height: '680px',
                    marginTop: '-320px',
                    marginLeft: '-200px'
                }
            }}
            className = { 'App-game-modal-playerpop App-game-modal-gray-headless' }
            isOpen={ userPop }
            onRequestClose={ ()=>props.onClose() }
            contentLabel="Battles Status">
            <div style={{ position: 'relative' }}>
            <span 
                _ga_event="player.pop.close"
                onClick={ ()=>props.onClose() }
                style={{
                    display: 'none',
                    position: 'absolute',
                    right: '-50px',
                    top: '-60px',
                    cursor: 'pointer'
                }}>
                <CreateIcon type="back" size="64" />
            </span>
            <div className="App-user-status-basic">
                <span className="App-general-item-avatar">
                    <img src={ generalImagePath({
                        generalId: userInfo.username.toLowerCase() === currentUser.username ? userAvatar : userInfo.iconId
                    }) } />
                </span>
                <img className="union" src={ unionImagePath(userInfo.unionId) } title={ userInfo.username }/>
                <h2>{ getNickName(allPlayers, userInfo.username) }</h2>
            </div>

            <div className="App-user-status-buff">
                <UserScoreBuffsInfo address={ currentUser.username } />
            </div>

            <div className="App-user-status-info">
                <div className="App-user-status-detail">
                    <label>Castle Level</label><span>{ battleStatus.fortressLevel }</span>
                </div>
                <div className="App-user-status-detail">
                    <label>Glory</label><span>{ numberFormat(battleStatus.glory) }</span>
                </div>
            </div>
            <div className="App-user-status-wallet">
                <h2>Wallet Address</h2>
                <div className="chain-content-wallet">
                    <div className="address">{ userInfo.username }</div>
                    {copied && (<span className="copied">Copied!</span>)}
                    <a className="chain-copy" _ga_event="player.pop.copy" onClick={ ()=>{
                        copy(userInfo.username, {
                            onCopy: function(){
                                setCopied(true);
                                setTimeout(function(){
                                    setCopied(false);   
                                }, 2000);
                            }
                        });
                    }}>copy</a>
                </div>
            </div>
            </div>
        </Modal>
    );
}













