import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import { resourceRefresh } from '../../services/slice-city'
import { pointUpdate } from '../../services/slice-strategies'
import { mapInit, miningSilvers, resetMiningResult } from '../../services/slice-map';

import SelectGeneral from '../generals/generals-selector'
import BattleResult from '../battle/battle-result'
import { ActionResultPop, ClockCounter, Loading, ToastTip } from '../../components'

import { timeFormat, numberFormat, getStamina } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import Texts from '../../const/texts.json';
import Texts2 from '../../const/texts2.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SilverMining(props){
    const dispatch = useDispatch(); //must in component function

    const [isMining, setIsMining] = useState(0);

    const { mapReady, miningResult } = useSelector(function(state){
        return state.map;
    });

    const { strategyReady, pointInfo } = useSelector(function(state){
        return state.strategies;
    });

    const { tileData } = props;
    const xyIndex = (tileData.xyIndex || '').split('^');
    const xId = xyIndex[0]/1;
    const yId = xyIndex[1]/1;

    // console.log('miningResult', { miningResult , tileData })

    useEffect(() => {
        dispatch(pointUpdate());
    }, [ strategyReady ]); 

    useEffect(() => {
        // console.log('miningResult', { miningResult , tileData })
        dispatch(resourceRefresh());
        props.afterMining && props.afterMining();
    }, [ miningResult ]); 

    return (<>  
        <span
            _ga_event="block.mining"
            onClick={ ()=>{ setIsMining(1)  } }
            style={{ verticalAlign: 'top', marginTop: '15px' }}
            className="App-general-btn App-general-btn-blue">
            Gather
            &nbsp; <span className="heart">❤</span>-{ getStamina('gather') }
        </span>

        {isMining === 1 && <SelectGeneral battleType="miningSilvers"
            onClose={ ()=>{ setIsMining(0) } }
            onSelected={ (res)=>{
                let { times, general, flag } = res;
                if(flag){
                    dispatch(miningSilvers({
                        xId: xId, //tileData.x,
                        yId: yId, //tileData.y,
                        generalId: general.id,
                        times
                    }));
                    setIsMining(2);
                }
            } } 
        />}

        { (isMining === 2 && !miningResult.back) && <Loading type="headless" /> }
        { (isMining === 2 && miningResult.back) && <ActionResultPop 
            info={{
                result: miningResult.result,
                label: miningResult.result ? 'silvers:' : '',
                text: miningResult.result ? numberFormat(miningResult.getSilver) :  Texts2['dialog.gather.gather_error ']
            }} 
            overConfirm={ ()=>{ 
                setIsMining(0); 
                dispatch(resetMiningResult());
            } 
        } /> }
    </>)
}