import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCodList } from '../../services/slice-general'

import { unionImagePath, generalImagePath, getNickName } from '../../utils/ui-res'

import { CreateIcon, GoBack } from '../../components'
import * as cache from '../../utils/cache'

import { Texts, Texts2, Chains, CodTexts } from '../../const'
import { numberFormat, getStamina } from '../../utils/utils'
import { logTrace } from '../../utils/log'

export default function CodMessage(props) {
    let dispatch = useDispatch();
    let { codId, detail, sentTime } = props;
    let blockInfo = detail.detail || {};

    const keys = {
        name: 'dialog.map.block_' + blockInfo.type + '_' + (blockInfo.parameter || 0) + '.name',
        buff: 'dialog.staff.skill.' + blockInfo.buff_id
    }

    const { currentUser } = useSelector(function(state){
        return state.app;
    });
    const { allPlayers } = useSelector(function(state){
        return state.user;
    });
    const { codsAll, codGeneralIds, codResult } = useSelector(function(state){
        return state.generals;
    });
    let currentCod = codsAll[codId] || {};
    let isCodCreated = !!currentCod['creator'];

    const remainTime = currentCod.createTime + currentCod.lastTime - Math.ceil(new Date().getTime()/1000);
    const isExperied = sentTime <= currentCod.createTime;

    useEffect(()=>{
        dispatch(getCodList());
    }, []);

    if(!isCodCreated || isExperied){
        return (<>{ CodTexts['dialog.assembly_over'] }</>);
    }

    return (<>
        <div className="App-cod-msg-avatar">
            <span className="App-general-item-avatar">
                <img src={ generalImagePath({generalId: currentCod.generalId}) } />
            </span>
            <img className="union" src={ unionImagePath(currentCod.unionId) } title={ currentCod.creator }/>
        </div>

        <div className="App-cod-msg-name">
            <div className="App-chat-user-name">
                { getNickName(allPlayers, currentCod.creator) }
            </div>

            <div className="App-cod-msg-troops">
                <label>{ CodTexts['dialog.assembly_troops'] }: </label>
                <span>{ currentCod.troopNow }/{ currentCod.troopTotal }</span>
            </div>
        </div>


        <div className="App-cod-msg-attack">
            <label>{ CodTexts['dialog.assembly_attack'] }: </label>
            <span className="block-share"></span>
            <span className="blockinfo">
                { Texts[keys.name] || keys.name } ( {blockInfo.x_id},{blockInfo.y_id} )
            </span>
        </div>

        <div className="App-cod-msg-remain">
            <label>{ CodTexts['dialog.assembly_remain'] }: </label>
            <span>{ Math.max(remainTime, 0) } { CodTexts['dialog.assembly_remain.unit'] }</span>
        </div>

        <div className="App-cod-msg-btns">
            <span
                className={ "App-general-btn App-general-btn-green" }
                _ga_event="chat.assembly.block"
                onClick = { (e) => {
                    if(!window.game){
                        return;
                    }
                    window.game.events.emit("TileClick", {
                        pop: true,
                        data: {
                            x: blockInfo.x_id,
                            y: blockInfo.y_id,
                            isBlock: false,
                            xyIndex: blockInfo.x_id + '^' + blockInfo.y_id,
                            target: null
                        }
                    });
                    props.onClose && props.onClose();
                }}>
                { CodTexts['button.assembly'] }
            </span>
        </div>
    </>);
}














