import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SeasonList from './season-list';
import { isProd } from '../../utils/ui-res'

import banner from './assets/banner.png'

export default function SeasonAll(props){
    const dispatch = useDispatch();
    const { version } = useSelector(function(state){
        return state.app;
    });

	return(
        <div className="App-loaded">
            <div className="App-loading-header">
                <img src={ banner } onMouseOver={ ()=>{
                    setTimeout(()=>{
                        if(isProd){
                            document.title += ' ' + window.LeagueOfThronesConfig.version + ' ' + version;
                        }
                    }, 5000);
                } } />
            </div>

            <SeasonList/ >
        </div>
    );
}