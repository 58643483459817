import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resourceRefresh } from '../../services/slice-city'
import { 
    getMorale
} from '../../services/slice-general'

import { GoBack } from '../../components';
import MoraleDetail from './morale-detail';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function MoraleBtn(props){
    const dispatch = useDispatch(); 
    const [moralePop, setMoralePop] = useState(false);

    const { coins } = props;
    const { generalReady, morale } = useSelector(function(state){
        return state.generals;
    });

    let recoverInfo = morale.recoverInfo || {};
    let canUpgradeBySilver = coins.silver >= recoverInfo.silverUse;
    let canUpgradeByGolds = coins.golds >= recoverInfo.goldUse;
    let lowMorale = morale.value < 100;

    useEffect(()=>{
        dispatch(getMorale());
        dispatch(resourceRefresh());
    }, [generalReady]);

    return (<>
        <div className="App-preview-map-btn App-morale-map-btn"
            _ga_event={ "morale.open" }  onClick={()=>setMoralePop(true)}>
            {(lowMorale && (canUpgradeByGolds || canUpgradeBySilver)) && 
                (<span className="App-redpoint" style={{ margin: '-73px 0 0 63px' }}>new</span>)}
            <span className="text">{ 'MORALE' }</span>
        </div>
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '800px',
                    height: '450px',
                    margin: '-200px 0 0 -400px'
                }
            }}
            className = { 'App-game-modal-morale App-game-modal-gray' }
            isOpen={ moralePop }
            onRequestClose = { () => setMoralePop(false) }
            contentLabel="MORALE Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event={ "morale.close" } onClick={ ()=>{ setMoralePop(false) } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ padding: '0 10px' }}>MORALE</div>
                <MoraleDetail coins={ coins } close={()=>setMoralePop(false)} />
            </div>
        </Modal>
    </>);
}