import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import * as echarts from '../../da/echarts-5.5.1';
// import * as echarts from 'echarts';
import * as DASlices from '../../da/slice';
import { numberFormat, timeFormat, dateFormat, subs, showPriceData } from '../../utils/utils'
import { shortHash } from '../../utils/ui-res'
import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { zetaTransactionLink } from '../../const'

export default function PurchaseHistory(props){
    const dispatch = useDispatch(); 
    const { kLineData } = props;

    useEffect(()=>{
    }, []);

    return (<div className="App-acution-purchase-history">
        <h2>Purchase History</h2>
        <div className="App-acution-purchase-list">
            {kLineData.map((item, index)=>
                <div className="App-acution-purchase-item" key={ index }>
                    <div className="App-acution-purchase-time">{ dateFormat(item['timestamp'], 'MM-dd hh:mm:ss') }</div>
                    <div className="App-acution-purchase-owner">
                        <CreateIcon type={ (item['args'] || {})['unoinId'] || 'usdt' } size="20" />
                        { ' ' + shortHash((item['args'] || {})['owner'] || '', 4) }
                    </div>
                    <div className="App-acution-purchase-price">
                        <CreateIcon type="zeta" size="20" />
                        { showPriceData((item['args'] || {})['currentPrice'], 18, 4) }
                    </div>
                    <a className="App-acution-purchase-link" style={{ zoom: '0.95' }} target="_blank" href={ zetaTransactionLink + item['hash'] }>{ item['hash'] }</a>
                </div>
            )}
        </div>
    </div>);
}
